import {
	IInitializeCartSelectionStoreItemPayload,
	ISetCartRecommendationsPayload,
	ISetCartSelectionItemDatePayload,
	ISetCartSelectionItemPayload,
	ISetCartSelectionItemPeoplePayload,
	ISetCartSelectionItemTimePayload,
	ISetCartSelectionItemTourIdPayload,
	ISetCartSelectionItemVariantPayload,
} from 'Utils/crossSell/types';

export enum CartActions {
	SET_CART_RECOMMENDATIONS_DATA = 'SET_CART_RECOMMENDATIONS_DATA',
	SET_CART_SELECTION_ITEM = 'SET_CART_SELECTION_ITEM',
	SET_CART_ITEM_INITIALIZATION = 'SET_CART_ITEM_INITIALIZATION',
	SET_CART_ITEM_BOOKING_DATE = 'SET_CART_ITEM_BOOKING_DATE',
	SET_CART_ITEM_BOOKING_TIME = 'SET_CART_ITEM_BOOKING_TIME',
	SET_CART_ITEM_BOOKING_PEOPLE = 'SET_CART_ITEM_BOOKING_PEOPLE',
	SET_CART_ITEM_VARIANT_ID = 'SET_CART_ITEM_VARIANT_ID',
	SET_CART_ITEM_LANGUAGE = 'SET_CART_ITEM_LANGUAGE',
	SET_CART_ITEM_TOUR_ID = 'SET_CART_ITEM_TOUR_ID',
}

/**
 * Sets the cart recommendations data.
 *
 * @param {ISetCartRecommendationsPayload} - The payload containing cart recommendations.
 * @returns {object} The action object with type and payload.
 */
export const setCartRecommendationsData = ({
	cartRecommendations,
}: ISetCartRecommendationsPayload): {
	type: CartActions.SET_CART_RECOMMENDATIONS_DATA;
	payload: ISetCartRecommendationsPayload;
} => {
	return {
		payload: { cartRecommendations },
		type: CartActions.SET_CART_RECOMMENDATIONS_DATA,
	};
};

/**
 * Creates a TGID entry in the cart store
 *
 * @param {IInitializeCartSelectionStoreItemPayload} - The payload containing the ID of the item to initialize.
 * @returns {object} The action object with type and payload.
 */
export const initializeCartSelectionStoreItem = ({
	id,
}: IInitializeCartSelectionStoreItemPayload): {
	type: CartActions.SET_CART_ITEM_INITIALIZATION;
	payload: IInitializeCartSelectionStoreItemPayload;
} => {
	return {
		payload: {
			id,
		},
		type: CartActions.SET_CART_ITEM_INITIALIZATION,
	};
};

export const setCartSelectionItem = (
	selectionParams: ISetCartSelectionItemPayload,
): {
	type: CartActions.SET_CART_SELECTION_ITEM;
	payload: ISetCartSelectionItemPayload;
} => {
	const {
		id,
		selectedDate,
		selectedTime,
		selectionMap,
		selectedVariantId,
		userFields,
		language,
		instantCheckout,
		isDefaultDate,
		isDefaultTour,
		isDefaultVariant,
		groupSize,
		selectedTourId,
	} = selectionParams;

	return {
		payload: {
			id,
			selectedDate,
			selectedTime,
			selectionMap,
			selectedVariantId,
			userFields,
			language,
			instantCheckout,
			isDefaultDate,
			isDefaultTour,
			isDefaultVariant,
			groupSize,
			selectedTourId,
		},
		type: CartActions.SET_CART_SELECTION_ITEM,
	};
};

export const setCartSelectionItemDate = ({
	id,
	date,
	defaultSelection,
}: ISetCartSelectionItemDatePayload): {
	type: CartActions.SET_CART_ITEM_BOOKING_DATE;
	payload: ISetCartSelectionItemDatePayload;
} => {
	return {
		payload: {
			id,
			date,
			defaultSelection,
		},
		type: CartActions.SET_CART_ITEM_BOOKING_DATE,
	};
};

export const setCartSelectionItemTime = ({
	id,
	time,
	defaultSelection,
}: ISetCartSelectionItemTimePayload): {
	type: CartActions.SET_CART_ITEM_BOOKING_TIME;
	payload: ISetCartSelectionItemTimePayload;
} => {
	return {
		payload: {
			id,
			time,
			defaultSelection,
		},
		type: CartActions.SET_CART_ITEM_BOOKING_TIME,
	};
};

export const setCartSelectionItemVariantId = ({
	id,
	variantId,
	defaultSelection,
}: ISetCartSelectionItemVariantPayload): {
	type: CartActions.SET_CART_ITEM_VARIANT_ID;
	payload: ISetCartSelectionItemVariantPayload;
} => {
	return {
		payload: {
			id,
			variantId,
			defaultSelection,
		},
		type: CartActions.SET_CART_ITEM_VARIANT_ID,
	};
};

export const setCartSelectionItemPax = ({
	selectionMap,
	groupSize,
	id,
	pricing,
}: ISetCartSelectionItemPeoplePayload): {
	type: CartActions.SET_CART_ITEM_BOOKING_PEOPLE;
	payload: ISetCartSelectionItemPeoplePayload;
} => {
	return {
		payload: {
			id,
			pricing,
			selectionMap,
			groupSize,
		},
		type: CartActions.SET_CART_ITEM_BOOKING_PEOPLE,
	};
};

export const setCartItemTourId = ({
	id,
	tourId,
	defaultSelection,
}: ISetCartSelectionItemTourIdPayload): {
	type: CartActions.SET_CART_ITEM_TOUR_ID;
	payload: ISetCartSelectionItemTourIdPayload;
} => {
	return {
		payload: {
			id,
			tourId,
			defaultSelection,
		},
		type: CartActions.SET_CART_ITEM_TOUR_ID,
	};
};
