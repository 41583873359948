import { FIELD_NAME } from 'Constants/constants';

export type customUserFieldType = {
	id: string;
	tourId: number;
	vendorId: number | null;
	userFieldId: number;
	name: string;
	title: string;
	description: string;
	type: string;
	datatype: string;
	level: string;
	required: boolean;
	language: string;
	errorMessage?: string;
	maxLength?: number;
	maxValue?: number;
	minLength?: number;
	minValue?: number;
	displayName?: string;
	userFieldOptions: Record<string, any>[];
};

export type customUserFieldModelType = {
	id: string;
	errorMessage?: string;
	required: boolean;
	tourId: number;
	vendorId: number | null;
	maxLength?: number;
	maxValue?: number;
	minLength?: number;
	minValue?: number;
	value: string;
	level: string;
	name: string;
	userFieldOptions: Record<string, any>[];
	dataType: string;
	displayName?: string;
	description: string;
	type: string;
	fieldID: number;
	customFieldType?: string;
};

export const createUserFieldModel = (
	field: customUserFieldType,
	selectedVendorId: number,
): customUserFieldModelType => ({
	id: field.id,
	errorMessage: field.errorMessage,
	required: field.required,
	tourId: field.tourId,
	vendorId: field.vendorId ?? selectedVendorId,
	maxLength: field.maxLength,
	maxValue: field.maxValue,
	minLength: field.minLength,
	minValue: field.minValue,
	value: '',
	level: field.level,
	name: field.type ?? field.name,
	userFieldOptions: field.userFieldOptions,
	dataType: field.datatype,
	displayName: field.title,
	description: field.description,
	type: field.type,
	fieldID: field.userFieldId,
	...(field.type === FIELD_NAME.CUSTOM
		? { customFieldType: field.datatype }
		: {}),
});
