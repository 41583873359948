import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';

import { isBookingFlowPage } from 'Utils/bookingFlowUtils';
import { deseparatelyGetDomEl } from 'Utils/gen';
import PlatformUtils from 'Utils/platformUtils';
import {
	doesCurrentRouteIncludeString,
	getLocationObjectFromRouter,
	getSourceFromUrl,
	isExperiencePageUrl,
	isHomePageUrl,
} from 'Utils/urlUtils';

import {
	EXPERIENCE_PAGE_CONTENT_START_ID,
	HOME_PAGE_CONTENT_START_ID,
} from 'Constants/constants';

const DesktopHeader = dynamic(() => import('Containers/desktop/header'));
const StickyHeaderFooter = dynamic(
	() =>
		import(
			/* webpackChunkName: 'StickyHeaderFooter' */ 'Containers/mobile/stickyHeaderFooter'
		),
);

const shouldShowStickyHeaderFooter = (location: any) =>
	getSourceFromUrl(location) !== 'ios_app' &&
	getSourceFromUrl(location) !== 'android_app' &&
	!doesCurrentRouteIncludeString('/profile/booking', location);

const shouldHideDesktopHeader = ({ currentPage, location }: any) =>
	(isBookingFlowPage({ currentPage, location }) &&
		!doesCurrentRouteIncludeString('/confirmation', location) &&
		!doesCurrentRouteIncludeString('/choose-alternative', location)) ||
	doesCurrentRouteIncludeString('/book/', location) ||
	doesCurrentRouteIncludeString('post-covid', location) ||
	doesCurrentRouteIncludeString(
		'global-travel-reopening-tracker',
		location,
	) ||
	doesCurrentRouteIncludeString('supply-partner', location) ||
	doesCurrentRouteIncludeString('/payment/payment-proof', location);

const getOptions = (isDesktop: boolean) => ({
	rootMargin: isDesktop ? '-130px 0px 0px 0px' : '-30px 0px 0px 0px',
});

const CommonHeader = (props: any) => {
	const {
		router: { query, pathname, asPath },
		currentPage,
	} = props;
	const location = getLocationObjectFromRouter(query, pathname, asPath);
	const { lang = 'en' } = query;
	const isHomePage = isHomePageUrl(location.pathname);
	const isExperiencePage = isExperiencePageUrl(location.pathname);
	const [transparentBar, setTransparentBar] = useState(
		isHomePage || isExperiencePage,
	);

	useEffect(() => {
		if (!isHomePage && !isExperiencePage) return;

		const observer = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				setTransparentBar(true);
			} else {
				const eleY = entry.boundingClientRect.y;
				const windowHeight = window.innerHeight;
				setTransparentBar(eleY > windowHeight);
			}
		}, getOptions(PlatformUtils.isDesktop()));

		const contentID = isHomePage
			? HOME_PAGE_CONTENT_START_ID
			: EXPERIENCE_PAGE_CONTENT_START_ID;

		deseparatelyGetDomEl('#' + contentID, 5, 500)
			.then(domEl => {
				observer.observe(domEl as Element);
			})
			// eslint-disable-next-line no-console
			.catch(e => console.error(e));

		return () => {
			observer.disconnect();
		};
	}, [isHomePage, isExperiencePage]);

	const desktopHeader = !shouldHideDesktopHeader({
		currentPage,
		location,
	}) && (
		<DesktopHeader
			key='desktop-header'
			location={location}
			paramLang={lang}
			transparentBar={transparentBar}
		/>
	);

	const mobileHeader = (
		<StickyHeaderFooter
			key='mobile-header'
			show={shouldShowStickyHeaderFooter(location)}
			// @ts-expect-error TS(2322): Type '{ key: string; show: boolean; location: { qu... Remove this comment to see the full error message
			location={location}
			transparentBar={transparentBar}
		/>
	);

	const isDesktop = PlatformUtils.isDesktop();

	return <>{isDesktop ? desktopHeader : mobileHeader}</>;
};

export default withRouter(CommonHeader);
