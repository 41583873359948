import styled from 'styled-components';

export const LogoWrapper = styled.div`
	display: flex;
	column-gap: 0.625rem;
	align-items: center;

	picture {
		display: flex;
		width: max-content;
	}
	img {
		width: 9.5625rem;
		&.whitewash {
			filter: brightness(0) invert(1) !important;
		}
	}

	.whitelabel-logo:not(.with-powered-by) {
		width: 9.5625rem;
	}

	.whitelabel-logo.with-powered-by {
		height: 2rem;
		width: auto;
	}

	.powered-by-headout {
		height: 2rem;
		width: auto;
	}
`;
