import { TItineraryActionParam } from 'ReduxTypes/itinerary';

export enum ItineraryActions {
	REQUEST_ITINERARY_DATA = 'REQUEST_ITINERARY_DATA',
	RECEIVE_ITINERARY_DATA = 'RECEIVE_ITINERARY_DATA',
}

export const requestItinerary = ({
	id,
}: Omit<TItineraryActionParam, 'response'>): {
	payload: Pick<TItineraryActionParam, 'id'>;
	type: ItineraryActions.REQUEST_ITINERARY_DATA;
} => ({
	payload: { id },
	type: ItineraryActions.REQUEST_ITINERARY_DATA,
});

export const receiveItineraryData = ({
	id,
	response,
}: TItineraryActionParam): {
	payload: TItineraryActionParam;
	type: ItineraryActions.RECEIVE_ITINERARY_DATA;
} => ({
	payload: { id, response },
	type: ItineraryActions.RECEIVE_ITINERARY_DATA,
});
