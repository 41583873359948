import { ActionTypes } from 'Actions/actions';

export const orderList = (state = {}, action: any) => {
	switch (action.type) {
		case ActionTypes.FETCHED_ORDER_LIST:
			const { orderList } = action.payload;
			return {
				...state,
				...(orderList || {}),
			};
		default:
			return state;
	}
};
