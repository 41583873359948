import * as React from 'react';

export const InfoIcon = (props: any) => (
	<svg
		width='16'
		height='16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<g
			clipPath='url(#a)'
			stroke='#A46E00'
			strokeLinecap='round'
			strokeLinejoin='round'
		>
			<path d='M8 1.333a6.667 6.667 0 1 0 0 13.334A6.667 6.667 0 0 0 8 1.333ZM8 10.667V8M8 5.333h-.007' />
		</g>
		<defs>
			<clipPath id='a'>
				<path fill='#fff' d='M0 0h16v16H0z' />
			</clipPath>
		</defs>
	</svg>
);
