import { ActionTypes } from 'Actions/actions';

export const membershipReducer = (state = {}, action: any) => {
	switch (action.type) {
		case ActionTypes.SET_MEMBERSHIP_DATA: {
			const { membershipData } = action.payload;
			return {
				...state,
				membershipDetails: membershipData,
			};
		}
		case ActionTypes.SET_MEMBERSHIP_PROFILE_VIEWED: {
			const { isMembershipProfileViewed } = action.payload;
			return {
				...state,
				isMembershipProfileViewed,
			};
		}
		default:
			return state;
	}
};
