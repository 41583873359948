import * as React from 'react';

export const ApplePaySvg = (props: any) => (
	<svg viewBox='0 0 27 16' xmlns='http://www.w3.org/2000/svg' {...props}>
		<g clipPath='url(#clip20)'>
			<path
				d='M24.3333 0H3.25718C3.16413 0 3.08659 0 2.99354 0C2.91599 0 2.83845 0 2.77642 0C2.62133 0 2.45074 0.0150943 2.29565 0.045283C2.14056 0.0754717 1.98548 0.120755 1.83039 0.196226C1.69082 0.256604 1.55124 0.362264 1.44268 0.467925C1.33412 0.573585 1.22556 0.709434 1.16353 0.845283C1.08598 0.981132 1.03946 1.13208 1.00844 1.29811C0.977423 1.44906 0.977423 1.61509 0.961914 1.76604C0.961914 1.84151 0.961914 1.91698 0.961914 1.97736C0.961914 2.06792 0.961914 2.1434 0.961914 2.23396V13.7509C0.961914 13.8415 0.961914 13.917 0.961914 14.0075C0.961914 14.083 0.961914 14.1585 0.961914 14.2189C0.961914 14.3698 0.977423 14.5358 1.00844 14.6868C1.03946 14.8377 1.08598 14.9887 1.16353 15.1396C1.24107 15.2755 1.33412 15.4113 1.44268 15.517C1.55124 15.6226 1.69082 15.7283 1.83039 15.7887C1.96997 15.8642 2.12506 15.9094 2.29565 15.9396C2.45074 15.9698 2.62133 15.9698 2.77642 15.9849C2.85396 15.9849 2.9315 15.9849 2.99354 15.9849C3.08659 15.9849 3.16413 15.9849 3.25718 15.9849H24.3333C24.4264 15.9849 24.5039 15.9849 24.597 15.9849C24.6745 15.9849 24.752 15.9849 24.8141 15.9849C24.9692 15.9849 25.1398 15.9698 25.2948 15.9396C25.4499 15.9094 25.605 15.8642 25.7601 15.7887C25.8997 15.7132 26.0393 15.6226 26.1478 15.517C26.2564 15.4113 26.3649 15.2755 26.427 15.1396C26.5045 15.0038 26.551 14.8528 26.5821 14.6868C26.6131 14.5358 26.6131 14.3698 26.6286 14.2189C26.6286 14.1434 26.6286 14.0679 26.6286 14.0075C26.6286 13.917 26.6286 13.8415 26.6286 13.7509V2.23396C26.6286 2.1434 26.6286 2.06792 26.6286 1.97736C26.6286 1.90189 26.6286 1.82642 26.6286 1.76604C26.6286 1.61509 26.6131 1.44906 26.5821 1.29811C26.551 1.14717 26.5045 0.996226 26.427 0.845283C26.3494 0.709434 26.2564 0.573585 26.1478 0.467925C26.0393 0.362264 25.8997 0.256604 25.7601 0.196226C25.6205 0.120755 25.4654 0.0754717 25.2948 0.045283C25.1398 0.0150943 24.9692 0.0150943 24.8141 0C24.7365 0 24.659 0 24.597 0C24.5039 0 24.4264 0 24.3333 0Z'
				fill='black'
			/>
			<path
				d='M24.3335 0.528265H24.5971C24.6747 0.528265 24.7367 0.528265 24.8143 0.528265C24.9383 0.528265 25.0779 0.543359 25.2175 0.558454C25.3415 0.573548 25.4346 0.603737 25.5276 0.64902C25.6207 0.694303 25.6982 0.75468 25.7758 0.830152C25.8533 0.905623 25.9154 0.981095 25.9619 1.07166C26.0084 1.16223 26.0394 1.25279 26.0549 1.37355C26.086 1.5094 26.086 1.64525 26.086 1.766C26.086 1.82638 26.086 1.90185 26.086 1.97732C26.086 2.06789 26.086 2.14336 26.086 2.23393V13.7509C26.086 13.8415 26.086 13.9169 26.086 14.0075C26.086 14.0679 26.086 14.1434 26.086 14.2188C26.086 14.3396 26.0704 14.4754 26.0549 14.6113C26.0394 14.7169 26.0084 14.8226 25.9619 14.9132C25.9154 15.0037 25.8533 15.0792 25.7758 15.1547C25.6982 15.2302 25.6207 15.2905 25.5276 15.3358C25.4346 15.3811 25.3415 15.4113 25.2175 15.4264C25.0779 15.4566 24.9383 15.4566 24.8143 15.4566C24.7367 15.4566 24.6747 15.4566 24.5971 15.4566C24.5041 15.4566 24.4265 15.4566 24.3335 15.4566H3.25735C3.17981 15.4566 3.08676 15.4566 2.9937 15.4566C2.91616 15.4566 2.85413 15.4566 2.77658 15.4566C2.66802 15.4566 2.51294 15.4415 2.37336 15.4264C2.2648 15.4113 2.15624 15.3811 2.06319 15.3207C1.97014 15.2754 1.8926 15.2151 1.81505 15.1396C1.73751 15.0641 1.67548 14.9886 1.62895 14.8981C1.58243 14.8075 1.55141 14.7169 1.5359 14.5962C1.50488 14.4603 1.50488 14.3245 1.50488 14.2037C1.50488 14.1283 1.50488 14.0679 1.50488 13.9924V13.7962V13.7358V2.23393V2.17355V1.97732C1.50488 1.90185 1.50488 1.84147 1.50488 1.766C1.50488 1.64525 1.52039 1.5094 1.5359 1.37355C1.55141 1.26789 1.58243 1.16223 1.62895 1.07166C1.67548 0.981095 1.73751 0.905623 1.81505 0.830152C1.8926 0.75468 1.97014 0.694303 2.06319 0.64902C2.15624 0.603737 2.24929 0.573548 2.37336 0.558454C2.51294 0.528265 2.65252 0.528265 2.77658 0.528265C2.85413 0.528265 2.91616 0.528265 2.9937 0.528265H3.25735H24.3335Z'
				fill='white'
			/>
			<path
				d='M7.70812 5.40373C7.92524 5.13203 8.08032 4.78486 8.0338 4.4226C7.70812 4.43769 7.3204 4.63392 7.10328 4.89052C6.90167 5.11694 6.71557 5.4943 6.7621 5.84147C7.1343 5.85656 7.491 5.66034 7.70812 5.40373Z'
				fill='black'
			/>
			<path
				d='M8.03404 5.90182C7.52226 5.87163 7.07251 6.18862 6.82437 6.18862C6.57624 6.18862 6.20403 5.91692 5.7853 5.91692C5.25801 5.93201 4.76173 6.2188 4.48258 6.68673C3.92427 7.62258 4.343 9.01126 4.87029 9.78107C5.13394 10.1584 5.44411 10.5811 5.86284 10.566C6.25056 10.5509 6.40564 10.3094 6.88641 10.3094C7.36717 10.3094 7.50675 10.566 7.92548 10.5509C8.35972 10.5358 8.62337 10.1735 8.88701 9.79616C9.18167 9.37352 9.30574 8.95088 9.32125 8.93579C9.30574 8.92069 8.48379 8.6188 8.48379 7.68296C8.46828 6.91314 9.13515 6.53579 9.16617 6.5056C8.80947 5.97729 8.23565 5.91692 8.03404 5.90182Z'
				fill='black'
			/>
			<path
				d='M13.2136 4.8452C14.3457 4.8452 15.1211 5.59992 15.1211 6.70181C15.1211 7.80369 14.3147 8.5735 13.1825 8.5735H11.9264V10.5056H11.0269V4.8452H13.2136ZM11.9264 7.83388H12.9654C13.7564 7.83388 14.2061 7.42633 14.2061 6.70181C14.2061 5.99237 13.7564 5.58482 12.9809 5.58482H11.9419V7.83388H11.9264Z'
				fill='black'
			/>
			<path
				d='M15.3535 9.3282C15.3535 8.60367 15.9273 8.18103 16.9664 8.12065L18.0985 8.06027V7.74329C18.0985 7.29046 17.7884 7.03386 17.23 7.03386C16.7803 7.03386 16.4391 7.26027 16.3771 7.60744H15.5551C15.5861 6.86782 16.284 6.33952 17.2456 6.33952C18.2846 6.33952 18.9515 6.86782 18.9515 7.68292V10.5056H18.114V9.82631H18.0985C17.8659 10.264 17.3386 10.5508 16.7648 10.5508C15.9273 10.5508 15.3535 10.0678 15.3535 9.3282ZM18.083 8.96594V8.64895L17.075 8.70933C16.5167 8.73952 16.222 8.95084 16.222 9.3131C16.222 9.66027 16.5322 9.88669 17.0129 9.88669C17.6178 9.88669 18.083 9.49424 18.083 8.96594Z'
				fill='black'
			/>
			<path
				d='M19.7272 12.0301V11.3508C19.7892 11.3659 19.9133 11.3659 19.9909 11.3659C20.3941 11.3659 20.6112 11.1999 20.7508 10.7772L20.8283 10.5206L19.293 6.39987H20.239L21.3091 9.73572H21.3246L22.3947 6.39987H23.3097L21.7278 10.7319C21.3711 11.7282 20.9524 12.0452 20.0684 12.0452C20.0064 12.0452 19.7892 12.0452 19.7272 12.0301Z'
				fill='black'
			/>
		</g>
		<defs>
			<clipPath id='clip20'>
				<rect
					width='25.6667'
					height='16'
					fill='white'
					transform='translate(0.961914)'
				/>
			</clipPath>
		</defs>
	</svg>
);
