import { ActionTypes } from 'Actions/actions';

const initialState = {
	userCountryCode: null,
	isUserCountrySameAsCurrentCountryCode: null,
	isFetching: null,
};

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
export const userGeoLocation = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.REQUEST_USER_GEO_LOCATION: {
			return {
				...state,
				isFetching: true,
			};
		}
		case ActionTypes.RECEIVE_USER_GEO_LOCATION: {
			const {
				payload: { userCountryCode, currentCountryCode },
			} = action;

			return {
				...state,
				userCountryCode: userCountryCode,
				isFetching: false,
				isUserCountrySameAsCurrentCountryCode:
					userCountryCode === currentCountryCode,
			};
		}
		default:
			return state;
	}
};
