import * as React from 'react';

export const WarningSvg = (props: any) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='20'
		height='20'
		fill='none'
		viewBox='0 0 20 20'
		{...props}
	>
		<path
			stroke='#D60404'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M8.575 3.217L1.517 15a1.667 1.667 0 001.425 2.5h14.116a1.667 1.667 0 001.425-2.5L11.425 3.217a1.667 1.667 0 00-2.85 0v0zM10 7.5v3.333M10 14.167h.008'
		></path>
	</svg>
);
