import { BreakupResponse } from 'src/types/breakup';

import { getModifiedBreakupObject } from 'Utils/breakupUtils';

import { ActionTypes } from 'Actions/actions';

type BreakupState = {
	breakupResponse: BreakupResponse | null;
	fetchingBreakup: boolean;
	breakupFetchTimestamp: number;
};

const breakupInitialState = {
	breakupResponse: null,
	fetchingBreakup: false,
	breakupFetchTimestamp: 0,
} as BreakupState;

export const breakup = (state = breakupInitialState, action: any) => {
	switch (action.type) {
		// Reset breakup on booking date, time, tour id or variant id change
		case ActionTypes.SET_BOOKING_DATE:
		case ActionTypes.SET_BOOKING_TIME:
		case ActionTypes.SET_BOOKING_TOUR_ID:
		case ActionTypes.SET_BOOKING_VARIANT_ID:
			return {
				...state,
				breakupResponse: null,
			};

		case ActionTypes.FETCHING_BREAKUP: {
			const { timestamp } = action;
			return {
				...state,
				fetchingBreakup: true,
				breakupFetchTimestamp: timestamp,
			};
		}

		case ActionTypes.FETCHED_CART_BREAKUP:
		case ActionTypes.FETCHED_BREAKUP: {
			const { breakup } = action;

			const isLatestFetchData =
				action.timestamp >= state?.breakupFetchTimestamp;

			if (!isLatestFetchData) {
				return state;
			}

			return {
				...state,
				breakupResponse: getModifiedBreakupObject(breakup),
				fetchingBreakup: false,
			};
		}

		case ActionTypes.SET_PROMO_CODE_ERROR: {
			const {
				payload: { variantBreakup: couponError },
			} = action;

			return {
				...state,
				breakupResponse: {
					...(state.breakupResponse || {}),
					...couponError,
				},
				fetchingBreakup: false,
			};
		}

		default:
			return state;
	}
};
