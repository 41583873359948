import React from 'react';

export const CardSvg = (props: any) => (
	<svg
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<g clipPath='url(#clip0000)'>
			<path
				d='M13.9998 2.66667L1.99984 2.66667C1.26346 2.66667 0.666504 3.26362 0.666504 4L0.666504 12C0.666504 12.7364 1.26346 13.3333 1.99984 13.3333L13.9998 13.3333C14.7362 13.3333 15.3332 12.7364 15.3332 12L15.3332 4C15.3332 3.26362 14.7362 2.66667 13.9998 2.66667Z'
				stroke='#444444'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M0.666504 6.66667L15.3332 6.66667'
				stroke='#444444'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</g>
		<defs>
			<clipPath id='clip0000'>
				<rect width='16' height='16' fill='white' />
			</clipPath>
		</defs>
	</svg>
);
