import cookie from 'nookies';

import { getBaseUrl, getNakedDomain } from 'Utils/urlUtils';

import { ActionTypes } from 'Actions/actions';

import { ACTIVE_LANGUAGE_CODES, COOKIE } from 'Constants/constants';

export const requestLanguages = () => ({
	type: ActionTypes.REQUEST_LANGUAGES,
});
export const receiveLanguages = ({ list, url }: any) => ({
	list,
	url,
	type: ActionTypes.RECEIVE_LANGUAGES,
	receivedAt: Date.now(),
});

export const changeLanguage = ({
	code,
	context = {},
}: {
	code: string;
	context?: any;
}) => {
	let sanitizedCode = code;
	if (!ACTIVE_LANGUAGE_CODES.includes(code)) {
		sanitizedCode = 'en';
	}
	const host = context?.req
		? context.req.headers.host
		: typeof window !== 'undefined'
		? window.location.host
		: getBaseUrl();
	const nakedDomain = getNakedDomain(host);
	const cookies = cookie.get(context);
	const cookieLang = cookies[COOKIE.CONTENT_LANG];
	if (cookieLang !== sanitizedCode) {
		cookie.set(context, COOKIE.CONTENT_LANG, sanitizedCode, {
			httpOnly: false,
			path: '/',
			domain: nakedDomain,
		});
		cookie.set(context, COOKIE.GOOGLE_TRANSLATE, `/en/${sanitizedCode}`, {
			httpOnly: false,
			path: '/',
			domain: nakedDomain,
		});
	}

	return {
		code: sanitizedCode,
		type: ActionTypes.CHANGE_LANGUAGE,
	};
};

export const changeLocalizedContentLanguage = ({ lang } = { lang: 'en' }) => {
	return {
		lang,
		type: ActionTypes.CHANGE_LOCALIZED_CONTENT_LANGUAGE,
	};
};
