import * as React from 'react';

export const CloseIcon = (props: any) => (
	<svg
		width='16'
		height='16'
		viewBox='0 0 16 16'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M13.3334 2.66669L2.66669 13.3334'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M2.66669 2.66669L13.3334 13.3334'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const CloseIconWithoutBackground = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='12'
		height='12'
		viewBox='0 0 12 12'
		fill='none'
	>
		<path
			d='M11.3333 0.666656L0.666626 11.3333'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M0.666626 0.666656L11.3333 11.3333'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const CloseIconWithBackground = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='26'
		height='27'
		viewBox='0 0 26 27'
		fill='none'
	>
		<rect
			x='0.4'
			y='0.9'
			width='25.2'
			height='25.2'
			rx='12.6'
			fill='#F0F0F0'
		/>
		<rect
			x='0.4'
			y='0.9'
			width='25.2'
			height='25.2'
			rx='12.6'
			stroke='#E2E2E2'
			strokeWidth='0.8'
		/>
		<path
			d='M17.3332 9.1665L8.6665 17.8332'
			stroke='#666666'
			strokeWidth='1.2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M8.6665 9.1665L17.3332 17.8332'
			stroke='#666666'
			strokeWidth='1.2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
