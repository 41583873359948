/* less commonly used currencies and for such currencies we do not want to show the symbols*/
export const LESSER_KNOWN_CURRENCY_CODES = [
	'AED',
	'CHF',
	'ZAR',
	'MYR',
	'SEK',
	'ISK',
	'DKK',
	'MOP',
	'CNY',
	'MXN',
	'QAR',
	'SAR',
	'AZN',
	'BHD',
	'LBP',
];

/* currency symbol overrides for specific languages */
export const CURRENCY_SYMBOL_OVERRIDES: Record<
	string,
	Record<string, string>
> = {
	USD: {
		pt: 'US$',
	},
	SGD: {
		fr: '$S',
	},
	AUD: {
		fr: '$AU',
		it: 'A$',
	},
	HKD: {
		fr: '$HK',
	},
	CAD: {
		fr: '$CA',
		nl: 'C$',
	},
	NZD: {
		fr: '$NZ',
	},
	TWD: {
		fr: '$NT',
	},
	EGP: {
		fr: '£E',
	},
};

/* currency symbol overrides whre short prices to be shown 
These overrides are on top of CURRENCY_SYMBOL_OVERRIDES */
export const CURRENCY_SYMBOL_SHORT_OVERRIDES: Record<
	string,
	Record<string, string>
> = {
	USD: {
		pt: '$',
	},
};

export interface ICurrency {
	code: string;
	currencyName: string;
	symbol: string;
	localSymbol: string;
	precision: number;
	currency: string;
}
