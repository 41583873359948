import { sendLog } from 'Server/logger';

/*Utils */
import { isProductionEnvironment } from 'Utils/envUtils';

import { LOG_CATEGORIES } from 'Constants/constants';

import resolveResponse from './resolveResponse';

const FETCH_PARAMS = {
	credentials: 'include',
	referrerPolicy: 'no-referrer-when-downgrade',
};

const HSID_HEADER = 'X-H-SID';

const TRAILING_SLASH_SOURCES = [
	'api.headout.com',
	'api.test-headout.com',
	'headout.com/api',
	'test-headout.com/api',
];

const addTrailingSlash = (url?: string) => {
	if (url && TRAILING_SLASH_SOURCES.some(domain => url.includes(domain))) {
		const [path, query] = url.split('?');
		let finalUrl = path.endsWith('/') ? path : `${path}/`;
		if (query) finalUrl += `?${query}`;

		return finalUrl;
	}

	return url;
};

const fetchWrapper = (
	source?: string,
	{ hsid, ...options }: Record<string, any> = {},
) => {
	const sourceWithTrailingSlash = addTrailingSlash(source);

	const reqHeaders = {
		...(typeof options?.headers === 'object' ? options.headers : {}),
		...(hsid && {
			[HSID_HEADER]: hsid,
		}),
	};

	if (Object.keys(reqHeaders).length) options.headers = reqHeaders;

	return fetch(
		// @ts-expect-error TS(2769): No overload matches this call.
		sourceWithTrailingSlash,
		Object.assign({}, FETCH_PARAMS, options),
	)
		.then(async response => {
			return resolveResponse(response);
		})
		.catch(async errJSON => {
			if (
				errJSON.status >= 400 &&
				typeof process !== 'undefined' &&
				!process.browser &&
				isProductionEnvironment()
			) {
				sendLog({
					err: errJSON,
					category: LOG_CATEGORIES.API_ERROR,
				});
			} else if (errJSON.status >= 400 && isProductionEnvironment()) {
				// logClientMessages(
				// 	`[API REQUEST FAILED] [${
				// 		errJSON.status
				// 	}] - [client error] -${JSON.stringify(
				// 		errJSON.message,
				// 	)} - Request - ${source} - ${JSON.stringify(options)}`,
				// );
			}
			return Promise.reject(errJSON);
		});
};

export default fetchWrapper;
