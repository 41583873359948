import { ActionTypes } from 'Actions/actions';

import { PAGE_TYPE } from 'Constants/constants';

/**
 * Action that changes the page type.
 * Since this is not a hard dependency to change a page, people are likely to miss it.
 * But its extremely important to do this on every 'major' page change since components like header depend on it.
 *
 * @param newPage
 */
export const changePage = (newPage = PAGE_TYPE.HOME) => ({
	newPage,
	type: ActionTypes.CHANGE_PAGE,
});
