import { ActionTypes } from 'Actions/actions';

import { DATA_SOURCE_TYPE } from 'Constants/constants';

export const currencies = (
	state = {
		currentCurrencyCode: null,
		locationCurrencyCode: null,
		isFetching: false,
		geolocationCurrency: null,
	},
	action: any,
) => {
	switch (action.type) {
		case ActionTypes.CHANGE_CURRENCY: {
			return {
				...state,
				currentCurrencyCode: action.currency,
			};
		}
		case ActionTypes.CHANGE_LOCATION_CURRENCY: {
			return {
				...state,
				locationCurrencyCode: action.currency,
			};
		}
		case ActionTypes.REQUEST_CURRENCIES: {
			return {
				...state,
				isFetching: true,
			};
		}
		case ActionTypes.SET_GEOLOCATION_CURRENCY: {
			return {
				...state,
				geolocationCurrency: action.currency,
			};
		}
		case ActionTypes.RECEIVE_CURRENCIES: {
			const { currenciesList, dataSource } = action;
			const currenciesMap =
				currenciesList?.reduce(
					(result: any, item: any) => ({
						...result,
						[item.code]: item,
					}),
					{},
				) || {};
			return {
				...state,
				isFetching: !(dataSource === DATA_SOURCE_TYPE.API),
				currenciesMap: currenciesMap,
			};
		}
		default:
			return state;
	}
};
