import * as React from 'react';

export const PoweredByHeadout = (props: any) => (
	<svg
		width='97'
		height='46'
		viewBox='0 0 97 46'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<line
			x1='0.5'
			y1='0.5'
			x2='0.499998'
			y2='45.5'
			stroke='#E2E2E2'
			strokeLinecap='round'
		/>
		<path
			d='M11.952 15.96C13.92 15.96 15.36 14.424 15.36 11.992V11.976C15.36 9.544 13.904 8.088 11.968 8.088C10.912 8.088 10.064 8.552 9.536 9.336V5H8V15.8H9.536V14.696C10.064 15.48 10.912 15.96 11.952 15.96ZM11.584 14.616C10.24 14.616 9.456 13.608 9.456 12.12V11.992C9.456 10.488 10.272 9.432 11.584 9.432C12.88 9.432 13.712 10.312 13.712 11.928V12.04C13.712 13.672 12.848 14.616 11.584 14.616Z'
			fill='#888888'
		/>
		<path
			d='M15.8645 17.416V18.84H16.6645C18.4725 18.84 19.1285 18.136 19.8165 16.344L22.7125 8.248H21.0485L19.2085 13.88L17.1925 8.248H15.5445L18.4565 15.656C17.8645 17.192 17.6245 17.416 16.5845 17.416H15.8645Z'
			fill='#888888'
		/>
		<g clipPath='url(#clip0_419_33)'>
			<path
				d='M10.5756 29.0303C11.8781 28.0059 13.7073 27.1425 15.6683 27.1425C17.5268 27.1425 19.0781 27.9035 19.0781 30.8011V40.6498H16.4878V31.4157C16.4878 29.762 15.7561 29.3084 14.4829 29.3084C13.0342 29.3084 11.6878 29.9962 10.5902 30.6255V40.6498H8V22.8401H9.41951C10.4146 22.8401 10.5902 23.0742 10.5902 24.5669V29.0303H10.5756Z'
				fill='#8000FF'
			/>
			<path
				d='M23.7903 34.5035C23.922 37.6645 25.3415 38.8498 27.8439 38.8498C29.5561 38.8498 30.7268 38.2938 31.5317 37.6352H32.3659V39.4352C31.4 40.0938 29.9512 40.8108 27.4488 40.8108C23.3805 40.8108 21.1122 38.2206 21.1122 34.0352C21.1122 29.7913 23.7317 27.1425 27.2 27.1425C30.7122 27.1425 32.7756 29.2059 32.7756 33.5816C32.7756 33.9328 32.7463 34.4011 32.7463 34.5035H23.7903ZM23.8342 32.7913H30.2C30.1561 29.9084 29.0293 28.9279 27.2 28.9279C25.4585 28.9279 24.1122 29.9669 23.8342 32.7913Z'
				fill='#8000FF'
			/>
			<path
				d='M42.3171 39.1864C41.322 40.2547 40.122 40.8108 38.3512 40.8108C35.9366 40.8108 34.1805 39.4352 34.1805 36.845C34.1805 34.123 36.3464 32.7913 39.3464 32.7913C40.4878 32.7913 41.3366 32.923 42.2439 33.1425V31.3864C42.2439 29.8059 41.322 29.1181 39.5659 29.1181C37.9854 29.1181 36.7707 29.6011 35.922 30.2157H35.1024V28.284C36.2732 27.6986 37.7512 27.1425 39.8878 27.1425C43.0488 27.1425 44.8488 28.284 44.8488 31.2108V40.6498H43.8244C42.8293 40.6645 42.4781 40.3718 42.3171 39.1864ZM42.2439 37.5035V34.6791C41.5122 34.5328 40.6927 34.4303 39.7707 34.4303C37.9854 34.4303 36.7122 35.0889 36.7122 36.6986C36.7122 38.3523 37.7512 39.0401 39.1854 39.0401C40.5903 39.0255 41.4829 38.3669 42.2439 37.5035Z'
				fill='#8000FF'
			/>
			<path
				d='M56.2927 38.8498C55.3707 39.9474 53.9512 40.8108 52.122 40.8108C49.3415 40.8108 46.9854 38.8206 46.9854 34.1962C46.9854 28.9718 49.8098 27.1425 52.4878 27.1425C54.3171 27.1425 55.4146 27.684 56.2781 28.5181V22.8401H57.6976C58.722 22.8401 58.8683 23.0889 58.8683 24.5669V40.6498H57.1561C56.5707 40.6645 56.3659 40.3572 56.2927 38.8498ZM56.2634 37.123V30.3474C55.5024 29.6303 54.5805 29.2206 53.2049 29.2206C51.4488 29.2206 49.5903 30.1572 49.5903 34.0498C49.5903 37.6937 50.9366 38.8791 52.7512 38.8791C54.2293 38.9084 55.2683 38.2206 56.2634 37.123Z'
				fill='#8000FF'
			/>
			<path
				d='M61.0195 34.0352C61.0195 29.7913 63.7707 27.1425 67.3854 27.1425C71 27.1425 73.7512 29.7913 73.7512 34.0352C73.7512 38.2645 71 40.8108 67.3854 40.8108C63.7707 40.8108 61.0195 38.2645 61.0195 34.0352ZM71.0878 34.0352C71.0878 30.8303 69.639 29.1035 67.3707 29.1035C65.1317 29.1035 63.6537 30.8303 63.6537 34.0352C63.6537 37.2401 65.1317 38.8498 67.3707 38.8498C69.639 38.8498 71.0878 37.2547 71.0878 34.0352Z'
				fill='#8000FF'
			/>
			<path
				d='M84.3025 37.5328V27.3474H86.8927V40.6645H85.2683C84.6537 40.6645 84.4049 40.4596 84.3317 39.1572C83.0147 40.0937 81.2732 40.8401 79.5171 40.8401C77.4244 40.8401 75.8732 39.9767 75.8732 37.284V27.3474H78.4634V36.684C78.4634 38.3962 79.2976 38.7913 80.5268 38.7913C81.8732 38.8059 83.2342 38.162 84.3025 37.5328Z'
				fill='#8000FF'
			/>
			<path
				d='M88.6927 27.3035H90.3025V23.7035H91.8683C92.6732 23.7035 92.922 23.9962 92.922 25.123L92.8927 27.3035H95.8488V29.1328H92.8927V36.962C92.8927 38.3816 93.4781 38.7913 94.7951 38.7913H95.9951V40.4742C95.5268 40.5767 94.7952 40.723 93.8878 40.723C91.6342 40.723 90.3171 39.7718 90.3171 37.2986V29.1328H88.7073V27.3035H88.6927Z'
				fill='#8000FF'
			/>
		</g>
		<defs>
			<clipPath id='clip0_419_33'>
				<rect
					width='88.2'
					height='18'
					fill='white'
					transform='translate(8 22.8401)'
				/>
			</clipPath>
		</defs>
	</svg>
);
