import React from 'react';

import { CancelIconSvg } from 'Assets/svg/feedPage/CancelIconSvg';

import { copyTextToClipboard } from 'Utils/clipboardUtils';

type Props = {
	location?: any;
};

type State = any;

class CouponCodeBanner extends React.Component<Props, State> {
	state = {
		show: false,
		code: null,
		value: null,
	};

	componentDidMount() {
		this.showBanner();
	}

	onClose = () => {
		this.setState({ show: false });
	};

	getHtml = () => {
		const { code, value, show } = this.state;
		if (!code || !value || !show) return null;
		return (
			<div className='mobile-coupon-element'>
				<div
					className='left'
					onClick={() => this.copyText(code, 'Code copied!')}
				>
					<div className='title'>
						<span>
							Use coupon code <b>{code}</b> to get <b>{value}</b>{' '}
							OFF.
						</span>
					</div>
					<div className='copy-code'>
						<span>TAP TO COPY</span>
					</div>
				</div>
				<div className='right'>
					<div className='svg-icon' onClick={this.onClose}>
						<CancelIconSvg />
					</div>
				</div>
			</div>
		);
	};

	copyText = (code: any, text: any) => {
		copyTextToClipboard(code, text);
	};

	showBanner = () => {
		const query = this.props.location.query;
		if (
			Object.prototype.hasOwnProperty.call(query, 'couponCode') &&
			Object.prototype.hasOwnProperty.call(query, 'couponValue')
		) {
			this.setState({
				show: true,
				code: query.couponCode,
				value: query.couponValue,
			});
		}
	};

	render() {
		return this.getHtml();
	}
}

export default CouponCodeBanner;
