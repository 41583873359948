import { ActionTypes } from 'Actions/actions';

export const searchResults = (state = {}, action: any) => {
	switch (action.type) {
		case ActionTypes.REQUEST_SEARCH_RESULTS: {
			const {
				query,
				cityCode,
				nextPageUrl,
				productOnly,
				isWorldWideSearch,
			} = action;
			const type = !productOnly ? 'collections' : 'products';
			if (nextPageUrl) {
				return {
					...state,
					[query]: {
						// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
						...state[query],
						[!isWorldWideSearch && cityCode ? cityCode : 'WORLD']: {
							// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
							...state[query][
								!isWorldWideSearch && cityCode
									? cityCode
									: 'WORLD'
							],
							results: {
								// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
								...state?.[query][
									!isWorldWideSearch && cityCode
										? cityCode
										: 'WORLD'
								]?.['results'],
								[type]: {
									// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
									...state?.[query]?.[
										!isWorldWideSearch && cityCode
											? cityCode
											: 'WORLD'
									]?.['results']?.[type],
									isFetching: true,
								},
							},
						},
					},
				};
			}
			const prevProducts = (state as any).searchResults?.[query]?.[
				!isWorldWideSearch && cityCode ? cityCode : 'WORLD'
			].results.products;

			const prevCollections = (state as any).searchResults?.[query]?.[
				!isWorldWideSearch && cityCode ? cityCode : 'WORLD'
			].results.collections;

			const results = {
				products: { hits: [], isFetching: true, ...prevProducts },
				collections: {
					hits: [],
					isFetching: true,
					...prevCollections,
				},
			};
			return {
				...state,
				[query]: {
					// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					...state[query],
					[!isWorldWideSearch && cityCode ? cityCode : 'WORLD']: {
						// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
						...state?.[query]?.[
							!isWorldWideSearch && cityCode ? cityCode : 'WORLD'
						],
						results: {
							// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
							...state?.[query]?.[
								!isWorldWideSearch && cityCode
									? cityCode
									: 'WORLD'
							]?.['results'],
							...results,
							[type]: {
								...results[type],
								// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
								...state?.[query]?.[
									!isWorldWideSearch && cityCode
										? cityCode
										: 'WORLD'
								]?.['results']?.[type],
								isFetching: true,
							},
						},
					},
				},
			};
		}
		case ActionTypes.RECEIVE_SEARCH_RESULTS: {
			const {
				query,
				cityCode,
				results,
				nHits,
				tHits,
				nextPageUrl,
				productOnly,
				showMore,
				isWorldWideSearch,
			} = action;
			const fResults = (
				showMore
					? // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					  state?.[query]?.[
							!isWorldWideSearch && cityCode ? cityCode : 'WORLD'
					  ]?.results?.[productOnly ? 'products' : 'collections']
							?.hits || []
					: []
			).concat(results);
			const finalNumHits =
				(showMore
					? // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					  state?.[query]?.[
							!isWorldWideSearch && cityCode ? cityCode : 'WORLD'
					  ]?.results?.[productOnly ? 'products' : 'collections']
							?.nHits || 0
					: 0) + nHits;
			const mockContainer = {
				nHits: finalNumHits,
				tHits,
				nextPageUrl,
				isFetching: false,
			};
			const resultsMap = {
				...mockContainer,
				hits: fResults,
			};
			if (!productOnly) {
				return {
					...state,
					[query]: {
						// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
						...state?.[query],
						[!isWorldWideSearch && cityCode ? cityCode : 'WORLD']: {
							// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
							...state?.[query]?.[
								!isWorldWideSearch && cityCode
									? cityCode
									: 'WORLD'
							],
							results: {
								// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
								...state?.[query]?.[
									!isWorldWideSearch && cityCode
										? cityCode
										: 'WORLD'
								]?.['results'],
								collections: resultsMap,
							},
						},
					},
				};
			}
			return {
				...state,
				[query]: {
					// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					...state?.[query],
					[!isWorldWideSearch && cityCode ? cityCode : 'WORLD']: {
						// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
						...state?.[query]?.[
							!isWorldWideSearch && cityCode ? cityCode : 'WORLD'
						],
						results: {
							// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
							...state?.[query]?.[
								!isWorldWideSearch && cityCode
									? cityCode
									: 'WORLD'
							]?.['results'],
							products: resultsMap,
						},
					},
				},
			};
		}
		default:
			return state;
	}
};
