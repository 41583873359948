import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { isServer } from 'Utils/envUtils';

import rootReducer from 'Reducers/rootReducer';

export const configureStore = (preloadedState: any) => {
	if (isServer()) {
		return createStore(
			rootReducer,
			preloadedState,
			applyMiddleware(thunkMiddleware),
		);
	}
	/*
 Instead of window.devToolsExtension (which was planned to be deprecated in
 favour of window.__REDUX_DEVTOOLS_EXTENSION__),
 now we’ll be using window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
 */
	/* eslint-disable no-underscore-dangle */
	// process.env.NODE_ENV !== 'production'
	const composeEnhancers =
		typeof window === 'object' &&
		(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			: compose;

	return createStore(
		rootReducer,
		preloadedState,
		composeEnhancers(applyMiddleware(thunkMiddleware)),
	);
};
