import { ActionTypes } from 'Actions/actions';

const initialState = {
	byId: {},
	isFetching: {},
};

interface TTourIdMetadataTypes {
	tourId: number;
	ticketValidity: Object;
	cancellationPolicy: Object;
	paxSelectionInstructions: Object;
}

const getMetadataByTourId = (checkoutMetadata: Array<TTourIdMetadataTypes>) => {
	const metadataByTourIdMap = {};
	checkoutMetadata.forEach(el => {
		const { tourId, ...rest } = el;
		(metadataByTourIdMap as any)[String(tourId)] = { ...rest };
	});
	return metadataByTourIdMap;
};

export const checkoutMetadataStore = (
	state = initialState,
	action: { type: string; payload: any },
) => {
	const { type, payload } = action;

	switch (type) {
		case ActionTypes.REQUEST_CHECKOUT_METADATA: {
			const { id } = payload;
			return {
				...state,
				isFetching: { ...state.isFetching, [id]: true },
			};
		}
		case ActionTypes.RECEIVE_CHECKOUT_METADATA: {
			const { id, checkoutMetadata } = payload;
			const metaDataByTourId = getMetadataByTourId(checkoutMetadata);

			return {
				...state,
				byId: {
					...state.byId,
					[id]: {
						...((state as any)?.byId?.[id] ?? {}),
						...metaDataByTourId,
					},
				},
				isFetching: { ...state.isFetching, [id]: false },
			};
		}
		default:
			return state;
	}
};
