import { ActionTypes } from 'Actions/actions';

const initialState = {
	byCityCode: {},
	byCategoryId: {},
	bySubCategoryId: {},
	byCollectionId: {},
	status: {},
};

const getFetchingByStatus = ({
	collectionId,
	categoryId,
	subCategoryId,
	city,
	value,
}: any) => {
	if (collectionId) {
		return { [`collection-${collectionId}`]: value };
	}
	if (categoryId) {
		return { [`category-${city}-${categoryId}`]: value };
	}
	if (subCategoryId) {
		return { [`subCategory-${city}-${subCategoryId}`]: value };
	}
	return { [`city-${city}`]: value };
};

export const blogPostsStore = (state = initialState, action: any) => {
	const { type, payload } = action;

	switch (type) {
		case ActionTypes.REQUEST_BLOG_POSTS: {
			const { collectionId, city, categoryId, subCategoryId } = payload;
			return {
				...state,
				status: {
					...state.status,
					...getFetchingByStatus({
						collectionId,
						city,
						categoryId,
						subCategoryId,
						value: true,
					}),
				},
			};
		}
		case ActionTypes.RECEIVE_BLOG_POSTS: {
			const { collectionId, city, categoryId, subCategoryId, posts } =
				payload;
			return {
				...state,
				byCollectionId: collectionId
					? { ...state.byCollectionId, [collectionId]: posts }
					: { ...state.byCollectionId },
				byCategoryId:
					categoryId && city
						? {
								...state.byCategoryId,
								[`${city}-${categoryId}`]: posts,
						  }
						: { ...state.byCategoryId },
				bySubCategoryId:
					subCategoryId && city
						? {
								...state.bySubCategoryId,
								[`${city}-${subCategoryId}`]: posts,
						  }
						: { ...state.bySubCategoryId },
				byCityCode:
					city && !(categoryId || subCategoryId)
						? { ...state.byCityCode, [city]: posts }
						: { ...state.byCityCode },
				status: {
					...state.status,
					...getFetchingByStatus({
						collectionId,
						city,
						categoryId,
						subCategoryId,
						value: false,
					}),
				},
			};
		}

		default:
			return state;
	}
};
