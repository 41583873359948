import { ActionTypes } from 'Actions/actions';

export const ugcUploads = (state = { byCategoryId: {} }, action: any) => {
	switch (action.type) {
		case ActionTypes.SET_UGC_UPLOADS_BY_CATEGORY: {
			const { categoryId, userIdToUploadsMap } = action;
			return {
				...state,

				byCategoryId: {
					...state['byCategoryId'],
					[categoryId]: userIdToUploadsMap,
				},
			};
		}
		case ActionTypes.SET_UGC_UPLOADS_BY_TOUR_GROUP: {
			const { tourGroupId, userIdToUploadsMap } = action;
			return {
				...state,
				byTourGroupId: {
					// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					...state['byTourGroupId'],
					[tourGroupId]: userIdToUploadsMap,
				},
			};
		}
		default:
			return state;
	}
};
