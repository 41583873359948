import { ActionTypes } from 'Actions/actions';

export const paymentGatewayStore = (state = {}, action: any) => {
	switch (action.type) {
		case ActionTypes.SET_PAYMENT_GATEWAY_DETAILS: {
			const {
				paymentGatewayDetails: {
					paymentGatewayCredentials,
					supportedPaymentMethods,
				},
			} = action.payload;
			return {
				...state,
				paymentGatewayCredentials,
				supportedPaymentMethods,
			};
		}
		case ActionTypes.SET_CUSTOM_PAYMENT_FIELD_VALUE: {
			const { paymentMethod, customFieldId, value } = action.payload;
			return {
				...state,
				customPaymentFieldValues: {
					// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					...state?.['customPaymentFieldValues'],
					[paymentMethod]: {
						// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
						...state?.['customPaymentFieldValues']?.[paymentMethod],
						[customFieldId]: value,
					},
				},
			};
		}
		case ActionTypes.DISABLE_PAYMENT_METHOD: {
			const { paymentMethod: paymentMethodToBeDisabled } = action.payload;
			const {
				// @ts-expect-error TS(2339): Property 'paymentGatewayDetails' does not exist on... Remove this comment to see the full error message
				paymentGatewayStore: { supportedPaymentMethods },
			} = state;
			return {
				...state,
				paymentGatewayStore: {
					...(state as any)?.paymentGatewayStore,
					supportedPaymentMethods: supportedPaymentMethods.filter(
						// @ts-expect-error TS(7031): Binding element 'paymentMethod' implicitly has an ... Remove this comment to see the full error message
						({ paymentMethod }) =>
							paymentMethod !== paymentMethodToBeDisabled,
					),
				},
			};
		}
		default:
			return state;
	}
};
