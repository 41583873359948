import { LOG_LEVELS } from 'Constants/constants';

interface ILogData {
	level?: keyof typeof LOG_LEVELS;
	message?: String;
	err?: unknown;
	category?: string;
}

export const sendLog = async ({
	level,
	message,
	err,
	// @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
	category = null,
}: ILogData) => {
	if (typeof window !== 'undefined') return;
	const [{ getCoralogixLoggerInstance, getCoralogixSeverity }, { Log }] =
		await Promise.all([
			import(/* webpackChunkName: 'coralogix-module' */ './coralogix'),
			import(
				/* webpackChunkName: 'coralogix-logger' */ 'coralogix-logger'
			),
		]);

	let text = message;
	if (err instanceof Error) {
		const stringCustomProperties = JSON.stringify(err, null, 2);
		text = `${
			stringCustomProperties.length > 3 // avoids logging `{}`
				? stringCustomProperties + '\n'
				: ''
		}${err.stack}`;
	}
	// @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
	if (['production', 'test'].indexOf(process.env.NEXT_PUBLIC_NODE_ENV) > -1) {
		const log = new Log({
			// @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
			severity: getCoralogixSeverity(level),
			text,
			category,
		});
		getCoralogixLoggerInstance().addLog(log);
	}
};
