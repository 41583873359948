import React from 'react';
import NextFutureImage from 'next/image';

import { imgixLoader, makeIMGIXUrl, sanitiseImageUrl } from 'Utils/imageUtils';

import { CustomImageProps } from './interface';
import {
	getLayoutAssociatedFutureImageStyle,
	getShimmerStringSVGMarkup,
	toBase64,
} from './util';

const FALLBACK_IMG_URL = '//cdn-imgix-open.headout.com/fallback/landscape.svg';
const KNOWN_SOURCE_PATTERN = /imgix|images\.prismic|i\.ytimg/;

const Image: React.FC<React.PropsWithChildren<CustomImageProps>> = ({
	height,
	width,
	src,
	density,
	fill,
	alt: altText,
	layout,
	priority,
	useImg = false, // TODO: Get rid of useImg prop once NextJS removes wrappers for images. (feature is currently in Beta)
	style = {},
	fit = null,
	enhance,
	cropFaces = true,
	useEmptyPlaceholder = false,
	...otherProps
}) => {
	const sanitizedUrl = sanitiseImageUrl({
		url: (src as string) || FALLBACK_IMG_URL,
	});
	let [finalUrl] = sanitizedUrl?.split('?') ?? [];
	let blurDataURL: string;
	let futureImageStyle = style;
	finalUrl = makeIMGIXUrl({
		height,
		width,
		url: finalUrl,
		density,
		enhance,
		fit,
		cropFaces,
	});
	const isKnownSource = KNOWN_SOURCE_PATTERN.test(finalUrl);

	React.useEffect(() => {
		const isAcceptableUnkownSource = /blog\/wp-content/.test(finalUrl);
		if (!isKnownSource && !isAcceptableUnkownSource) {
			// logClientMessages(ERROR_MESSAGES.UNKNOWN_IMAGE_SRC_RENDERED, {
			// 	pageUrl: window.location.href,
			// 	src,
			// 	finalUrl,
			// });
		}
	}, []);

	if (!finalUrl) return null;

	if (!isKnownSource || useImg) {
		return (
			<picture>
				{/* eslint-disable-next-line @next/next/no-img-element */}
				<img
					src={finalUrl}
					alt={altText}
					{...otherProps}
					// @ts-expect-error TS(2322): Type '"lazy" | null' is not assignable to type '"l... Remove this comment to see the full error message
					loading={!priority ? 'lazy' : null}
				/>
			</picture>
		);
	}

	if (!finalUrl) return null;

	blurDataURL = `data:image/svg+xml;base64,${toBase64(
		getShimmerStringSVGMarkup(width, height),
	)}`;
	futureImageStyle = {
		...futureImageStyle,
		...getLayoutAssociatedFutureImageStyle(layout),
	};

	const getImagePlaceholderType = () => {
		if (useEmptyPlaceholder) {
			return 'empty';
		} else {
			return priority ? 'empty' : 'blur';
		}
	};

	return (
		<NextFutureImage
			// @ts-expect-error TS(2322): Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
			width={fill ? null : width}
			// @ts-expect-error TS(2322): Type 'number | null | undefined' is not assignable... Remove this comment to see the full error message
			height={fill ? null : height}
			// fill={fill}
			// @ts-expect-error TS(2322): Type '({ src, width, quality }: { src: any; width:... Remove this comment to see the full error message
			loader={imgixLoader}
			src={finalUrl}
			alt={altText || ''}
			placeholder={getImagePlaceholderType()}
			blurDataURL={blurDataURL}
			css={{
				filter: 'unset !important',
			}}
			style={futureImageStyle}
			priority={priority} // disables lazy load,
			unoptimized // We use IMGIX, which does all the optimisation required. Letting Next process images will add to TTFB.
			fetchpriority={priority ? 'high' : 'auto'}
			{...otherProps}
		/>
	);
};

export default Image;
