import { SectionTabInfo } from 'Components/desktop/sectionsTabCarousel/interface';

import { isServer } from 'Utils/envUtils';
import { getDecodedUrlSlugs } from 'Utils/gen';
import { read, write } from 'Utils/localStorageUtils';
import { replaceNotAlphaNumbericWithHyphen } from 'Utils/stringUtils';
import { getApiCDNBaseUrlV2, getApiString } from 'Utils/urlUtils';

import type { BannerData } from 'ReduxTypes/banner';
import { CategoryType } from 'ReduxTypes/categories';

import {
	BANNER_IMAGE_URLS_CITY_PAGE_DESKTOP,
	BANNER_IMAGE_URLS_CITY_PAGE_MOBILE,
	BANNERS_PLATFORM,
	CITIES_SITEMAP_FILTERS,
	GLOBAL_CITY_CODE,
	LANDING_PAGE_BANNER_TYPES,
	LAZY_COMPONENT_SECTION_WRAPPER_PREFIX,
	MEMBERSHIP,
	RECENTLY_VIEWED_CITIES_KEY,
	SECTION_CONSTRUCTOR_PREFIX,
	SITEMAP_PAGE_LIMIT,
} from 'Constants/constants';

export const getCity = (citiesMap: any, cityCode: any) => citiesMap?.[cityCode];

export const getCityUrl = ({ city, paramLang }: any) => {
	const urlSlugs = getDecodedUrlSlugs(city?.urlSlugs);
	const currentLang: string = paramLang
		? paramLang?.toUpperCase()?.replace(/-/g, '_')
		: 'EN';
	return urlSlugs?.[currentLang];
};

export const getCityPageHref = ({ city, paramLang }: any) => {
	const cityCode = city?.cityCode.toLowerCase();
	const langQueryParam = paramLang ? `&lang=${paramLang}` : '';
	return `${
		paramLang ? `/[lang]` : ''
	}/cities/[city]?city=${cityCode}${langQueryParam}`;
};

export const getDefaultCurrency = (city: any) =>
	city && city?.country?.currency?.code && city?.country?.currency;

export const getPhoneNumber = (city: any) => city?.phoneNumber;

export const getCityDisplayName = (citiesMap: any, cityCode: any) =>
	citiesMap?.[cityCode]?.displayName;

export const getCityImage = (citiesMap: any, cityCode: any) =>
	citiesMap?.[cityCode]?.imageURL;

export const getCityCountryName = (citiesMap: any, cityCode: any) =>
	cityCode ? citiesMap?.[cityCode]?.country?.displayName : null;

export const getCitiesList = () => {
	const url = `${getApiCDNBaseUrlV2({})}/api/v2/city/list`;

	return fetch(url)
		.then(response => response.json())
		.then(json => {
			return json;
		})
		.catch(err => {
			return err;
		});
};

export const saveCityToLocalStorage = ({ key, value }: any) => {
	if (isServer()) return;
	const recentlyViewedCities = read(key);
	const recentlyViewedCitiesArray = recentlyViewedCities
		? recentlyViewedCities.split(',')
		: [];
	if (!recentlyViewedCitiesArray.includes(value)) {
		recentlyViewedCitiesArray.push(value);
	}
	write(key, recentlyViewedCitiesArray.toString());
};

export const getRecentCitiesFromLocalStore = (noOfHits: any) => {
	if (isServer()) return;
	const recentlyViewedCities = read(RECENTLY_VIEWED_CITIES_KEY);
	if (!recentlyViewedCities) return;

	const recentlyViewedCitiesArray = recentlyViewedCities
		?.split(',')
		.reverse();
	return noOfHits && recentlyViewedCitiesArray
		? recentlyViewedCitiesArray.splice(0, 5)
		: recentlyViewedCitiesArray;
};

export const getCityUrlHref = ({ lang, cityCode }: any) =>
	`/[lang]/cities/[city]?city=${replaceNotAlphaNumbericWithHyphen(
		cityCode,
	)}&lang=${lang}`;

export const getCityUrlAsPath = ({ lang, urlSlugs }: any) =>
	`${getDecodedUrlSlugs(urlSlugs)?.[getApiString(lang) || 'EN']}`;

export const getGlobalPageUrlHref = ({ lang }: any) =>
	`${lang && lang !== 'en' ? '/[lang]/' : '/'}`;

export const getGlobalPageUrlAsPath = ({ lang }: any) =>
	`${lang && lang !== 'en' ? `/${lang}` : '/'}`;

export const getCitiesUrl = (lang: any) =>
	`${lang && lang !== 'en' ? `/${lang}` : ''}/cities`;

export const getCitiesHref = (lang: any) =>
	`${lang && lang !== 'en' ? `/[lang]` : ''}/cities`;

export const shouldShowMembershipBanner = ({ cityCode, tgid }: any) => {
	const isUaeCity = MEMBERSHIP.TARGET_CITIES.includes(cityCode);
	if (cityCode) {
		return isUaeCity;
	}
	return tgid ? tgid !== MEMBERSHIP.PRODUCT_TGID && isUaeCity : false;
};

export const isCityCodeNotGlobal = (cityCode: any) =>
	cityCode && cityCode !== GLOBAL_CITY_CODE;

export const getPlaceholderBanner = (
	platform: BANNERS_PLATFORM,
): BannerData => ({
	url:
		platform === BANNERS_PLATFORM.DESKTOP
			? BANNER_IMAGE_URLS_CITY_PAGE_DESKTOP[0]
			: BANNER_IMAGE_URLS_CITY_PAGE_MOBILE[0],
	type: LANDING_PAGE_BANNER_TYPES.IMAGE,
	metadata: { duration: null, height: null, width: null, altText: null },
	info: { title: null, videoFallbackUrl: null, imageRedirectUrl: null },
});

export const getCitySitemapPageListURLParams = ({
	filter,
}: {
	filter?: string;
}) => {
	let apiFilter = '';
	if (filter) {
		const { apiParam } =
			CITIES_SITEMAP_FILTERS.find(
				filterObj => filterObj.urlParam === filter,
			) ?? {};
		apiFilter = apiParam ? `continent=${apiParam}` : '';
	}

	return apiFilter;
};

export const generateLimitOffsetByPage = (
	pageNumber: string,
	filter?: string,
) => {
	let limit = 100;
	if (filter) limit = SITEMAP_PAGE_LIMIT;
	return !pageNumber
		? { limit: `${limit}`, offset: '0' }
		: {
				limit: `${limit}`,
				offset: `${limit * (+pageNumber - 1)}`,
		  };
};

export const getCitySitemapBasePath = ({
	lang,
	filter,
	page,
}: {
	lang?: string;
	filter?: string;
	page?: string | number;
}) => {
	const skipPage = page && +page === 1;
	return `${lang && lang.toLowerCase() !== 'en' ? `/${lang}` : ``}/cities/${
		filter ? `filter-${filter}/` : ''
	}${page && !skipPage ? `p-${page}/` : ''}`;
};

export const getSectionContainerId = ({
	type,
	title,
	suffix = '',
}: {
	type: string;
	title: string;
	suffix?: string;
}) => {
	return `${type}_${title.replace(/ /g, '_')}${suffix}`;
};

export const getSectionTabInfos = (sections: any[]): SectionTabInfo[] => {
	return sections.map((section: any) => {
		const {
			title,
			tourGroups,
			category: { type: categoryType, id: categoryId },
		} = section;

		return {
			type: SECTION_CONSTRUCTOR_PREFIX,
			tourGroups,
			title,
			entityId: categoryId,
			isCategory: categoryType === CategoryType.CATEGORY,
			lazyContainerId: getSectionContainerId({
				type: LAZY_COMPONENT_SECTION_WRAPPER_PREFIX,
				title,
			}),
		};
	});
};
