import { Dispatch, SetStateAction, useState } from 'react';

import Conditional from 'Components/common/conditional';
import {
	GIFContainer,
	InlineLoader,
	LoadingText,
	PageLoaderContainer,
	PageLoaderWrapper,
} from 'Components/common/pageLoader/styles';
import type { TPageLoaderProps } from 'Components/common/pageLoader/types';

import { GIF_LOADER_WHITE_BG_URL } from 'Constants/constants';

/**
 * keeping this for any more gif based loader changes.
 * @returns gif loader
 */
export const GIFLoader = ({
	gif,
	alt = '',
	isGIFLoaded,
	setIsGIFLoaded,
}: {
	gif: string;
	alt?: string;
	isGIFLoaded: boolean;
	setIsGIFLoaded: Dispatch<SetStateAction<boolean>>;
}) => {
	const onLoadComplete = () => {
		setIsGIFLoaded(true);
	};

	return (
		<GIFContainer $isLoaded={isGIFLoaded}>
			{/* NextJS image component does not support gif, hence using <img> element */}
			<img src={gif} alt={alt} onLoad={onLoadComplete} />
		</GIFContainer>
	);
};

/**
 * Use this component if you wish to cover entire screen with a loader. (it will overlay on top of components)
 * for inline usages, use the Named Import `InlineLoader` from this file instead.
 *
 * @param props.textToDisplay pass this if you wish to override the default loading text (default: 'Loading...')
 * @returns Markup for Full Page Overlay with a centered Loader Animation.
 */
const PageLoader = ({
	className = '',
	textToDisplay,
	gifSrc,
}: TPageLoaderProps) => {
	const [isGIFLoaded, setIsGIFLoaded] = useState(false);

	return (
		<PageLoaderContainer className={`page-loader ${className}`}>
			<PageLoaderWrapper>
				<GIFLoader
					gif={gifSrc ?? GIF_LOADER_WHITE_BG_URL}
					alt='Loading'
					isGIFLoaded={isGIFLoaded}
					setIsGIFLoaded={setIsGIFLoaded}
				/>

				<Conditional if={!isGIFLoaded}>
					<InlineLoader />
				</Conditional>
				<Conditional if={textToDisplay}>
					<LoadingText>{textToDisplay}</LoadingText>
				</Conditional>
			</PageLoaderWrapper>
		</PageLoaderContainer>
	);
};

export default PageLoader;
