import { isServer } from 'Utils/envUtils';

import { ActionTypes } from 'Actions/actions';

const initialState = {
	pageStatus: {},
	APIStatus: {},
};

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
export const serverStatus = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.SET_SERVER_PAGE_STATUS: {
			const { pageUrl, statusCode, info } = action;
			if (isServer()) {
				return {
					...state,
					pageStatus: {
						...state.pageStatus,
						...{ pageUrl, statusCode, info },
					},
				};
			}
			return state;
		}
		case ActionTypes.SET_SERVER_API_STATUS: {
			const { APIUrl, statusCode, info } = action;
			if (isServer()) {
				return {
					...state,
					APIStatus: {
						...state.APIStatus,
						...{ APIUrl, statusCode, info },
					},
				};
			}
			return state;
		}
		default:
			return state;
	}
};
