import { ActionTypes } from 'Actions/actions';

export const setMembershipData = ({ membershipData }: any) => ({
	payload: { membershipData },
	type: ActionTypes.SET_MEMBERSHIP_DATA,
});

export const setMembershipProfileViewed = (
	isMembershipProfileViewed: boolean,
) => {
	return {
		payload: { isMembershipProfileViewed },
		type: ActionTypes.SET_MEMBERSHIP_PROFILE_VIEWED,
	};
};
