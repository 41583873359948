import { ActionTypes } from 'Actions/actions';

export const changeUserProfileType = ({ userProfileType }: any) => ({
	type: ActionTypes.CHANGE_PROFILE_TYPE,
	userProfileType,
});

export const requestAffiliateDetails = () => ({
	type: ActionTypes.REQUEST_AFFILIATE_DETAILS,
});

export const requestUserDetails = (currencyCode: any) => ({
	type: ActionTypes.REQUEST_USER_DETAILS,
	currencyCode,
});

// @ts-expect-error TS(7031): Binding element 'json' implicitly has an 'any' typ... Remove this comment to see the full error message
export const receiveUserDetails = ({ json }) => ({
	user: json,
	lastUpdatedAt: new Date().getTime(),
	type: ActionTypes.RECEIVE_USER_DETAILS,
});

export const deleteUserDetails = () => ({
	type: ActionTypes.DELETE_USER_DETAILS,
});

export const handleLoginFailure = () => ({
	type: ActionTypes.HANDLE_LOGIN_FALURE,
});

export const updateUserAttributes = (attributes: any) => ({
	type: ActionTypes.UPDATE_USER_ATTRIBUTES,
	attributes,
});

export const requestAffiliateResetPassword = () => ({
	type: ActionTypes.REQUEST_AFFILIATE_RESET_PASSWORD,
});

export const receiveAffiliateResetPasswordDetails = (json: any) => ({
	resetAffiliatePassword: json,
	type: ActionTypes.RECEIVE_AFFILIATE_RESET_PASSWORD_DETAILS,
});

export const openLoginModal = () => ({
	type: ActionTypes.OPEN_LOGIN_MODAL,
});

export const closeLoginModal = () => ({
	type: ActionTypes.CLOSE_LOGIN_MODAL,
});

// @ts-expect-error TS(7031): Binding element 'hsid' implicitly has an 'any' typ... Remove this comment to see the full error message
export const setSandboxID = ({ hsid }) => ({
	type: ActionTypes.SET_SANDBOX_ID,
	hsid,
});
