import { sendVariableToDataLayer } from 'Utils/analytics';
import { setCurrencyCookie } from 'Utils/cookieUtils';

import { ActionTypes } from 'Actions/actions';

import { ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { DATA_SOURCE_TYPE } from 'Constants/constants';

export const requestCurrencies = () => ({
	type: ActionTypes.REQUEST_CURRENCIES,
});

export const receiveCurrencies = ({
	// @ts-expect-error TS(7031): Binding element 'currenciesList' implicitly has an... Remove this comment to see the full error message
	currenciesList,
	dataSource = DATA_SOURCE_TYPE.API,
	// @ts-expect-error TS(7031): Binding element 'url' implicitly has an 'any' type... Remove this comment to see the full error message
	url,
}) => ({
	currenciesList,
	dataSource,
	url,
	type: ActionTypes.RECEIVE_CURRENCIES,
	receivedAt: Date.now(),
});

export const changeCurrency = ({ currencyCode = 'USD', context = {} }) => {
	setCurrencyCookie(currencyCode, context);

	// Setting the updated Custom Dimension - Currency
	sendVariableToDataLayer({
		name: ANALYTICS_PROPERTIES.CURRENCY,
		value: currencyCode,
	});
	return {
		currency: currencyCode,
		type: ActionTypes.CHANGE_CURRENCY,
	};
};

export const changeLocationCurrency = ({ currencyCode = '' }) => {
	return {
		currency: currencyCode,
		type: ActionTypes.CHANGE_LOCATION_CURRENCY,
	};
};

export const setGeoLocationCurrency = ({ currencyCode = 'USD' }) => ({
	currency: currencyCode,
	type: ActionTypes.SET_GEOLOCATION_CURRENCY,
});
