import { RootStateOrAny } from 'react-redux';

import { type TPersonaTag } from 'Containers/common/aboutPoiSection/types';

import type { TCategoriesAndSubCategoriesInfoItem } from 'Components/common/categoriesAndSubCategories/interface';

import {
	getCityUrlAsPath,
	getCityUrlHref,
	getGlobalPageUrlAsPath,
	getGlobalPageUrlHref,
} from 'Utils/cityUtils';
import { getDecodedUrlSlugs } from 'Utils/gen';
import { getPlacesToVisitSlug } from 'Utils/placesToVisit';
import {
	getBlogsByCollectionId,
	getCategoriesInfoByCityCode,
	getCategoriesInfoById,
	getCitiesMap,
	getCityIdByCityCode,
	getCollectionById,
	getCollectionCardById,
	getCollectionCardIdsByCategoryId,
	getCollectionCardIdsByCityCode,
	getCollectionCardIdsBySubcategoryId,
	getCurrenciesMap,
	getCurrentPage,
	getFetchingStatus,
	getNearByCityCodes,
	getPoiInfo,
	getProductListByCityCode,
	getSandboxID,
	getStarredCategoriesInfoByCityCode,
	getSubCategoriesInfoById,
} from 'Utils/stateUtils';
import { replaceNotAlphaNumbericWithHyphen } from 'Utils/stringUtils';
import {
	getApiString,
	getBaseUrl,
	getSEOLanguageLabel,
	getStringifiedQueryFromObject,
} from 'Utils/urlUtils';

import { setLazyOverride } from 'Thunks/app';
import { fetchCollectionsInfoById } from 'Thunks/collections';
import { TPOIObj } from 'ReduxTypes/generics';

import {
	ALL_COLLECTIONS,
	ALL_SUB_CATEGORIES,
	CATEGORY_PAGE_DEFAULT_SORTING_PARAMS,
	COLLECTION_DESC_TYPE,
	COLLECTION_PAGE_SECTIONS,
	COLLECTIONS_LIMIT,
	FILTER_VIEW_PAGE_TYPE,
	GLOBAL_CITY_CODE,
	PAGE_TYPE,
	SEO_INDEXABLE_LANGUAGE_CODES,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

type OwnPropsType = {
	id?: string;
	cityCode?: string;
	isHeaderDropdown?: boolean;
	isFilters?: boolean;
	subCategoryCarousel?: boolean;
	lang?: string;
	isExperiencePage?: boolean;
	isOnCollectionPage?: boolean;
	isOnTicketedSubCategoryPage?: boolean;
	primaryCategory?: string | number;
};

export const mapStateToProps = (state: object, ownProps: OwnPropsType) => ({
	collectionsInfo: getCollectionById(state, ownProps.id),
	citiesMap: getCitiesMap(state),
	productListInfo: getProductListByCityCode(state, ownProps.cityCode),
	isFetching: getFetchingStatus(state),
	pageType: getCurrentPage(state),
	blogPosts: getBlogsByCollectionId({ state, collectionId: ownProps.id }),
	nearByCityCodes: getNearByCityCodes(state),
	hsid: getSandboxID(state),
	poiInfo: getPoiInfo(state, { collectionID: Number(ownProps.id) })?.[0],
});

export const mapDispatchToProps = (dispatch: any) => {
	return {
		onFetchCollectionsInfoById({
			id,
			lang,
			params,
			includeCarouselSections,
		}: any) {
			dispatch(
				fetchCollectionsInfoById({
					id,
					lang,
					params,
					...(includeCarouselSections && {
						includeCarouselSections,
					}),
				}),
			);
		},

		onMountDispatch({
			id,
			lang,
			limit,
			isDistributionPartner,
			includeCarouselSections,
			includeUnavailable,
		}: any) {
			// Prices Markup works based on h-at, so we need to refresh API call on Client Side.
			// to show markedup prices for Distribution Partner.
			if (isDistributionPartner)
				dispatch(
					fetchCollectionsInfoById({
						id,
						params: null,
						lang,
						limit,
						...(includeCarouselSections && {
							includeCarouselSections,
						}),
						...(includeUnavailable && { includeUnavailable }),
					}),
				);
		},
		onOverrideLazyload() {
			return dispatch(setLazyOverride());
		},
	};
};

export const thingsToDoProps = (state: object, ownProps: OwnPropsType) => ({
	categoriesAndSubCategoriesInfo: getCategoriesAndSubCategoriesInfo(
		state,
		ownProps,
	),
	starredCategoriesInfo: getStarredCategoriesInfoByCityCode({
		state,
		cityCode: ownProps.cityCode,
	}),
});

export const categoryDropdownProps = (
	state: object,
	ownProps: OwnPropsType,
) => {
	const collectionCardIds = getCollectionCardIdsByCityCode(
		state,
		ownProps.cityCode,
	);
	// @ts-expect-error TS(7034): Variable 'collectionInfoByCardIds' implicitly has ... Remove this comment to see the full error message
	let collectionInfoByCardIds = [];
	collectionCardIds &&
		collectionCardIds.forEach((id: any) =>
			collectionInfoByCardIds.push(getCollectionCardById(state, id)),
		);
	return {
		categoriesAndSubCategoriesInfo: getCategoriesAndSubCategoriesInfo(
			state,
			ownProps,
		),
		// @ts-expect-error TS(7005): Variable 'collectionInfoByCardIds' implicitly has ... Remove this comment to see the full error message
		collectionInfoByCardIds,
		citiesMap: getCitiesMap(state),
		currenciesMap: getCurrenciesMap(state),
	};
};

export const getCategoriesAndSubCategoriesInfo = (
	state: any,
	ownProps: OwnPropsType,
): Array<TCategoriesAndSubCategoriesInfoItem> => {
	const {
		cityCode = GLOBAL_CITY_CODE,
		isHeaderDropdown,
		isFilters,
		subCategoryCarousel,
		lang,
		isOnTicketedSubCategoryPage,
	} = ownProps;
	let categoriesByCityCode = getCategoriesInfoByCityCode(state, cityCode);
	const cityId = getCityIdByCityCode(state, cityCode);
	let categoriesAndSubCategoriesInfo = [];

	if (isHeaderDropdown) {
		categoriesAndSubCategoriesInfo.push({
			name:
				cityCode === GLOBAL_CITY_CODE
					? strings.TOP_ATTRACTION.GLOBAL
					: strings.formatString(
							strings.TOP_ATTRACTION.CITY,
							state?.city?.citiesMap?.[cityCode]?.displayName,
					  ),
			id: ALL_COLLECTIONS,
			isCategory: true,
			as: getAllPlacesToVisitURL({ lang, cityCode }),
			actualName: '',
		});
	}

	if (isFilters && cityCode && cityCode !== GLOBAL_CITY_CODE) {
		categoriesAndSubCategoriesInfo.push({
			name: strings.CATEGORY_PAGE.ALL,
			id: ALL_COLLECTIONS,
			isCategory: true,
			href: getTourListPageHref({ lang }),
			as: getTourListPageAsPath({ lang, cityCode }),
			actualName: '',
		});
	}

	categoriesByCityCode &&
		categoriesByCityCode.map((id: any) => {
			const categoryInfoById = getCategoriesInfoById(state, id, cityCode);
			const { name, subCategories, urlSlugs, displayName, heading } =
				categoryInfoById || {};
			const categoryUrlAsPath = getCategoryUrlAsPath({
				urlSlugs,
				lang,
			});
			const categoryUrlHref = getCategoryUrlHref({
				id,
				lang,
				cityId,
			});
			categoriesAndSubCategoriesInfo.push({
				actualName: name,
				name: displayName || name,
				id,
				isCategory: true,
				href: categoryUrlHref,
				as: categoryUrlAsPath,
				subCategories,
				heading,
			});
			if (subCategoryCarousel) {
				categoriesAndSubCategoriesInfo.push({
					name: strings.CATEGORY_PAGE.ALL,
					id: ALL_SUB_CATEGORIES,
					isCategory: false,
					href: categoryUrlHref,
					as: categoryUrlAsPath,
					categoryId: id,
					actualName: '',
				});
			}
			subCategories?.forEach((subCategory: any) => {
				const subCategoryInfoById = getSubCategoriesInfoById(
					state,
					subCategory,
					cityCode,
				);
				const { name, urlSlugs, displayName, heading } =
					subCategoryInfoById || {};
				const subCategoryUrlAsPath = getSubCategoryUrlAsPath({
					urlSlugs,
					lang,
				});
				const subCategoryUrlHref = getSubCategoryUrlHref({
					id: subCategory,
					lang,
					cityId,
				});
				categoriesAndSubCategoriesInfo.push({
					actualName: name,
					name: displayName || name,
					id: subCategory,
					isCategory: false,
					href: subCategoryUrlHref,
					as: subCategoryUrlAsPath,
					categoryId: id,
					heading,
				});
			});
		});

	if (isOnTicketedSubCategoryPage) {
		categoriesAndSubCategoriesInfo = categoriesAndSubCategoriesInfo.filter(
			({ id }) => id !== ALL_SUB_CATEGORIES,
		);
	}

	return categoriesAndSubCategoriesInfo;
};

export const getSubCategoriesInfoByCategoryId = (
	categoriesAndSubCategoriesInfo: Array<TCategoriesAndSubCategoriesInfoItem>,
	selectedCategoryId: number,
) => {
	let subCategoryInfo: Array<TCategoriesAndSubCategoriesInfoItem> = [];
	categoriesAndSubCategoriesInfo.forEach((category: any) => {
		const { categoryId } = category;
		if (categoryId === selectedCategoryId) subCategoryInfo.push(category);
	});
	return subCategoryInfo;
};

export const getCollectionUrlAsPath = ({
	urlSlugs,
	lang = 'en',
	city = '',
}: {
	urlSlugs: Record<string, string>;
	lang?: string;
	city?: string;
}) => {
	let slug = urlSlugs?.[getApiString(lang) || 'EN'];

	if (city) slug += `?city=${city}`;

	return slug;
};

// @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
export const getCollectionUrlHref = ({ id, lang = 'en', city = '' }) =>
	`/[lang]/collection/[id]/?id=${id}&lang=${lang}${city && `&=city${city}`}`;

// @ts-expect-error TS(7031): Binding element 'urlSlugs' implicitly has an 'any'... Remove this comment to see the full error message
export const getCategoryUrlAsPath = ({ urlSlugs, lang = 'en' }) =>
	urlSlugs?.[getApiString(lang) || 'EN'];

// @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
export const getCategoryUrlHref = ({ id, lang = 'en', cityId }) =>
	`/[lang]/category/[id]/?id=${id}&lang=${lang}&cityId=${cityId}`;

// @ts-expect-error TS(7031): Binding element 'urlSlugs' implicitly has an 'any'... Remove this comment to see the full error message
export const getSubCategoryUrlAsPath = ({ urlSlugs, lang = 'en' }) =>
	urlSlugs?.[getApiString(lang) || 'EN'];

// @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
export const getSubCategoryUrlHref = ({ id, lang = 'en', cityId }) =>
	`/[lang]/subcategory/[id]/?id=${id}&lang=${lang}&cityId=${cityId}`;

export const getAllPlacesToVisitURL = ({ lang, cityCode }: any) =>
	cityCode &&
	`${lang && lang !== 'en' ? `/${lang}` : ''}/${getPlacesToVisitSlug(
		lang,
	)}-pv-${replaceNotAlphaNumbericWithHyphen(cityCode?.toLowerCase())}/`;

export const getTourListPageAsPath = ({ lang, cityCode }: any) =>
	cityCode &&
	`${
		lang && lang !== 'en' ? `/${lang}` : ''
	}/tours/${replaceNotAlphaNumbericWithHyphen(cityCode?.toLowerCase())}/`;

export const getTourListPageHref = ({ lang }: any) =>
	`${lang && lang !== 'en' ? '/[lang]' : ''}/tours/[city]/`;

export const getSubCategoryCanonicalUrl = ({ urlSlugs, lang }: any) =>
	`${getBaseUrl()}${getSubCategoryUrlAsPath({
		urlSlugs,
		lang,
	})}`;

export const getCategoryCanonicalUrl = ({ urlSlugs, lang }: any) =>
	`${getBaseUrl()}${getCategoryUrlAsPath({
		urlSlugs,
		lang,
	})}`;

export const getCollectionCanonicalUrl = ({
	urlSlugs,
	lang,
}: {
	urlSlugs: Record<string, string>;
	lang: string;
}) =>
	`${getBaseUrl()}${getCollectionUrlAsPath({
		urlSlugs,
		lang,
	})}`;

export const getTopCollectionCardInfoByCityCode = (
	state: any,
	cityCode: any,
) => {
	const collectionIds = getCollectionCardIdsByCityCode(
		state,
		cityCode || GLOBAL_CITY_CODE,
	);
	return (
		collectionIds &&
		collectionIds.length > 0 &&
		collectionIds
			.slice(0, 3)
			.map((collectionId: any) =>
				getCollectionCardById(state, collectionId),
			)
	);
};
export const getQueryParamsFromPath = (queryString: any) => {
	let query: Record<string, any> = {};
	queryString
		.split('?')[1]
		?.split('&')
		.map(
			(param: any) => (query[param.split('=')[0]] = param.split('=')[1]),
		);
	return query;
};
export const isOldQueryParameter = (query: any) => {
	const newQueryParamsList = ['limit', 'offset'];
	return Object.keys(query).some(key => !newQueryParamsList.includes(key));
};

export const isNoIndex = ({
	isCityNoIndex = false,
	overrideNoIndexForOldQueryParams = false,
	supportedLanguages,
	lang = 'en',
	noIndexTag = false,
}: {
	isCityNoIndex?: boolean;
	overrideNoIndexForOldQueryParams?: boolean;
	supportedLanguages?: string[];
	lang?: string;
	noIndexTag?: boolean;
}) => {
	if (
		supportedLanguages &&
		!supportedLanguages?.includes(lang?.toUpperCase?.() || 'EN')
	) {
		return true;
	}

	return isCityNoIndex || noIndexTag || overrideNoIndexForOldQueryParams;
};

export const getCityPageCanonicalUrl = ({ urlSlugs, lang }: any) =>
	`${getBaseUrl()}${getCityUrlAsPath({
		urlSlugs: getDecodedUrlSlugs(urlSlugs),
		lang,
	})}`;

export const getPaginationParams = ({ query, id, type }: any) => {
	const { limit, offset } = query;
	return getStringifiedQueryFromObject({
		limit,
		offset,
		collectionId: id,
		type,
	});
};

export const getLangAlternateTags = ({
	urlSlugs,
	lang,
	queryParamsString,
	supportedLanguages = SEO_INDEXABLE_LANGUAGE_CODES,
}: any) => {
	let tags = [];

	// Show alt-lang, only if there's atleast one non english supported language.
	if (supportedLanguages.includes(lang) && supportedLanguages?.length > 1) {
		supportedLanguages.forEach((lang: any) => {
			tags.push({
				rel: 'alternate',
				hreflang: getSEOLanguageLabel(lang),
				href: `${getBaseUrl()}${
					urlSlugs?.[getApiString(lang) || 'EN']
				}${queryParamsString ? '?' + queryParamsString : ''}`,
			});
		});
		tags.push({
			rel: 'alternate',
			hreflang: 'x-default',
			href: `${getBaseUrl()}${urlSlugs?.['EN']}${
				queryParamsString ? '?' + queryParamsString : ''
			}`,
		});
	}
	return tags;
};

export const getBreadCrumbsForFilterViewPage = ({
	pageType,
	lang,
	cityDisplayName,
	cityUrlSlugs,
	categoryId,
	cityId,
	categoryUrlSlugs,
	categoryDisplayName,
	cityCode,
	name,
	localizedStrings: serverStrings,
	subCategoryDisplayName,
}: any) => {
	// @ts-expect-error TS(7034): Variable 'linkTexts' implicitly has type 'any[]' i... Remove this comment to see the full error message
	let linkTexts;
	// @ts-expect-error TS(7034): Variable 'linkHrefs' implicitly has type 'any[]' i... Remove this comment to see the full error message
	let linkHrefs;
	// @ts-expect-error TS(7034): Variable 'linkUrls' implicitly has type 'any[]' in... Remove this comment to see the full error message
	let linkUrls;
	if (serverStrings) {
		strings.setContent({
			default: serverStrings,
		});
	}

	const globalPageHref = getGlobalPageUrlHref({ lang });
	const globalPageAsPath = getGlobalPageUrlAsPath({ lang });
	const cityUrlHref = cityDisplayName
		? getCityUrlHref({ lang, cityCode })
		: '';
	const cityUrlAsPath = cityDisplayName
		? getCityUrlAsPath({
				lang,
				urlSlugs: getDecodedUrlSlugs(cityUrlSlugs),
		  })
		: '';
	const categoryUrlHref = categoryId
		? getCategoryUrlHref({
				lang,
				id: categoryId,
				cityId,
		  })
		: null;
	const categoryUrlAsPath = categoryUrlSlugs
		? getCategoryUrlAsPath({
				lang,
				urlSlugs: categoryUrlSlugs,
		  })
		: null;
	switch (pageType) {
		case FILTER_VIEW_PAGE_TYPE.PERSONA:
		case FILTER_VIEW_PAGE_TYPE.CATEGORY:
			linkTexts = cityDisplayName
				? [
						strings.BREADCRUMB_HOME,
						strings.formatString(
							strings.THINGS_TO_DO_IN_CITY,
							cityDisplayName,
						),
						categoryDisplayName,
				  ]
				: [strings.BREADCRUMB_HOME, name];
			linkHrefs = cityDisplayName
				? [globalPageHref, cityUrlHref, '']
				: [globalPageHref, ''];
			linkUrls = cityDisplayName
				? [globalPageAsPath, cityUrlAsPath, '']
				: [globalPageAsPath, ''];
			break;
		case FILTER_VIEW_PAGE_TYPE.SUB_CATEGORY:
			linkTexts = cityDisplayName
				? [
						strings.BREADCRUMB_HOME,
						strings.formatString(
							strings.THINGS_TO_DO_IN_CITY,
							cityDisplayName,
						),
						categoryDisplayName,
						subCategoryDisplayName,
				  ]
				: [strings.BREADCRUMB_HOME, categoryDisplayName, name];
			linkHrefs = cityDisplayName
				? [globalPageHref, cityUrlHref, categoryUrlHref, '']
				: [globalPageHref, categoryUrlHref, ''];
			linkUrls = cityDisplayName
				? [globalPageAsPath, cityUrlAsPath, categoryUrlAsPath, '']
				: [globalPageAsPath, categoryUrlAsPath, ''];
			break;
		case FILTER_VIEW_PAGE_TYPE.TOUR_LIST_PAGE:
			linkTexts = cityDisplayName
				? [
						strings.BREADCRUMB_HOME,
						strings.formatString(
							strings.THINGS_TO_DO_IN_CITY,
							cityDisplayName,
						),
						strings.formatString(
							strings.TOP_EXPERIENCES_IN_CITY,
							cityDisplayName,
						),
				  ]
				: [strings.BREADCRUMB_HOME, ''];
			linkHrefs = cityDisplayName
				? [globalPageHref, cityUrlHref, '']
				: [globalPageHref, ''];
			linkUrls = cityDisplayName
				? [globalPageAsPath, cityUrlAsPath, '']
				: [globalPageAsPath, ''];
			break;
		default:
			linkTexts = [];
			linkHrefs = [];
			linkUrls = [];
			break;
	}
	return {
		// @ts-expect-error TS(7005): Variable 'linkTexts' implicitly has an 'any[]' typ... Remove this comment to see the full error message
		linkTexts,
		// @ts-expect-error TS(7005): Variable 'linkUrls' implicitly has an 'any[]' type... Remove this comment to see the full error message
		linkUrls,
		// @ts-expect-error TS(7005): Variable 'linkHrefs' implicitly has an 'any[]' typ... Remove this comment to see the full error message
		linkHrefs,
	};
};

export const removeCategoryDefaultParamsKeys = (query: any) => {
	const newQuery = Object.assign({}, query);
	Object.keys(newQuery).forEach(key => {
		if (!CATEGORY_PAGE_DEFAULT_SORTING_PARAMS.includes(key)) {
			delete newQuery[key];
		}
	});
	return newQuery;
};

export const getBreadcrumbsForCollectionPage = ({
	collection,
	city,
	lang,
	localizedStrings: serverStrings,
}: any) => {
	const { displayName } = city;
	const urlSlugs = getDecodedUrlSlugs(city.urlSlugs);
	const {
		displayName: collectionDisplayName,
		cityCode,
		parentCollections,
	} = collection;
	if (serverStrings) {
		strings.setContent({
			default: serverStrings,
		});
	}
	let linkTexts = [
			strings.BREADCRUMB_HOME,
			strings.formatString(strings.THINGS_TO_DO_IN_CITY, displayName),
		],
		linkUrls = [
			getGlobalPageUrlAsPath({ lang }),
			getCityUrlAsPath({ lang, urlSlugs }),
		],
		linkHrefs = [
			getGlobalPageUrlHref({ lang }),
			getCityUrlHref({ lang, cityCode }),
		];
	if (parentCollections?.length > 0) {
		linkTexts = linkTexts.concat(
			parentCollections.map(
				(parentCollection: any) => parentCollection?.displayName,
			),
		);
		linkUrls = linkUrls.concat(
			parentCollections.map((parentCollection: any) =>
				parentCollection.active
					? getCollectionUrlAsPath({
							urlSlugs: parentCollection.urlSlugs,
							lang,
					  })
					: '',
			),
		);
		linkHrefs = linkHrefs.concat(
			parentCollections.map((parentCollection: any) =>
				parentCollection.active
					? getCollectionUrlHref({ id: parentCollection.id, lang })
					: '',
			),
		);
	}
	linkTexts.push(collectionDisplayName);
	linkHrefs.push('');
	linkUrls.push('');

	return { linkTexts, linkHrefs, linkUrls };
};

export const shouldShowCollectionRating = (ratingsInfo: {
	averageRating: number;
	ratingsCount: number;
}) => {
	if (!ratingsInfo) return false;
	const { averageRating, ratingsCount } = ratingsInfo;
	return averageRating >= 4 && ratingsCount >= 100;
};

export const doesCollectionHavePinnedCards = (collectionInfo: any) => {
	const { collectionSectionProductMap } = collectionInfo || {};

	return !!collectionSectionProductMap?.[
		COLLECTION_PAGE_SECTIONS.PINNED_CARDS
	]?.tourGroupIds?.length;
};

export const getCollectionLimit = (currentPage: string) => {
	switch (currentPage) {
		case PAGE_TYPE.HOME:
			return COLLECTIONS_LIMIT.GLOBAL_HOMEPAGE;
		case PAGE_TYPE.PLACES_TO_VISIT:
			return COLLECTIONS_LIMIT.PLACES_TO_VISIT;
		default:
			return COLLECTIONS_LIMIT.DEFAULT;
	}
};

export const getCollectionSitemapPageListURLParams = ({
	filter,
	limit,
	offset,
}: {
	filter?: string;
	limit: string;
	offset: string;
}) => {
	let apiFilter = '';
	if (filter) {
		const characterRange = filter.split('-');
		apiFilter = `start=${characterRange?.[0]}&end=${characterRange[1]}&`;
	}
	const urlParams = `${apiFilter}limit=${limit}&offset=${offset}`;
	return urlParams;
};

export const getCollectionSitemapFilterSlug = (filter: string) =>
	filter?.substring('filter'.length + 1) ?? '';

export const getCollectionSitemapPageSlug = (page: string) =>
	page.split('-')[1];

export const getCollectionSitemapBasePath = ({
	lang,
	filter,
	page,
}: {
	lang?: string;
	filter?: string;
	page?: string | number;
}) => {
	const skipPage = page && +page === 1;
	return `${
		lang && lang.toLowerCase() !== 'en' ? `/${lang}` : ``
	}/collections/${filter ? `filter-${filter}/` : ''}${
		page && !skipPage ? `p-${page}/` : ''
	}`;
};

export const getCollectionSitemapBaseHref = ({
	lang,
	filter,
	page,
}: {
	lang?: string;
	filter?: string;
	page?: string | number;
}) => {
	const skipPage = page && +page === 1;
	return `${
		lang && lang.toLowerCase() !== 'en' ? `/[lang]` : ``
	}/collections/${filter ? `[filter]/` : ''}${
		page && !skipPage ? `[page]/` : ''
	}`;
};

export const isValidTTDParams = (
	productId: string | number,
	utmSource: string,
	utmMedium: string,
) => {
	return productId && utmSource === 'google' && utmMedium === 'ttd';
};

export const getTopCollectionIds = (
	state: RootStateOrAny,
	{ collectionsIds, subcategoryId, categoryId, cityCode }: any,
) => {
	return (
		collectionsIds ||
		(subcategoryId &&
			getCollectionCardIdsBySubcategoryId(state, subcategoryId)) ||
		(categoryId && getCollectionCardIdsByCategoryId(state, categoryId)) ||
		(cityCode && getCollectionCardIdsByCityCode(state, cityCode)) ||
		[]
	);
};

export const getAboutSectionPoiInfo = (poiInfo: TPOIObj) => {
	if (!poiInfo) return {};

	const { content, operatingSchedules: poiOpSchedules } = poiInfo;
	const { data } = content || {};
	const {
		description: descriptionsList,
		nickname,
		facts,
		transportationOptions,
		entrances,
		timingNotes,
		amenities,
		accessibility,
		itemRestrictions,
		clothingRestrictions,
		guestRestrictions,
	} = data || {};
	const {
		architectedBy,
		architecturalStyle,
		expectedWaitTime,
		location,
		unescoYear,
		visitorsPerYear,
		yearOpened,
	} = poiInfo;
	const { standard } = expectedWaitTime || {};
	const { address } = location || {};

	function findOrFallback(
		array: { type: string; description: string }[] = [],
		targetType: string,
		fallbackType: string,
	) {
		if (!array) return;
		const collectionDesc = array.find(obj => obj.type === targetType);
		if (collectionDesc) {
			return collectionDesc.description;
		} else {
			return array.find(obj => obj.type === fallbackType)?.description;
		}
	}

	const aboutPoiDescription = findOrFallback(
		descriptionsList,
		COLLECTION_DESC_TYPE.COLLECTION_LANDING_PAGE,
		COLLECTION_DESC_TYPE.GENERAL,
	);
	const funFacts = facts?.map((factObj: any) => factObj.content);
	const quickInfo = {
		address,
		architectedBy,
		architecturalStyle,
		nickname,
		standardWaitTime: standard,
		unescoYear,
		visitorsPerYear,
		yearOpened,
	};

	return {
		quickInfo,
		aboutPoiDescription,
		funFacts,
		transportationOptions,
		amenities,
		accessibility,
		itemRestrictions,
		clothingRestrictions,
		guestRestrictions,
		entrances,
		timingNotes,
		poiOpSchedules,
		location,
	};
};

type Coordinates = {
	latitude: number;
	longitude: number;
};

export const getGMapUrl = ({ latitude, longitude }: Coordinates): string => {
	return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
};

export const getGMapDirectionsUrl = (
	start: Coordinates,
	end: Coordinates,
): string => {
	return `https://www.google.com/maps/dir/?api=1&origin=${start.latitude},${start.longitude}&destination=${end.latitude},${end.longitude}`;
};

export const sortPersonaTags = (personaTags: TPersonaTag[]) => {
	let groupCount = 0;
	let interestCount = 0;
	const MAX_TAGS_COUNT = 2;

	const filteredPersonaTags = personaTags.filter(tag => {
		if (tag.type === 'GROUP' && groupCount < MAX_TAGS_COUNT) {
			groupCount++;
			return true;
		}
		if (tag.type === 'INTEREST' && interestCount < MAX_TAGS_COUNT) {
			interestCount++;
			return true;
		}
		return false;
	});
	filteredPersonaTags.sort((a, b) => {
		if (a.type === 'GROUP' && b.type !== 'GROUP') {
			return -1;
		}
		if (a.type !== 'GROUP' && b.type === 'GROUP') {
			return 1;
		}
		return 0;
	});
	return filteredPersonaTags;
};
