import { getParsedHtml } from 'Utils/voucherUtils';

import { ActionTypes } from 'Actions/actions';

export const voucherReducer = (state = {}, action: any) => {
	switch (action.type) {
		case ActionTypes.SET_VOUCHER_DATA: {
			const { voucherData } = action.payload;

			// parsing html for redemption instructions
			voucherData.extraInformationHtml = getParsedHtml(
				voucherData.extraInformationHtml ?? '',
			);
			return {
				...state,
				...voucherData,
			};
		}
		default:
			return state;
	}
};
