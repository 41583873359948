/******************************
 ** Font Type Labels
 ******************************/

const TYPE_LABELS = {
	DISPLAY_LARGE: 'Display/Large',
	DISPLAY_REGULAR: 'Display/Regular',
	DISPLAY_SMALL: 'Display/Small',
	HEADING_LARGE: 'Heading/Large',
	HEADING_REGULAR: 'Heading/Regular',
	HEADING_SMALL: 'Heading/Small',
	HEADING_XS: 'Heading/XS',
	HEADING_PRODUCT_CARD: 'Heading/Product Card',
	SUBHEADING_LARGE: 'Subheading/Large',
	SUBHEADING_REGULAR: 'Subheading/Regular',
	SUBHEADING_SMALL: 'Subheading/Small',
	SUBHEADING_XS: 'Subheading/XS',
	BUTTON_BIG: 'Button/Big',
	BUTTON_MEDIUM: 'Button/Medium',
	BUTTON_SMALL: 'Button/Small',
	UI_LABEL_LARGE: 'UI/Label Large',
	UI_LABEL_MEDIUM: 'UI/Label Medium',
	UI_LABEL_REGULAR: 'UI/Label Regular',
	UI_LABEL_SMALL: 'UI/Label Small',
	UI_LABEL_XS: 'UI/Label XS',
	UI_LABEL_CAPTION: 'UI/Label Caption',
	UI_LABEL_LARGE_HEAVY: 'UI/Label Large (Heavy)',
	UI_LABEL_MEDIUM_HEAVY: 'UI/Label Medium (Heavy)',
	UI_LABEL_REGULAR_HEAVY: 'UI/Label Regular (Heavy)',
	UI_LABEL_SMALL_HEAVY: 'UI/Label Small (Heavy)',
	PARAGRAPH_LARGE: 'Paragraph/Large',
	PARAGRAPH_MEDIUM: 'Paragraph/Medium',
	PARAGRAPH_REGULAR: 'Paragraph/Regular',
	PARAGRAPH_SMALL: 'Paragraph/Small',
	PARAGRAPH_XS: 'Paragraph/XS',
	PARAGRAPH_CAPTION: 'Paragraph/Caption',
	PARAGRAPH_BLOCKQUOTE: 'Paragraph/Blockquote',
	LIST_SMALL: 'List/Small',
	LIST_REGULAR: 'List/Regular',
	LIST_MEDIUM: 'List/Medium',
	LIST_LARGE: 'List/Large',
	MISC_OVERLINE_LARGE: 'Misc/Overline Large',
	MISC_OVERLINE: 'Misc/Overline',
	MISC_TAG_HEAVY: 'Misc/Tag Heavy',
	MISC_TAG_MEDIUM: 'Misc/Tag Medium',
	MISC_TAG_REGULAR: 'Misc/Tag Regular',
	MISC_BADGE_SMALL: 'Misc/Badge Small',
	MISC_BOOSTER: 'Misc/Booster',
	TABLE_MEDIUM_HEAVY: 'Table/Medium (Heavy)',
	TABLE_MEDIUM: 'Table/Medium',
	TABLE_REGULAR_HEAVY: 'Table/Regular (Heavy)',
	TABLE_REGULAR: 'Table/Regular',
	TABLE_LARGE_HEAVY: 'Table/Large (Heavy)',
	TABLE_LARGE: 'Table/Large',
	TABLE_SMALL_HEAVY: 'Table/Small (Heavy)',
	TABLE_SMALL: 'Table/Small',
};

export default TYPE_LABELS;
