import { deferByMs } from 'Utils/gen';
import { getLazyloadOverrideStatus } from 'Utils/stateUtils';

import { setOverrideLazyload } from 'Actions/app';

export const setLazyOverride = () => (dispatch: any, getState: any) => {
	const state = getState();
	const isOverrideActive = getLazyloadOverrideStatus(state);
	if (!isOverrideActive) {
		dispatch(setOverrideLazyload());
		return deferByMs(200);
	}
};
