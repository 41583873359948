import {
	TPersonaActionParam,
	TPersonaCategoryActionParam,
} from 'ReduxTypes/persona';

export enum PersonaActions {
	REQUEST_PERSONAS_BY_CITY = 'REQUEST_PERSONAS_BY_CITY',
	RECEIVE_PERSONAS_BY_CITY = 'RECEIVE_PERSONAS_BY_CITY',
	REQUEST_CATEGORIES_BY_PERSONA = 'REQUEST_CATEGORIES_BY_PERSONA',
	RECEIVE_CATEGORIES_BY_PERSONA = 'RECEIVE_CATEGORIES_BY_PERSONA',
}

export const requestPersonasByCity = ({
	city,
}: Omit<TPersonaActionParam, 'response'>): {
	payload: Pick<TPersonaActionParam, 'city'>;
	type: PersonaActions.REQUEST_PERSONAS_BY_CITY;
} => ({
	payload: { city },
	type: PersonaActions.REQUEST_PERSONAS_BY_CITY,
});

export const receivePersonasByCity = ({
	response,
	city,
}: TPersonaActionParam): {
	payload: TPersonaActionParam;
	type: PersonaActions.RECEIVE_PERSONAS_BY_CITY;
} => ({
	payload: { response, city },
	type: PersonaActions.RECEIVE_PERSONAS_BY_CITY,
});

export const requestCategoriesByPersona = ({
	city,
	personaAffinityId,
}: Omit<TPersonaCategoryActionParam, 'response'>): {
	payload: Omit<TPersonaCategoryActionParam, 'response'>;
	type: PersonaActions.REQUEST_CATEGORIES_BY_PERSONA;
} => ({
	payload: { city, personaAffinityId },
	type: PersonaActions.REQUEST_CATEGORIES_BY_PERSONA,
});

export const receiveCategoriesByPersona = ({
	response,
	city,
	personaAffinityId,
}: TPersonaCategoryActionParam): {
	payload: TPersonaCategoryActionParam;
	type: PersonaActions.RECEIVE_CATEGORIES_BY_PERSONA;
} => ({
	payload: { response, city, personaAffinityId },
	type: PersonaActions.RECEIVE_CATEGORIES_BY_PERSONA,
});
