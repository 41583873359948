import { ActionTypes } from 'Actions/actions';

export const domainConfig = (state = {}, action: any) => {
	switch (action.type) {
		case ActionTypes.SET_DOMAIN_CONFIG: {
			return action.payload;
		}
		default:
			return state;
	}
};
