import { deviceActions } from 'Actions/device';

export const deviceStore = (state = {}, action: any) => {
	switch (action.type) {
		case deviceActions.SET_USER_AGENT: {
			const { userAgent, host, deviceType, countryCode, isUABot } =
				action;
			return {
				...state,
				host,
				userAgent,
				deviceType,
				countryCode,
				isBot: isUABot,
			};
		}
		default:
			return state;
	}
};
