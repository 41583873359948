import { isServer } from './envUtils';
import { getSmartLookKey } from './keyUtils';

export const getSmartLookUrl = () => {
	if (isServer()) {
		return null;
	}
	return (
		window &&
		(window as any).smartlook &&
		(window as any)?.smartlook?.playUrl
	);
};

export const initializeSmartLook = () => {
	const smartLookKey = getSmartLookKey();
	const script = document.createElement('script');
	script.innerHTML = `window.smartlook||(function(d) {var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);})(document);smartlook('init', '${smartLookKey}');`;
	script.type = 'text/javascript';
	document.head.appendChild(script);
};
