import { TPropertiesSelectionState } from 'Containers/desktop/sharedAirportTransfers/interface';

import {
	DIRECTION_OPTIONS,
	TOUR_PROPERTY_TYPE,
} from 'Constants/sharedAirportTransfers';

import { TTourIdPropertiesMap } from './types';

/**
 * Matches the `TO_FROM_AIRPORT` tour direction property with `FROM_AIRPORT` or `TO_AIRPORT` selection state.
 */
export function isDirectionCompatibleWithBothAirportDirections(
	selectedProperty: {
		value: string | null;
		type: string;
	},
	tourProperty: {
		value: string | null;
		type: string;
	},
): boolean {
	return (
		selectedProperty.type === TOUR_PROPERTY_TYPE.DIRECTION &&
		tourProperty.value === 'TO_FROM_AIRPORT' &&
		(selectedProperty.value === DIRECTION_OPTIONS.FROM_AIRPORT ||
			selectedProperty.value === DIRECTION_OPTIONS.TO_AIRPORT)
	);
}

export const filterTourPropertiesMap = (
	availableTourIdPropertiesMap: TTourIdPropertiesMap,
	selectedProperty: {
		value: string | null;
		type: string;
	},
) => {
	const filteredTourPropertiesMap = {} as TTourIdPropertiesMap;

	for (const tourId in availableTourIdPropertiesMap) {
		const tourProperties = availableTourIdPropertiesMap[tourId];
		const matchingProperties = tourProperties.filter(tourProperty => {
			// true if property does not have a value in tourProperties
			if (selectedProperty.value === null) {
				return true;
			}
			// Special case when tourProperty has DIRECTION === 'TO_FROM_AIRPORT'
			if (
				isDirectionCompatibleWithBothAirportDirections(
					selectedProperty,
					tourProperty,
				)
			) {
				return true;
			}

			return (
				tourProperty.type === selectedProperty.type &&
				tourProperty.value === selectedProperty.value
			);
		});

		if (matchingProperties.length) {
			filteredTourPropertiesMap[tourId] = tourProperties;
		}
	}

	return filteredTourPropertiesMap;
};

export function findMatchingToursBasedOnSelection(
	tourIdPropertiesMap: TTourIdPropertiesMap,
	selectionState: TPropertiesSelectionState,
): string[] {
	const matchingKeys: string[] = [];

	Object.entries(tourIdPropertiesMap).forEach(([tourId, properties]) => {
		const isMatch = properties.every(({ type, value }) => {
			if (
				selectionState[type as keyof TPropertiesSelectionState] == null
			) {
				return true; // Skip comparison if selection state for this property is null or undefined
			}
			// Special case when tourProperty has DIRECTION === 'TO_FROM_AIRPORT'
			if (
				isDirectionCompatibleWithBothAirportDirections(
					{
						type,
						value: selectionState[
							type as keyof TPropertiesSelectionState
						],
					},
					{ type, value },
				)
			) {
				return true;
			}

			return (
				selectionState[type as keyof TPropertiesSelectionState] ===
				value
			);
		});

		if (isMatch) {
			matchingKeys.push(tourId);
		}
	});
	return matchingKeys;
}
