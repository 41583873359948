import {
	CDN_IMGIX_BASE_SERVER,
	CDN_IMGIX_OPEN_BASE_SERVER,
	CDN_IMGIX_PRISMIC,
	DEFAULT_IMAGE_DENSITY,
	ENTITY_ICONS_FOLDER_LINK,
	FLAGS_FOLDER_URL,
	PERSONA_ASSETS_FOLDER_LINK,
} from 'Constants/constants';

// Some Imgix URls being called have prefxied Query Params. Remove them.
export const getSanitizedImgixUrl = (url?: string) => {
	return url ? url.split('?')[0] : '';
};

/**
 * Imgixify the url based on image tag/view width and height
 */
export const getImgixImageUrlFixed = ({
	url,
	width,
	height,
	compression,
}: {
	url: string;
	width?: number;
	height?: number;
	compression?: number | string;
}) => {
	const urlCopy = getSanitizedImgixUrl(url).replace(
		'headout-s3.imgix.net',
		CDN_IMGIX_BASE_SERVER,
	);
	const w = width ? `&w=${width * 1.5}` : '';
	const h = height ? `&h=${height * 1.5}` : '';
	if (compression) {
		return `${urlCopy}?q=${compression}&fm=pjpg${w}${h}&fit=min&crop=faces`;
	}
	return `${urlCopy}?auto=compress&fm=pjpg${w}${h}&fit=min&crop=faces`;
};

// Add the Suffix to Image Url for imgix images
export const getImgixCompressedImageUrl = (url: string) => {
	const urlCopy = getSanitizedImgixUrl(url).replace(
		/cdn-s3.headout.com|headout-s3.imgix.net/,
		CDN_IMGIX_BASE_SERVER,
	);
	return `${urlCopy}?auto=compress&fit=min&fm=pjpg`;
};

export const getImgixUrl = ({
	url,
	height = null,
	width = null,
	quality = 100,
}: {
	url: string;
	height?: number | null;
	width?: number | null;
	quality?: number;
}) => {
	// @Note - Weird why do we even have reference on headout-s3.imgix.net.
	const urlCopy =
		url && url.indexOf('.gif') === -1
			? getSanitizedImgixUrl(url).replace(
					/cdn-s3.headout.com|headout-s3.imgix.net/,
					CDN_IMGIX_BASE_SERVER,
			  )
			: url;
	return `${urlCopy}?auto=format&h=${height || ''}&w=${
		width || ''
	}&crop=faces&fit=min&fm=jpeg&q=${quality || ''}`;
};

// Add the Suffix to Image Url for imgix images
export const getImgixImageUrl = (url: string) => {
	return getImgixImageUrlFixed({ url, width: 336, height: 210 });
};

export const getImgixCarouselImageUrl = (url: string) => {
	return getImgixImageUrlFixed({ url, width: 560, height: 321 });
};

export const getImgixCategoryCardImageUrl = (url: string) => {
	return getImgixImageUrlFixed({ url, width: 266, height: 160 });
};

export const getLargerFacebookImage = (
	width: string,
	height: string,
	imageUrl: string,
) => {
	const regex = /\?type=large/g;
	const subst = `?width=${width}&height=${height}`;
	return imageUrl.replace(regex, subst);
};

export const makeIMGIXUrl = ({
	url,
	width,
	height,
	density = DEFAULT_IMAGE_DENSITY,
	fm = null,
	quality = null,
	fit = 'min',
	enhance = false,
	cropFaces = true,
}: {
	url: string;
	width?: number;
	height?: number;
	density?: number;
	fm?: string | null;
	quality?: string | null;
	enhance?: boolean;
	fit?: string | null;
	cropFaces?: boolean;
}) => {
	const query = new URLSearchParams();
	const format = url?.includes('.svg') ? 'svg' : fm;

	if (width) query.set('w', (width * density).toString());
	if (height) query.set('h', (height * density).toString());
	if (quality) query.set('q', quality);
	if (format) query.set('fm', format);
	if (cropFaces) query.set('crop', 'faces');

	query.set('auto', enhance ? 'enhance' : 'compress,format');
	query.set('fit', !fit ? 'min' : fit);

	return `${url}?${query.toString()}`;
};

export const sanitiseURLProtocol = (url: string, queryString = '') => {
	const trimmedUrl = url.trim();
	return trimmedUrl && !trimmedUrl.startsWith('/')
		? `https://${trimmedUrl.replace(/(http)?[s]?(:)?(\/\/)?/i, '')}${
				queryString ? `?${queryString}` : ''
		  }`
		: trimmedUrl;
};

export const sanitiseImageUrl = ({ url }: { url: string }) => {
	let updatedUrl = url;
	updatedUrl = updatedUrl?.replace(
		's3.amazonaws.com/tourlandish',
		CDN_IMGIX_BASE_SERVER,
	);
	updatedUrl = updatedUrl?.replace(
		's3.amazonaws.com/headout-open',
		CDN_IMGIX_OPEN_BASE_SERVER,
	);
	updatedUrl = updatedUrl?.replace(
		'cdn-s3-open.headout.com',
		CDN_IMGIX_OPEN_BASE_SERVER,
	);
	updatedUrl = updatedUrl?.replace(
		/prismic-io.s3.amazonaws.com|mystique.cdn.prismic.io/,
		CDN_IMGIX_PRISMIC,
	);

	updatedUrl = sanitiseURLProtocol(updatedUrl);

	return updatedUrl;
};

export const imgixLoader = ({
	src,
	width,
	quality = null,
}: {
	src: string;
	width: number;
	quality?: boolean | null;
}) => {
	const url = new URL(src);
	const params = url.searchParams;

	params.set('auto', params.get('auto') || 'format');
	params.set('fit', params.get('fit') || 'max');
	params.set('w', params.get('w') || width.toString());

	if (quality) {
		params.set('q', (quality as any).toString());
	}

	return url.href;
};

export const getCategoryIconUrl = ({
	entityId,
	isCategory = false,
}: {
	entityId: number | string;
	isCategory?: boolean;
}) => {
	const filePrefix = isCategory ? 'cat_' : 'sub_';
	return `${ENTITY_ICONS_FOLDER_LINK}/${filePrefix}${entityId}.svg`;
};

export const getCountryFlagUrl = (countryCode?: string) =>
	FLAGS_FOLDER_URL + '/' + countryCode?.toLowerCase() + '.svg';

export const getPersonaIconById = (id: number) =>
	`${PERSONA_ASSETS_FOLDER_LINK}/icons/${id}.svg`;

export const applyPrimaryColorFromImage = (
	imageUrl: string,
	container: any,
) => {
	if (!imageUrl) return;

	let primaryColor: any;

	const img = new Image();
	const canvas = document.createElement('canvas');
	const context: any = canvas.getContext('2d');

	img.crossOrigin = 'Anonymous';

	img.onload = function () {
		canvas.width = img.width;
		canvas.height = img.height;
		context.drawImage(img, 0, 0, img.width, img.height);

		const imageData = context.getImageData(
			0,
			0,
			img.width,
			img.height,
		).data;
		const colorCount: any = {};

		for (
			let i = 0;
			i < imageData.length;
			i += parseInt((imageData.length / 50) as any)
		) {
			const r = imageData[i];
			const g = imageData[i + 1];
			const b = imageData[i + 2];
			const rgb = `${r},${g},${b}`;
			colorCount[rgb] = (colorCount[rgb] || 0) + 1;
		}

		let maxCount = 0;

		for (const color in colorCount) {
			if (colorCount[color] > maxCount) {
				maxCount = colorCount[color];
				primaryColor = color;
			}
		}

		container.style.background = `linear-gradient(180deg, rgba(${primaryColor}, 0.1) 0%, #fff 100%)`;
	};

	img.src = imageUrl;
};
