import { ActionTypes } from 'Actions/actions';

const initialState: { activeModal: string | null; overrideLazyload: boolean } =
	{
		activeModal: null,
		overrideLazyload: false,
	};

export const app = (
	state = initialState,
	action: { type: string; payload: any },
) => {
	switch (action.type) {
		case ActionTypes.SET_ACTIVE_MODAL:
			return {
				...state,
				activeModal: action.payload,
			};
		case ActionTypes.SET_OVERRIDE_LAZYLOAD:
			return {
				...state,
				overrideLazyload: true,
			};
		default:
			return state;
	}
};
