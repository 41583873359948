import { ParsedUrlQuery } from 'querystring';

import { TPropertiesSelectionState } from 'Containers/desktop/sharedAirportTransfers/interface';

import { getInventoryWithMinPrice } from 'Utils/pricingUtils';

import { TOUR_PROPERTY_TYPE } from 'Constants/sharedAirportTransfers';

import { filterTourPropertiesMap } from './filteringUtils';
import { ITour, TTourIdPropertiesMap } from './types';

export const generateTourIdPropertiesMap = (tours: ITour[]) => {
	const tourIdPropertiesMap: TTourIdPropertiesMap = {};
	tours.forEach((tour: any) => {
		if (tour.additionalProperties?.length) {
			tourIdPropertiesMap[tour.id] = tour.additionalProperties;
		}
	});
	return tourIdPropertiesMap;
};

export const generatePotentialPropertyValuesMap = (
	tours: ITour[],
): Record<string, string[]> => {
	const allProperties = tours.flatMap(tour => tour.additionalProperties);

	const typeValuesMap = {} as Record<string, Set<string> | string[]>;

	allProperties?.forEach(property => {
		const { type, value } = property || {};

		if (!typeValuesMap[type]) {
			typeValuesMap[type] = new Set();
		}

		(typeValuesMap[type] as Set<string>).add(value);
	});

	// convert sets to arrays
	for (const type in typeValuesMap) {
		typeValuesMap[type] = Array.from(typeValuesMap[type]);
	}

	return typeValuesMap as Record<string, string[]>;
};

export const getAvailableTourIdPropertiesMap = (
	tours: ITour[],
	availableToursIds: number[],
) => {
	const availableTourIdPropertiesMap: TTourIdPropertiesMap = {};

	const tourIdProperitesMap = generateTourIdPropertiesMap(tours);

	availableToursIds.forEach(tourId => {
		availableTourIdPropertiesMap[tourId] = tourIdProperitesMap[tourId];
	});

	return availableTourIdPropertiesMap;
};

export const getAvailablePropertyValues = (
	tourIdPropertiesMap: TTourIdPropertiesMap,
	propertyType: string,
) =>
	new Set(
		Object.values(tourIdPropertiesMap)
			.flat()
			.filter(v => v.type === propertyType)
			.map(v => v.value),
	);

export const getInitialSelectionState = ({
	allAvailableTourIdPropertiesMap,
	selectedTourId,
	potentialPropertyValuesMap,
	query,
}: {
	allAvailableTourIdPropertiesMap: TTourIdPropertiesMap;
	selectedTourId: number | null;
	potentialPropertyValuesMap: Record<
		keyof TPropertiesSelectionState,
		string[]
	>;

	query?: ParsedUrlQuery;
}) => {
	// If a tour is selected, return the properties of the selected tour
	if (selectedTourId) {
		return allAvailableTourIdPropertiesMap[selectedTourId].reduce(
			(acc, property) => {
				// @ts-expect-error TS(2532): I have no idea what the problem is will fix later
				acc[property.type] = property.value;
				return acc;
			},
			{} as TPropertiesSelectionState,
		);
	}

	const {
		direction: directionFromQuery,
		destination: destinationFromQuery,
		tripType: tripTypeFromQuery,
	} = query || {};

	const availableTripTypes = getAvailablePropertyValues(
		allAvailableTourIdPropertiesMap,
		TOUR_PROPERTY_TYPE.TRIP_TYPE,
	);

	const tripType = availableTripTypes.has(tripTypeFromQuery as string)
		? (tripTypeFromQuery as string)
		: availableTripTypes.has('ONE_WAY')
		? 'ONE_WAY'
		: 'ROUND_TRIP';

	const tripTypeFilteredTourPropertiesMap = filterTourPropertiesMap(
		allAvailableTourIdPropertiesMap,
		{
			value: tripType,
			type: TOUR_PROPERTY_TYPE.TRIP_TYPE,
		},
	);

	const availableDirections = getAvailablePropertyValues(
		tripTypeFilteredTourPropertiesMap,
		TOUR_PROPERTY_TYPE.DIRECTION,
	);

	const direction = availableDirections.has(directionFromQuery as string)
		? (directionFromQuery as string)
		: availableDirections.has('FROM_AIRPORT') ||
		  availableDirections.has('TO_FROM_AIRPORT')
		? 'FROM_AIRPORT'
		: 'TO_AIRPORT';

	const directionFilteredTourPropertiesMap = filterTourPropertiesMap(
		tripTypeFilteredTourPropertiesMap,
		{
			value: direction,
			type: TOUR_PROPERTY_TYPE.DIRECTION,
		},
	);

	const airportName = Array.from(
		getAvailablePropertyValues(
			directionFilteredTourPropertiesMap,
			TOUR_PROPERTY_TYPE.AIRPORT,
		).values(),
	)?.[0];

	const potentialTerminals = potentialPropertyValuesMap?.TERMINAL;

	const potentialDestinations = potentialPropertyValuesMap.DESTINATION;

	const initialSelectionState = {
		TRIP_TYPE: tripType,
		DIRECTION: direction,
		AIRPORT: null,
		TERMINAL: null,
		DESTINATION: null,
		TIER: potentialPropertyValuesMap.TIER?.length ? null : undefined,
	} as TPropertiesSelectionState;

	// Multiple terminals and destinations
	if (potentialTerminals?.length > 1 && potentialDestinations.length > 1) {
		return initialSelectionState;
	}

	// Multiple terminals, single destination
	if (potentialTerminals?.length > 1) {
		return {
			...initialSelectionState,
			TERMINAL: null,
			DESTINATION: Array.from(potentialDestinations.values())?.[0],
		};
	}
	// Single terminal, multiple destinations
	if (potentialDestinations.length > 1) {
		return {
			...initialSelectionState,
			AIRPORT: airportName,
			TERMINAL: potentialTerminals?.[0] ?? null,
			DESTINATION: potentialDestinations.includes(
				destinationFromQuery as string,
			)
				? (destinationFromQuery as string)
				: null,
		};
	}

	// Single terminal, single destination

	return {
		...initialSelectionState,
		AIRPORT: airportName,
		DESTINATION: Array.from(potentialDestinations.values())?.[0],
	};
};

export function sequentiallyFilterProperties(
	availableTourIdProperitesMap: TTourIdPropertiesMap,
	selectionState: TPropertiesSelectionState,
) {
	// Define the filtering sequence based on direction
	const filterSequence =
		selectionState.DIRECTION === 'FROM_AIRPORT'
			? ([
					'TRIP_TYPE',
					'DIRECTION',
					'TERMINAL',
					'DESTINATION',
					'TIER',
			  ] as const)
			: ([
					'TRIP_TYPE',
					'DIRECTION',
					'DESTINATION',
					'TERMINAL',
					'TIER',
			  ] as const);

	const availableProperties = {} as Record<
		keyof TPropertiesSelectionState,
		Set<string>
	>;

	let finalFilteredTourPropertiesMap = availableTourIdProperitesMap;

	filterSequence.forEach(filterType => {
		// Update available properties for the current filter type
		availableProperties[filterType] = getAvailablePropertyValues(
			finalFilteredTourPropertiesMap,
			filterType,
		);

		// Apply the filter if a selection state exists for the current filter type
		if (selectionState[filterType as keyof TPropertiesSelectionState]) {
			finalFilteredTourPropertiesMap = filterTourPropertiesMap(
				finalFilteredTourPropertiesMap,
				{
					value: selectionState[
						filterType as keyof TPropertiesSelectionState
					],
					type: filterType,
				},
			);
		}
	});

	return {
		filteredTourPropertiesMap: finalFilteredTourPropertiesMap,
		availableProperitesBasedOnSelection: availableProperties,
	};
}

export const getNearestDateWithFromAirportTour = (
	tours: ITour[],
	availabilities: any,
) => {
	const tourIdPropertiesMap = generateTourIdPropertiesMap(tours);

	const tourIds = Object.keys(tourIdPropertiesMap);

	const tourIdsWithFromAirportProperty = tourIds.filter(
		tourId =>
			tourIdPropertiesMap[Number(tourId)]?.find(
				property =>
					property.type === TOUR_PROPERTY_TYPE.DIRECTION &&
					property.value === 'FROM_AIRPORT',
			) !== undefined,
	);

	if (!tourIdsWithFromAirportProperty?.length) {
		return null;
	}

	const availabilitiesWithFromAirportProperty = availabilities.filter(
		(a: Record<string, any>) =>
			tourIdsWithFromAirportProperty.includes(String(a.tourId)),
	);

	const nearestDateInventory = availabilitiesWithFromAirportProperty?.[0];

	if (!nearestDateInventory) {
		return null;
	}

	return nearestDateInventory.startDate;
};

export const isSelectionComplete = (
	selectionState: TPropertiesSelectionState,
	potentialPropertyValuesMap: Record<
		keyof TPropertiesSelectionState,
		string[]
	>,
) => {
	const { DESTINATION, TERMINAL, TIER } = selectionState || {};

	if (!DESTINATION) return false;

	if (potentialPropertyValuesMap.TERMINAL?.length > 1 && !TERMINAL) {
		return false;
	}

	if (potentialPropertyValuesMap.TIER && !TIER) {
		return false;
	}

	return true;
};
