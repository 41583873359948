import { ActionTypes } from 'Actions/actions';

const initialState = {
	code: 'en',
	isFetching: false,
	languages: [],
	localizedContentLanguageCode: 'en',
};

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
export const languageReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.CHANGE_LANGUAGE: {
			return {
				...state,
				code: action.code,
				localizedContentLanguageCode: action.code,
			};
		}
		case ActionTypes.REQUEST_LANGUAGES: {
			return {
				...state,
				isFetching: true,
			};
		}
		case ActionTypes.RECEIVE_LANGUAGES: {
			const { list } = action;
			const languages = list?.map((item: any) => item) || [];
			return {
				...state,
				isFetching: false,
				languages,
			};
		}
		case ActionTypes.CHANGE_LOCALIZED_CONTENT_LANGUAGE: {
			const { lang } = action;
			return {
				...state,
				localizedContentLanguageCode: lang,
			};
		}
		default:
			return state;
	}
};
