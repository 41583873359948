/*eslint sort-keys: ["error", "asc", {natural: true}]*/
import Experiment from 'Utils/experiments/experiment';

export const VARIANTS = {
	CONTROL: 'Control',
	DISABLE_GOOGLE_PAY: 'Control',
	ENABLE_GOOGLE_PAY: 'Treatment',
	HIDE_AUDIO_GUIDE: 'HIDE',
	HIDE_COMBO_UPSELL_SCREEN: 'Control',
	HIDE_FULFILLED_BY_HEADOUT: 'Control',
	HIDE_MULTI_CITIES: 'Control',
	HIDE_NEW_EXPERIENCE_PAGE_DESCRIPTORS: 'Control',
	HIDE_REVAMP_SELECT_PAGE: 'Control',
	HIDE_REVIEW_MEDIA: 'Control',
	LOAD_USER_RECORDING_SCRIPT: 'LOAD',
	NEGLECT_USER_RECORDING_SCRIPT: 'NEGLECT',
	PROXIMITY_CONTROL: 'CONTROL',
	PROXIMITY_TREATMENT: 'TREATMENT',
	SHOW_AUDIO_GUIDE: 'SHOW',
	SHOW_COMBO_UPSELL_SCREEN: 'Treatment',
	SHOW_FULFILLED_BY_HEADOUT: 'Treatment',
	SHOW_MULTI_CITIES: 'Treatment',
	SHOW_NEW_CHECKOUT_SCREEN: 'Treatment',
	SHOW_NEW_EXPERIENCE_PAGE_DESCRIPTORS: 'Treatment',
	SHOW_OLD_CHECKOUT_SCREEN: 'Control',
	SHOW_REVAMP_SELECT_PAGE: 'Treatment',
	SHOW_REVIEW_MEDIA: 'Treatment',
	TREATMENT: 'Treatment',
	TREATMENT_A: 'Treatment_A',
	TREATMENT_B: 'Treatment_B',
};

const COMBO_UPSELL_SCREEN_EXPERIMENT = 'Combo Upgrade Screen Experiment 2';
const GOOGLE_PAY = 'Google Pay';
const GUIDED_TOUR = 'DWEB Guided Tours Booking Flow Upgrade Experiment';
const HOLIDAY_BANNER_VISIBILITY = 'Holiday ProductBanner Visibility';
const MB_FULLFILLED_BY_HEADOUT = 'Fulfilled By Headout Banner Experiment';
const MULTI_CITIES_CONFIRMATION = 'Cross-City Recommendations Experiment';
const PROXIMITY_CONF_PAGE_EXPERIMENT = 'Confirmation Page Proximity Experiment';
const REVAMP_SELECT_PAGE = 'Revamped Select Page - 2';
const REVIEW_MEDIA_VISIBILITY = 'Review Media Experiment';
const SELECT_SCREEN_PAX = 'Dweb Pax Selection on Select Page 2024';
const SELECT_REVAMP = 'Select Page Revamp 2.5';
const HOHO_REVAMP = 'HOHO Select Page';
const CHECKOUT_SCREEN_REDESIGN_EXPERIMENT = 'Checkout Page Revamp';
const CROSS_SELL_EXPERIMENT = 'Cross Sell Experiment';
const BROADWAY_SVG_EXPERIMENT = 'Broadway SVG Section Selection';

export const EXPERIMENT_NAMES = {
	BROADWAY_SVG_EXPERIMENT,
	CHECKOUT_SCREEN_REDESIGN_EXPERIMENT,
	COMBO_UPSELL_SCREEN_EXPERIMENT,
	CROSS_SELL_EXPERIMENT,
	GOOGLE_PAY,
	GUIDED_TOUR,
	HOHO_REVAMP,
	HOLIDAY_BANNER_VISIBILITY,
	MB_FULLFILLED_BY_HEADOUT,
	MULTI_CITIES_CONFIRMATION,
	PROXIMITY_CONF_PAGE_EXPERIMENT,
	REVAMP_SELECT_PAGE,
	REVIEW_MEDIA_VISIBILITY,
	SELECT_REVAMP,
	SELECT_SCREEN_PAX,
};

export const EXPERIMENTS = {
	[BROADWAY_SVG_EXPERIMENT]: new Experiment(
		BROADWAY_SVG_EXPERIMENT,
		[VARIANTS.TREATMENT, VARIANTS.CONTROL],
		[50, 50],
	),
	[CHECKOUT_SCREEN_REDESIGN_EXPERIMENT]: new Experiment(
		CHECKOUT_SCREEN_REDESIGN_EXPERIMENT,
		[VARIANTS.SHOW_NEW_CHECKOUT_SCREEN, VARIANTS.SHOW_OLD_CHECKOUT_SCREEN],
		[100, 0],
	),
	[COMBO_UPSELL_SCREEN_EXPERIMENT]: new Experiment(
		COMBO_UPSELL_SCREEN_EXPERIMENT,
		[VARIANTS.HIDE_COMBO_UPSELL_SCREEN, VARIANTS.SHOW_COMBO_UPSELL_SCREEN],
		[100, 0],
	),
	[CROSS_SELL_EXPERIMENT]: new Experiment(
		CROSS_SELL_EXPERIMENT,
		[VARIANTS.TREATMENT, VARIANTS.CONTROL],
		[0, 100],
	),
	[GOOGLE_PAY]: new Experiment(
		GOOGLE_PAY,
		[VARIANTS.ENABLE_GOOGLE_PAY, VARIANTS.DISABLE_GOOGLE_PAY],
		[100, 0],
	),
	[MB_FULLFILLED_BY_HEADOUT]: new Experiment(
		MB_FULLFILLED_BY_HEADOUT,
		[
			VARIANTS.HIDE_FULFILLED_BY_HEADOUT,
			VARIANTS.SHOW_FULFILLED_BY_HEADOUT,
		],
		[50, 50],
	),
	[MULTI_CITIES_CONFIRMATION]: new Experiment(
		MULTI_CITIES_CONFIRMATION,
		[VARIANTS.SHOW_MULTI_CITIES, VARIANTS.HIDE_MULTI_CITIES],
		[50, 50],
	),
	[PROXIMITY_CONF_PAGE_EXPERIMENT]: new Experiment(
		PROXIMITY_CONF_PAGE_EXPERIMENT,
		[VARIANTS.PROXIMITY_TREATMENT, VARIANTS.PROXIMITY_CONTROL],
		[50, 50],
	),
	[REVAMP_SELECT_PAGE]: new Experiment(
		REVAMP_SELECT_PAGE,
		[VARIANTS.SHOW_REVAMP_SELECT_PAGE, VARIANTS.HIDE_REVAMP_SELECT_PAGE],
		[0, 100],
	),
	[REVIEW_MEDIA_VISIBILITY]: new Experiment(
		REVIEW_MEDIA_VISIBILITY,
		[VARIANTS.SHOW_REVIEW_MEDIA, VARIANTS.HIDE_REVIEW_MEDIA],
		[100, 0],
	),
	[SELECT_REVAMP]: new Experiment(
		EXPERIMENT_NAMES.SELECT_REVAMP,
		[VARIANTS.CONTROL, VARIANTS.TREATMENT],
		[100, 0],
	),
	[SELECT_SCREEN_PAX]: new Experiment(
		SELECT_SCREEN_PAX,
		[VARIANTS.TREATMENT, VARIANTS.CONTROL],
		[0, 100],
	),
	USER_RECORDING_SCRIPT_RANDOMIZATION: new Experiment(
		'user_recording_script_randomization',
		[
			VARIANTS.NEGLECT_USER_RECORDING_SCRIPT,
			VARIANTS.LOAD_USER_RECORDING_SCRIPT,
		],
		[98, 2],
	),
};
