import { getSeatSelectionValidationData } from 'Utils/seatmapUtils';

import { ActionTypes } from 'Actions/actions';

const initialState = {
	isFetching: null,
	invalidBookableFromSelectedSeats: null,
	isSelectedSeatsValid: null,
	modifiedInvalidBookableFromSelectedSeats: null,
};

export const selectedSeatsValidationDetails = (
	state = initialState,
	action: any,
) => {
	switch (action.type) {
		case ActionTypes.REQUEST_VALIDATED_SEAT_SELECTION: {
			return {
				...state,
				isFetching: true,
				invalidBookableFromSelectedSeats: null,
			};
		}
		case ActionTypes.RECEIVE_VALIDATED_SEAT_SELECTION: {
			const { validationData } = action;
			const { seatsSelectionValidationInfo, isSelectedSeatsValid } =
				getSeatSelectionValidationData(validationData);
			return {
				...state,
				isFetching: false,
				invalidBookableFromSelectedSeats: seatsSelectionValidationInfo,
				modifiedInvalidBookableFromSelectedSeats:
					seatsSelectionValidationInfo,
				isSelectedSeatsValid: isSelectedSeatsValid,
			};
		}
		case ActionTypes.DELETE_INVALID_SELECTED_SEATS: {
			const { bookableId } = action;
			let modifiedInvalidBookableFromSelectedSeats =
				state?.modifiedInvalidBookableFromSelectedSeats;
			if (modifiedInvalidBookableFromSelectedSeats)
				delete modifiedInvalidBookableFromSelectedSeats[bookableId];
			return {
				...state,
				modifiedInvalidBookableFromSelectedSeats:
					modifiedInvalidBookableFromSelectedSeats,
			};
		}
		case ActionTypes.CLEAR_SEAT_VALIDATION_SELECTION:
			return initialState;
		default:
			return state;
	}
};
