export type CheckoutProps = {
	paymentCard: any;
	onCardHolderNameChange: (...args: any[]) => any;
	setCardValidity: (...args: any[]) => any;
	setCheckoutPaymentFieldValidities: (...args: any[]) => any;
	forceValidate: boolean;
	publicKey: string;
	isMobile?: boolean;
	paymentGateway: string;
};

export enum CheckoutFieldType {
	CARD_NUMBER = 'card-number',
	EXPIRY_DATE = 'expiry-date',
	CVV = 'cvv',
}

export const CHECKOUT_ANALYTICS_FIELD_NAME = {
	'card-number': 'Card Number',
	'expiry-date': 'Expiry',
	cvv: 'CVV',
};
