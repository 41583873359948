import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import cookie from 'nookies';

import { error } from 'Utils/logUtils';

import {
	HASOFFER_OFFER_ID_COOKIE_KEY,
	HASOFFER_OFFER_ID_KEY,
	HASOFFER_TRANSACTION_ID_COOKIE_KEY,
	HASOFFER_TRANSACTION_ID_KEY,
} from 'Constants/constants';

const TIME_ONE_MONTH = 30 * 24 * 3600;

type HasOffersProps = {
	location?: any;
}; // in secs

class HasOffers extends React.Component<HasOffersProps> {
	componentDidMount() {
		const { location } = this.props;
		const hasofferTransactionId =
			location.query[HASOFFER_TRANSACTION_ID_KEY];
		const hasofferOfferId = location.query[HASOFFER_OFFER_ID_KEY];
		if (hasofferOfferId && hasofferTransactionId) {
			cookie.set(
				// @ts-expect-error TS(2345): Argument of type '"hasoffer_transaction_id"' is no... Remove this comment to see the full error message
				HASOFFER_TRANSACTION_ID_COOKIE_KEY,
				hasofferTransactionId,
				{ maxAge: TIME_ONE_MONTH, path: '/' },
			);
			// @ts-expect-error TS(2345): Argument of type '"hasoffer_offer_id"' is not assi... Remove this comment to see the full error message
			cookie.set(HASOFFER_OFFER_ID_COOKIE_KEY, hasofferOfferId, {
				maxAge: TIME_ONE_MONTH,
				path: '/',
			});
		}
	}

	componentDidCatch(err: any, errInfo: any) {
		error(err, errInfo);
	}

	render() {
		return null;
	}
}

// @ts-expect-error TS(6133): 'state' is declared but its value is never read.
const mapStateToProps = (state, ownProps) => ({
	location: ownProps.router,
});
const connectHasOffers = withRouter(connect(mapStateToProps)(HasOffers));

export default connectHasOffers;
