import { DEVICE_TYPE } from 'Constants/constants';

class PlatformUtils {
	static ua: string | null = null;

	static canUseDOM = () =>
		!!(
			typeof window !== 'undefined' &&
			window.document &&
			window.document.createElement
		);

	static cdnDetectDevice(req: any) {
		if (
			req &&
			req.headers &&
			req.headers['cloudfront-is-mobile-viewer'] === 'true'
		) {
			PlatformUtils.ua =
				'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1';
			return DEVICE_TYPE.MOBILE;
		}

		if (
			req &&
			req.headers &&
			req.headers['cloudfront-is-desktop-viewer'] === 'true'
		) {
			PlatformUtils.ua =
				'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/65.0.3325.181 Safari/537.36';
			return DEVICE_TYPE.DESKTOP;
		}

		return PlatformUtils.detectDevice(
			req ? req.headers['user-agent'] : navigator.userAgent,
		);
	}

	static detectDevice(userAgent?: string) {
		// This might be redundant, as we will always have UA as it will be initialized by store.
		let latestUserAgent = userAgent || PlatformUtils.ua;

		// If no user agent, try to fetch it from window
		if (!latestUserAgent && this.canUseDOM()) {
			latestUserAgent =
				navigator.userAgent ||
				navigator.vendor ||
				(window as any).opera;
		}

		// If no user agent, defaults to DESKTOP.
		if (!latestUserAgent) {
			return DEVICE_TYPE.DESKTOP;
		}

		// Set user agent for latest use
		PlatformUtils.ua = latestUserAgent;
		const mobileRegex = new RegExp(
			[
				'(android|bb\\d+|meego).+mobile|avantgo|bada\\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle',
				'|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\\.',
				'(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk|',
				'Mobile|iP(hone|od|ad)|Android|BlackBerry|', // these two lines are picked up from the sane solution in https://gist.github.com/dalethedeveloper/1503252
				'IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune',
			].join(''),
			'i',
		);
		const mobileRegex2 = new RegExp(
			[
				'/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac',
				'|ac(er|oo|s\\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|',
				'br(e|v)w|bumb|bw\\-(n|u)|c55\\/|capi|ccwa|cdm\\-|cell|chtm|cldc|cmd\\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\\-s|devi|dica|dmob|do(c|p)o|ds(12|\\-d)',
				'|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\\-|_)|g1 u|g560|gene|gf\\-5|g\\-mo|go(\\.w|od)|gr(ad|un)|haie|hcit|hd\\-(m|p|t)|hei\\-',
				'|hi(pt|ta)|hp( i|ip)|hs\\-c|ht(c(\\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\\-(20|go|ma)|i230|iac( |\\-|\\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro',
				'|jemu|jigs|kddi|keji|kgt( |\\/)|klon|kpt |kwc\\-|kyo(c|k)|le(no|xi)|lg( g|\\/(k|l|u)|50|54|\\-[a-w])|libw|lynx|m1\\-w|m3ga|m50\\/|ma(te|ui|xo)',
				'|mc(01|21|ca)|m\\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)',
				'|n7(0(0|1)|10)|ne((c|m)\\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\\-2',
				'|po(ck|rt|se)|prox|psio|pt\\-g|qa\\-a|qc(07|12|21|32|60|\\-[2-7]|i\\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\\/|sa(ge|ma|mm|ms|ny|va)',
				'|sc(01|h\\-|oo|p\\-)|sdk\\/|se(c(\\-|0|1)|47|mc|nd|ri)|sgh\\-|shar|sie(\\-|m)|sk\\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\\-|v\\-|v )',
				'|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\\-|tdg\\-|tel(i|m)|tim\\-|t\\-mo|to(pl|sh)|ts(70|m\\-|m3|m5)|tx\\-9|up(\\.b|g1|si)|utst|v400|v750|veri',
				'|vi(rg|te)|vk(40|5[0-3]|\\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\\-',
				'|your|zeto|zte\\-/i',
			].join(''),
			'i',
		);

		if (
			mobileRegex.test(latestUserAgent) ||
			(typeof latestUserAgent === 'string' &&
				mobileRegex2.test(latestUserAgent.substr(0, 4)))
		) {
			return DEVICE_TYPE.MOBILE;
		}

		return DEVICE_TYPE.DESKTOP;
	}

	static isDesktop() {
		return PlatformUtils.detectDevice() === DEVICE_TYPE.DESKTOP;
	}

	static isBot(userAgent: any) {
		let UA = userAgent || PlatformUtils.ua;
		if (!UA && this.canUseDOM()) {
			UA =
				navigator.userAgent ||
				navigator.vendor ||
				(window as any).opera;
		}
		if (!UA) {
			return false;
		}
		const botPattern =
			'(googlebot/|Googlebot-Mobile|Googlebot-Image|Google favicon|Slackbot|Slackbot-LinkExpanding|Slack-ImgProxy|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
		const regularExpression = new RegExp(botPattern, 'i');
		return regularExpression.test(UA);
	}

	static isApps(source: any) {
		return source === 'ios_app' || source === 'android_app';
	}

	static isSafari(useragent = '') {
		const ua = useragent?.toLowerCase();
		return ua?.includes('safari') && !ua?.includes('chrome');
	}

	static isFirefox(useragent = '') {
		const ua = useragent?.toLowerCase();
		return ua?.includes('firefox');
	}

	static isIPhone() {
		const ua = navigator?.userAgent || PlatformUtils.ua;

		return ua && new RegExp(/iPhone|iPad|iPod/i).test(ua);
	}

	static isWebview() {
		const userAgent = navigator.userAgent?.toLowerCase();
		const rules = [
			// if it says it's a webview, let's go with that
			'webview',
			// check for instagram,
			'instagram',
			// iOS webview will be the same as safari but missing "Safari"
			'(iphone|ipod|ipad)(?!.*safari)',
			// Android Lollipop and Above: webview will be the same as native but it will contain "wv"
			// Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
			'android.*(;\\s+wv|version/\\d.\\d\\s+chrome/\\d+(\\.0){3})',
			// old chrome android webview agent
			'linux; u; android',
		];
		return !!userAgent?.match(
			new RegExp('(' + rules.join('|') + ')', 'ig'),
		);
	}
}

export default PlatformUtils;
