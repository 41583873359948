import React from 'react';
import { connect } from 'react-redux';

import { getClarityUrl } from 'Utils/clarityUtils';
import { isProductionEnvironment, isServer } from 'Utils/envUtils';
import { getABTestingVariant } from 'Utils/experiments/experimentUtils';
import { getSmartLookKey } from 'Utils/keyUtils';
import { getCurrentPage, getSandboxID, isBot } from 'Utils/stateUtils';

import { COOKIE } from 'Constants/constants';

type SmartLookProps = {
	cookies?: any;
	isBot?: boolean;
	currentPage?: string;
};

type SmartLookState = any;

class SmartLook extends React.Component<SmartLookProps, SmartLookState> {
	static identifyUser(userTraits: any) {
		if (
			!isServer() &&
			(window as any).smartlook &&
			(window as any).smartlook.identify &&
			userTraits.email &&
			userTraits.userId
		) {
			(window as any)?.smartlook?.identify(userTraits.userId, {
				displayName: userTraits.name,
				email: userTraits.email,
				userTraits,
			});
		}
	}

	state = {
		initialized: false,
	};

	componentDidMount() {
		this.initializeIfApplicable();
	}

	componentDidUpdate() {
		this.initializeIfApplicable();
	}

	initializeIfApplicable() {
		// @ts-expect-error TS(2339): Property 'hsid' does not exist on type 'Readonly<S... Remove this comment to see the full error message
		const { isBot, hsid, cookies } = this.props;
		// Do not initialize on dev env
		// if (isDevelopmentEnvironment()) return;

		// Only proceed if not already initialized.
		if (this.state.initialized) {
			return;
		}
		// Do not proceed forward for bots or display traffic or not prod env.
		if (
			isBot ||
			// @ts-expect-error TS(2538): Type '{ GOOGLE_TRANSLATE: string; CURRENT_CITY_COD... Remove this comment to see the full error message
			cookies?.[COOKIE]?.IS_DISPLAY_TRAFFIC ||
			!isProductionEnvironment()
		) {
			return;
		}
		const updatedHsid = hsid || cookies?.[COOKIE.SANDBOX_ID];
		const experimentName = 'USER_RECORDING_SCRIPT_RANDOMIZATION';
		if (updatedHsid) {
			(this as any).abTestingVariant =
				(this as any).abTestingVariant ||
				getABTestingVariant(
					experimentName,
					{
						[COOKIE.SANDBOX_ID]: updatedHsid,
					},
					true,
				);
			// Only proceed if A/B variant allows
			if ((this as any).abTestingVariant === 'LOAD') {
				this.initialize();
			}
		}
	}

	initialize() {
		this.setState({ initialized: true });
		const smartLookKey = getSmartLookKey();
		const script = document.createElement('script');
		script.innerHTML = `window.smartlook||(function(d) {var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);})(document);smartlook('init', '${smartLookKey}');smartlook('record', { numbers: true });`;
		script.type = 'text/javascript';
		document.head.appendChild(script);
	}

	render() {
		return null;
	}
}

const mapStateToProps = (state: any, _ownProps: any) => ({
	isBot: isBot(state),
	currentPage: getCurrentPage(state),
	hsid: getSandboxID(state),
});

export default connect(mapStateToProps, null)(SmartLook);
