import { checkFullName } from 'Utils/validationUtils';

import { ActionTypes } from 'Actions/actions';

export const paymentCard = (
	state = {
		addressCity: '',
		addressCountry: '',
		addressLine1: '',
		addressLine2: '',
		addressState: '',
		addressZip: '',
		cardHolderName: '',
		cardFirst6: '',
		cardNumber: '',
		cardLast4: '',
		cvv: '',
		encryptedCardDetails: {},
		month: '',
		year: '',
	},
	action: any,
) => {
	switch (action.type) {
		case ActionTypes.SET_USER_NAME_COMPLETE: {
			const { userName } = action;
			const { cardHolderName } = state;
			if (userName && checkFullName(userName) && !cardHolderName) {
				return {
					...state,
					cardHolderName: userName,
				};
			}
			return state;
		}
		case ActionTypes.SET_CARD_HOLDER_NAME: {
			const { cardHolderName } = action;
			return {
				...state,
				cardHolderName,
			};
		}
		case ActionTypes.SET_CARD_NUMBER: {
			const { cardNumber } = action;
			const cardFirst6 = cardNumber.substring(0, 6);
			const cardLast4 = cardNumber.substring(
				cardNumber.length - 4,
				cardNumber.length,
			);
			return {
				...state,
				cardNumber,
				cardFirst6,
				cardLast4,
			};
		}
		case ActionTypes.SET_CARD_FIRST_6: {
			const { cardFirst6 } = action;
			return {
				...state,
				cardFirst6,
			};
		}
		case ActionTypes.SET_CARD_LAST_4: {
			const { cardLast4 } = action;
			return {
				...state,
				cardLast4,
			};
		}
		case ActionTypes.SET_CARD_MONTH_EXPIRY: {
			const { month } = action;
			return {
				...state,
				month,
			};
		}
		case ActionTypes.SET_CARD_YEAR_EXPIRY: {
			const { year } = action;
			return {
				...state,
				year,
			};
		}
		case ActionTypes.SET_CARD_CVV: {
			const { cvv } = action;
			return {
				...state,
				cvv,
			};
		}
		case ActionTypes.SET_CARD_ADDRESS: {
			const { key, val } = action;
			return {
				...state,
				[key]: val,
			};
		}
		case ActionTypes.SET_CARD_VALIDITY: {
			const { isValid } = action.payload;
			return {
				...state,
				isValid,
			};
		}
		case ActionTypes.SET_CHECKOUT_PAYMENT_FIELD_VALIDITIES: {
			const { paymentFieldValidities } = action.payload;
			return {
				...state,
				paymentFieldValidities,
			};
		}
		case ActionTypes.SET_ENCRYPTED_CARD_DETAILS: {
			const { encryptedFieldName, encryptedFieldValue } = action.payload;
			return {
				...state,
				encryptedCardDetails: {
					...state.encryptedCardDetails,
					[encryptedFieldName]: encryptedFieldValue,
				},
			};
		}
		default:
			return state;
	}
};
