import { NODE_ENV } from 'Constants/constants';

export const getNodeEnvironment = () => process.env.NEXT_PUBLIC_NODE_ENV;

export const isDevelopmentEnvironment = () =>
	process.env.NEXT_PUBLIC_NODE_ENV === NODE_ENV.DEV;

export const isOnDemandEnv = () =>
	process.env.NEXT_PUBLIC_NODE_ENV === NODE_ENV.ONDEMAND;

export const isTestingEnvironment = () =>
	process.env.NEXT_PUBLIC_NODE_ENV === NODE_ENV.TEST ||
	process.env.NEXT_PUBLIC_NODE_ENV === NODE_ENV.STAGE;

export const isProductionEnvironment = () =>
	process.env.NEXT_PUBLIC_NODE_ENV === NODE_ENV.PROD;

export const isServer = () => !process.browser;
