import { createGlobalStyle } from 'styled-components';

/** NOTE:
 * This is added to have global classes that will be used for accessibility features.
 * We will be incorporating more accessibility guidelines moving forward,
 * and this is to keep shared styles more organized.
 */
export const AccessibilityGlobalStyle = createGlobalStyle`
    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: clip;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    }

    .scroll-lock {
        overflow: hidden;
    }
`;
