import dayjs from 'dayjs';
import dayjsAdvancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjsDurationPlugin from 'dayjs/plugin/duration';
import dayjsIsBetweenPlugin from 'dayjs/plugin/isBetween';
import dayjsIsSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter';
import dayjsLocaleDataPlugin from 'dayjs/plugin/localeData';
import dayjsLocalizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import dayjsTimezonePlugin from 'dayjs/plugin/timezone';
import dayjsUTCPlugin from 'dayjs/plugin/utc';

/**
 * Plugins to allow using dayjs time-zone, locale, UTC and duration methods
 */
dayjs.extend(dayjsUTCPlugin);
dayjs.extend(dayjsTimezonePlugin);
dayjs.extend(dayjsDurationPlugin);
dayjs.extend(dayjsIsBetweenPlugin);
dayjs.extend(dayjsLocaleDataPlugin);
dayjs.extend(dayjsLocalizedFormatPlugin);
dayjs.extend(dayjsIsSameOrAfterPlugin);
dayjs.extend(customParseFormat);
dayjs.extend(dayjsAdvancedFormatPlugin);

export default dayjs;
