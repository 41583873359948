// @ts-expect-error TS(7016): Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import groupBy from 'lodash.groupby';

import { ENDPOINTS } from 'Utils/apiEndpoints';
import dayjs from 'Utils/dayjsUtil';
import {
	getApiCurrencyParameter,
	getApiLanguageParameter,
} from 'Utils/fetchUtils';

import {
	AVAILABILITY_TYPE,
	FLEXIBLE_START_TIME,
	INVENTORY_TYPE,
	PRICE_FETCH_STATUS,
	PRICE_RANGE_DELIMETER,
	PROFILE_TYPE,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

import { EN } from 'Static/labels/english';

import { IPriceProfile, ISelectionMap } from './crossSell/types';
import {
	checkIfDateInBetweenDateRange,
	localDateTimetoJSDate,
} from './dateUtils';
import fetchWrapper from './fetchWrapper';
import { formatPrice } from './gen';
import { log } from './logUtils';
import { getApiCDNBaseUrl, getBaseUrl } from './urlUtils';

export const getAvailableDates = (pricing: any) => {
	const availabilities = pricing?.availabilities;
	return availabilities?.map((inventory: any) => inventory?.startDate);
};

export const getDistinctTimes = (
	inventories: any,
	timingDetails: any,
	getFullObject?: boolean,
) => {
	const { date, tourId } = timingDetails;
	const pushIfDistinct = (distinctTimes: any, time: any) => {
		if (
			distinctTimes.indexOf(getFullObject ? time?.startTime : time) === -1
		)
			distinctTimes.push(time);

		return distinctTimes;
	};
	const filterTimeSlotsByStartTime = (timeSlots: any) =>
		timeSlots.reduce((allSlots: any, currentObj: any) => {
			const startTime = currentObj.startTime;
			const existingObj = allSlots.find(
				(obj: any) => obj.startTime === startTime,
			);

			if (!existingObj) {
				allSlots.push(currentObj);
			}

			return allSlots;
		}, []);

	let res = inventories
		.filter((inv: any) => (date ? inv?.startDate === date : true))
		.filter((inv: any) => (tourId ? inv?.tourId === tourId : true))
		.map((inv: any) => (getFullObject ? inv : inv?.startTime))
		.sort(
			(a: any, b: any) =>
				// @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
				localDateTimetoJSDate(
					'2001-01-01',
					getFullObject ? a?.startTime : a,
				) -
				// @ts-expect-error TS(2363): The right-hand side of an arithmetic operation mus... Remove this comment to see the full error message
				localDateTimetoJSDate(
					'2001-01-01',
					getFullObject ? b?.startTime : b,
				),
		)
		.reduce(pushIfDistinct, []);
	if (getFullObject) res = filterTimeSlotsByStartTime(res);
	return res;
};

/**
 * @namespace
 * @property {string} selectedDate date for which week range is required
 * @property {Array} sortedInventoryDates [null] - optional, array of already fetched inventory dates from state
 * @returns {*} selected dates start of week as fromDate & end of week as toDate
 */
export const getDateWeekRange = ({
	// @ts-expect-error TS(7031): Binding element 'selectedDate' implicitly has an '... Remove this comment to see the full error message
	selectedDate,
	sortedInventoryDates = null,
}) => {
	if (!selectedDate) return {};
	const date = dayjs(selectedDate);
	let startOffset = 2; // Horizontal Date List shows 2 previous dates.
	let endOffset = 7;

	if (sortedInventoryDates) {
		const checkIfDateCollide = (date: any) =>
			(sortedInventoryDates as any).indexOf(date.format('YYYY-MM-DD')) >
			-1;

		// Ensures selected range doesnt collide with existing inventory
		let isStartColliding, isEndColliding;
		do {
			const iteratorDate = dayjs(selectedDate);
			let iteratorStartRange = dayjs(
				iteratorDate.subtract(startOffset, 'd'),
			);
			let iteratorEndRange = dayjs(iteratorDate.add(endOffset, 'd'));
			isStartColliding = checkIfDateCollide(iteratorStartRange);
			isEndColliding = checkIfDateCollide(iteratorEndRange);

			const areAdjacentDate = iteratorStartRange
				.clone()
				.add(1, 'd')
				.isSame(iteratorEndRange); // (01/01/2022, 02/01/2022)

			if (areAdjacentDate) {
				// Since API is Not Inclusive of Start/End Date.
				startOffset = 1;
				endOffset = 1;
				break;
			}

			if (isStartColliding) startOffset--;
			if (isEndColliding) endOffset--;
		} while (isStartColliding || isEndColliding);
	}

	let distinctFromDate = dayjs(date.subtract(startOffset, 'd'));
	let distinctToDate = dayjs(date.add(endOffset, 'd'));

	if (distinctFromDate.isSame(selectedDate))
		// since API is not inclusive
		distinctFromDate.subtract(1, 'd');

	if (distinctToDate.isSame(selectedDate))
		// since API is not inclusive
		distinctToDate.add(1, 'd');

	if (!distinctFromDate.isValid() || !distinctToDate.isValid()) return {};

	return {
		fromDate: distinctFromDate?.locale('en')?.format('YYYY-MM-DD'),
		toDate: distinctToDate?.locale('en')?.format('YYYY-MM-DD'),
	};
};

export const getTour = (pricing: any, tourId: any) =>
	pricing?.tours?.find((tour: any) => tour?.id === tourId);

export const isTourFixedStart = (pricing: any, tourId: any) => {
	const inventoryType = getTour(pricing, tourId)?.inventoryType;
	return (
		inventoryType === INVENTORY_TYPE.FIXED_START_FIXED_DURATION ||
		inventoryType === INVENTORY_TYPE.FIXED_START_FLEXIBLE_DURATION
	);
};

export const filterInventoriesByDate = (inventories: any, date: any) => {
	return inventories.filter((x: any) => x?.startDate === date);
};

export const filterInventoriesByTime = (
	pricing: any,
	inventories: any,
	time: any,
) => {
	return inventories.filter(
		(x: any) =>
			!isTourFixedStart(pricing, x?.tourId) || x?.startTime === time,
	);
};

export const filterInventoriesByTourId = (inventories: any, tourId: any) => {
	return inventories?.filter((x: any) => x?.tourId == tourId);
};

export const getInventoriesForTuple = (
	{
		date = null,
		time = null,
		tourId = null,
	}: { date?: string | null; time?: string | null; tourId?: number | null },
	pricing: any,
) => {
	let inventories = pricing?.availabilities;
	inventories =
		tourId !== null
			? filterInventoriesByTourId(inventories, tourId)
			: inventories;
	inventories =
		date !== null
			? filterInventoriesByDate(inventories, date)
			: inventories;
	inventories =
		time !== null
			? filterInventoriesByTime(pricing, inventories, time)
			: inventories;
	if (inventories.length === 0) {
		return null;
	}
	return inventories;
};

export const getInventoryWithMinPrice = (inventories: any) => {
	const priceOf = (inventory: any) => {
		if (!inventory?.priceProfile) return Number.MAX_SAFE_INTEGER;
		return getListingPriceFromPriceProfile(inventory?.priceProfile);
	};

	return inventories.reduce((current: any, prev: any) => {
		return priceOf(current) < priceOf(prev) ? current : prev;
	}, {});
};

export const getAvailableInventory = (tourDetails: any, pricing: any) => {
	const { date, time, tourId } = tourDetails;

	let filteredInventories = pricing?.availabilities
		?.filter((inv: any) => !tourId || inv?.tourId === tourId)
		?.filter((inv: any) => !date || inv?.startDate === date)
		?.filter((inv: any) => {
			return (
				!time ||
				!isTourFixedStart(pricing, inv?.tourId) ||
				inv?.startTime === time
			);
		});

	if (filteredInventories?.length > 0) {
		let finalAvailableInventory = filteredInventories?.[0];
		// In case more than 1 `flexible start` invs are present in array, select the one with the correct time
		if (time && filteredInventories.length > 1) {
			filteredInventories = filteredInventories.filter(
				(inv: any) =>
					inv?.startTime === time || time === FLEXIBLE_START_TIME,
			);

			if (filteredInventories.length) {
				finalAvailableInventory = filteredInventories?.[0];
			}
		}

		return finalAvailableInventory;
	}
	return null;
};

export const validatePaxSelections = ({
	selectionMap,
	paxAvailability,
	paxValidation,
	isCombo,
	tour,
}: any) => {
	if (isCombo) return { isValid: true }; // For Combo Pax Selection Validation Takes Place at Select Screen

	const { maxPax: tourMaxPax } = tour ?? {};
	let totalPaxCount = 0;

	for (const [paxType, paxSelectionCount] of Object.entries(selectionMap)) {
		const paxGroup = paxAvailability?.find((paxGroup: any) =>
			paxGroup?.paxTypes.includes(paxType),
		);
		const { maxPax, minPax } = paxValidation?.[paxType] || {};
		const { availability, remaining, paxTypes } = paxGroup || {};
		totalPaxCount += paxSelectionCount as any;

		if ((paxSelectionCount as any) < minPax) {
			return {
				isValid: false,
				invalidPaxDetails: { ...paxValidation[paxType] },
				insufficientPaxCount: true,
			};
		}

		if (availability === AVAILABILITY_TYPE.LIMITED) {
			const selectionSum = paxTypes.reduce(
				(accSum: any, localPaxType: any) =>
					accSum + (selectionMap[localPaxType] ?? 0),
				0,
			);
			const finalMaxPax = Math.min(remaining, maxPax, tourMaxPax);

			if (selectionSum > finalMaxPax)
				return {
					isValid: false,
					invalidPaxDetails: {
						...paxValidation[paxType],
						maxPax: finalMaxPax,
					},
				};
		}

		if (
			availability === AVAILABILITY_TYPE.UNLIMITED &&
			totalPaxCount > tourMaxPax
		)
			return {
				isValid: false,
				invalidPaxDetails: { ...paxValidation[paxType] },
			};
	}

	return { isValid: true };
};

export const getMinPax = (inventory: any, pricing: any) => {
	const { tourId } = inventory;
	const tour = getTour(pricing, tourId);
	return tour?.minPax || 1;
};

export const getPeopleSelectionsFromProduct = (
	inventory: any,
	pricing: any,
) => {
	let groupSize = 0,
		selectionMap = {};
	const tourMinPax = getMinPax(inventory, pricing);
	const { priceProfile, paxValidation } = inventory;
	const { priceProfileType, persons, groups } = priceProfile;
	if (priceProfileType === PROFILE_TYPE.PER_PERSON) {
		selectionMap = persons.reduce(
			(acc: any, person: any, index: number) => {
				const { type } = person;
				const isFirstKey = index === 0;
				const { minPax } = paxValidation[type] || { minPax: 0 };
				return {
					...acc,
					[type?.toUpperCase()]:
						isFirstKey && minPax === 0 ? 1 : minPax,
				};
			},
			{},
		);
	}

	if (priceProfileType === PROFILE_TYPE.PER_GROUP) {
		groupSize = groups?.[0]?.people ?? tourMinPax;
	}

	return { selectionMap, groupSize };
};

export const getListingPriceFromPriceProfile = (priceProfile: any) => {
	const { priceProfileType, persons, groups } = priceProfile;

	if (priceProfileType === PROFILE_TYPE.PER_PERSON) {
		return persons?.[0]?.listingPrice;
	}
	return groups?.[0]?.listingPrice;
};

export const getOriginalPriceFromAvailablePrices = (priceProfile: any) => {
	if (priceProfile?.priceProfileType === PROFILE_TYPE.PER_PERSON) {
		return priceProfile?.persons?.[0]?.retailPrice;
	}
	return priceProfile?.groups?.[0]?.retailPrice;
};

export const getListingPriceFromInventory = (inventory: any) => {
	if (!inventory) return null;
	return getListingPriceFromPriceProfile(inventory?.priceProfile);
};

export const getPriceTag = (inventory: any, currency?: any) => {
	if (!inventory) return null;
	const { priceProfile } = inventory;
	const listingPrice = getListingPriceFromPriceProfile(priceProfile);
	const originalPrice = getOriginalPriceFromAvailablePrices(priceProfile);
	const discount =
		originalPrice > listingPrice ? originalPrice - listingPrice : 0;
	const discountInPercent = discount ? (discount / originalPrice) * 100 : 0;
	return {
		price: listingPrice,
		originalPrice,
		discount,
		discountInPercent,
		currency,
	};
};

export const getListingPrice = (
	inventory: any,
	currency: any,
	hasOtherPrices = false,
) => {
	if (!currency) return null;
	const priceTag = getPriceTag(inventory, currency);
	if (!priceTag) return null;
	const {
		price: finalPrice,
		originalPrice,
		discount,
		discountInPercent,
		currency: { code: currencyCode },
	} = priceTag;
	return {
		finalPrice,
		originalPrice,
		discount,
		discountInPercent,
		currencyCode,
		otherPricesExist: hasOtherPrices,
	};
};

export const getMinPriceTag = ({ inventories, currency }: any) => {
	if (!inventories || inventories.length === 0) return null;

	const minPriceInventory = getInventoryWithMinPrice(inventories);
	return getPriceTag(minPriceInventory, currency);
};

export const getStructuredTimeList = (
	timeSlots: any,
	pricing: any,
	isSeatMap: any,
) => {
	const { currency } = pricing;
	// @ts-expect-error TS(7034): Variable 'structuredTimeSlots' implicitly has type... Remove this comment to see the full error message
	const structuredTimeSlots = [];
	timeSlots.forEach((inventory: any) => {
		// @ts-expect-error TS(2339): Property 'price' does not exist on type '{ price: ... Remove this comment to see the full error message
		const { price, originalPrice } = getPriceTag(inventory, currency);
		const { availability, remaining } = inventory;
		const isBoosters =
			availability === AVAILABILITY_TYPE.LIMITED &&
			remaining < 10 &&
			remaining > 0;
		let boostersTextSeatmap = null;
		let boostersText = null;
		if (isBoosters) {
			boostersTextSeatmap = EN.VPVI_SELLING_FAST;
			boostersText =
				remaining === 1
					? EN.VPVI_TICKET_LEFT
					: strings.formatString(EN.VPVI_TICKETS_LEFT, remaining);
		}
		const timeSlot = inventory?.startTime;
		structuredTimeSlots.push({
			timeSlot,
			availability: true,
			netPrice: formatPrice(price, currency),
			originalPrice: formatPrice(originalPrice, currency),
			boosters: isSeatMap ? boostersTextSeatmap : boostersText,
		});
	});
	// @ts-expect-error TS(7005): Variable 'structuredTimeSlots' implicitly has an '... Remove this comment to see the full error message
	return groupBy(structuredTimeSlots, 'timeSlot');
};

export const getPaxPriceDetails = (
	selectionMap: any,
	finalPriceProfile: any,
) => {
	const paxPriceDetails: Array<{
		type: string;
		price: number | null;
		count: number;
	}> = [];

	Object.keys(selectionMap ?? {}).forEach(pax => {
		let pricePerPax = null;
		finalPriceProfile?.persons.forEach((paxDetails: any) => {
			if (paxDetails?.type === pax) {
				pricePerPax = paxDetails?.listingPrice;
			}
		});

		paxPriceDetails.push({
			type: pax,
			price: pricePerPax,
			count: selectionMap?.[pax],
		});
	});

	return paxPriceDetails;
};

export const doesTourHaveSingleTimeSlot = (invMap: any, tourId: any) =>
	invMap?.[tourId] &&
	Object.values(invMap?.[tourId])?.every(
		invList => (invList as any).length === 1,
	);

export const doesTourDateHaveSingleTimeSlot = (
	invMap: any,
	tourId: any,
	tourDate: any,
) =>
	tourDate
		? Object.values(invMap?.[tourId]?.[tourDate])?.every(invList => {
				return (invList as any).length === 1;
		  })
		: false;

export const getClientCurrency = (pricing: any) => pricing?.currency?.code;

export const getCurrencyToUSDConversionRate = async (currencyCode: any) => {
	try {
		const response = await fetchWrapper(
			`${getBaseUrl()}/api/v1/currency/convert?from-currency=${currencyCode}&to-currency=USD`,
		);
		const data = await response.json();

		return data.conversionRate;
	} catch (error) {
		log('Failed to get USD to currency conversion rate');
	}
};

export const getPriceFetchingStatusByProductId = (
	state: any,
	productId: number,
) => Boolean(state?.pricingStore?.status?.isFetching?.[productId]);

export const getPriceStoreStatus = (state: any) => {
	return state?.pricingStore?.status;
};

export const getPriceFetchingStatusForDate = ({
	status,
	date,
	productId,
}: any) => {
	const dateRangeEntries = Object.entries(status?.isFetching ?? {}).filter(
		([key]) => key?.includes(`${productId}${PRICE_RANGE_DELIMETER}`),
	);
	let fetchStatus = PRICE_FETCH_STATUS.NOT_FETCHED;

	dateRangeEntries?.forEach(([key, isFetching]) => {
		const [_productId, startDate, endDate] =
			key?.split?.(PRICE_RANGE_DELIMETER) ?? [];

		if (
			checkIfDateInBetweenDateRange({
				date,
				startDate,
				endDate,
			})
		)
			fetchStatus = isFetching
				? PRICE_FETCH_STATUS.IN_PROGRESS
				: PRICE_FETCH_STATUS.COMPLETE;
	});

	return fetchStatus;
};

export const getMinMaxPaxForPaxType = ({
	paxAvailability,
	paxValidation,
	selectionMap = {},
	paxType: currentPaxType,
	tour,
}: any) => {
	const { minPax: tourMinPax, maxPax: tourMaxPax } = tour ?? {};
	const paxAvailabilityGroup = paxAvailability?.find((paxGroup: any) =>
		paxGroup.paxTypes.includes(currentPaxType),
	);
	const { availability, remaining, paxTypes } = paxAvailabilityGroup || {};
	const { minPax, maxPax } = paxValidation?.[currentPaxType] || {};
	let finalMaxPax = 0,
		finalMinPax = 0;

	const selectionTuples = Object.entries(selectionMap);
	const currentTotalPaxSelection = selectionTuples.reduce(
		(accumulatedSum, [selectionPaxType, paxCount]) =>
			accumulatedSum +
			// @ts-expect-error TS(2571): Object is of type 'unknown'.
			(selectionPaxType !== currentPaxType ? paxCount : 0), // Doesn't Include currentPaxType in the sum.
		0,
	);

	const tourRemainingMaxPax = tourMaxPax - currentTotalPaxSelection;
	const tourRemainingMinPax = tourMinPax - currentTotalPaxSelection;

	finalMinPax = Math.max(tourRemainingMinPax, minPax);
	finalMaxPax = Math.min(tourMaxPax, maxPax, tourRemainingMaxPax);

	if (availability === AVAILABILITY_TYPE.LIMITED) {
		// Different Sum, since each paxAvailability group can have its own remaining value.
		const currentGroupedSelectionSum = selectionTuples.reduce(
			(accumulatedSum, [selectionPaxType, paxCount]) =>
				selectionPaxType !== currentPaxType && // Doesnt Include currentPaxType in the sum.
				paxTypes?.includes(selectionPaxType)
					? accumulatedSum + (paxCount as any)
					: accumulatedSum,
			0,
		);
		const remainingMaxPax = remaining - currentGroupedSelectionSum;
		finalMaxPax = Math.min(finalMaxPax, remainingMaxPax);
	}

	return {
		maxPax: finalMaxPax,
		minPax: finalMinPax,
	};
};

export const getInventoryBySelectedOrDefaultDate = ({
	id,
	inventoryMapByTourDate,
	selectedDate,
	defaultSelectedDate,
}: {
	id: number;
	inventoryMapByTourDate: any;
	selectedDate: string | undefined;
	defaultSelectedDate: string;
}) => inventoryMapByTourDate?.[id]?.[selectedDate || defaultSelectedDate]?.[0];

export function concatenateUniqueAvailabilities(
	currentAvailabilities: any,
	fetchedAvailabilities: any,
) {
	if (
		currentAvailabilities.length === 0 ||
		fetchedAvailabilities.length === 0
	) {
		return currentAvailabilities.concat(fetchedAvailabilities);
	}

	const mergedMap = new Map([
		...currentAvailabilities.map((item: any) => {
			const key = `${item.startDate}_${item.startTime}_${item.tourId}`;
			return [key, item];
		}),
		...fetchedAvailabilities.map((item: any) => {
			const key = `${item.startDate}_${item.startTime}_${item.tourId}`;
			return [key, item];
		}),
	]);

	const finalArray = Array.from(mergedMap.values());

	return finalArray;
}

export const getPriceOfSelectedTour = ({
	pricing,
	selectedDate,
	selectedTime,
	selectedTour,
}: {
	pricing?: any;
	selectedDate: string;
	selectedTime: string;
	selectedTour: number;
}) => {
	if (!pricing) return;
	const { inventoryMap } = pricing;
	return getPriceTag(
		inventoryMap?.[selectedDate]?.[selectedTour]?.filter(
			(slot: any) => slot.startTime === selectedTime,
		)?.[0],
	);
};

export const getSharedRemainingAvailability = (
	paxAvailability: PaxAvailability[],
) => {
	const remaining = paxAvailability?.reduce(
		(accSum: any, paxGroup: any) => paxGroup?.remaining + accSum,
		0,
	);

	return remaining;
};

export const getSharedRemainingAvailabilityText = (
	paxAvailability: PaxAvailability[],
) => {
	const { availability } = paxAvailability?.[0] || {};

	const remaining = getSharedRemainingAvailability(paxAvailability);

	let remainingText: string | null = null;

	if (availability !== AVAILABILITY_TYPE.LIMITED) return null;

	if (remaining > 20) {
		remainingText = strings.CVRB.SELL_OUT_SOON;
	} else if (remaining > 1) {
		remainingText = strings.formatString(
			strings.VPVI_TICKETS_LEFT,
			remaining,
		);
	} else if (remaining === 1) {
		remainingText = strings.VPVI_TICKET_LEFT;
	}

	return remainingText;
};

export const getRemainingAvailabilityForPersonType = ({
	paxAvailability,
	personType,
}: {
	paxAvailability: PaxAvailability[];
	personType: string;
}) => {
	const paxTypeAvailability = paxAvailability.find(availability =>
		availability.paxTypes.includes(personType!),
	);
	if (paxTypeAvailability?.availability !== AVAILABILITY_TYPE.LIMITED)
		return Number.MAX_SAFE_INTEGER;
	const remaining = paxTypeAvailability?.remaining;

	return remaining;
};

export const getRemainingForPersonTypeText = ({
	paxAvailability,
	personType,
}: {
	paxAvailability: PaxAvailability[];
	personType: string;
}) => {
	const remaining = getRemainingAvailabilityForPersonType({
		paxAvailability,
		personType,
	});

	if (!remaining) return '';

	if (remaining > 20) {
		return strings.CVRB.SELL_OUT_SOON;
	} else if (remaining > 1) {
		return strings.formatString(strings.VPVI_TICKETS_LEFT, remaining);
	} else if (remaining === 1) {
		return strings.VPVI_TICKET_LEFT;
	}
};

export const fetchBulkInventories = async ({
	tgids,
	fromDate,
	toDate,
	lang,
	currencyCode,
	state,
}: {
	tgids: (number | string)[];
	fromDate: string | null;
	toDate: string | null;
	lang: string;
	currencyCode: string;
	state: any;
}) => {
	const hostName = getApiCDNBaseUrl({ state });
	const toDateParam =
		toDate && dayjs(toDate).add(1, 'day').format('YYYY-MM-DD');
	const url = `${hostName}${
		ENDPOINTS.BULK_INVENTORY
	}?from-date=${fromDate}&to-date=${toDateParam}&tour-group-ids=${tgids
		?.sort()
		?.join(',')}${getApiLanguageParameter(
		lang,
		true,
	)}${getApiCurrencyParameter(state, currencyCode)}`;
	const response = await fetchWrapper(url);
	const pricingData = await response.json();
	return pricingData;
};

/**
 * Creates a map of listing prices based on the price profile.
 * The map is keyed by type (e.g., person type or group type) and values are the corresponding listing prices.
 *
 * @param {IPriceProfile} priceProfile - The price profile containing person and/or group pricing details.
 * @returns {Map<string, number>} A map where keys are type strings (e.g., "ADULT", "GROUP_1") and values are the listing prices.
 */
const createFinalPriceMap = (
	priceProfile: IPriceProfile,
): Map<string, number> => {
	const priceMap = new Map<string, number>();
	const { priceProfileType, persons = [], groups = [] } = priceProfile;

	if (priceProfileType === PROFILE_TYPE.PER_PERSON) {
		persons.forEach(person => {
			priceMap.set(person.type, person.listingPrice);
		});

		return priceMap;
	}

	groups.forEach(group => {
		priceMap.set(group.type, group?.listingPrice);
	});

	return priceMap;
};

/**
 * Calculates the total listing price based on the selection map and price profile.
 * The function multiplies the number of selected items by their respective listing prices and sums up the total.
 *
 * @param {ISelectionMap | null} selectionMap - A map where keys are type strings (e.g., "ADULT", "GROUP_1") and values are the counts of selected items. Can be null.
 * @param {IPriceProfile | null} priceProfile - The price profile containing person and/or group pricing details. Can be null.
 * @returns {number | null} The total listing price based on the selection map and price profile, or null if either input is null.
 */
export const getFinalPriceFromSelectionMap = (
	selectionMap: ISelectionMap | null,
	priceProfile: IPriceProfile | null,
): number | null => {
	if (!(selectionMap && priceProfile)) {
		return null;
	}

	const finalPriceMap = createFinalPriceMap(priceProfile);
	const cumulativeFinalPrice = Object.entries(selectionMap).reduce(
		(total, [type, count]) => {
			const price = finalPriceMap.get(type);
			if (price !== undefined) {
				return total + price * count;
			}
			return total;
		},
		0,
	);

	return parseFloat(cumulativeFinalPrice?.toFixed(2));
};

export const getFinalPriceFromGroupSelection = (
	groupSize: number,
	priceProfile: IPriceProfile | null,
): number | null => {
	if (groupSize <= 0 || !priceProfile) return null;
	const price =
		priceProfile?.groups?.find(group => group?.people === groupSize)
			?.listingPrice ?? null;

	return price;
};

export const getCumulativeOriginalPrice = (
	priceProfile: IPriceProfile | null,
	selectionMap: ISelectionMap | null,
	groupSize: number,
):
	| ReturnType<typeof getFinalPriceFromGroupSelection>
	| ReturnType<typeof getFinalPriceFromSelectionMap> => {
	const isPerGroupProfile =
		priceProfile?.priceProfileType === PROFILE_TYPE.PER_GROUP;
	const isPerPersonProfile =
		priceProfile?.priceProfileType === PROFILE_TYPE.PER_PERSON;

	if (isPerGroupProfile && groupSize > 0) {
		return getFinalPriceFromGroupSelection(groupSize, priceProfile);
	}

	if (isPerPersonProfile && selectionMap) {
		return getFinalPriceFromSelectionMap(selectionMap, priceProfile);
	}

	return null;
};
