import {
	openZendeskChatWidget as openZendeskChatWidgetAqua,
	ZendeskApi,
} from '@headout/aqua';

declare global {
	interface Window {
		zE?: (...args: any[]) => void;
		/**
		 * attachEvent is only supported on IE - this handles cross-browser chat interactions
		 */
		attachEvent?: (event: string, cb: () => void) => void;
	}
}

export const hideZendeskChatWidget = () => {
	ZendeskApi('messenger', 'hide');
};

export const openZendeskChatWidget = () => {
	openZendeskChatWidgetAqua();
};
