import Cookies from 'nookies';

import { sendVariableToDataLayer } from 'Utils/analytics';
import { saveCityToLocalStorage } from 'Utils/cityUtils';

import { ActionTypes } from 'Actions/actions';

import { ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { COOKIE, RECENTLY_VIEWED_CITIES_KEY } from 'Constants/constants';

export const cityActions = {
	// using v2 actions to prevent collisions with previous reducer
	CHANGE_CITY: 'CHANGE_CITY',
	REQUEST_CITIES: 'REQUEST_CITIES',
	RECEIVE_CITIES: 'RECEIVE_CITIES',
	REQUEST_CITIES_V2: 'REQUEST_CITIES_V2',
	TRIM_CITIES: 'TRIM_CITIES',
};

// @ts-expect-error TS(7031): Binding element 'cityCode' implicitly has an 'any'... Remove this comment to see the full error message
export const changeCity = ({ cityCode, context = {} }) => {
	Cookies.set(context, COOKIE.CURRENT_CITY_CODE, cityCode, {
		httpOnly: false,
		path: '/',
	});

	saveCityToLocalStorage({
		key: RECENTLY_VIEWED_CITIES_KEY,
		value: cityCode,
	});

	// Updating the custom dimension: City
	sendVariableToDataLayer({
		name: ANALYTICS_PROPERTIES.CITY,
		value: cityCode,
	});
	return {
		cityCode,
		type: cityActions.CHANGE_CITY,
	};
};

export const requestCities = () => ({
	type: cityActions.REQUEST_CITIES,
});

export const requestNearbyCities = () => ({
	type: ActionTypes.REQUEST_NEARBY_CITIES_FROM_LAT_LONG,
});

export const receiveCities = (json: any, url: any) => ({
	url,
	json,
	type: cityActions.RECEIVE_CITIES,
	receivedAt: Date.now(),
});

export const receiveCloseByCities = (cities: any) => ({
	type: ActionTypes.RECEIVE_CLOSEBY_CITY_CODES,
	cities,
});

export const receiveNearByCities = (codes: any, url: any) => ({
	type: ActionTypes.RECEIVE_NEARBY_CITIES_FROM_LAT_LONG,
	codes,
	url,
});

export const requestCityListByParams = (payload: { params: string }) => ({
	type: ActionTypes.REQUEST_CITIES_LIST,
	payload,
});

export const receiveCityListByParams = (payload: {
	params: string;
	response: any;
	receivedAt: number;
}) => ({
	type: ActionTypes.RECEIVE_CITIES_LIST,
	payload,
});

export const trimCities = ({
	retainCityCount,
}: {
	retainCityCount: number;
}) => ({
	type: cityActions.TRIM_CITIES,
	retainCityCount,
});
