import { ActionTypes } from 'Actions/actions';

export const setActiveModal = (activeModal: string | null) => ({
	type: ActionTypes.SET_ACTIVE_MODAL,
	payload: activeModal,
});

export const setOverrideLazyload = () => ({
	type: ActionTypes.SET_OVERRIDE_LAZYLOAD,
});
