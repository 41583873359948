import { createGlobalStyle } from 'styled-components';

export const AquaGlobalStyleObject = createGlobalStyle`
    .slide-in-aqua {
		@media (min-width: 600px) {
			transform: translateX(0%);
		}
		@media (max-width: 600px) {
			transform: translateY(0%);
		}
	}

	.slide-out-aqua {
		@media (min-width: 600px) {
			transform: translateX(100%);
		}
		@media (max-width: 600px) {
			transform: translateY(100%);
		}
	}
`;
