export const TOUR_PROPERTY_TYPE = {
	TRIP_TYPE: 'TRIP_TYPE',
	DIRECTION: 'DIRECTION',
	AIRPORT: 'AIRPORT',
	DESTINATION: 'DESTINATION',
	TERMINAL: 'TERMINAL',
	TIER: 'TIER',
} as const;

export const TRIP_TYPE_OPTIONS = {
	ONE_WAY: 'ONE_WAY',
	ROUND_TRIP: 'ROUND_TRIP',
} as const;

export const DIRECTION_OPTIONS = {
	FROM_AIRPORT: 'FROM_AIRPORT',
	TO_AIRPORT: 'TO_AIRPORT',
} as const;

export const VARIANT_INFO_FIRST_LINE_REGEX = /^-\s.*?(?=\r\n|\n|$)/;
