import { combineUnique } from 'Utils/gen';

import { ActionTypes } from 'Actions/actions';

import { DATA_SOURCE_TYPE } from 'Constants/constants';

export const productList = (state = {}, action: any) => {
	switch (action.type) {
		case ActionTypes.REQUEST_PRODUCT_LIST: {
			const { cityCode, params } = action;
			const map = { isFetching: true };
			return {
				...state,
				[cityCode]: {
					// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					...state[cityCode],
					[params]: {
						// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
						...state?.[cityCode]?.[params],
						...map,
					},
				},
			};
		}
		case ActionTypes.RECEIVE_PRODUCT_LIST: {
			const {
				cityCode,
				params,
				metaData,
				nextPage,
				pageData: lastUpdatedPageInfo,
				productIdList,
				dataSource,
				receivedAt,
			} = action;
			const finalProductIdList = nextPage
				? combineUnique(
						// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
						state?.[cityCode]?.[params]?.productIdList || [],
						productIdList,
				  )
				: productIdList;
			const partialMap = {
				metaData,
				lastUpdatedPageInfo,
				productIdList: finalProductIdList,
				receivedAt,
				isFetching: !(dataSource === DATA_SOURCE_TYPE.API),
			};

			return {
				...state,
				[cityCode]: {
					// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					...state[cityCode],
					[params]: partialMap,
				},
			};
		}
		case ActionTypes.RECEIVE_RECENT_PRODUCT_LIST: {
			const { cityCode, params, productIdList, receivedAt } = action;

			const partialMap = {
				productIdList,
				receivedAt,
				isFetching: false,
			};

			return {
				...state,
				[cityCode]: {
					// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					...state[cityCode],
					[params]: partialMap,
				},
			};
		}
		default:
			return state;
	}
};
