import { ILayoutValue } from 'Components/common/image/interface';

export const getShimmerStringSVGMarkup = (w = 160, h = 100) => `
  <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient id="g">
        <stop stop-color="#e6e5e5" offset="20%" />
        <stop stop-color="#f9f7f78c" offset="50%" />
        <stop stop-color="#e6e5e5" offset="100%" />
      </linearGradient>
    </defs>
    <rect width="${w}" height="${h}" fill="#e6e5e5" />
    <rect id="r" width="${w * 0.567375887}" height="${h}" fill="url(#g)" />
    <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
  </svg>
`;

export const toBase64 = (str: any) =>
	typeof window === 'undefined'
		? Buffer.from(str).toString('base64')
		: window.btoa(str);

export const getLayoutAssociatedFutureImageStyle = (layout: ILayoutValue) => {
	switch (layout) {
		case 'fixed':
			return {};
		case 'responsive':
			return { width: '100%', height: 'auto' };
		case 'fill':
		case 'intrinsic':
		default:
			return { maxWidth: '100%', height: 'auto' };
	}
};
