import { COUPON_TYPE } from 'Constants/constants';

export const getIsApplied = (booking: any) => {
	if (!booking) return null;
	return booking?.promoObject?.isApplied;
};

export const getHasShownPromoSuccessModal = (booking: any) => {
	if (!booking) return null;
	return booking?.promoObject?.hasShownPromoSuccessModal;
};

export const getPromoCode = (booking: any) => {
	if (!booking) return null;
	return booking?.promoObject?.promoCode;
};

export const getAppliedPromoCode = (booking: any) => {
	if (!booking) return null;
	return booking?.promoObject?.appliedPromoCode;
};

export const isCouponTypeBOGO = (booking: any) =>
	booking?.promoObject?.couponType === COUPON_TYPE.BNGN;
