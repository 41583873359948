import React from 'react';

import { initializeGTM } from 'Server/initializeTrackingScripts';

import useAttribution from 'Hooks/useAttribution';
import useReportVitals from 'Hooks/useReportVitals';
import {
	getExternalGTMKey,
	getGTMAuth,
	getGTMKey,
	getGTMPreview,
} from 'Utils/keyUtils';

import { DEVICE_TYPE } from 'Constants/constants';

const Analytics = ({ host }: any) => {
	const externalGTMKey = getExternalGTMKey(host);
	useReportVitals();
	useAttribution();

	return (
		<>
			{initializeGTM(
				// @ts-expect-error TS(2554): Expected 0-1 arguments, but got 2.
				getGTMKey(DEVICE_TYPE.COMMON, host),
				// @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
				getGTMAuth(host),
				// @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
				getGTMPreview(host),
			)}
			{/* @ts-expect-error TS(2554): Expected 3 arguments, but got 1. */}
			{externalGTMKey ? initializeGTM(externalGTMKey) : null}
		</>
	);
};

export default Analytics;
