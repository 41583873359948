import * as React from 'react';

export const CancelIconSvg = (props: any) => {
	return (
		<svg viewBox='0 0 24 24' {...props}>
			<path
				fillRule='evenodd'
				d='M21.86 23.352l-9.943-9.943-9.86 9.86-1.41-1.408L10.509 12 .648 2.14 2.056.73l9.861 9.861L21.861.648l1.409 1.408L13.326 12l9.944 9.944z'
			/>
		</svg>
	);
};
