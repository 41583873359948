import { ActionTypes } from 'Actions/actions';
import { TMedia, TMicroBrandInfo, TRatingsInfo } from 'ReduxTypes/generics';

interface requestCategoriesAction {
	type: typeof ActionTypes.REQUEST_CATEGORIES_AND_SUBCATEGORIES;
	cityCode: string;
}

interface receiveCategoriesAction {
	type: typeof ActionTypes.RECEIVE_CATEGORIES_AND_SUBCATEGORIES;
	cityCode: string;
	data: object;
	url: string;
}

export type CategoriesActionTypes =
	| requestCategoriesAction
	| receiveCategoriesAction;

export type CategoriesStoreType = {
	byCityCode: any;
	status: any;
	categoriesByCityCode: any;
	subCategoriesByCityCode: any;
	sections: any;
	starredCategoriesAndSubCategoriesByCityCode: {
		[key: string]: TStarredCategoryOrSubCategory;
	};
	subCategoriesFilterDataByCategoryId: {
		[key: string]: TSubCategory;
	};
	subCategoriesFilterDataByCategoryIdWithCollectionFilter: {
		[key: string]: TSubCategory;
	};
};

export type TStarredCategoryOrSubCategory = {
	id: number;
	isCategory: boolean;
	rank: number;
};

export enum CategoryType {
	CATEGORY = 'CATEGORY',
	SUB_CATEGORY = 'SUB_CATEGORY',
}

export type TCategory = {
	id: number;
	subCategories: number[];
	name: string;
	rank: number;
	displayName: string;
	heading: string;
	metaTitle: string;
	metaDescription: string;
	noIndex: boolean;
	canonicalUrl?: string;
	urlSlugs: Record<string, string>;
	microBrandInfo: TMicroBrandInfo;
	medias: TMedia[];
	ratingsInfo: TCategoryRatingsInfo;
};

export type TSubCategory = {
	id: number;
	name: string;
	categoryId: number;
	rank: number;
	displayName: string;
	heading: string;
	metaTitle: string;
	metaDescription: string;
	noIndex: boolean;
	canonicalUrl?: string;
	urlSlugs: Record<string, string>;
	medias: TMedia[];
	ratingsInfo: TCategoryRatingsInfo;
	microBrandInfo: TMicroBrandInfo;
};

export type TCategoryRatingsInfo = TRatingsInfo & {
	showRatings: boolean;
};
