import { slotsActions } from 'Actions/slots';

const initialState = {
	byProductId: {},
	status: { isFetching: {} },
};

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
export const slotsStore = (state = initialState, action) => {
	switch (action.type) {
		case slotsActions.REQUEST_SLOTS: {
			const { productId } = action;
			return {
				...state,

				status: {
					...state['status'],

					isFetching: {
						...state['status']['isFetching'],
						[String(productId)]: true,
					},
				},
			};
		}
		case slotsActions.RECEIVE_SLOTS: {
			const { productId, slotsJson } = action;
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						[String(productId)]: false,
					},
				},
				byProductId: {
					...state['byProductId'],
					[String(productId)]: slotsJson,
				},
			};
		}
		case slotsActions.RESET_SLOTS: {
			const { productId } = action;
			return {
				...state,
				byProductId: {
					...state['byProductId'],
					[String(productId)]: {},
				},
			};
		}
		default:
			return state;
	}
};
