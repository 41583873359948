import { ActionTypes } from 'Actions/actions';

const initialState = {
	byProductId: {},
	byTourId: {},
	isFetching: {},
};

export const calendarInvsStore = (state = initialState, action: any) => {
	const { type, payload } = action;

	switch (type) {
		case ActionTypes.REQUEST_CALENDAR_INVENTORIES:
			const { id, tourId } = payload;
			// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
			const fetchingStateForId = state.isFetching?.[String(id)];

			return {
				...state,
				isFetching: {
					...state.isFetching,
					[String(id)]: tourId
						? { ...fetchingStateForId, [String(tourId)]: true }
						: true,
				},
			};
		case ActionTypes.RECEIVE_CALENDAR_INVENTORIES: {
			const {
				id,
				tourId,
				inventoryMap,
				fromDate,
				toDate,
				currency,
				sortedInventoryDates,
			} = payload;
			const inventoryData = {
				inventoryMap,
				fromDate,
				toDate,
				currency,
				sortedInventoryDates,
			};
			const fetchingStateForId = (state as any).isFetching?.[String(id)];
			return {
				...state,
				byProductId: !tourId
					? {
							...state.byProductId,
							[String(id)]: inventoryData,
					  }
					: { ...state.byProductId },
				byTourId: tourId
					? { ...state.byTourId, [String(tourId)]: inventoryData }
					: { ...state.byTourId },
				isFetching: {
					...state.isFetching,
					[String(id)]: tourId
						? { ...fetchingStateForId, [String(tourId)]: false }
						: false,
				},
			};
		}
		default:
			return state;
	}
};
