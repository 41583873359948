import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			/**
			 * With SSR, we usually want to set some default staleTime
			 * above 0 to avoid refetching immediately on the client
			 * this is partly redundant as of now since we're fetching on client-side but good to keep
			 */
			staleTime: 60 * 1000, //1 minute
		},
	},
});

export const QUERY_KEYS = {
	CART_RECOMMENDATIONS: 'CART_RECOMMENDATIONS',
	BULK_CALENDAR: 'BULK_CALENDAR',
	CART: 'CART',
	BULK_PRODUCT: 'BULK_PRODUCT',
	BULK_INVENTORIES: 'BULK_INVENTORIES',
	BULK_USER_FIELDS: 'BULK_USER_FIELDS',
};
