import React from 'react';

import { isDevelopmentEnvironment } from 'Utils/envUtils';
import { log } from 'Utils/logUtils';

/* eslint-disable */

class ServiceWorker extends React.Component {
	componentDidMount() {
		if (isDevelopmentEnvironment()) {
			return;
		}
		setTimeout(() => {
			this.registerServiceWorker();
		}, 12000);
	}

	registerServiceWorker = () => {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker
				.register('/service-worker.js')
				.then(
					registration => {
						registration.onupdatefound = function () {
							const installingWorker = registration.installing;

							// @ts-expect-error TS(2531): Object is possibly 'null'.
							installingWorker.onstatechange = function () {
								// @ts-expect-error TS(2531): Object is possibly 'null'.
								switch (installingWorker.state) {
									case 'installed':
										if (
											navigator.serviceWorker.controller
										) {
											log(
												'New or updated content is available.',
											);
										} else {
											log(
												'Content is now available offline!',
											);
										}
										break;
									case 'redundant':
										log(
											'The installing service worker became redundant.',
										);
										break;
									default:
										break;
								}
							};
						};
					},
					err => {
						log('ServiceWorker registration failed: ', err);
					},
				)
				.catch(e => {
					log('Error during service worker registration:', e);
				});
		}
	};

	render() {
		return null;
	}
}

export default ServiceWorker;
