import { ActionTypes } from 'Actions/actions';

export const fetchStore = (state = {}, action: any) => {
	const fetched = (url: any) => ({
		...state,
		[url]: new Date().getTime(),
	});
	switch (action.type) {
		case ActionTypes.RECEIVE_PRODUCT:
		case ActionTypes.RECEIVE_REVIEWS_BY_TGID:
		case ActionTypes.RECEIVE_PRODUCT_LIST:
		case ActionTypes.RECEIVE_CURRENCIES:
		case ActionTypes.RECEIVE_PRODUCTS:
		case ActionTypes.RECEIVE_CITIES:
		case ActionTypes.RECEIVE_NEARBY_CITIES_FROM_LAT_LONG:
		case ActionTypes.RECEIVE_BANNERS:
		case ActionTypes.RECEIVE_USER_GEO_LOCATION:
		case ActionTypes.RECEIVE_LANGUAGES:
		case ActionTypes.RECEIVE_COLLECTIONS_DATA_BY_CITY_CODE:
		case ActionTypes.RECEIVE_CATEGORY_SECTIONS:
		case ActionTypes.RECEIVE_CATEGORIES_AND_SUBCATEGORIES:
		case ActionTypes.RECEIVE_COLLECTIONS_CARD_DATA_BY_ID:
		case ActionTypes.RECEIVE_TGID_BILINK_CONFIG:
			return fetched(action.url);
		case ActionTypes.PURGE_CITY_LIST_CACHE:
			const filteredUrlKeys = Object.keys(state).filter(urlKey => {
				return !urlKey.includes('/city/list');
			});
			let filteredUrlCache: Record<string, number> = {};
			filteredUrlKeys.forEach(key => {
				filteredUrlCache = {
					...filteredUrlCache,
					[key]: (state as any)[key],
				};
			});
			return {
				...filteredUrlCache,
			};
		default:
			return state;
	}
};

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
export const fetchingStatus = (state = { isFetching: 0 }, action) => {
	switch (action.type) {
		// FIXME: WTF is going on over here. Why do request and response have different types?
		// This is such a bad way of maintaining a state for ongoing and finished actions.
		case ActionTypes.REQUEST_PRODUCT:
		case ActionTypes.REQUEST_REVIEWS_BY_TGID:
		case ActionTypes.REQUEST_PRODUCT_LIST:
		case ActionTypes.REQUEST_CITIES:
		case ActionTypes.REQUEST_USER_GEO_LOCATION:
		case ActionTypes.REQUEST_CURRENCIES:
		case ActionTypes.REQUEST_PRODUCTS:
		case ActionTypes.REQUEST_NEARBY_CITIES_FROM_LAT_LONG:
		case ActionTypes.INCREMENT_API_COUNT:
		case ActionTypes.REQUEST_LANGUAGES: {
			return {
				...state,
				isFetching: state?.isFetching + 1,
			};
		}
		case ActionTypes.RECEIVE_PRODUCT:
		case ActionTypes.RECEIVE_REVIEWS_BY_TGID:
		case ActionTypes.RECEIVE_PRODUCT_LIST:
		case ActionTypes.RECEIVE_CITIES:
		case ActionTypes.RECEIVE_NEARBY_CITIES_FROM_LAT_LONG:
		case ActionTypes.RECEIVE_USER_GEO_LOCATION:
		case ActionTypes.RECEIVE_CURRENCIES:
		case ActionTypes.RECEIVE_PRODUCTS:
		case ActionTypes.RECEIVE_LANGUAGES:
		case ActionTypes.DECREMENT_API_COUNT: {
			return {
				...state,
				isFetching: Math.max(state?.isFetching - 1, 0),
			};
		}
		default:
			return state;
	}
};
