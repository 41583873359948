import { ActionTypes } from 'Actions/actions';

export const gtm = (state = null, action: any) => {
	switch (action.type) {
		case ActionTypes.SET_GTM_DATA: {
			const data = action.data;
			// @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
			return { ...state, ...data };
		}
		default:
			return state;
	}
};
