/** **Note:** this util does not verify if event is already attached,
		that logic needs to be handled in parent Component/Fn.
 */
export const waitForEventWithTimeout = ({
	// @ts-expect-error TS(7031): Binding element 'emitter' implicitly has an 'any' ... Remove this comment to see the full error message
	emitter,
	// @ts-expect-error TS(7031): Binding element 'eventName' implicitly has an 'any... Remove this comment to see the full error message
	eventName,
	// @ts-expect-error TS(7031): Binding element 'timeout' implicitly has an 'any' ... Remove this comment to see the full error message
	timeout,
	validatorFn = null,
}) => {
	return new Promise((resolve, reject) => {
		// @ts-expect-error TS(7034): Variable 'timer' implicitly has type 'any' in some... Remove this comment to see the full error message
		let timer;

		const listener = (data: any) => {
			try {
				// @ts-expect-error TS(2349): This expression is not callable.
				if (validatorFn && !validatorFn(data)) return;
			} catch (e) {
				//
			}
			// @ts-expect-error TS(7005): Variable 'timer' implicitly has an 'any' type.
			clearTimeout(timer);
			emitter.removeEventListener(eventName, listener);
			resolve(data);
		};

		emitter.addEventListener(eventName, listener);
		timer = setTimeout(() => {
			emitter.removeEventListener(eventName, listener);
			reject({});
		}, timeout);
	});
};

export const waitForElement = (
	document: Document,
	selector: string,
): Promise<Element> => {
	return new Promise(resolve => {
		const element = document.querySelector(selector);
		if (element) {
			return resolve(element);
		}

		const observer = new MutationObserver(() => {
			const element = document.querySelector(selector);
			if (element) {
				observer.disconnect();
				resolve(element);
			}
		});

		observer.observe(document.body, {
			childList: true,
			subtree: true,
		});
	});
};
