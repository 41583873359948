export const getPaxSelectionDataByTourId = ({
	checkoutMetadata,
	tourId,
}: any) => {
	return checkoutMetadata?.[tourId]?.paxSelectionInstructions ?? null;
};

export const getCancellationPolicyByTourId = ({
	checkoutMetadata,
	tourId,
}: any) => {
	return checkoutMetadata?.[tourId]?.cancellationPolicy ?? {};
};

export const getTicketValidityByTourId = ({
	checkoutMetadata,
	tourId,
}: any) => {
	return checkoutMetadata?.[tourId]?.ticketValidity ?? {};
};
