import { ActionTypes } from 'Actions/actions';

export const requestUserCountry = () => ({
	type: ActionTypes.REQUEST_USER_GEO_LOCATION,
});

export const receivedUserCountry = (
	userCountryCode: any,
	currentCountryCode: any,
) => ({
	type: ActionTypes.RECEIVE_USER_GEO_LOCATION,
	payload: {
		userCountryCode,
		currentCountryCode,
	},
});
