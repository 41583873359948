import React from 'react';

type Props = {
	id?: string;
	className?: string;
	onClick?: (...args: any[]) => any;
	children?: React.ReactNode;
	testId?: string;
};

/**
 * Localized h2 component
 * Disable google-translate by default
 */
const LH2 = ({ id, className, onClick, children, testId }: Props) => (
	<h2
		id={id}
		className={`notranslate ${className || ''}`}
		onClick={onClick}
		{...(testId && { 'data-testid': testId })}
	>
		{children}
	</h2>
);

export default LH2;
