import Cookies from 'nookies';

import { notify } from 'Components/common/notify';

import {
	identifyUser,
	sendUserVariablesToDataLayer,
	trackEvent,
} from 'Utils/analytics';
import {
	getDeleteRequest,
	getPostRequest,
	getPutRequest,
} from 'Utils/fetchUtils';
import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getCurrentCurrency, getUserProfileType } from 'Utils/stateUtils';
import { getBaseUrl } from 'Utils/urlUtils';

import { decrementAPICount, incrementAPICount } from 'Actions/apiCount';
import { setMembershipData } from 'Actions/membership';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';
import {
	deleteUserDetails,
	handleLoginFailure,
	receiveAffiliateResetPasswordDetails,
	receiveUserDetails,
	requestAffiliateDetails,
	requestAffiliateResetPassword,
	requestUserDetails,
} from 'Actions/user';

import {
	COOKIE,
	CUSTOM_HEADER,
	TIME,
	UserProfileTypes,
} from 'Constants/constants';

const setUserProfileTypeCookie = ({ userProfileType, context = {} }: any) => {
	if (userProfileType === UserProfileTypes.DISTRIBUTION_PARTNER) {
		Cookies.set(
			context,
			COOKIE.USER_PROFILE_TYPE,
			UserProfileTypes.DISTRIBUTION_PARTNER,
			{
				httpOnly: false,
				maxAge: 1 * TIME.SECONDS_IN_YEARS,
				path: '/',
				secure: true,
			},
		);
	} else {
		Cookies.set(
			context,
			COOKIE.USER_PROFILE_TYPE,
			UserProfileTypes.CUSTOMER,
			{
				httpOnly: false,
				maxAge: 1 * TIME.SECONDS_IN_YEARS,
				path: '/',
				secure: true,
			},
		);
	}
};

// @ts-expect-error TS(7031): Binding element 'isSignedIn' implicitly has an 'an... Remove this comment to see the full error message
const setIsSignedInCookie = ({ isSignedIn, context = {} }) => {
	Cookies.set(context, COOKIE.IS_SIGNED_IN, isSignedIn, {
		httpOnly: false,
		maxAge: 1 * TIME.SECONDS_IN_YEARS,
		path: '/',
		secure: true,
	});
};

export const fetchUserDetails =
	({ req, onSuccess, onRequestFinish }: any) =>
	(dispatch: any, getState: any) => {
		const allCookies = Cookies.get();
		const isSignedInCookie = allCookies[COOKIE.IS_SIGNED_IN];
		if (!isSignedInCookie) {
			if (onRequestFinish) {
				onRequestFinish();
			}
			return Promise.resolve();
		}
		const state = getState();
		const currencyCode = getCurrentCurrency(state);
		const currencyParam = currencyCode
			? `?base-currency=${currencyCode}`
			: '';
		dispatch(requestUserDetails(currencyCode));
		const url = `/api/v2/account/get/customer-details${currencyParam}`;
		const options = req ? { headers: { cookie: req.headers.cookie } } : {};
		const trackFailure = (err: any) =>
			trackEvent({
				eventName: 'Customer Details API Failed',
				...(err?.message && { 'Error Message': err.message }),
			});

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				setUserProfileTypeCookie({
					userProfileType: json.userProfileType,
				});
				if (json.customerId) {
					dispatch(receiveUserDetails({ json }));
				}

				if (json?.membershipDetails?.membershipId) {
					dispatch(
						setMembershipData({
							membershipData: json?.membershipDetails ?? null,
						}),
					);
				}
				// @ts-expect-error TS(2345): Argument of type '{ email: any; attributes: any; }... Remove this comment to see the full error message
				identifyUser({ email: json.email, attributes: json });
				sendUserVariablesToDataLayer(json);
				if (onSuccess) onSuccess();
				return json;
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				dispatch(handleLoginFailure());
				error(err);
				trackFailure(err);
				notify.showNetworkError(err);
			})
			.finally(() => {
				if (onRequestFinish) {
					onRequestFinish();
				}
			});
	};

export const authenticateAffiliate =
	({ creds }: any) =>
	(dispatch: any) => {
		dispatch(requestAffiliateDetails());
		dispatch(incrementAPICount());

		return fetch(`/api/v2/account/auth/email`, getPostRequest(creds))
			.then(response => response.json())
			.then(json => {
				if (json.customerId) {
					dispatch(decrementAPICount());
					setUserProfileTypeCookie({
						userProfileType: json.userProfileType,
					});

					if (json?.membershipDetails?.membershipId) {
						dispatch(
							setMembershipData({
								membershipData: json?.membershipDetails ?? null,
							}),
						);
					}
					setIsSignedInCookie({ isSignedIn: true });
					dispatch(receiveUserDetails({ json }));
					window.location = json.landingUrl || '/';
				} else {
					dispatch(decrementAPICount());
					const errorMsg =
						'Login Failed. Password/Username might be incorrect';
					throw new Error(errorMsg);
				}
			})
			.catch(err => {
				error(err);
				dispatch(decrementAPICount());
				notify.showNetworkError(err);
			});
	};

export const signInUser =
	({ loginOption, request, cookies, onSuccess, onFailure }: any) =>
	(dispatch: any, getState: any) => {
		const state = getState();
		const currencyCode = getCurrentCurrency(state);
		dispatch(requestUserDetails(currencyCode));
		const url = `/api/v2/account/auth/${loginOption}-access-token`;

		fetch(url, request)
			.then(response => {
				if (response.headers?.['X-H-AT'] !== null) {
					return response.json();
				}
				let {
					error: { message: errorMsg },
				} = response.json();
				errorMsg = errorMsg || 'Network Error';
				throw new Error(errorMsg);
			})
			.then(json => {
				dispatch(receiveUserDetails({ json }));
				setIsSignedInCookie({ isSignedIn: true });
				setUserProfileTypeCookie({
					userProfileType: json.userProfileType,
				});
				// @ts-expect-error TS(2345): Argument of type '{ isSignedIn: boolean; cookies: ... Remove this comment to see the full error message
				setIsSignedInCookie({ isSignedIn: true, cookies });
				dispatch(receiveUserDetails({ json }));
				if (json?.membershipDetails?.membershipId) {
					dispatch(
						setMembershipData({
							membershipData: json?.membershipDetails ?? null,
						}),
					);
				}
				sendUserVariablesToDataLayer(json);
				identifyUser({ emailId: json.email, attributes: json });
				if (onSuccess) {
					onSuccess();
				}
				return json;
			})
			.catch(err => {
				error(
					`Error while signing in the user with ${loginOption}`,
					err,
				);
				dispatch(setAPIServerAPIStatus(url, err.status));
				notify.showNetworkError(err);
				dispatch(handleLoginFailure());
				if (onFailure) {
					onFailure();
				}
			});
	};

export const updateAffiliateDetails = (body: any) => (dispatch: any) => {
	dispatch(incrementAPICount());
	const url = `/api/v2/account/updateProfile`;
	return fetch(url, getPostRequest(body))
		.then(response => response.json())
		.then(json => {
			dispatch(decrementAPICount());
			dispatch(receiveUserDetails({ json }));
		})
		.catch(err => {
			error(err);
			dispatch(setAPIServerAPIStatus(url, err.status));
			dispatch(decrementAPICount());
			notify.showNetworkError(err);
		});
};

export const resetAffiliatePassword = (body: string) => (dispatch: any) => {
	dispatch(requestAffiliateResetPassword());
	dispatch(incrementAPICount());
	const url = `/api/v2/account/reset`;
	return fetch(url, getPutRequest(body))
		.then(response => response.json())
		.then(json => {
			dispatch(decrementAPICount());
			dispatch(receiveAffiliateResetPasswordDetails(json));
		})
		.catch(err => {
			error(err);
			dispatch(setAPIServerAPIStatus(url, err.status));
			dispatch(decrementAPICount());
			notify.showNetworkError(err);
		});
};

export const signOut = () => (dispatch: any, getState: any) => {
	const url = `/api/v2/account/logout`;
	return fetch(url, getDeleteRequest())
		.then(response => response.json())
		.then(json => {
			if (json.success !== true) {
				throw new Error('Logout failed');
			}
			Cookies.set({}, COOKIE.USER_PROFILE_TYPE, '', {
				httpOnly: false,
				maxAge: 1 * TIME.SECONDS_IN_YEARS,
				path: '/',
				secure: true,
			});
			Cookies.set({}, COOKIE.IS_SIGNED_IN, '', {
				httpOnly: false,
				maxAge: 1 * TIME.SECONDS_IN_YEARS,
				path: '/',
				secure: true,
			});
			dispatch(deleteUserDetails());
			dispatch(setMembershipData({ membershipData: null }));
		})
		.then(() => {
			if (
				getUserProfileType(getState()) ===
				UserProfileTypes.DISTRIBUTION_PARTNER
			) {
				// @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
				window.location = getBaseUrl();
			}
		})
		.catch(err => {
			dispatch(setAPIServerAPIStatus(url, err.status));
			error(err);
			notify.showNetworkError(err);
		});
};

export const requestEmailLoginLink =
	({ email, redirectUrl, language, host, onSuccess, onFailure }: any) =>
	() => {
		const postOptions = getPostRequest({
			email,
			redirectUrl,
			language,
		});
		const { headers } = postOptions;
		const customHeaders = new Headers(headers);
		customHeaders.append(CUSTOM_HEADER.ORIGIN, `https://${host}`);
		const requestOptions = {
			...postOptions,
			headers: customHeaders,
		};
		return fetch(`/api/v3/account/email/send-login-link`, requestOptions)
			.then(() => onSuccess())
			.catch(err => {
				onFailure();
				notify.showNetworkError(err);
				error(err);
			});
	};
