import { CartItem } from 'src/types/cart';
import { Pricing } from 'src/types/pricing';

import { MComboUpsell } from 'Components/mobile/comboUpsell/interfaces';

import { hasValidPax } from 'Utils/bookingFlowUtils';
import { ICartSelectionItemDetails } from 'Utils/crossSell/types';
import {
	storeDateSelection,
	storeTimeSelection,
	storeTourDateSelection,
	storeTourTimeSelection,
	write,
} from 'Utils/localStorageUtils';
import {
	changePageQuery,
	getSanitizedPaxQuery,
	getSanitizedTourPaxQuery,
	removePageQuery,
} from 'Utils/urlUtils';

import { ActionTypes } from 'Actions/actions';

import { DATA_SOURCE_TYPE, QUERY_PARAM } from 'Constants/constants';

export const resetBookingStore = ({ id }: any) => ({
	id: String(id),
	type: ActionTypes.RESET_BOOKING,
});

export const removeBookingStore = ({ id }: any) => ({
	id: String(id),
	type: ActionTypes.REMOVE_BOOKING,
});

export const removeAllRecommendationBookingStore = (baseTgid: string) => ({
	id: String(baseTgid),
	type: ActionTypes.REMOVE_ALL_RECOMMENDATIONS_BOOKINGS,
});

export const bulkResetBookingStore = ({
	tgids,
}: {
	tgids: (string | number)[];
}) => ({
	ids: tgids,
	type: ActionTypes.RESET_BULK_BOOKING,
});

export const setCartBookingObjects = ({
	bookingObjects,
	lang,
}: {
	bookingObjects: Record<string | number, CartItem>;
	lang: string;
}) => ({
	bookingObjects,
	lang,
	type: ActionTypes.ADD_CART_SELECTIONS,
});

export const setBookingLang = ({ id, lang }: any) => ({
	id: String(id),
	lang,
	type: ActionTypes.SET_BOOKING_LANG,
});

export const setBookingVariantId = (
	{ id, variantId, defaultSelection }: any,
	changeQuery = true,
) => {
	if (changeQuery && variantId) {
		changePageQuery({ [QUERY_PARAM.VARIANT_ID]: variantId }, false);
	}
	return {
		payload: {
			id: String(id),
			variantId,
			defaultSelection,
		},
		type: ActionTypes.SET_BOOKING_VARIANT_ID,
	};
};

export const setBookingDate = (
	{
		id,
		date,
		isComparisonTable,
		defaultSelection,
		isNonBookingFlow = true,
	}: any,
	changeQuery = true,
) => {
	if (!defaultSelection) storeDateSelection(id, date);
	if (changeQuery && date && !isComparisonTable) {
		write('dateQuery', date);
		changePageQuery({ [QUERY_PARAM.DATE]: date }, false, isNonBookingFlow);
	}
	return {
		id: String(id),
		date,
		defaultSelection,
		type: ActionTypes.SET_BOOKING_DATE,
	};
};

type setBookingTimeArgs = {
	id?: string | number;
	time?: any;
	isComparisonTable?: boolean;
	defaultSelection?: any;
};

export const setBookingTime = (
	{ id, time, isComparisonTable, defaultSelection }: setBookingTimeArgs,
	changeQuery = true,
) => {
	write('timeQuery', time);
	if (!defaultSelection) storeTimeSelection(id, time);
	if (changeQuery && time && !isComparisonTable) {
		changePageQuery({ [QUERY_PARAM.TIME]: time }, false);
	} else if (!time) {
		removePageQuery(QUERY_PARAM.TIME);
	}
	return {
		id: String(id),
		time,
		type: ActionTypes.SET_BOOKING_TIME,
		defaultSelection,
	};
};

export const setBookingTourId = (
	{ id, tourId, defaultSelection }: any,
	changeQuery = true,
) => {
	if (changeQuery && tourId) {
		changePageQuery({ [QUERY_PARAM.TOUR_ID]: tourId }, false, true);
	}
	return {
		id: String(id),
		tourId,
		type: ActionTypes.SET_BOOKING_TOUR_ID,
		defaultSelection,
	};
};

export const setBookingPeople = (
	{ id, pricing, people }: any,
	changeQuery = true,
) => {
	if (changeQuery && hasValidPax(people)) {
		changePageQuery(getSanitizedPaxQuery(people), false);
	}
	return {
		id: String(id),
		pricing,
		selectionMap: people.selectionMap,
		groupSize: people.groupSize,
		type: ActionTypes.SET_BOOKING_PEOPLE,
	};
};

export const setBookingSlot = ({
	id,
	tourId,
	time,
	pricing,
	people = null,
	retainSelectionMap,
	fallbackToBookingTime = true,
}: any) => ({
	id: String(id),
	tourId,
	time,
	pricing,
	people,
	retainSelectionMap,
	fallbackToBookingTime,
	type: ActionTypes.SET_BOOKING_SLOT,
});

export const setTourSlot = ({ id, tourId, time, pricing }: any) => ({
	id: String(id),
	tourId,
	time,
	pricing,
	type: ActionTypes.SET_TOUR_SLOT,
});

export const setTourDate = (
	{ id, date, tourId, defaultSelection }: any,
	changeQuery = true,
) => {
	if (!defaultSelection) storeTourDateSelection(id, tourId, date);
	if (changeQuery && date) {
		const dateQueryParam = `${[QUERY_PARAM.DATE]}.${tourId}`;
		changePageQuery({ [dateQueryParam]: date }, false, false);
	}
	return {
		payload: {
			id: String(id),
			date,
			tourId: String(tourId),
		},
		type: ActionTypes.SET_TOUR_DATE,
	};
};

export const setTourTime = (
	{ id, time, tourId, defaultSelection }: any,
	changeQuery = true,
) => {
	if (!defaultSelection) storeTourTimeSelection(id, tourId, time);
	if (changeQuery && time) {
		const timeQueryParam = `${[QUERY_PARAM.TIME]}.${tourId}`;
		changePageQuery({ [timeQueryParam]: time }, false, false);
	}
	return {
		payload: {
			id: String(id),
			time,
			tourId: String(tourId),
			defaultSelection,
		},
		type: ActionTypes.SET_TOUR_TIME,
	};
};

export const setTourPeople = (
	{ id, tourId, pricing, people }: any,
	changeQuery = true,
) => {
	if (changeQuery && hasValidPax(people)) {
		changePageQuery(getSanitizedTourPaxQuery(people, tourId), false, false);
	}
	return {
		payload: {
			id: String(id),
			tourId: String(tourId),
			pricing,
			selectionMap: people.selectionMap,
			groupSize: people.groupSize,
		},
		type: ActionTypes.SET_TOUR_PEOPLE,
	};
};

export const setBookingSeatMap = ({ id, seatMapInfo, pricing }: any) => ({
	id: String(id),
	seatMapInfo,
	pricing,
	type: ActionTypes.SET_BOOKING_SEATMAP,
});

export const setBookingStage = ({ id, stage }: any) => ({
	id: String(id),
	stage,
	type: ActionTypes.SET_BOOKING_STAGE,
});

export const setItineraryComment = ({ id, comment }: any) => ({
	id: String(id),
	comment,
	type: ActionTypes.SET_BOOKING_ITINERARY_COMMENT,
});

export const requestSeatingSVG = (id: any) => ({
	id: String(id),
	type: ActionTypes.REQUEST_SEATING_SVG,
});

export const receiveSeatingSVG = ({
	// @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
	id,
	// @ts-expect-error TS(7031): Binding element 'svg' implicitly has an 'any' type... Remove this comment to see the full error message
	svg,
	dataSource = DATA_SOURCE_TYPE.API,
}) => ({
	id: String(id),
	svg,
	dataSource,
	type: ActionTypes.RECEIVE_SEATING_SVG,
});

export const setPromoCode = ({ id, promoCode }: any) => ({
	id: String(id),
	promoCode,
	type: ActionTypes.SET_PROMO_CODE,
});

export const applyPromoCode = ({ id }: any) => ({
	id: String(id),
	type: ActionTypes.APPLY_PROMO_CODE,
});

export const setHasShownPromoSuccessModal = ({ id, hasShown }: any) => ({
	id: String(id),
	type: ActionTypes.SET_HAS_SHOWN_PROMO_SUCCESS_MODAL,
	hasShown,
});

export const setAutoAppliedCoupon = ({ id, couponCode }: any) => ({
	id: String(id),
	couponCode,
	type: ActionTypes.SET_AUTO_APPLIED_COUPON,
});

export const makingBooking = ({ id }: any) => ({
	id: String(id),
	type: ActionTypes.MAKING_BOOKING,
});

export const madeBooking = ({ id, status }: any) => ({
	id: String(id),
	status,
	type: ActionTypes.MADE_BOOKING,
});

export const bookingRequiresUserAction = ({ id }: { id: number }) => ({
	id: String(id),
	type: ActionTypes.REQUEST_USER_ACTION,
});

export const setExtraInformation = (flag: any) => ({
	flag,
	type: ActionTypes.SET_EXTRA_INFORMATION_FLAG,
});

export const setUserNameComplete = (userName: any) => ({
	userName,
	type: ActionTypes.SET_USER_NAME_COMPLETE,
});

export const setCardHolderName = (cardHolderName: any) => ({
	cardHolderName,
	type: ActionTypes.SET_CARD_HOLDER_NAME,
});

export const setCardNumber = (cardNumber: any) => ({
	cardNumber,
	type: ActionTypes.SET_CARD_NUMBER,
});

export const setCardFirst6 = (cardFirst6: any) => ({
	cardFirst6,
	type: ActionTypes.SET_CARD_FIRST_6,
});

export const setCardLast4 = (cardLast4: any) => ({
	cardLast4,
	type: ActionTypes.SET_CARD_LAST_4,
});

export const setCardMonthExpiry = (month: any) => ({
	month,
	type: ActionTypes.SET_CARD_MONTH_EXPIRY,
});

export const setCardYearExpiry = (year: any) => ({
	year,
	type: ActionTypes.SET_CARD_YEAR_EXPIRY,
});

export const setCardCVV = (cvv: any) => ({
	cvv,
	type: ActionTypes.SET_CARD_CVV,
});

export const setCardAddress = (key: any, val: any) => ({
	key,
	val,
	type: ActionTypes.SET_CARD_ADDRESS,
});

export const setEncryptedCardDetails = ({
	encryptedFieldName,
	encryptedFieldValue,
}: any) => ({
	payload: {
		encryptedFieldName,
		encryptedFieldValue,
	},
	type: ActionTypes.SET_ENCRYPTED_CARD_DETAILS,
});

export const setCardValidity = (isValid: boolean) => ({
	payload: {
		isValid,
	},
	type: ActionTypes.SET_CARD_VALIDITY,
});

export const setCheckoutPaymentFieldValidities = (
	paymentFieldValidities: any,
) => ({
	payload: {
		paymentFieldValidities,
	},
	type: ActionTypes.SET_CHECKOUT_PAYMENT_FIELD_VALIDITIES,
});

export const setUserDetails = (id: any, userDetails: any) => ({
	id: String(id),
	userDetails,
	type: ActionTypes.SET_USER_DETAILS,
});

export const setTourUserDetails = (id: any, tourId: any, userDetails: any) => ({
	id: String(id),
	tourId,
	userDetails,
	type: ActionTypes.SET_TOUR_USER_DETAILS,
});

export const setUserFieldValues = ({ id, userFieldValues }: any) => ({
	id: String(id),
	userFieldValues,
	type: ActionTypes.SET_USER_FIELD_VALUES,
});

export const setTourUserFieldValues = ({
	id,
	tourId,
	userFieldValues,
}: any) => ({
	id: String(id),
	tourId,
	userFieldValues,
	type: ActionTypes.SET_TOUR_USER_FIELD_VALUES,
});

export const setBookingResponseData = ({
	id,
	json,
	resetBookingResponse = false,
}: any) => ({
	id: String(id),
	bookingResponse: json,
	resetBookingResponse,
	type: ActionTypes.SET_BOOKING_RESPONSE_DATA,
});

export const setBookingId = ({ id, json, resetBookingId = false }: any) => ({
	id: String(id),
	bookingResponse: json,
	resetBookingId,
	type: ActionTypes.SET_BOOKING_ID,
});

export const setBookButtonLock = ({ id, status }: any) => ({
	id: String(id),
	status,
	type: ActionTypes.SET_BOOK_BUTTON_LOCK,
});

export const setPaymentError = ({ id, error }: any) => ({
	payload: {
		id: String(id),
		error,
	},
	type: ActionTypes.SET_PAYMENT_ERROR,
});

export const setFingerprintVisitorDetails = ({ id, details }: any) => ({
	payload: {
		id: String(id),
		details,
	},
	type: ActionTypes.SET_FINGERPRINT_DETAILS,
});

export const setItineraryDetails = ({ id, itineraryDetails }: any) => ({
	id: String(id),
	itineraryDetails,
	type: ActionTypes.SET_ITINERARY_DETAILS,
});

export const setInstantCheckoutType = ({ id }: any) => ({
	id: String(id),
	type: ActionTypes.SET_INSTANT_CHECKOUT_TYPE,
});

export const requestComboUpsellData = ({
	id,
	tourId,
	lang,
	currency,
}: {
	id: string;
	tourId: string;
	lang: string;
	currency: string;
}) => {
	return {
		type: ActionTypes.REQUEST_COMBO_UPSELL_DATA,
		payload: {
			id,
			tourId,
			lang,
			currency,
		},
	};
};

export const receiveComboUpsellData = ({
	id,
	tourId,
	currency,
	lang,
	payload,
}: {
	id: string;
	tourId: string;
	currency: string;
	lang: string;
	payload: MComboUpsell.IComboUpsellData;
}) => {
	return {
		type: ActionTypes.RECEIVE_COMBO_UPSELL_DATA,
		payload: {
			id,
			tourId,
			currency,
			lang,
			comboUpsellData: { ...payload },
		},
	};
};

export const setAirportTransfersBookingQuoteId = ({ id, quoteId }: any) => ({
	id: String(id),
	quoteId,
	type: ActionTypes.SET_AIRPORT_TRANSFERS_BOOKING_QUOTE_ID,
});

export const setBookingUserFields = ({
	id,
	tourId,
	pricing,
	time,
}: {
	id: string;
	tourId: string;
	time: string;
	pricing: Record<string, any>;
}) => ({
	id: String(id),
	tourId,
	pricing,
	time,
	type: ActionTypes.SET_BOOKING_USER_FIELDS,
});

export const setBulkBookingUserFields = (
	productIds: (number | string)[],
	pricingStore: {
		[productId: string]: Pricing;
	} | null,
) => ({
	productIds,
	pricingStore,
	type: ActionTypes.SET_BULK_BOOKING_USER_FIELDS,
});

export const setVariantFlow = ({
	id,
	isExperimentalFlow = false,
}: {
	id: string;
	isExperimentalFlow?: boolean;
}) => {
	return {
		id: String(id),
		isExperimentalFlow,
		type: ActionTypes.SET_VARIANT_FLOW,
	};
};

export const transferCartSelections = (
	bookingDetails: ICartSelectionItemDetails,
	language: string,
) => {
	const { id } = bookingDetails;

	return {
		...bookingDetails,
		id: String(id),
		language,
		type: ActionTypes.TRANSFER_CART_SELECTION_TO_BOOKINGS,
	};
};
