export const slotsActions = {
	REQUEST_SLOTS: 'REQUEST_SLOTS',
	RECEIVE_SLOTS: 'RECEIVE_SLOTS',
	RESET_SLOTS: 'RESET_SLOTS',
};

export const requestSlots = (productId: any) => ({
	productId,
	type: slotsActions.REQUEST_SLOTS,
});

export const receiveSlots = (productId: any, slotsJson: any) => ({
	productId,
	slotsJson,
	type: slotsActions.RECEIVE_SLOTS,
});

export const resetSlots = ({ productId }: { productId: string }) => ({
	productId,
	type: slotsActions.RESET_SLOTS,
});
