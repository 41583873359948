import React from 'react';

export const IDealSvg = (props: any) => (
	<svg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' {...props}>
		<g clipPath='url(#clip000)'>
			<g clipPath='url(#clip01)'>
				<path
					d='M4.35714 3.2619V13.369H8.75595C12.75 13.369 14.4821 11.1131 14.4821 7.92262C14.4821 4.86905 12.75 2.5 8.75595 2.5H5.11905C4.69643 2.5 4.35714 2.84524 4.35714 3.2619Z'
					fill='#CC0066'
				/>
				<path
					d='M8.25 14.875H1.14286C0.511905 14.875 0 14.3631 0 13.7321V2.14286C0 1.5119 0.511905 1 1.14286 1H8.25C14.994 1 16 5.33929 16 7.92262C16 12.4048 13.244 14.875 8.25 14.875ZM1.14286 1.38095C0.720238 1.38095 0.380952 1.72024 0.380952 2.14286V13.7321C0.380952 14.1548 0.720238 14.494 1.14286 14.494H8.25C13 14.494 15.619 12.1607 15.619 7.92262C15.619 2.23214 11 1.38095 8.25 1.38095H1.14286Z'
					fill='black'
				/>
				<path
					d='M5.90476 6.55954C6.05952 6.55954 6.20238 6.58335 6.33929 6.63097C6.47619 6.67859 6.58929 6.75597 6.69048 6.85121C6.78571 6.9524 6.8631 7.0774 6.92262 7.22026C6.97619 7.36907 7.00595 7.54168 7.00595 7.74407C7.00595 7.92264 6.98214 8.08335 6.94048 8.23216C6.89286 8.38097 6.82738 8.51192 6.73809 8.61907C6.64881 8.72621 6.53571 8.80954 6.39881 8.87502C6.2619 8.93454 6.10119 8.97026 5.91667 8.97026H4.875V6.55359H5.90476V6.55954ZM5.86905 8.52978C5.94643 8.52978 6.01786 8.51788 6.09524 8.49407C6.16667 8.47026 6.23214 8.42859 6.28571 8.36907C6.33929 8.30954 6.3869 8.23811 6.42262 8.14287C6.45833 8.04764 6.47619 7.94049 6.47619 7.80359C6.47619 7.68454 6.46429 7.57145 6.44048 7.47621C6.41667 7.38097 6.375 7.29168 6.32143 7.22621C6.26786 7.16073 6.19643 7.10121 6.10714 7.06549C6.01786 7.02978 5.91071 7.01192 5.77976 7.01192H5.39881V8.53573H5.86905V8.52978Z'
					fill='white'
				/>
				<path
					d='M9.15476 6.55954V7.00597H7.88095V7.52383H9.05357V7.93454H7.88095V8.52383H9.18452V8.97026H7.35119V6.55359H9.15476V6.55954Z'
					fill='white'
				/>
				<path
					d='M10.9762 6.55957L11.881 8.97624H11.3274L11.1429 8.44052H10.2381L10.0476 8.97624H9.51191L10.4226 6.55957H10.9762ZM11.006 8.04171L10.7024 7.15481H10.6964L10.381 8.04171H11.006Z'
					fill='white'
				/>
				<path
					d='M12.744 6.55957V8.52981H13.9226V8.97624H12.2143V6.55957H12.744Z'
					fill='white'
				/>
				<path
					d='M2.36905 8.88098C2.98379 8.88098 3.48214 8.38263 3.48214 7.76788C3.48214 7.15314 2.98379 6.65479 2.36905 6.65479C1.7543 6.65479 1.25595 7.15314 1.25595 7.76788C1.25595 8.38263 1.7543 8.88098 2.36905 8.88098Z'
					fill='black'
				/>
				<path
					d='M3.20834 13.369C2.27381 13.369 1.52381 12.6131 1.52381 11.6845V10.369C1.52381 9.90476 1.89881 9.5238 2.36905 9.5238C2.83334 9.5238 3.21429 9.8988 3.21429 10.369V13.369H3.20834Z'
					fill='black'
				/>
			</g>
		</g>
		<defs>
			<clipPath id='clip000'>
				<rect width='16' height='16' fill='white' />
			</clipPath>
			<clipPath id='clip01'>
				<rect width='16' height='16' fill='white' />
			</clipPath>
		</defs>
	</svg>
);
