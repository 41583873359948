export const faqActions = {
	REQUEST_FAQS: 'REQUEST_FAQS',
	RECEIVE_FAQS: 'RECEIVE_FAQS',
};

export const requestFaqs = () => ({
	type: faqActions.REQUEST_FAQS,
});

export const receiveFaqs = ({ generalFaqs, categoryFaqs }: any) => ({
	generalFaqs,
	categoryFaqs,
	type: faqActions.RECEIVE_FAQS,
});
