import { ActionTypes } from 'Actions/actions';

export const thunkErrorStore = (state = { byCategory: {} }, action: any) => {
	switch (action.type) {
		case ActionTypes.SET_THUNK_ERROR: {
			const { errorCategory, error } = action;
			return {
				...state,

				byCategory: {
					...state['byCategory'],
					[errorCategory]: error,
				},
			};
		}
		case ActionTypes.CLEAR_THUNK_ERROR: {
			const { errorCategory } = action;
			return {
				...state,

				byCategory: {
					...state['byCategory'],
					[errorCategory]: null,
				},
			};
		}
		default:
			return state;
	}
};
