import type { TBilinkConfig } from 'Containers/common/biLinkPopup';

import { ActionTypes } from 'Actions/actions';

const initialState = {
	shouldRemoveUrgencyBanner: false,
	isBiLinkCouponApplied: false,
	byProductId: {},
};

export const biLinkStore = (
	state = initialState,
	action: {
		type: string;
		payload: {
			shouldRemoveUrgencyBanner?: boolean;
			isUrgencyBannerCouponApplied?: boolean;
			tgidBilinkData?: TBilinkConfig;
		};
	},
) => {
	switch (action.type) {
		case ActionTypes.REMOVE_URGENCY_BANNER:
			return {
				...state,
				shouldRemoveUrgencyBanner:
					action.payload.shouldRemoveUrgencyBanner,
			};
		case ActionTypes.BILINK_COUPON_APPLIED:
			return {
				...state,
				isBiLinkCouponApplied:
					action.payload.isUrgencyBannerCouponApplied,
			};
		case ActionTypes.REQUEST_TGID_BILINK_CONFIG:
			return {
				...state,
				byProductId: { ...state.byProductId, isFetching: true },
			};
		case ActionTypes.RECEIVE_TGID_BILINK_CONFIG:
			return {
				...state,
				byProductId: {
					...action.payload.tgidBilinkData,
					isFetching: false,
				},
			};
		default:
			return state;
	}
};
