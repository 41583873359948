/**
 * Created by shivam on 9/13/17.
 */
import { captureException as sendLogToSentry } from '@sentry/nextjs';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'sess... Remove this comment to see the full error message
import sessionStore from 'sessionstorage';

/* Note:
 * Session storage gives at max 5mb, and is supported in mordern browsers only.
 * So be careful what kind of data you want to store here.
 */

export const clear = () => {
	sessionStore.clear();
};

export const read = (key: any) => {
	return sessionStore.getItem(key);
};

export const write = (key: any, value: any) => {
	try {
		sessionStore.setItem(key, value);
	} catch (e) {
		clear();
		sendLogToSentry(new Error('Session Store overflowed and cleared'));
	}
};

export const remove = (key: any) => {
	sessionStore.removeItem(key);
};
