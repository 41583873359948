import {
	isDevelopmentEnvironment,
	isProductionEnvironment,
	isServer,
} from './envUtils';
import { getPostRequest } from './fetchUtils';
import { debounce } from './gen';

// @ts-expect-error TS(7019): Rest parameter 'printStatement' implicitly has an ... Remove this comment to see the full error message
export const log = (...printStatement) => {
	if (isLogEnabled() && isDevelopmentEnvironment()) {
		console.log(...printStatement); // eslint-disable-line no-console
	}
};

// @ts-expect-error TS(7019): Rest parameter 'printStatement' implicitly has an ... Remove this comment to see the full error message
export const prodLog = (...printStatement) => {
	console.log(...printStatement); // eslint-disable-line no-console
};

// @ts-expect-error TS(7019): Rest parameter 'printStatement' implicitly has an ... Remove this comment to see the full error message
export const warn = (...printStatement) => {
	if (isLogEnabled() && !isProductionEnvironment()) {
		console.warn(...printStatement); // eslint-disable-line no-console
	}
};

// @ts-expect-error TS(7019): Rest parameter 'printStatement' implicitly has an ... Remove this comment to see the full error message
export const logWithTime = (...printStatement) => {
	log(...printStatement, new Date().getTime());
};

// @ts-expect-error TS(7019): Rest parameter 'err' implicitly has an 'any[]' typ... Remove this comment to see the full error message
export const error = (...err) => {
	if (
		(isServer() && !isDevelopmentEnvironment()) ||
		isProductionEnvironment()
	) {
		// TODO: log the error in sentry
	} else {
		console.error(...err); // eslint-disable-line no-console
	}
};

export const formatLog = (message: any, meta: any) => {
	return `${message}\n${JSON.stringify(meta, null, 2)}`;
};

export const isLogEnabled = () => {
	return process.env.LOG === 'enable';
};

/*
Util method to log to logging engine from client
From server you can directly log with papertrail lib
but for client side error you have to go with api route
Added buffer support, so it doesn't call api as soon as it receives
Experimental: use it with caution, logging too much can cause load on deimos-server
Papertrail didn't have REST api support, but coralogix has
TODO: Use coralogix endpoint instead of  /feapi/capture-exception/ to avoid load on deimos
*/
export const logClientMessages = (() => {
	let errQueue: any[] = [];
	async function captureException(errQueue: any) {
		try {
			const request = getPostRequest(errQueue);
			await fetch(`/feapi/capture-exception/`, request);
			return true;
		} catch (e) {
			return false;
		} finally {
			errQueue = [];
		}
	}
	const debouncedCaptureException = debounce(captureException, 1000);
	return async (title: any, metaData?: any) => {
		errQueue.push({ title, meta: metaData });
		// @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
		debouncedCaptureException([...errQueue]);
	};
})();
