import type { SVGProps } from 'react';

const Cookie = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={22}
		height={22}
		fill='none'
		{...props}
	>
		<path
			fill='#8000FF'
			d='M14.136 14.052a1.032 1.032 0 1 1-1.463 1.456 1.032 1.032 0 0 1 1.463-1.456Zm-6.961-.688a1.032 1.032 0 1 0 .002 0h-.002Zm.773-3.351a1.031 1.031 0 1 0-.334.223c.125-.053.239-.129.334-.225v.002Zm4.125-.086a1.032 1.032 0 1 0-1.456 1.461 1.032 1.032 0 0 0 1.456-1.461ZM19.937 11A8.938 8.938 0 1 1 11 2.062a.687.687 0 0 1 .688.688 3.438 3.438 0 0 0 3.437 3.438.687.687 0 0 1 .688.687 3.438 3.438 0 0 0 3.437 3.438.687.687 0 0 1 .688.687Zm-1.401.635A4.824 4.824 0 0 1 14.48 7.52a4.824 4.824 0 0 1-4.115-4.056 7.563 7.563 0 1 0 8.17 8.171Z'
		/>
	</svg>
);

export default Cookie;
