import Cookies from 'nookies';

import { COOKIE, TIME } from 'Constants/constants';

export const getCookie = (cname: any) => {
	const cookie = document.cookie;
	const cnameCookie = cookie
		? cookie
				.split(';')
				.filter(entry => entry.split('=')[0].trim() === cname)[0]
		: null;
	return cnameCookie ? cnameCookie.split('=')[1].trim() : null;
};

export const setCurrencyCookie = (val: string, context: any = null) => {
	Cookies.set(context, COOKIE.CURRENT_CURRENCY, val, {
		httpOnly: false,
		path: '/',
		maxAge: TIME.SECONDS_IN_MONTH * 3,
	});
};
