import React from 'react';
import { withRouter } from 'next/router';

import CouponCodeBanner from 'Components/common/couponCodeBanner';
import PromotionalPopup from 'Components/desktop/promotionalPopup';

import PlatformUtils from 'Utils/platformUtils';

// @ts-expect-error TS(7031): Binding element 'location' implicitly has an 'any'... Remove this comment to see the full error message
const StickyBanner = ({ router: location }) => {
	return PlatformUtils.isDesktop() ? (
		<PromotionalPopup location={location} />
	) : (
		<CouponCodeBanner location={location} />
	);
};

export default withRouter(StickyBanner);
