import 'Utils/nodeFillers';

class UniqueId {
	constructor(sandboxIdBase64Encoded: any) {
		(this as any).value = btoa(sandboxIdBase64Encoded);
	}

	getBase64EncodedValue() {
		return atob((this as any).value);
	}
}

export default UniqueId;
