/**
 * Generic constants used throughout
 */
import { ApplePaySvg } from 'Assets/svg/checkout/ApplePaySvg';
import { GooglePaySvg } from 'Assets/svg/checkout/GooglePaySvg';
import { CardSvg } from 'Assets/svg/payment/CardSvg';
import { FPXSvg } from 'Assets/svg/payment/FPX';
import { IDealSvg } from 'Assets/svg/payment/IDealSvg';
import { PayPalSvg } from 'Assets/svg/payment/PayPalSvg';

import { strings } from './strings';

// @NOTE: IMPORTANT. If you are changing the versions.
// All of our users current store data is cleared. We need to do this ONLY when
// previous store schema is not supported anymore. not for additions.
// Make sure you are clear on that before bumping up the number
export const STORE_JS_VERSION = '0.2.3';

export const DATA_SOURCE_TYPE = {
	API: 'API',
	STORE: 'STORE',
	LOCAL_STORAGE: 'LOCAL_STORAGE',
};

export const PRICE_RANGE_DELIMETER = '~';

export const PRICE_FETCH_STATUS = {
	IN_PROGRESS: 'IN_PROGRESS',
	NOT_FETCHED: 'NOT_FETCHED',
	COMPLETE: 'COMPLETE',
};

export const TOUR_TYPE = {
	TOUR: 'TOUR',
	ACTIVITY: 'ACTIVITY',
	TRANSFER: 'TRANSFER',
	EVENT: 'EVENT',
	ATTRACTION: 'ATTRACTION',
	ONE_TIME: 'ONE_TIME',
};

export const DROPDOWN_TYPES = {
	CUSTOMER_FIELD: 'Customer Field',
	PAYMENT_OPTIONS: 'Payment Options',
	PAYMENT_FIELD: 'Payment Field',
};

export const STORE_ENTITY_TYPE = {
	CITIES_LIST: 'CITIES_LIST',
	CURRENT_CITY_CODE: 'CURRENT_CITY_CODE',
	CURRENT_CURRENCY: 'CURRENT_CURRENCY',
	LOCATION_CURRENCY: 'LOCATION_CURRENCY',
	CART_ABANDONMENT_TRACKER: 'CART_ABANDONMENT_TRACKER',
	PRODUCT_LIST: 'PRODUCT_LIST',
	CATEGORIES_LIST: 'CATEGORIES_LIST',
	PRODUCT: 'PRODUCT',
	BOOKINGS: 'BOOKINGS',
	SEAT_SVG: 'SEAT_SVG',
	USER: 'USER',
	STORE_VERSION: 'STORE_VERSION',
};

export const BANNER_IMAGE_SIZE = {
	DESKTOP: {
		HEIGHT: 400,
		WIDTH: 960,
	},
	MOBILE: {
		HEIGHT: 400,
		WIDTH: 343,
	},
};

export const PRODUCT_BANNER_SIZE = {
	DESKTOP: {
		HEIGHT: 450,
		WIDTH: 720,
	},
	MOBILE: {
		HEIGHT: 310,
		WIDTH: 496,
	},
};

export const BOOKING_FLOW_TYPE = {
	SEATMAP: 'SEATMAP',
	SVG: 'SVG',
	COMBO: 'COMBO',
	NORMAL: 'NORMAL',
	RESERVATION: 'RESERVE',
	OPEN_DATED: 'OPEN_DATED',
	PRIVATE_AIRPORT_TRANSFER: 'PRIVATE_AIRPORT_TRANSFER',
	SHARED_AIRPORT_TRANSFER: 'SHARED_AIRPORT_TRANSFER',
	HOP_ON_HOP_OFF: 'HOP_ON_HOP_OFF',
};

export const BOOKING_FLOW_STAGE = {
	CONTENT: 'content',
	SELECT: 'select',
	CHECKOUT: 'checkout',
	COMBO_UPSELL: 'upgrade-your-experience',
	/* SVG FLOW */
	SVG_SELECT: 'svg-select',
	SVG_VARIANT: 'svg-variant',
	/* SEATMAP FLOW */
	EXTERNAL_SEATMAP_SELECT: 'seatmap-iframe-select',
	SEATMAP_SELECT: 'seatmap-select',
	SEATMAP_VARIANT: 'seatmap-variant',
	SEATMAP_CHECKOUT: 'seatmap-checkout',
	/* POST-CHECKOUT */
	CONFIRMATION: 'confirmation',
	/* SEPARATED-ROUTES */
	RECOMMENDATIONS: 'recommendations',
};

export const POST_CHECKOUT_STAGES = [BOOKING_FLOW_STAGE.CONFIRMATION];

export const BOOKING_FLOW_CHECKOUT_STAGES = [
	BOOKING_FLOW_STAGE.CHECKOUT,
	BOOKING_FLOW_STAGE.SEATMAP_CHECKOUT,
];

export const BOOKING_FLOW_TOP_LEVEL_ROUTES = ['book', ...POST_CHECKOUT_STAGES];

export const BOOKING_FLOW_SUBSTAGE = {
	TIME: 'time',
	PAX: 'pax',
	RESERVATION_DETAILS: 'reservation-details',
};

export const BOOKING_STATUS = {
	ON_REQUEST: 'ON_REQUEST',
	PENDING: 'PENDING',
	SUCCESSFUL: 'SUCCESSFUL',
	ERROR: 'ERROR',
	UNCAPTURED: 'UNCAPTURED',
};

export const ORDER_STATUS_TYPES = {
	PENDING: 'PENDING',
	COMPLETED: 'COMPLETED',
	CANCELLED: 'CANCELLED',
	UNCAPTURED: 'UNCAPTURED',
};

export const PAYMENT_POLLING_STATE = {
	CONTINUE: 'CONTINUE',
	STOP: 'STOP',
};

export const PAGE_TYPE = {
	HOME: 'Home Page',
	CITY: 'City Page',
	PERSONA: 'Persona Page',
	SEARCH_LIST: 'Search Results Page',
	TOUR_LIST: 'TOUR_LIST',
	COLLECTION: 'Collection Page',
	CATEGORY: 'Category Page',
	SUB_CATEGORY: 'Sub Category Page',
	CATEGORY_TAB: 'Category Tab Page',
	EXPERIENCE: 'Experience Page',
	REVIEW: 'Review Page',
	CITIES_SITEMAP: 'Cities Sitemap Page',
	COLLECTIONS_SITEMAP: 'Collections Sitemap Page',
	PRODUCT_CARD: 'Product Card Page',
	PRODUCT_CARD_LIST: 'Product Card List Page',
	ORDER_STATUS: 'Order Status Page',
	ABOUT_US: 'About Us Page',
	ACCOUNT: 'Account Page',
	PRIVACY_POLICY: 'Privacy Policy Page',
	TERMS_OF_USE: 'Terms Of Use Page',
	APP_SHOWCASE: 'App Showcase Page',
	EMBED_CREATE: 'Embed Create Page',
	HELP: 'Help Page',
	BOOKING: 'Booking Page',
	SELECT: 'Select Page',
	TOURS: 'Tours Page',
	CHECKOUT: 'Checkout Page',
	ORDER_CONFIRMATION: 'Bookings Confirmation Page',
	REFERRAL: 'Referral Page',
	SCHOLARSHIP: 'Scholarship Page',
	ERROR: 'Error Page',
	NOT_FOUND: 'Page Not Found',
	PLACES_TO_VISIT: 'Places to visit Page',
	SEATS: 'Seats Page',
	STUDENT_GRADUATE_DISCOUNT: 'Student & Graduate Discount Page',
	VOUCHER: 'Vouchers',
	MANAGE_BOOKING: 'Manage Booking',
	COMBO_UPSELL: 'Combo Upsell',
	APP_PUSH_PAGE: 'App Download Nudge Page',
	RECOMMENDATIONS: 'Recommendations Page',
};

export const COLLECTION_TYPE = {
	POI: 'POI',
};

export const CATEGORY_TYPE = {
	CATEGORY: 'Category',
	SUB_CATEGORY: 'Sub-Category',
};

export const PROFILE_TYPE = {
	PER_PERSON: 'PER_PERSON',
	PER_GROUP: 'PER_GROUP',
} as const;

export const NODE_ENV = {
	DEV: 'development',
	TEST: 'test',
	STAGE: 'stage',
	PROD: 'production',
	ONDEMAND: 'ondemand',
};

export const CDN_IMGIX_BASE_SERVER = 'cdn-imgix.headout.com';

export const CDN_IMGIX_OPEN_BASE_SERVER = 'cdn-imgix-open.headout.com';

export const CDN_IMGIX_PRISMIC = 'images.prismic.io';

export const INVENTORY_TYPE = {
	FIXED_START_FIXED_DURATION: 'FIXED_START_FIXED_DURATION',
	FIXED_START_FLEXIBLE_DURATION: 'FIXED_START_FLEXIBLE_DURATION',
	FLEXIBLE_START_FIXED_DURATION: 'FLEXIBLE_START_FIXED_DURATION',
	FLEXIBLE_START_FLEXIBLE_DURATION: 'FLEXIBLE_START_FLEXIBLE_DURATION',
};

export const INVENTORY_START_TYPE = {
	FIXED_START: 'FIXED_START',
	FLEXIBLE_START: 'FLEXIBLE_START',
};

export const INVENTORY_DURATION_TYPE = {
	FIXED_DURATION: 'FIXED_DURATION',
	FLEXIBLE_DURATION: 'FLEXIBLE_DURATION',
};

export const AVAILABILITY_TYPE = {
	LIMITED: 'LIMITED',
	UNLIMITED: 'UNLIMITED',
};

export const PAX_TYPES = {
	GROUP: 'GROUP',
	ADULT: 'ADULT',
};

export const TIME = {
	SECONDS_IN_YEARS: 365 * 24 * 60 * 60,
	SECONDS_IN_MONTH: 24 * 60 * 60 * 30,
	SECONDS_IN_DAY: 24 * 60 * 60,
	SECONDS_IN_HOUR: 60 * 60,
	SECONDS_IN_MINUTE: 60,
	IN_YEARS: 365 * 24 * 60 * 60 * 1000,
	IN_DAYS: 24 * 60 * 60 * 1000,
	IN_HOURS: 60 * 60 * 1000,
	IN_MINUTES: 60 * 1000,
	IN_SECONDS: 1000,
};

export const DEVICE_TYPE = {
	DESKTOP: 'DESKTOP',
	MOBILE: 'MOBILE',
	COMMON: 'COMMON',
};

export const EVENTS = {
	WINDOW: {
		MESSAGE: 'message',
	},
};

export const GTM_KEY_PROD = {
	COMMON: 'GTM-NPMNPB',
};

export const GTM_KEY_TEST = {
	COMMON: 'GTM-5NXSKCP',
};

export const GTM_AUTH_DEV = '8pwO-VJc8ITZdfyLd4gB9g';
export const GTM_AUTH_PROD = 'gjKqx84Vs2f3zwF3HUVSdg';
export const GTM_PREVIEW_DEV = 'env-305';
export const GTM_PREVIEW_PROD = 'env-1';

export const GTM_KEY_EXT = {
	LTD: 'GTM-KRXWM4G',
};

export const SMARTLOOK_KEY = '17ca818c8a439f21ae5591ffe88899bdd1b690e3';

export const GCLOUD_KEY = {
	PROD: 'AIzaSyASJdYx_PaF1NYURepT0FuEgqJIgy0wt30',
	TEST: 'AIzaSyAOiMzGkoNkmH9nBDyXxBi_HMEcVQEDyAI',
	DEV: 'AIzaSyBmvsC5WXgrpLMSYDt7VCF1IiIKpUQS818',
};

export const AMPLITUDE_KEY = {
	PROD: '0d0bf3b04d91a9a79926398eca5cac45',
	SANDBOX: '9d33325a1028921e1dc2174dc0527722',
};

const FINGERPRINT_PUBLIC_API_KEY = {
	LIVE: 'FKrnFQBPaWEB22nmjlmq',
	TEST: 'BFaaW5eyryIPB37yAwdm',
} as const;

export const FINGERPRINT_CONFIG = {
	LIVE: {
		PUBLIC_KEY: FINGERPRINT_PUBLIC_API_KEY.LIVE,
		SCRIPT_PATTERN: `https://metrics.headout.com/web/v3/${FINGERPRINT_PUBLIC_API_KEY.LIVE}/loader_v3.8.1.js`,
		ENDPOINT: 'https://metrics.headout.com',
	},
	TEST: {
		PUBLIC_KEY: FINGERPRINT_PUBLIC_API_KEY.TEST,
		SCRIPT_PATTERN: `https://fpjscdn.net/v3/${FINGERPRINT_PUBLIC_API_KEY.TEST}/loader_v3.8.1.js`,
		ENDPOINT: 'https://fpjscdn.net',
	},
} as const;

export const BANNER_NON_CITY_KEY = 'NON_CITY_SPECIFIC';

export const OPENGRAPH_VIDEO_URL =
	'https://cdn-imgix-open.headout.com/opengraph/headout.mp4';

export const BANNER_HOMEPAGE = {
	imageUrl:
		'https://cdn-imgix-open.headout.com/flaps/non-city-specific/desktop/headout-default-banner-desktop.png',
	redirectUrl: '',
};

export const BANNER_HOMEPAGE_MOBILE = {
	imageUrl:
		'https://cdn-imgix-open.headout.com/flaps/non-city-specific/web/headout-default-banner-mobile-1.png',
	alt: '',
	imageHtml: '',
	redirectUrl: '',
};

export const BANNER_IMAGE_URLS = [
	'https://cdn-imgix-open.headout.com/flaps/non-city-specific/desktop/headout-default-banner-desktop.png',
];

export const BANNER_IMAGE_URLS_CITY_PAGE_DESKTOP = [
	'https://cdn-imgix-open.headout.com/flaps/non-city-specific/desktop/headout-default-banner-desktop-1.png',
];

export const BANNER_IMAGE_URLS_MOBILE = [
	'https://cdn-imgix-open.headout.com/flaps/non-city-specific/web/headout-default-banner-mobile.png',
];

export const BANNER_IMAGE_URLS_CITY_PAGE_MOBILE = [
	'https://cdn-imgix-open.headout.com/flaps/non-city-specific/web/headout-default-banner-mobile-1.png',
];

export const ACTIVITY_TYPES = {
	KEY_PRESS: 'Key Press',
	SCROLL: 'Scroll',
	CLICK: 'Click',
	TAP: 'Tap',
	MOUNT: 'Mount',
	BLUR: 'Blur',
	FOCUS: 'Focus',
};

export const COOKIE = {
	GOOGLE_TRANSLATE: 'googtrans',
	CURRENT_CITY_CODE: 'currentCityCode',
	CURRENT_CURRENCY: 'currentCurrency',
	LOCATION_CURRENCY: 'locationCurrency',
	IS_SIGNED_IN: 'is-signed-in',
	HEADOUT_ACCESS_TOKEN: 'h-at',
	CONCIERGE_USER_TOKEN: 'user.token',
	USER_PROFILE_TYPE: 'user-profile-type',
	SANDBOX_ID: 'h-sid',
	IS_DISPLAY_TRAFFIC: 'isDisplayTraffic',
	LOCATION_POPUP: 'locationPopup',
	CONTENT_LANG: 'content_lang',
	USER_AGENT: 'user-agent',
	REDIRECTION_TO_NEXT_JS: 'redirection_to_nextjs',
	DEBUG_MODE: 'debug_mode',
	ORIGINAL_HOST_URL: 'original_host_url',
	BOOKING_FLOW_SERVER_REDIRECTION: 'booking-flow-server-redirection',
	AFFILIATE_CODE: 'affiliate_code',
	AFFILIATE_UTM_INFO: 'affiliate_utm_info',
	TRAVELPAYOUTS_SUB_ID: 'sub_id',
	TIME_URGENCY_SHOWN: 'time-urgency-shown',
	CLARITY_PROJECT_ID: 'clarity-pid',
	LOGIN_SUCCESS_TRACKER: 'magic-signin-success',
	CASHBACK_EXP_VIEWED: 'cshbck-exp',
	IMPACT_CLICK_ID: 'irclickid',
	EXPERIMENT_OVERRIDE: 'experimentOverride',
	LIVE_INVENTORY: 'live-inventory',
	CONSENT_POLICY_STATE: 'consent-state',
	HEADOUT_ATTRIBUTION_TRACKER: 'h-attr',
	TTD_TGIDS: 'ttd-tgids',
	PARTNER_TRACE_ID: 'partner-trace-id',
	USER_PREFERENCES: 'user-preferences',
};

export const PROMOTION_TYPES = {
	TTD: 'ttd',
	BAC: 'bac',
} as const;

export const IMPACT_AFFILIATE_CODE = 'V8ODIn';

export const CUSTOM_HEADER = {
	ORIGIN: 'h-origin',
	AFFILIATE_CODE: 'affiliate-code',
	FORWARDED_COUNTRY_CODE: 'X-Forwarded-Country-Code',
	PROMO_DETAILS: 'X-Promo-Details',
	PARTNER_TRACE_ID: 'partner-trace-id',
	FINGERPRINT: 'x-headout-fingerprint',
};

export const CHANNEL_NAME_ID_MAP = {
	TTD: '6',
};

export const HEADER = {
	SANDBOX_ID: 'X-H-SID',
	BACKEND_EXPERIMENT: 'SERVER-EXP',
	CLOUDFRONT_COUNTRY_CODE: 'cloudfront-viewer-country',
};

export const SESSION_TIME = {
	START_TIME: 'start_time',
	LAST_UPDATE_TIME: 'last_update_time',
};

export const SEO_META_DESC =
	'✔ Best Price Guaranteed ✔ Last-minute deals ✔ User reviews ✔ Hassle-free booking process ✔ 24x7 Customer Support';

export const HASOFFER_TRANSACTION_ID_KEY = 'transaction_id';

export const HASOFFER_OFFER_ID_KEY = 'offer_id';

export const HASOFFER_TRANSACTION_ID_COOKIE_KEY = 'hasoffer_transaction_id';

export const HASOFFER_OFFER_ID_COOKIE_KEY = 'hasoffer_offer_id';

export const COUNTRY_CODES = {
	US: {
		NAME: 'United States of America',
		ISO2: 'US',
		ISO3: 'USA',
	},
	FR: {
		NAME: 'France',
		ISO2: 'FR',
		ISO3: 'FRA',
	},
	IT: {
		NAME: 'Italy',
		ISO2: 'IT',
		ISO3: 'ITA',
	},
	AU: {
		NAME: 'Australia',
		ISO2: 'AU',
		ISO3: 'AUS',
	},
	SG: {
		NAME: 'Singapore',
		ISO2: 'SG',
		ISO3: 'SGP',
	},
	NL: {
		NAME: 'Nederlands',
		ISO2: 'NL',
		ISO3: 'NLD',
	},
	AT: {
		NAME: 'Austria',
		ISO2: 'AT',
		ISO3: 'AUT',
	},
	DE: {
		NAME: 'Germany',
		ISO2: 'DE',
		ISO3: 'DEU',
	},
};
export const CITIES = {
	NEW_YORK: 'NEW_YORK',
	LAS_VEGAS: 'LAS_VEGAS',
	SAN_FRANCISCO: 'SAN_FRANCISCO',
	DUBAI: 'DUBAI',
	ABU_DHABI: 'ABU_DHABI',
	SINGAPORE: 'SINGAPORE',
	BANGKOK: 'BANGKOK',
	LONDON: 'LONDON',
	EDINBURGH: 'EDINBURGH',
	SYDNEY: 'SYDNEY',
	MELBOURNE: 'MELBOURNE',
	HONG_KONG: 'HONG_KONG',
	PARIS: 'PARIS',
	ROME: 'ROME',
	AMSTERDAM: 'AMSTERDAM',
	BARCELONA: 'BARCELONA',
	MADRID: 'MADRID',
	VENICE: 'VENICE',
	NAPLES: 'NAPLES',
	MILAN: 'MILAN',
	BUDAPEST: 'BUDAPEST',
	FLORENCE: 'FLORENCE',
	SEVILLE: 'SEVILLE',
	BERLIN: 'BERLIN',
	GRANADA: 'GRANADA',
	PATTAYA: 'PATTAYA',
	SEOUL: 'SEOUL',
	OSAKA: 'OSAKA',
	TOKYO: 'TOKYO',
	TENERIFE: 'TENERIFE',
	HAMBURG: 'HAMBURG',
	PRAGUE: 'PRAGUE',
	REYKJAVIK: 'REYKJAVIK',
	MACHU_PICCHU: 'MACHU_PICCHU',
	PHUKET: 'PHUKET',
	CHIANG_MAI: 'CHIANG_MAI',
	MUNICH: 'MUNICH',
	KRAKOW: 'KRAKOW',
	CORDOBA: 'CORDOBA',
	BILBAO: 'BILBAO',
	BALI: 'BALI',
	SAN_DIEGO: 'SAN_DIEGO',
	PHILADELPHIA: 'PHILADELPHIA',
	WASHINGTON_DC: 'WASHINGTON_DC',
	LOS_ANGELES: 'LOS_ANGELES',
	CHICAGO: 'CHICAGO',
	BOSTON: 'BOSTON',
	ORLANDO: 'ORLANDO',
	SAN_ANTONIO: 'SAN_ANTONIO',
	MALAGA: 'MALAGA',
	LISBON: 'LISBON',
	VALENCIA: 'VALENCIA',
	VIENNA: 'VIENNA',
	DUBLIN: 'DUBLIN',
	ATHENS: 'ATHENS',
	ISTANBUL: 'ISTANBUL',
	ZURICH: 'ZURICH',
	GOLD_COAST: 'GOLD_COAST',
	CAIRNS: 'CAIRNS',
};

export const FIELD_NAME = {
	NAME: 'NAME',
	EMAIL: 'EMAIL',
	PHONE: 'PHONE',
	WEIGHT: 'WEIGHT',
	CUSTOM: 'CUSTOM',
	ADDRESS: 'ADDRESS',
};

export const COMMON_FIELD_NAME = {
	NAME: 'NAME',
	EMAIL: 'EMAIL',
	PHONE: 'PHONE',
};

export const PAYMENT_FIELD_NAME = {
	CARD_HOLDER_NAME: 'cardHolderName',
	CARD_NUMBER: 'cardNumber',
	MONTH: 'month',
	YEAR: 'year',
	CVV: 'cvv',
};

export const FIELD_ERROR = {
	NAME: strings.CPUD_ERROR_NAME,
	EMAIL: strings.CPUD_ERROR_EMAIL,
	PHONE: strings.CPUD_ERROR_PHONE,
	WEIGHT: strings.CPUD_ERROR_WEIGHT,
};

export const FIELD_DESCRIPTION = {
	EMAIL: strings.CPUD_YOUR_TICKETS_SENT_HERE,
	PHONE: strings.CPUD_MENTION_COUNTRY,
};

export const FIELD_LEVEL = {
	PRIMARY_CUSTOMER: 'PRIMARY_CUSTOMER',
	ALL_CUSTOMER: 'ALL_CUSTOMER',
};

export const MORE_LINKS_LIST = [
	{
		displayName: strings.CMN_ABOUT_US,
		trackingName: 'about',
		link: '/about-us',
	},
	{
		displayName: 'Careers',
		trackingName: 'careers',
		link: '/careers',
	},
	{
		displayName: 'Our Blog',
		trackingName: 'blog',
		link: 'https://www.headout.com/blog',
	},
	{
		displayName: 'Privacy Policy',
		trackingName: 'privacy-policy',
		link: '/privacy-policy',
	},
	{
		displayName: 'Terms of Usage',
		trackingName: 'terms-of-use',
		link: '/terms-of-use',
	},
];

export enum BANNERS_PLATFORM {
	MOBILE = 'MOBILE_WEB',
	DESKTOP = 'DESKTOP',
}

export enum LANDING_PAGE_BANNER_TYPES {
	IMAGE = 'IMAGE',
	VIDEO = 'VIDEO',
}

export const ALERT_TYPE = {
	FOOTER_EMAIL_ALERT: 'FOOTER_EMAIL_ALERT',
	INVENTORY_NOT_AVAILABLE_ALERT: 'INVENTORY_NOT_AVAILABLE_ALERT',
	PRICE_DROP_ALERT: 'PRICE_DROP_ALERT',
};

export const SORT_TYPE = {
	PRICE: 'PRICE',
	POPULARITY: 'POPULARITY',
	TRENDING: 'TRENDING',
	CHRONOLOGICAL: 'CHRONOLOGICAL',
	RECOMMENDED: 'RECOMMENDED',
};

export const SORT_ORDER = {
	ASC: 'ASC',
	DESC: 'DESC',
};

export const FILTER_KEYS = [
	'filter-dates[]',
	'filter-times[]',
	'filter-price-low',
	'filter-price-high',
];

export const SORT_FILTER_KEYS = ['sort-type', 'sort-order'];

export const FILTER_AND_SORT_KEYS = [
	'filter-dates[]',
	'filter-times[]',
	'filter-price-low',
	'filter-price-high',
	'sort-type',
	'sort-order',
];

export const CATEGORY_PAGE_DEFAULT_SORTING_PARAMS = [
	'filter-dates[]',
	'filter-times[]',
	'filter-price-low',
	'filter-price-high',
	'sort-type',
	'sort-order',
	'limit',
	'offset',
];

export const PERSONA_PAGE_FILTER_AND_PAGINATION_KEYS = [
	'offset',
	'limit',
	'categories',
	'subCategories',
];

export const CITY_CATEGORY_PAGE_FILTER_AND_PAGINATION_KEYS = [
	'offset',
	'limit',
	'subCategoryIds',
	'sort-type',
	'sort-order',
];

export const FILTER_SORT_AND_OFFSET_KEYS = [
	'filter-dates[]',
	'filter-times[]',
	'filter-price-low',
	'filter-price-high',
	'sort-type',
	'sort-order',
	'offset',
	'limit',
];

export const HSID_COOKIE_NAME = 'h-sid';

export const GMAPS_PARAM = {
	v: '3.exp',
	key: 'AIzaSyASJdYx_PaF1NYURepT0FuEgqJIgy0wt30',
};

export const SIFT_JS_KEY = 'e6f5f84411';

export const PAYMENT_GATEWAY_TYPE = {
	ADYEN: 'ADYEN',
	CHECKOUT: 'CHECKOUT',
};

export const PAYMENT_METHOD = {
	CARD: 'CARD',
	APPLE_PAY: 'APPLEPAY_CHECKOUT',
	GOOGLE_PAY: 'GOOGLEPAY_CHECKOUT',
	PAYPAL: 'PAYPAL_CHECKOUT',
	IDEAL: 'IDEAL_CHECKOUT',
	FPX_ONLINE_BANKING: 'FPX_ONLINE_BANKING',
};

export const EXPRESS_CHECKOUT_OPTIONS = [
	PAYMENT_METHOD.APPLE_PAY,
	PAYMENT_METHOD.GOOGLE_PAY,
];

export const SENTRY_TAGS = {
	ERROR_PAGE_VIEWED: 'ERROR_PAGE_VIEWED',
	GOOGLE_PAY_API_FAILED: 'GOOGLE_PAY_API_FAILED',
	FINGERPRINT_ERROR: 'FINGERPRINT_ERROR',
};

export const PAYMENT_METHOD_ICONS = {
	[PAYMENT_METHOD.CARD]: CardSvg,
	[PAYMENT_METHOD.APPLE_PAY]: ApplePaySvg,
	[PAYMENT_METHOD.GOOGLE_PAY]: GooglePaySvg,
	[PAYMENT_METHOD.PAYPAL]: PayPalSvg,
	[PAYMENT_METHOD.IDEAL]: IDealSvg,
	[PAYMENT_METHOD.FPX_ONLINE_BANKING]: FPXSvg,
};

export const TOP_CURRENCIES = ['EUR', 'USD', 'AED', 'SGD', 'INR', 'MYR'];

export const SEO_INDEXABLE_LANGUAGE_CODES = [
	'en',
	'fr',
	'de',
	'it',
	'es',
	'pt',
	'nl',
];

export const SEO_NON_INDEXABLE_LANGUAGE_CODES = [
	'ja',
	'ko',
	'zh-hans',
	'zh-hant',
	'ar',
	'pl',
	'id',
];

export const ACTIVE_LANGUAGE_CODES = [
	...SEO_INDEXABLE_LANGUAGE_CODES,
	...SEO_NON_INDEXABLE_LANGUAGE_CODES,
];

export const LANGUAGE_SORT_ORDER = ['en', 'es', 'fr', 'it', 'de', 'pt', 'nl'];

export const CALENDAR = {
	GOOGLE: 'GOOGLE',
	OUTLOOK: 'OUTLOOK',
	ICAL: 'ICAL',
};

export const TIMER_INFO_EXPERIMENT = 'TIMER_INFO_EXPERIMENT';

export const EXIT_INTENT_EXPERIMENT_KEY = 'exit_intent_shown_v1';

export const REFERRAL_LOGIN = 'REFERRAL_LOGIN';

export const INVENTORY_STRUCTURE = {
	SD_St_ST: 'SD_St_ST',
	SD_St_MT: 'SD_St_MT',
	SD_Mt_ST: 'SD_Mt_ST',
	SD_Mt_MT: 'SD_Mt_MT',
	MD_St_ST: 'MD_St_ST',
	MD_St_MT: 'MD_St_MT',
	MD_Mt_ST: 'MD_Mt_ST',
	MD_Mt_MT: 'MD_Mt_MT',
	UNAVAILABLE: 'Unavailable',
};

export const FLEXIBLE_START_TIME = 'FLEXIBLE_START_TIME';

export const THUNK_ERROR_TYPES = {
	CHECKOUT_PAGE: 'checkout',
	TOURS_PAGE: 'tourSelection',
};

export const THUNK_ERROR_CODES = {
	INVALID_CARD_DETAILS: 'CHECKOUT_TOKEN_CREATION',
	INVENTORY_EXPIRED: 'INVENTORY_EXPIRED',
};

export const SURVEY = {
	CHECKOUT_EXIT: 'checkoutPageHighIntent',
};

export const READ_MORE_BOX_MAX_HEIGHT = '4000px';

export const SALES_TRACKER_ID = 'xKAt5wZBBFuxqR7H2oMHEdeZksNqY2VL';

export const POWERED_BY_HEADOUT =
	'https://cdn-imgix-open.headout.com/logo/powered-by-headout-desktop-33467732.png?w=240&h=40&fit=fill';

export const UserProfileTypes = {
	CUSTOMER: 'CUSTOMER',
	DISTRIBUTION_PARTNER: 'DISTRIBUTION_PARTNER',
	ANONYMOUS: 'ANONYMOUS',
};

export const CITIES_EXCLUDED_FROM_VIEWING_TICKETS = [
	CITIES.DUBAI,
	CITIES.SINGAPORE,
];

export const PREFIX_TOGGLE_CLASS = 'prefix-hidden';

export const MWEB_DRAWER_TYPES = {
	PAX_DRAWER: 'Pax Drawer',
	TIME_DRAWER: 'Time Drawer',
	SEATS_DRAWER: 'Selected Seats Drawer',
	VARIANTS_DRAWER: 'Variant Cards Drawer',
};

export const SEARCH_DEBOUNCE_DELAY = 250;

export const LIVE_CHAT_DELAY = 7000;

export const GTM_INITIALIZATION_DELAY = 0;

export const TOUR_FLOW_TYPE = {
	MULTI_TOUR_FLOW: 'multi tour flow',
	SINGLE_TOUR_FLOW: 'single tour flow',
};

export const ANALYTICS_PLATFORM = {
	DESKTOP: 'Desktop',
	MOBILE: 'Mobile',
};

export const BANNER_POSITION = {
	TOP: 'TOP',
	BOTTOM: 'BOTTOM',
	NONE: 'NONE',
};

export const BANNER_PLATFORM = {
	ALL: 'ALL',
	MOBILE: 'MOBILE',
	DESKTOP: 'DESKTOP',
};

export const BANNER_TYPES = {
	COUPON: 'COUPON',
	COUPON_WITH_ACTION: 'COUPON_WITH_ACTION',
	APPLY_COUPON: 'APPLY_COUPON',
	ACTION: 'ACTION',
	FORM: 'FORM',
	INFORMATION: 'INFORMATION',
};

export const BANNER_DISPLAY_SIZE = {
	PARTIAL: 'PARTIAL',
	FULL: 'FULL',
	TOP: 'TOP',
	BOTTOM: 'BOTTOM',
};

export const LOGIN_OPTION = {
	FACEBOOK: 'facebook',
	GOOGLE: 'google',
	APPLE: 'apple',
	EMAIL: 'email',
};

export const LOGIN_RECIPES = {
	GOOGLE_ONE_TAP_SIGNIN: 'GOOGLE_ONE_TAP_SIGNIN',
};

export const GOOGLE_ONE_TAP = {
	MOMENT_TYPE: {
		DISPLAY: 'display',
		SKIPPED: 'skipped',
		DISMISSED: 'dismissed',
	},
	UI_STATUS: {
		DISPLAYED: 'Displayed',
		NOT_DISPLAYED: 'Not Displayed',
		SKIPPED: 'Skipped',
		DISMISSED: 'Dismissed',
	},
	SCRIPT_URL: 'https://accounts.google.com/gsi/client',
	SCRIPT_ID: 'google-one-tap-script',
};

export const GOOGLE_AUTH_CLIENT_ID =
	'519261007287-b6lj2ppf2r8gk8cnd7bt23d8t1jkro11.apps.googleusercontent.com';

export const LOGIN_EVENT = {
	ATTEMPTED: 'Sign In Attempted',
	SUCCESSFUL: 'Sign In Successful',
	FAILED: 'Sign In Failed',
};

export const ORDER_STATUS_API_POLLING_INTERVAL = 5000;

export const LIVE_CHAT_LICENCE_KEY = '8339531';

export const PREFERRED_COUNTRIES_CODES = [
	'us',
	'gb',
	'it',
	'fr',
	'ae',
	'sg',
	'au',
	'de',
	'th',
];

export const FLAGS_IMAGE =
	'https://cdn-imgix-open.headout.com/flags/flags@2x.png';

export const CARD_BIN_LENGTH = 6;

export const USER_STATUS_WHILE_BOOKING = {
	LOGGED_IN: 'LOGGED_IN',
	NOT_LOGGED_IN: 'NOT_LOGGED_IN',
};

export const MAX_LENGTH_FOR_PHONE_NUMBER = 18;

export const HEADOUT_SUPPORT_LINE = '+1 347-897-0100';

export const PERSONA = {
	TOURIST: 'tourist',
	LOCAL: 'local',
};

export const SEARCH_LOCATION = {
	TOP: 'top',
	MID: 'mid',
	TAB: 'tab',
	NONE: 'none',
	LEFT: 'left',
};

export const PROFILE_PAGE_MODAL = {
	NONE: 'NONE',
	CURRENCY: 'CURRENCY',
	LANGUAGE: 'LANGUAGE',
	MARKUP: 'PRICE_MARKUP',
	SEARCH: 'SEARCH',
};

export const NYC_CONCIERGE_SUPPORT_PHONE_NUMBER = '+1 347-474-6777';

export const HARRY_POTTER_CURSED_CHILD_TGID = '16816';

export const BROADWAY_MAX_PAX = 6;

export const BROADWAY_TIMER_DEFAULT = 480000;

export const BROADWAY_ACCORDION = {
	DATE: 'Date',
	TIME: 'Time',
	PAX: 'Pax',
	TOUR: 'Tour',
};

export const RESERVATION_SESSION_STORAGE_KEY = 'reservationSessions';

export const UNIT_ABBREVIATIONS = ['K', 'M', 'B', 'T'];

export const APP_DEEP_LINKS = {
	ANDROID: 'android-app://com.tourlandish.chronos/https/applink.headout.com/',
	IOS: 'ios-app://899327000/https/applink.headout.com/',
};

export const TOP_PERFORMING_CITIES = [
	'NEW_YORK',
	'LAS_VEGAS',
	'ROME',
	'PARIS',
	'LONDON',
	'DUBAI',
	'BARCELONA',
];

export const HEADOUT_DOMAIN = 'www.headout.com';

export const getDescriptorsMap = () => ({
	FREE_CANCELLATION: {
		icon: 'cancel',
		label: strings.PPD_FREE_CANCEL,
	},
	HOTEL_PICKUP: { icon: 'taxi', label: strings.PPD_HOTEL_PICKUP },
	INSTANT_CONFIRMATION: {
		icon: 'instant',
		label: strings.PPD_INSTANT_CONFIRMATION,
	},
	MOBILE_TICKET: { icon: 'mobile', label: strings.PPD_MOBILE_TICKET },
	SKIP_THE_LINE: { icon: 'skip', label: strings.PPD_SKIP_LINE },
	AUDIO_GUIDE: {
		icon: 'headphones',
		label: strings.PPD_AUDIO_GUIDE,
	},
	GUIDED_TOUR: { icon: 'guide', label: strings.PPD_GUIDED_TOUR },
	EXTENDED_VALIDITY: {
		icon: 'validity',
		label: strings.PPD_VALIDITY,
	},
	TRANSFERS: {
		icon: 'transfer',
		label: strings.PPD_RETURN_TRANSFERS,
	},
	MEALS_INCLUDED: {
		icon: 'food',
		label: strings.PPD_MEALS_INCLUDED,
	},
});

export const DESCRIPTORS = {
	INSTANT_CONFIRMATION: 'INSTANT_CONFIRMATION',
};

export const PROFILE_PAGE_TAB = {
	BOOKING: 'booking',
	CREDITS: 'credits',
	ABOUT_HEADOUT: 'about',
	PRICE_MARKUP: 'markup',
	SIGN_OUT: 'sign-out',
	RESET_PASSWORD: 'reset',
};

export const SEATMAP_RESIZE_THRESHOLD = 100;

export const ATTRACTION_TRACKER_AIRTABLE_LINK = {
	DESKTOP:
		'https://airtable.com/embed/shrxPunRNi4o7WOoD?backgroundColor=gray&viewControls=on',
	MOBILE: 'https://airtable.com/embed/shraqmmKupJPPesvY?backgroundColor=gray&viewControls=on',
};

export const COVID_PAGES_MORE_READS = [
	{
		heading: 'Post COVID 19 Packing Essentials',
		link: 'https://www.headout.com/blog/post-covid-travel-packing-checklist/',
		imageUrl:
			'https://cdn-imgix-open.headout.com/Post+Covid+19+Travel/Packing+Essentials.jpg',
		description: 'The ultimate post COVID-19 packing checklist',
	},
	{
		heading: 'Travel Reopening Trends - Future of Travel',
		link: 'https://www.headout.com/blog/post-covid-travel-reopening-trends',
		imageUrl:
			'https://cdn-imgix-open.headout.com/Post+Covid+19+Travel/Global+Travel+Reopening+Tracker+Social+Media.jpg',
		description: 'Travel trends and what lies ahead',
	},
	{
		heading: 'When Can We Travel Again',
		link: 'https://www.headout.com/blog/when-can-we-travel-again/',
		imageUrl:
			'https://cdn-imgix-open.headout.com/blog/Travel+Reopens/Travel+Bubble.jpg',
		description: 'Travel bubbles and the reopening of countries',
	},
	{
		heading: 'Headout Attraction and Country Tracker Trends',
		link: 'https://www.headout.com/blog/emerging-travel-trends-post-covid/',
		imageUrl:
			'https://cdn-imgix-open.headout.com/Post+Covid+19+Travel/Travel+Trends.jpg',
		description: 'Attraction opening dates, new rules and more',
	},
];

export const SLIDERS_X_AXIS_SCROLL_MIN_THRESHOLD = 5;
export const SLIDERS_Y_AXIS_SCROLL_MIN_THRESHOLD = 50;

export const AIRTABLE_BASE_LINK =
	'https://api.airtable.com/v0/appCiV6q7KyN8meXg';

export const QUERY_PARAM = {
	CURRENCY_CODE: 'currencyCode',
	LANGUAGE_CODE: 'languageCode',
	DATE: 'date',
	TIME: 'time',
	TOUR_ID: 'tourId',
	VARIANT_ID: 'variantId',
	PAX_PREFIX: 'pax.',
	SOURCE: 'source',
	REDIRECT_RESULT: 'redirectResult',
	RESULT_CODE: 'resultCode',
	COUPON_CODE: 'couponCode',
	TRACK_CONVERSION: 'trackConversion',
	SELECTOR: 'selector',
	RETAIN: 'retain',
	DF_SELECTION: 'dfSelection',
	AFFILIATE_CODE: 'affiliate_code',
	INSTANT_CHECKOUT: 'instantCheckout',
	GA_TRACKER: '_gl',
	SUB_ID: 'sub_id',
	OPEN_FILTER_TRAY: 'filterSelectionTray',
	OPEN_CATEGORIES_TRAY: 'categorySelectionTray',
	EDIT_VARIANT_ID: 'editVariantId',
	CREDIT_CASHBACK: 'creditCashback',
	LIMIT: 'limit',
	OFFSET: 'offset',
	REDIRECT: 'redirect',
	SECURE_ID: 'secureId',
	CKO_SESSION_ID: 'cko-session-id',
	IMPACT_CLICK_ID: 'irclickid',
	LIVE_PRICE_DISMISS: 'lp-dismiss',
	BY_HO: 'byHO',
	AIRPORT_TRANSFER_QUOTE_ID: 'airportTransferQuoteId',
	MEMBERSHIP_DATA: 'membership_data',
	MEMBERSHIP_META: 'membership_meta',
	HOHO_MWEB_FULL_SCREEN_PAX: 'showFullScreenPax',
	ATRIBUTION_CHANNEL_ID: 'ci',
	ATRIBUTION_CHANNEL_META: 'cm',
	PARTNER_TRACE_ID: 'partner_trace_id',
	UTM_SOURCE: 'utm_source',
	UTM_CONTENT: 'utm_content',
	UTM_TERM: 'utm_term',
};

export const LSCACHE_PARAM = {
	DATE: 'date',
	TIME: 'time',
};

export const UNKNOWN_ERROR = 'Unknown Error';

export const CANONICAL_QUERY_WHITELIST = [
	QUERY_PARAM.LIMIT,
	QUERY_PARAM.OFFSET,
];

export const QUERY_PARAM_WHITELIST = [
	QUERY_PARAM.GA_TRACKER,
	QUERY_PARAM.HOHO_MWEB_FULL_SCREEN_PAX,
	'showCustomCheckoutCTA',
];

export const HEADOUT_FROM_HOME = {
	IMAGE: 'https://cdn-imgix-open.headout.com/headout-from-home/popup.jpg',
	LINK: 'https://www.headout.com/blog/virtual-tour-world/',
	LOCAL_STORE_KEY: 'headout_from_home_popup_closed',
	WAIT_TIME: 25000,
};

export const IPHONE_MOCKUP_IMAGE_URL =
	'https://cdn-imgix-open.headout.com/assets/images/iPhoneX_mockup.png';

export const APP_VENDOR_ICONS = {
	PLAYSTORE:
		'https://cdn-imgix-open.headout.com/assets/images/badges/google-play-badge 2.svg',
	APPSTORE:
		'https://cdn-imgix-open.headout.com/assets/images/badges/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917 1.svg',
};

export const CONTACT_ICONS = {
	EMAIL: 'https://cdn-imgix-open.headout.com/headout-connect/contact-icons/Mail.svg',
	PHONE: 'https://cdn-imgix-open.headout.com/headout-connect/contact-icons/Phone.svg',
	HELP: 'https://cdn-imgix-open.headout.com/headout-connect/contact-icons/Help.svg',
	MESSAGE:
		'https://cdn-imgix-open.headout.com/headout-connect/contact-icons/Message.svg',
};

export const PAYMENT_CARD_ICONS = {
	VISA: 'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/Visa.svg',
	MASTERCARD:
		'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/MasterCard.svg',
	AMEX: 'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/AmexCard.svg',
	PAYPAL: 'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/PayPalLight.svg',
	MAESTROCARD:
		'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/MaestroCard.svg',
	APPLEPAY:
		'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/ApplePay.svg',
	GPAY: 'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/GPay.svg',
	DISCOVER:
		'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/DiscoverCard.svg',
	DINERS: 'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/DinerClub.svg',
	IDEAL: 'https://cdn-imgix-open.headout.com/headout-connect/payment-methods/IDealCard.svg',
};

export const SOCIAL_LINKS = {
	FB_URL: 'https://www.facebook.com/headoutapp',
	TWITTER_URL: 'https://www.twitter.com/headout',
	INSTAGRAM_HEADOUT_URL: 'https://www.instagram.com/headout/',
	INSTAGRAM_HEADOUT_DUBAI_URL: 'https://www.instagram.com/headoutuae/',
	PINTEREST_URL: 'https://in.pinterest.com/headout/',
	YOUTUBE_URL: 'https://www.youtube.com/c/Headout-Official',
	LINKEDIN_URL: 'https://www.linkedin.com/company/headout-com/mycompany/',
};

export const DOWNLOAD_APP_QR =
	'https://cdn-imgix-open.headout.com/headout-connect/app_download.svg';

export const NON_BOOKING_FLOW_REGEX = /^((?!book|confirmation).)*$/;

export const INFORMATION_BANNER_TYPE = {
	CANCELLATION_INFO: 'CANCELLATION_INFO',
};

export const BLOG_LINK = 'https://www.headout.com/blog/';

export const COMPANY_BLOG_LINK = 'https://backstage.headout.com/';

export const HEADOUT_STUDIO_LINK = 'https://www.headout.studio/';

export const INFLUENCER_PROGRAM_LINK =
	'https://headouthub.notion.site/Headout-Creator-Partnerships-d9c127c010174a18a4af8789e2ff6854';

export const HUB_LINK = 'https://hub.headout.com/login/';

export const JOIN_HEADOUT_LINK = 'https://hub.headout.com/';

export const AFFILIATES_PORTAL_LINK = 'https://partner.headout.com/affiliate/';

export const ANALYTICS_FLOW_TYPE = {
	SINGLE_TOUR: 'single-tour',
	MULTI_TOUR: 'multi-tour',
	HIFI_SEATMAP: 'hifi-seatmap',
	LOFI_SEATMAP: 'lofi-seatmap',
	COMBO: 'combo',
	RESERVE: 'reserve',
	OPEN_DATED_NO_VARIANT: 'Open Dated No Variant',
	OPEN_DATED_MULTI_VARIANT: 'Open Dated Multi Variant',
	PRIVATE_AIRPORT_TRANSFERS: 'Private Airport Transfers',
	SHARED_AIRPORT_TRANSFERS: 'Shared Airport Transfers',
	HOP_ON_HOP_OFF: 'Hop-On Hop-Off',
};

export const CREDITS_TERMS_AND_CONDITIONS_LINK = '/credits-policy';

export const PLATFORM = {
	QUERY: 'platform-source',
	APPS: 'apps',
	WEB: 'web',
};

export const PRISMIC_CUSTOM_TYPES = {
	HO_CATEGORY_CONTENT: 'ho_category_content',
};

export const PRISMIC_LOCALE_MAP = {
	en: 'en-us',
	es: 'es-es',
	it: 'it-it',
	fr: 'fr-fr',
	pt: 'pt-pt',
	nl: 'nl-nl',
	de: 'de-de',
	ja: 'ja-jp',
	'zh-hans': 'zh-cn',
	'zh-hant': 'zh-tw',
	ar: 'ar-ae',
};

export const SORT_ORDER_KEY = {
	ASC: 'ASC',
	DESC: 'DESC',
};

export const SORT_TYPE_KEY = {
	RECOMMENDED: 'RECOMMENDED',
	POPULARITY: 'POPULARITY',
	PRICE: 'PRICE',
};

export const SORT_TYPE_MODES = {
	RECOMMENDED: {
		key: SORT_TYPE_KEY.RECOMMENDED,
		urlParamValue: null,
		defaultOrder: SORT_ORDER_KEY.ASC,
	},
	POPULARITY: {
		key: SORT_TYPE_KEY.POPULARITY,
		urlParamValue: 'POPULARITY',
		defaultOrder: SORT_ORDER_KEY.DESC,
	},
	PRICE: {
		key: SORT_TYPE_KEY.PRICE,
		urlParamValue: 'PRICE',
		defaultOrder: SORT_ORDER_KEY.ASC,
	},
};

export const SORT_MODE_KEY = {
	RECOMMENDED: 'RECOMMENDED',
	POPULARITY: 'POPULARITY',
	PRICE_LOW_TO_HIGH: 'PRICE_LOW_TO_HIGH',
	PRICE_HIGH_TO_LOW: 'PRICE_HIGH_TO_LOW',
};

export const SORT_MODE = {
	RECOMMENDED: {
		key: SORT_MODE_KEY.RECOMMENDED,
		label: 'CP_PICKED_FOR_YOU', // cannot localize here since this gets pre-computed
		type: SORT_TYPE_MODES.RECOMMENDED,
		order: null, // RECOMMENDED does not require a sort order. The default though is ASC.
	},
	POPULARITY: {
		key: SORT_MODE_KEY.POPULARITY,
		label: 'CP_SORT_POPULARITY',
		type: SORT_TYPE_MODES.POPULARITY,
		order: SORT_ORDER_KEY.DESC,
	},
	PRICE_LOW_TO_HIGH: {
		key: SORT_MODE_KEY.PRICE_LOW_TO_HIGH,
		label: 'CP_PRICE_LOW_HIGH',
		type: SORT_TYPE_MODES.PRICE,
		order: SORT_ORDER_KEY.ASC,
	},
	PRICE_HIGH_TO_LOW: {
		key: SORT_MODE_KEY.PRICE_HIGH_TO_LOW,
		label: 'CP_PRICE_HIGH_LOW',
		type: SORT_TYPE_MODES.PRICE,
		order: SORT_ORDER_KEY.DESC,
	},
};

export const BARCELONA_PASS_URL = 'https://www.barcelonapass.com/';

export const PRODUCT_CARD_TYPES = {
	SEARCH_CARD: 'search_card',
	REGULAR: 'regular',
};

export const ALGOLIA_API_KEYS = {
	APPLICATION_KEY: '6POGGBHH6E',
	SEARCH_API_KEY: 'baed63596d3837ce66bf12f28b89e9de',
};

export const ALGOLIA_SEARCH_INDEXES = {
	citySearchIndex: 'headout-cities',
	productSearchIndex: 'headout-products',
	collectionSearchIndex: 'headout-collections',
};

export const RECENTLY_VIEWED_ENTITIES = 'recentlyViewedEntities';

export const INSTANT_CHECKOUT_TOUR_IDS = 'instantCheckoutTourIds';

export const RECENTLY_VIEWED_CITIES_KEY = 'recentlyViewedCities';

export const CARD_NUMBER_PLACEHOLDER = '0000 0000 0000 0000';

export const SECURITY_COMPLIANCE_SVG =
	'https://cdn-s3.headout.com/assets/images/checkout/security-compliance-v1.svg';

export const SEARCHABLE_MODAL_MIN_ITEMS = 10;

export const REWRITE_NORMALISED_HREF_MAP = {
	EXPERIENCE_PAGE: '/:lang/:slug/(.*)e-:id(\\d+)/',
	CATEGORY_PAGE: '/:lang/(.*)c-:id(\\d+)/',
	CITY_PAGE: '/:lang/(.*)city-:city/',
};

export const NEXT_LOCATION_PAGE_PATH = {
	HOME_PAGE: '/',
	CITY_PAGE: '/[lang]/cities/[city]',
	CATEGORY_PAGE: '/[lang]/category/[id]',
	EXPERIENCE_PAGE: '/[lang]/tour/[id]',
	COLLECTION_PAGE: '/[lang]/collection/[id]',
	SUB_CATEGORY_PAGE: '/[lang]/subcategory/[id]',
	BOOK_PAGE: '/[lang]/book/[id]/[...slug]',
	CONFIRMATION_PAGE: '/[lang]/confirmation/[piid]',
};

export const COLLECTION_PAGE_SECTIONS = {
	HEADOUT_PICKS: 'HEADOUT_PICKS',
	TTD_HIGHLIGHT: 'TTD_HIGHLIGHT',
	COLLECTION: 'GENERIC',
	CATEGORY: 'CATEGORY',
	SUB_CATEGORY: 'SUB_CATEGORY',
	PINNED_CARDS: 'PINNED_CARDS',
	CAROUSEL: 'CAROUSEL',
};

export const PERSONA_PAGE_SECTIONS = {
	PRODUCT_CARDS: 'PRODUCT_CARDS',
	CATEGORY_FILTER: 'CATEGORY_FILTER',
};

export const GLOBAL_CITY_CODE = 'GLOBAL';
export const ALL_COLLECTIONS = 'ALL_COLLECTIONS';
export const ALL_SUB_CATEGORIES = 'ALL_SUB_CATEGORIES';
export const BOOKING_API_FLOW_EVENTS = {
	FAILURE: 'Booking API Failure',
};

export const FILTER_VIEW_PAGE_TYPE = {
	CATEGORY: 'Category Page',
	SUB_CATEGORY: 'Sub Category Page',
	TOUR_LIST_PAGE: 'Tour List Page',
	PERSONA: 'Persona Page',
};

export const CANCELLATION_POLICY = {
	FULL: 'FULL',
	PARTIAL: 'PARTIAL',
};

export const GLOBAL_HEADOUT_PICKS_TAG = 'GLOBAL-HEADOUT-PICKS';

export const NOTIFY_TOAST_TYPES = {
	SUCCESS: 'success',
	WARN: 'warning',
	ERROR: 'error',
};

export const SUFFIX_PRODUCT_NAME = 'SFX-';

export const MAX_COLLECTION_CARDS_IN_HEADER = 15;

export const PRODUCT_CARD_TYPE = {
	COMPARISON: 'Comparison',
	STANDARD: 'Standard',
	PINNED: 'Pinned',
	GOOGLE_TTD_PINNED: 'Google TTD Pinned',
	RECENTLY_VIEWED: 'Recently Viewed',
};

export const NEXT_NEW_PRODUCTS_PAGINATION = 12;
export const PRODUCTS_GRID_DEFAULT_COUNT = 8;
export const HEADOUT_PICKS_EXPERIENCES = 20;
export const TOP_DESTINATIONS_COUNT = 30;
export const TOP_ATTRACTIONS_COUNT = 10;
export const NEAR_BY_CITIES_COUNT = 10;

export const INVENTORY_STATUS = {
	CLOSED: 'CLOSED',
};

export const COMBO_SUBSTEP = {
	DATE_TIME: 'DATE_TIME',
	PAX_FIELDS: 'PAX_FIELDS',
};

export const RESERVATION_TYPE = {
	UPCOMING: 'UPCOMING',
	PREVIOUS: 'PREVIOUS',
};

export const DAY_MONTH_DATE_YEAR_FORMAT = 'ddd, MMM D, YYYY';

export const TRAILING_CURRENCY_SYMBOLS_LANGS = ['it', 'de', 'fr', 'es'];

export const LANGS_WITH_COMMA_IN_PRICE = ['de', 'es', 'fr', 'pt', 'it', 'nl'];

export const LANGS_WITH_SPECIAL_PERCENTAGE_FORMATTING = ['de', 'fr'];

export const DE_TIME_SUFFIX = 'Uhr';

export const DEFAULT_REVIEWER_DP =
	'https://cdn-s3-open.headout.com/reviews/default.png';

export const CALENDAR_CONSTS = {
	TOTAL_WEEK_DAYS: 7,
	FADED_DATE_PRICE_LEN: 8,
	MONTHS_FIRST_INDEX: 0,
	MONTHS_LAST_INDEX: 11,
	MAX_INVS_IN_DATE_LIST_CAL: 12,
	REGULAR_DUMMY_CAL_ROWS_COUNT: 5,
	DUMMY_CAL_MONTHS_COUNT_MWEB: 3,
	CAL_TYPES: {
		DATE_LIST_CALENDAR: 'Date List Calendar',
		SEVEN_DAY_CALENDAR: 'Seven Day Calendar',
		REGULAR_CALENDAR: 'Regular Calendar',
	},
	SEVEN_DAY_CAL: {
		MAX_MONTHS_IN_A_COL: 3,
		MAX_MONTHS_AT_A_TIME: 6,
	},
	HORIZONTAL_DATE_LIST: {
		SHOW_MAX_DATES_DESKTOP: 7,
		SHOW_MAX_DATES_MWEB: 5,
		PRICE_ROUND_UP_THRESHOLD: 6,
	},
};

export const APPLE_PAY = {
	VERSION: 4,
	MERCHANT_ID: 'merchant.com.tourlandish.checkout.www.eu',
	SUPPORT_TYPE: {
		ACTIVE: 'ACTIVE',
		PASSIVE: 'PASSIVE',
	},
	SUPPORTED_NETWORKS: ['visa', 'masterCard', 'amex', 'discover'],
	MERCHANT_CAPABILITIES: ['supports3DS'],
	BUTTON_TYPE: 'book',
};

export const GOOGLE_PAY = {
	API_VERSION: 2,
	API_VERSION_MINOR: 0,
	tokenizationSpecifications: {
		type: 'PAYMENT_GATEWAY',
		gateway: 'checkoutltd',
	},
	CARD_PAYMENT_METHOD: {
		type: 'CARD',
		parameters: {
			allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
			allowedCardNetworks: [
				'AMEX',
				'DISCOVER',
				'INTERAC',
				'JCB',
				'MASTERCARD',
				'MIR',
				'VISA',
			],
		},
	},
	SUPPORT_TYPE: {
		PASSIVE: 'PASSIVE',
		ACTIVE: 'ACTIVE',
		NOT_SUPPORTED: 'NOT_SUPPORTED',
	},
	GOOGLE_MERCHANT_ID: {
		TEST: '12345678901234567890',
	},
	ENVIRONMENT: {
		TEST: 'TEST',
		PRODUCTION: 'PRODUCTION',
	},
	HEADOUT_ACQUIRER_BANK_COUNTRY_CODE: 'DE',
	MERCHANT_NAME: 'Headout, Inc.',
	IOS_SUPPORT_TYPE_COOKIE: 'iosGpaySupportType',
};

export const TOUR_SELECTION_MODAL = {
	TIME: 'TIME',
	PAX: 'PAX',
};

export const THREE_DS_REDIRECT_TYPE = {
	FAILURE: '3DS_FAILURE',
	SUCCESS: '3DS_SUCCESS',
};

export const ALTS_CANCELLATION_SLACK_CHANNEL_ID = 'G2DLJQC14';

export const BOOKING_SELECTION_LSCACHE_EXPIRY = 30;

export const LOG_LEVELS = {
	INFO: 'INFO',
	VERBOSE: 'VERBOSE',
	WARNING: 'WARNING',
	ERROR: 'ERROR',
	CRITICAL: 'CRITICAL',
};

export const LOG_CATEGORIES = {
	API_ERROR: 'API_ERROR',
	CLIENT_EXCEPTION: 'CLIENT_EXCEPTION',
	PAGE_LOADING_ERROR: 'PAGE_LOADING_ERROR',
};

export const DESCRIPTORS_RANKING_LOGIC = [
	'validity',
	'cancel',
	'instant',
	'mobile',
	'clock',
	'headphones',
	'guide',
	'transfer',
	'taxi',
	'food',
];

export const MODAL_CLOSE_REASON = {
	CLICK_OUTSIDE: 'CLICK_OUTSIDE',
	CLOSE_BUTTON: 'CLOSE_BUTTON',
	AUTOMATIC: 'AUTOMATIC',
};

export const MAX_DESCRIPTORS_DISPLAYED = 6;

export enum seatMapListenerType {
	iframeInitCompleted = 'iframeInitCompleted',
	initializingSeatmapStarted = 'initializingSeatmapStarted',
	initializingSeatmapCompleted = 'initializingSeatmapCompleted',
	onSeatSelectionChanged = 'onSeatSelectionChanged',
	onSeatSelectionSubmitted = 'onSeatSelectionSubmitted',
	onSeatAdded = 'onSeatAdded',
	onSeatRemoved = 'onSeatRemoved',
	inventoryUpdateStarted = 'inventoryUpdateStarted',
	inventoryUpdateCompleted = 'inventoryUpdateCompleted',
	onZoomLevelChanged = 'onZoomLevelChanged',
	onZoomInDoubleClick = 'onZoomInDoubleClick',
	onZoomInButtonClick = 'onZoomInButtonClick',
	onZoomOutButtonClick = 'onZoomOutButtonClick',
	onZoomResetButtonClick = 'onZoomResetButtonClick',
	onPriceFilterClick = 'onPriceFilterClick',
	onSeatsViewExpanded = 'onSeatsViewExpanded',
	setInventorySlot = 'setInventorySlot',
	selectSeats = 'selectSeats',
	removeSeat = 'removeSeat',
	addSeat = 'addSeat',
	initPlugin = 'initPlugin',
	init = 'init',
	priceListClicked = 'priceListClicked',
	priceListOpened = 'priceListOpened',
	priceListClosed = 'priceListClosed',
	clearFilterClicked = 'clearFilterClicked',
	applyFilterClicked = 'applyFilterClicked',
}

export const LOCALE_MAP = {
	en: 'en_US',
	es: 'es_ES',
	fr: 'fr_FR',
	de: 'de_DE',
	it: 'it_IT',
	nl: 'nl_NL',
	pt: 'pt_PT',
};

export const APPLE_SIGN_IN = {
	CLIENT_ID: 'com.headout.websignin',
};

export const TICKETS_CATEGORY = 'Tickets';

export const CLARITY_PROJECT_ID_1 = 'bkr8q7wx0t';

export const CLARITY_PROJECT_ID_2 = 'bkra4tjmu8';

export const CLARITY_PLAYER_URL = 'https://clarity.microsoft.com/player/';

export const MUST_DO_THINGS_CARDS_COUNT = { MIN: 4, MAX: 8 };

export const SECTION_CONSTRUCTOR_PREFIX = 'CAROUSEL';
export const LAZY_COMPONENT_SECTION_WRAPPER_PREFIX = 'LAZY_CAROUSEL';

export const VALIDITY_TYPES = {
	UNTIL_DATE: 'UNTIL_DATE',
	UNTIL_DAYS_FROM_PURCHASE: 'UNTIL_DAYS_FROM_PURCHASE',
	EXTENDABLE_BUT_UNKNOWN: 'EXTENDABLE_BUT_UNKNOWN',
	NOT_EXTENDABLE: 'NOT_EXTENDABLE',
};

export const BLOG_POSTS_TYPE = {
	GENERAL: 'GENERAL',
	LTD_BROADWAY_GUIDE: 'GUIDE',
	CITY_GUIDE: 'TRAVEL_GUIDE',
	LTD_AND_BROADWAY: ['SHOWS', 'CHEAP_TICKET', 'SEATING', 'RESTAURANTS'],
	CITY: ['ITINERARIES', 'BEST_TIME_TO_VISIT', 'DAY_TRIPS', 'TIPS'],
};

export const BLOG_PAGE_TYPE = {
	NORMAL: 'NORMAL',
	CITY: 'CITY',
	BROADWAY: 'BROADWAY',
	LTD: 'LTD',
};

export const BLOGS_SUPPORTED_LANG = ['en'];

export const BLOG_FALLBACK_IMAGE =
	'https://cdn-imgix-open.headout.com/blog/media/images/Blog.png';

export const BLOG_CARD_IMAGE_HEIGHT = {
	desk: {
		default: 284,
		small: 112,
	},
	mweb: {
		default: 88,
	},
};

export const BLOG_CARD_IMAGE_WIDTH = {
	desk: {
		default: 282,
		small: 180,
	},
	mweb: {
		default: 141,
	},
};

export const BLOG_GUIDE_CARD_IMAGE_HEIGHT = {
	desk: {
		default: 379,
	},
	mweb: {
		default: 152,
	},
};

export const BLOG_GUIDE_CARD_IMAGE_WIDTH = {
	desk: {
		default: 262,
	},
	mweb: {
		default: 290,
	},
};
export const BLOG_GUIDE_CARD_MAP_IMAGE_HEIGHT = 146;
export const BLOG_GUIDE_CARD_MAP_IMAGE_WIDTH = 155;

export const LTD_COLLECTION_ID = 167;

export const BROADWAY_COLLECTION_ID = 24;

export const RTL_LANGUAGE_CODES = ['ar'];

export const REM_VALUES = {
	DESKTOP: {
		1440: 16,
		1280: 14.4,
		1024: 12.8,
	},
	MOBILE: 16,
};

export const ENTITY_ICONS_FOLDER_LINK =
	'https://cdn-imgix-open.headout.com/categories';

export const PERSONA_ASSETS_FOLDER_LINK =
	'https://cdn-imgix.headout.com/persona-affinity';

export const LFC_SLICE_TYPES = {
	RICH_TEXT: 'rich_text',
	RICH_TEXT_ONLY: 'rich_text_only',
	TAB_WRAPPER: 'tab_wrapper',
	TAB: 'tab',
	ACCORDION: 'accordion',
	ACCORDION_ROW: 'accordion_row',
	FAQ_ROW: 'faq_row',
};

export const ZENDESK_VALIDITY_STRING = `^[^¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿƒΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩαβγδεζηθικλμνξοπρςστυφχψωϑϒϖ•…′″‾⁄℘ℑℜ™ℵ←↑→↓↔↵⇐⇑⇒⇓⇔∀∂∃∅∇∈∉∋∏∑−∗√∝∞∠∧∨∩∪∫∴∼≅≈≠≡≤≥⊂⊃⊄⊆⊇⊕⊗⊥⋅⌈⌉⌊⌋⟨⟩◊♠♣♥♦"<>ŒœŠšŸˆ˜‎‏–—‘’‚“”„†‡‰‹›€‐]+$`;

export const HSID_DEFAULT_VALUE = 'HSID_DEFAULT_VALUE';

export const DOMAINS_WITH_INDEXED_BOOKING_FLOWS = [
	'book.london-theater-tickets.com',
];

export const SEARCH_RESULT_TYPE = {
	CITY_COLLECTION: 'City Collection',
	CITY_EXPERIENCE: 'City Experience',
	WORLDWIDE_EXPERIENCE: 'Worldwide Experience',
	WORLDWIDE_COLLECTION: 'Worldwide Collection',
};

export const DINO_LOTTIE_URL =
	'https://cdn-imgix-open.headout.com/search/dino.json';

export const CASHBACK_LOTTIE_URL = `https://cdn-imgix.headout.com/assets/lottie/cashbackCoinAnimation.json`;

export const HEARTS_LOTTIE_URL =
	'https://cdn-imgix.headout.com/assets/lottie/reviewCountryBannerBackgroundHearts.json';

export const FIREWORKS_LOTTIE_URL =
	'https://cdn-imgix.headout.com/assets/lottie/fireworksAnimation.json';

export const STUDENTBEANS_CONNECT_LINK = {
	en: 'https://connect.studentbeans.com/v4/headout/us',
	fr: 'https://connect.studentbeans.com/v4/headout/fr',
	de: 'https://connect.studentbeans.com/v4/headout/de',
	es: 'https://connect.studentbeans.com/v4/headout/es',
	it: 'https://connect.studentbeans.com/v4/headout/it',
	// We will use en for PT and NL because localisation is tied to the territories we sign up for.
	pt: 'https://connect.studentbeans.com/v4/headout/us',
	nl: 'https://connect.studentbeans.com/v4/headout/us',
};

export const PARTNERS_IMAGES = {
	FOLDER_LINK: 'https://cdn-imgix-open.headout.com/partner-images',
	LIST: [
		{
			id: 'disneyland-paris',
			alt: 'Disneyland Paris',
		},
		{
			id: 'empire-state-building',
			alt: 'The Empire State Building',
		},
		{
			id: 'mvsei-vaticani',
			alt: 'Mvsei Vaticani',
		},
		{
			id: 'at-the-top',
			alt: 'At The Top: Burj Khalifa',
		},
		{
			id: 'chateau-de-versailles',
			alt: 'Chateau de Versailles',
		},
		{
			id: 'ain-dubai',
			alt: 'Ain Dubai',
		},
		{
			id: 'motf',
			alt: 'Museum of the Future',
		},
		{
			id: 'yas-waterworld',
			alt: 'Yas Waterworld',
		},
		{
			id: 'sydney-opera-house',
			alt: 'Sydney Opera House',
		},
		{
			id: 'big-bus',
			alt: 'Big Bus',
		},
		{
			id: 'merlin-entertainments',
			alt: 'Merlin Entertainments',
		},
		{
			id: 'warner-bros-world',
			alt: 'Warner Bros World',
		},
		{
			id: 'madison-square-garden',
			alt: 'Madison Square Garden',
		},
		{
			id: 'fcb',
			alt: 'Football Club Barcelona',
		},
		{
			id: 'parques-reunidos',
			alt: 'Parques Reunidos',
		},
		{
			id: 'real-madrid',
			alt: 'Real Madrid',
		},
		{
			id: 'universal-orlando-resorts',
			alt: 'Universal Orlando Resorts',
		},
		{
			id: 'resorts-world-singapore',
			alt: 'Resorts World Singapore',
		},
		{
			id: 'ferrari-world',
			alt: 'Ferrari World',
		},
		{
			id: 'royal-collection-trust',
			alt: 'Royal Collection Trust',
		},
		{
			id: 'coop-culture',
			alt: 'Co-op culture',
		},
		{
			id: 'english-heritage',
			alt: 'English Heritage',
		},
		{
			id: 'museu-nacional-del-prado',
			alt: 'Museu Nacional del Prado',
		},
		{
			id: 'historic-royal-palaces',
			alt: 'Historic Royal Palaces',
		},
		{
			id: 'belvedere',
			alt: 'Belvedere',
		},
		{
			id: 'uffizi-gallery',
			alt: 'Uffizi Gallery',
		},
		{
			id: 'duomo-di-milano',
			alt: 'Duomo di Milano',
		},
		{
			id: 'ocean-park',
			alt: 'Ocean Park',
		},
		{
			id: 'xoaret-mexico',
			alt: 'Xoaret Mexico',
		},
		{
			id: 'dubai-parks-and-resorts',
			alt: 'Dubai Parks and Resorts',
		},
	],
};

export const HEADOUT_HELP_NUMBERS = {
	AUS: '+61370663969',
	USA: '+1 347 897 0100',
	FR: '+33755537735',
	UK: '(+44) 117 325 5305',
	ES: '+34931227339',
	UAE: '+9718000321171',
	SG: '+6531580736',
	MY: '+601548770486',
	PL: '+48729087896',
	IT: '+39 02 8689 1704',
	PT: '+351300057144',
};

export const COUPON_TYPE = {
	BNGN: 'BNGN',
};

export const GRAND_PRIX = {
	BAHRAIN_GP: {
		TGID: '20014',
		BANNER_IMAGE_URL: {
			DESKTOP:
				'https://cdn-s3.headout.com/assets/images/banner/BahrainGP-hires-desktop.jpg',
			MOBILE: 'https://cdn-s3.headout.com/assets/images/banner/BahrainGP-hires-mobile.jpg',
		},
	},
	SAUDI_GP: {
		TGID: '20157',
		BANNER_IMAGE_URL: {
			DESKTOP:
				'https://cdn-s3.headout.com/assets/images/banner/SaudiGP-hires-desktop.jpg',
			MOBILE: 'https://cdn-s3.headout.com/assets/images/banner/SaudiGP-hires-mobile.jpg',
		},
	},
	ALL_TGIDS: [
		'20014',
		'20157',
		'20015',
		'20018',
		'19708',
		'20158',
		'20016',
		'20017',
		'12041',
		'12040',
		'20159',
		'19873',
		'20021',
		'20026',
		'20160',
		'20161',
		'17637',
		'17292',
		'20949',
		'19877',
		'6735',
	],
};

export const MEMBERSHIP = {
	TARGET_CITIES: ['DUBAI', 'ABU_DHABI', 'SHARJAH'],
	PRODUCT_TGID: 21252,
	LOGOS: {
		HORIZONTAL: {
			URL: 'https://cdn-imgix-open.headout.com/membership/icons/logo-horizontal.png',
			WIDTH: 358,
			HEIGHT: 64,
		},
		VERTICAL: {
			URL: 'https://cdn-imgix-open.headout.com/membership/icons/logo-vertical.png',
			WIDTH: 224,
			HEIGHT: 112,
		},
	},
	FEATURES: [
		{
			icon: 'https://cdn-imgix-open.headout.com/membership/icons/explore.png',
			title: 'Explore more, save more',
			description:
				'Book any experience worldwide and get an additional 10% discount.',
		},
		{
			icon: 'https://cdn-imgix-open.headout.com/membership/icons/stress.png',
			title: 'Book stress-free',
			description:
				'Need to cancel or reschedule? Just give us 24 hours notice.',
		},
		{
			icon: 'https://cdn-imgix-open.headout.com/membership/icons/birthday.png',
			title: 'Birthday rewards',
			description:
				'We’ll celebrate your birthday with special deals and reward you with other surprises.',
		},
		{
			icon: 'https://cdn-imgix-open.headout.com/membership/icons/cashback.png',
			title: '2x cashback',
			description:
				'Get double the cashback on every third booking with Headout.',
		},
		{
			icon: 'https://cdn-imgix-open.headout.com/membership/icons/moneyback.png',
			title: 'No questions asked refund',
			description:
				'Savings under AED 99 after 12 months? Get your membership fee back.',
		},
	],
	FAQS: [
		{
			question: 'Can I cancel or transfer my membership?',
			answer: 'Sorry, your membership cannot be cancelled or transferred. ',
		},
		{
			question: 'What’s the maximum discount I can get?',
			answer: 'Get 10% discount on any experience of your choice, the total discount you can avail can be a maximum of AED 500 in a year.',
		},
		{
			question: 'How soon can I avail my membership benefits?',
			answer: 'Your membership will be activated within 7 working days, and we’ll send you an email once it’s ready.',
		},
	],
	TRUST_BOX_IMAGES_PREFIX:
		'https://cdn-imgix-open.headout.com/membership/people/',
	GALLERY_IMAGES_PREFIX:
		'https://cdn-imgix-open.headout.com/membership/gallery/',
	GALLERY_IMAGES: [
		{
			height: 240,
			width: 180,
		},
		{
			height: 294,
			width: 220,
		},
		{
			height: 374,
			width: 280,
		},
		{
			height: 294,
			width: 220,
		},
		{
			height: 240,
			width: 180,
		},
	],
	GRADIENTS: {
		BANNER: {
			DESKTOP: {
				url: 'https://cdn-imgix-open.headout.com/membership/gradient/banner-desktop-2.png',
				width: 835.5,
				height: 510,
			},
			MOBILE: {
				url: 'https://cdn-imgix-open.headout.com/membership/gradient/banner-mobile.png',
				width: 327,
				height: 217,
			},
		},
		BENEFITS: {
			DESKTOP: {
				url: 'https://cdn-imgix-open.headout.com/membership/gradient/benefits-desktop.png',
				width: 588,
				height: 338,
			},
			MOBILE: {
				url: 'https://cdn-imgix-open.headout.com/membership/gradient/benefits-mobile.png',
				width: 375,
				height: 260,
			},
		},
	},
};

export const HOME_PAGE_VIDEO_BANNER_DWEB =
	'https://cdn-imgix.headout.com/media/videos/42bc24e61f0e32e3fbbefb0cbedbc961-Global%20Banner%20Video%20Desktop%20Version.mp4';

export const HOME_PAGE_VIDEO_BANNER_MWEB =
	'https://cdn-imgix.headout.com/media/videos/6303cabfd424e79083f458a3fddb367f-Global%20Banner%20Video%20Mobile%20Version.mp4';

export const HOME_PAGE_FALLBACK_BANNER_DWEB =
	'https://cdn-imgix.headout.com/media/images/214bb484bb33d864250442817c7a14e8-default_1.1.1.jpg';

export const HOME_PAGE_FALLBACK_BANNER_MWEB =
	'https://cdn-imgix.headout.com/media/images/102cf59506488691a790d5f65b6ac093-default_1.1.1.jpg';

export const HEART_URL =
	'https://cdn-imgix-open.headout.com/home/lottie-json/heart.json';

export const CASHBACK_COIN_ANIMATION_URL =
	'https://cdn-s3.headout.com/assets/lottie/cashbackCoinAnimation.json';

export const FLAGS_FOLDER_URL =
	'https://cdn-imgix-open.headout.com/home/country-flags/4x3';

export const WHY_HEADOUT_LIST_DATA = [
	{
		color: '#00C4EB',
		url: 'https://cdn-imgix-open.headout.com/home/lottie-json/gemstone.json',
	},
	{
		color: '#088943',
		url: 'https://cdn-imgix-open.headout.com/home/lottie-json/money.json',
	},
	{
		color: '#E5006E',
		url: HEART_URL,
	},
	{
		color: '#FFF266',
		url: 'https://cdn-imgix-open.headout.com/home/lottie-json/sunglasses.json',
	},
];

export const BLANK_PLACEHOLDER = ' ';

export const ADYEN = {
	SUPPORTED_LOCALES: {
		en: 'en-US',
		es: 'es-ES',
		fr: 'fr-FR',
		de: 'de-DE',
		it: 'it-IT',
		nl: 'nl-NL',
		pt: 'pt-PT',
		ar: 'ar',
		ko: 'ko-KR',
		pl: 'pl-PL',
	},
	FIELD_TYPE: {
		CARD_NUMBER: 'encryptedCardNumber',
		EXPIRY_DATE: 'encryptedExpiryDate',
		EXPIRY_MONTH: 'encryptedExpiryMonth',
		EXPIRY_YEAR: 'encryptedExpiryYear',
		CVV: 'encryptedSecurityCode',
	},
	THREE_DS_SANDBOX_URL: 'https://test.adyen.com/hpp/3d/validate.shtml',
	PLACEHOLDER_LABELS: {
		'creditCard.numberField.placeholder': CARD_NUMBER_PLACEHOLDER,
		'creditCard.cvcField.placeholder.4digits': '',
		'creditCard.cvcField.placeholder.3digits': '',
	},
	EMPTY_PLACEHOLDERS: {
		'creditCard.numberField.placeholder': BLANK_PLACEHOLDER,
		'creditCard.expiryDateField.placeholder': BLANK_PLACEHOLDER,
		'creditCard.cvcField.placeholder.4digits': BLANK_PLACEHOLDER,
		'creditCard.cvcField.placeholder.3digits': BLANK_PLACEHOLDER,
	},
	ANALYTICS_FIELD_NAME: {
		encryptedCardNumber: 'Card Number',
		encryptedExpiryDate: 'Expiry',
		encryptedSecurityCode: 'CVV',
	},
};

export const SEARCH_ENTITY_TYPE = {
	CITY: 'city',
	PRODUCT: 'product',
	COLLECTION: 'collections',
};

export const SEARCH_INDEX_SETTINGS = {
	CITY: {
		searchableAttributes: [
			'displayName',
			'country.displayName',
			'timeZone',
		],
		hitsPerPage: 1,
		isLocationSpecificSearchResults: true,
	},
	PRODUCT: {
		searchableAttributes: ['name', 'summary', 'highlights'],
		hitsPerPage: 3,
		isLocationSpecificSearchResults: false,
	},
	COLLECTION: {
		searchableAttributes: ['displayName'],
		hitsPerPage: 2,
		isLocationSpecificSearchResults: false,
	},
};

export const MAX_RESULTS_SEARCH_DROPDOWN = 6;

export const UNIVERSAL_SEARCH_ID = 'universal-search-input';

export const DESKTOP_HEADER_CLASSNAME = 'header-wrapper';

export const DESKTOP_HEADER_Z_INDEX = 100;

export const HOME_PAGE_CONTENT_START_ID = 'home-page-content-start';

export const EXPERIENCE_PAGE_CONTENT_START_ID = 'experience-page-content-start';

export const LIVE_PRICING = {
	SUPPORTED: 'YES',
	CHECKOUT_ONLY: 'CHECKOUT_ONLY',
	PRICE_CHANGED: 'pricing-change-success',
};

export const SORT_TYPE_RECENTLY_VIEWED = 'sort-type=RECENTLY_VIEWED';

export const DOCUMENT_READY_STATES = {
	LOADING: 'loading',
	INTERACTIVE: 'interactive',
	COMPLETE: 'complete',
};

export const SEATMAP_DATE_TIME_DRAWER_ANIMATION_TIME = 300;

export const CONFIRMATION_PAGE_EXPERIMENT_CITY_CODES = [
	'ROME',
	'PARIS',
	'BARCELONA',
	'LONDON',
	'DUBAI',
	'FLORENCE',
	'MADRID',
	'VENICE',
	'NAPLES',
	'LISBON',
];

export const COMBO_UPSELL_TGID_TID_MAP: { [key: string]: string | string[] } = {
	'3437': '5310',
	'1866': '2638',
	'6732': '17521',
	'5730': '10294',
	'9651': '17458',
	'8541': '15250',
	'6235': '11325',
	'7563': '13770',
	'12045': ['23110', '46004'],
	'14126': '26842',
	'17655': '33676',
	'3394': '5251',
};

export const COMBO_UPSELL_NON_FEE_TGIDS = [
	'3437',
	'1866',
	'6732',
	'5730',
	'12045',
	'14126',
	'3394',
];

export const BLIMP_ANIMATION_LOTTIE_URL = `https://cdn-s3.headout.com/assets/lottie/blimpAnimation.json`;

export const COMBO_UPSELL_ANIMATION_LOTTIE_URL = `https://cdn-s3.headout.com/assets/lottie/comboUpsell.json`;

export const CONFUSED_DINO_LOTTIE_URL = `https://cdn-s3.headout.com/assets/lottie/confusedDinoLottie.json`;

export const F1_EXPERIENCE_DATE_MAP = {
	'6735': '2023-11-24',
	'17292': '2023-09-15',
	'19877': '2023-10-27',
	'20021': '2023-07-21',
	'20026': '2023-07-28',
	'20160': '2023-08-25',
	'20161': '2023-08-26',
	'20949': '2023-10-20',
	'21071': '2023-09-01',
	'23520': '2023-07-30',
};
export const UAE_COUNTRY_CODE = 'ae';
export const COLLECTIONS_LIMIT = {
	PLACES_TO_VISIT: 100,
	GLOBAL_HOMEPAGE: 30,
	DEFAULT: 20,
};

export const PRODUCT_LIST_LIMIT = {
	DEFAULT: 30,
};

export const APP_PUSH_PAGE = {
	FALLBACK_DEEPLINK: 'https://applink.headout.com/emailAppPushFallback',
	COUPON_ICON_URL:
		'https://cdn-imgix.headout.com/assets/images/app-push-page/coupon.svg',
	FLASH_ICON_URL:
		'https://cdn-imgix.headout.com/assets/images/app-push-page/flash.svg',
	HEART_ICON_URL:
		'https://cdn-imgix.headout.com/assets/images/app-push-page/heart.svg',
	QR_SCANNER_URL:
		'https://cdn-imgix.headout.com/assets/images/app-push-page/app-push-page-qr.png',
	STORE_ICON_URL:
		'https://cdn-imgix.headout.com/assets/images/app-push-page/stores.svg',
	HEART_EYES_ICON_URL:
		'https://cdn-imgix.headout.com/assets/images/app-push-page/heart-eyes.svg',
	TICKET_SCREEN_ILLUSTRATION_URL:
		'https://cdn-imgix.headout.com/assets/images/app-push-page/ticket-screen.png',
};

export const BUTTON_LOADING_THRESHOLD_TIME = 45000;

export const SITEMAP_PAGE_LIMIT = 102;

export const CITIES_SITEMAP_FILTERS = [
	{ label: 'TOP_100', urlParam: '', apiParam: '' },
	{ label: 'ASIA', urlParam: 'asia', apiParam: 'ASIA' },
	{ label: 'AFRICA', urlParam: 'africa', apiParam: 'AFRICA' },
	{ label: 'EUROPE', urlParam: 'europe', apiParam: 'EUROPE' },
	{
		label: 'NORTH_AMERICA',
		urlParam: 'north-america',
		apiParam: 'NORTH_AMERICA',
	},
	{
		label: 'SOUTH_AMERICA',
		urlParam: 'south-america',
		apiParam: 'SOUTH_AMERICA',
	},
	{
		label: 'OCEANIA',
		urlParam: 'oceania',
		apiParam: 'OCEANIA',
	},
];

export const COLLECTIONS_SITEMAP_FILTERS = [
	{ label: 'TOP_100', urlParam: '' },
	{ label: 'A-E', urlParam: 'a-e' },
	{ label: 'F-K', urlParam: 'f-k' },
	{ label: 'L-P', urlParam: 'l-p' },
	{
		label: 'Q-U',
		urlParam: 'q-u',
	},
	{
		label: 'V-Z',
		urlParam: 'v-z',
	},
];

export const FPX_RESULT_CODE = {
	AUTHORISED: 'authorised',
	REFUSED: 'refused',
	CANCELLED: 'cancelled',
	ERROR: 'error',
};

export const FPX_FAILURE_CASES = [
	FPX_RESULT_CODE.REFUSED,
	FPX_RESULT_CODE.CANCELLED,
	FPX_RESULT_CODE.ERROR,
];

export const GUIDED_TOUR_UPGRADE_MAPPING: Record<
	string,
	{
		guided_tour_id: string;
		ticket_variant_id: number[];
		ticket_tid: number[];
		image_url?: string;
		guided_tour_variant_name?: string;
	}
> = {
	'12045': {
		guided_tour_id: '15140',
		ticket_variant_id: [23017, 28431, 46162],
		ticket_tid: [23110, 22980, 46004],
		image_url:
			'https://cdn-imgix.headout.com/media/images/9ffb37c4142bcf2e2e1d76934bad3511--2215140-athens-guided-tour-of-the-acropolis-with-skip-the-line-entry-09.jpg',
	},
	'7148': {
		guided_tour_id: '3075',
		ticket_variant_id: [33626, 46056, 46852],
		ticket_tid: [33599, 45899, 46563],
	},
	'6732': {
		guided_tour_id: '9379',
		ticket_variant_id: [19147],
		ticket_tid: [17521],
	},
	'8869': {
		guided_tour_id: '10988',
		ticket_variant_id: [15975],
		ticket_tid: [15975],
	},
	'7563': {
		guided_tour_id: '6705',
		ticket_variant_id: [13770],
		ticket_tid: [13770],
	},
	'3586': {
		guided_tour_id: '4082',
		ticket_variant_id: [27714, 27715, 45737],
		ticket_tid: [27784, 27785, 43065],
	},
	'10403': {
		guided_tour_id: '10410',
		ticket_variant_id: [19276, 19277],
		ticket_tid: [19316, 19317],
	},
	'11290': {
		guided_tour_id: '11291',
		ticket_variant_id: [21324, 21325],
		ticket_tid: [21401, 21402],
		image_url:
			'https://cdn-imgix.headout.com/tour/21403/TOUR-IMAGE/52b66298-ebf9-46b5-b7c5-370b39db525e-11291-milan-direct-access-to-duomo-cathedral---rooftop-guided-tour-01.jpg',
	},
	'15813': {
		guided_tour_id: '11291',
		ticket_variant_id: [29559, 29560],
		ticket_tid: [29586, 29587],
		image_url:
			'https://cdn-imgix.headout.com/tour/21403/TOUR-IMAGE/52b66298-ebf9-46b5-b7c5-370b39db525e-11291-milan-direct-access-to-duomo-cathedral---rooftop-guided-tour-01.jpg',
	},
	'15817': {
		guided_tour_id: '11291',
		ticket_variant_id: [29566],
		ticket_tid: [29593],
		image_url:
			'https://cdn-imgix.headout.com/tour/21403/TOUR-IMAGE/52b66298-ebf9-46b5-b7c5-370b39db525e-11291-milan-direct-access-to-duomo-cathedral---rooftop-guided-tour-01.jpg',
	},
	'15814': {
		guided_tour_id: '11291',
		ticket_variant_id: [29563],
		ticket_tid: [29590],
		image_url:
			'https://cdn-imgix.headout.com/tour/21403/TOUR-IMAGE/52b66298-ebf9-46b5-b7c5-370b39db525e-11291-milan-direct-access-to-duomo-cathedral---rooftop-guided-tour-01.jpg',
	},
	'15815': {
		guided_tour_id: '11291',
		ticket_variant_id: [29564],
		ticket_tid: [29591],
		image_url:
			'https://cdn-imgix.headout.com/tour/21403/TOUR-IMAGE/52b66298-ebf9-46b5-b7c5-370b39db525e-11291-milan-direct-access-to-duomo-cathedral---rooftop-guided-tour-01.jpg',
	},
	'20027': {
		guided_tour_id: '19130',
		ticket_variant_id: [39401, 39581],
		ticket_tid: [39382, 36333],
		image_url:
			'https://cdn-imgix.headout.com/media/images/83a6c2117449bfc5ec78479178702f37-19130-sintra--quinta-da-regaleira-guided-tour--ticket-included-01.jpg',
	},
	'18839': {
		guided_tour_id: '19130',
		ticket_variant_id: [36346],
		ticket_tid: [36333],
		image_url:
			'https://cdn-imgix.headout.com/media/images/83a6c2117449bfc5ec78479178702f37-19130-sintra--quinta-da-regaleira-guided-tour--ticket-included-01.jpg',
	},
	'7632': {
		guided_tour_id: '7713',
		ticket_variant_id: [21898, 13876],
		ticket_tid: [21989, 13876],
		image_url:
			'https://cdn-imgix.headout.com/media/images/83312f35c00065d0eb562ecfd3c65128-7713-florence-guided-tour-of-uffizi-gallery-with-priority-access-06.jpg',
	},
	'7600': {
		guided_tour_id: '7604',
		ticket_variant_id: [19495, 13827],
		ticket_tid: [22829, 13827],
		image_url:
			'https://cdn-imgix.headout.com/media/images/1ddacd9922f275c4d3857ad02e2bddef-22693-florence-accademia-gallery-guided-tour-in-florence-01.jpg',
	},
	'13091': {
		guided_tour_id: '19557',
		ticket_variant_id: [25140, 49213, 44659],
		ticket_tid: [25224, 48852, 36582],
		guided_tour_variant_name: 'English Guided Tour',
		image_url:
			'https://cdn-imgix.headout.com/media/images/67d6e97248c0db496b9bc389ef2dfe5e-19557-rome-castel-sant-angelo-tour-with-fast-track-access---drinks-on-the-terrace-01.jpg',
	},
	'11979': {
		guided_tour_id: '13477',
		ticket_variant_id: [37232, 47003],
		ticket_tid: [37219, 46705],
	},
	'11698': {
		guided_tour_id: '10109',
		ticket_variant_id: [22112, 25299],
		ticket_tid: [22195, 25376],
		image_url:
			'https://cdn-imgix.headout.com/media/images/bc7142dd9f0ea7f05e2bd895d0c8fa4a-11698-Skip-the-LineTicketstoSevilleCathedralandGiralda---002.jpg',
	},
	'21703': {
		guided_tour_id: '8581',
		ticket_variant_id: [44731, 42642],
		ticket_tid: [33594, 42582],
	},
	'8586': {
		guided_tour_id: '9769',
		ticket_variant_id: [15364],
		ticket_tid: [15364],
		image_url:
			'https://cdn-imgix.headout.com/media/images/2a5d3a5f4bbff313275c8d06ea831b24-8586-venice-museum-pass-and-doge-palace-tickets-01.jpg',
	},
	'8905': {
		guided_tour_id: '15284',
		ticket_variant_id: [17883, 32063],
		ticket_tid: [17911, 32047],
	},
	'7641': {
		guided_tour_id: '21882',
		ticket_variant_id: [13890, 45975],
		ticket_tid: [13890, 45818],
	},
	'13473': {
		guided_tour_id: '13472',
		ticket_variant_id: [25752],
		ticket_tid: [25826],
	},
	'8870': {
		guided_tour_id: '14304',
		ticket_variant_id: [15976],
		ticket_tid: [15976],
	},
	'11353': {
		guided_tour_id: '10651',
		ticket_variant_id: [21447, 34745],
		ticket_tid: [21528, 34713],
	},
	'11978': {
		guided_tour_id: '13478',
		ticket_variant_id: [22877],
		ticket_tid: [22975],
		guided_tour_variant_name: 'English Guided Tour',
		image_url:
			'https://cdn-imgix.headout.com/media/images/aaa31c29f09d6e13a93b4a1e9aa4c992-1231234.jpg',
	},
	'10996': {
		guided_tour_id: '11701',
		ticket_variant_id: [20553],
		ticket_tid: [20641],
	},
	'13592': {
		guided_tour_id: '16964',
		ticket_variant_id: [25911],
		ticket_tid: [25989],
	},
	'13486': {
		guided_tour_id: '15402',
		ticket_variant_id: [25772, 28979],
		ticket_tid: [25846, 28813],
		image_url:
			'https://cdn-imgix.headout.com/tour/25846/TOUR-IMAGE/988fbb98-3094-4c48-9ceb-2304d3170418-13486-rome-skip-the-line-tickets-to-pantheon---audioguide-01.jpg',
	},
	'7654': {
		guided_tour_id: '6173',
		ticket_variant_id: [28635, 13906],
		ticket_tid: [13903, 13906],
		image_url:
			'https://cdn-imgix.headout.com/media/images/fee554b65b585346868911d934efbd74-7654-TicketstoVersaillesPalace---002.jpg',
	},
	'3394': {
		guided_tour_id: '7679',
		ticket_variant_id: [5251],
		ticket_tid: [5251],
	},
	'17925': {
		guided_tour_id: '10117',
		ticket_variant_id: [34250],
		ticket_tid: [34221],
	},
};

export const GDPR_COUNTRY_CODES = [
	'AT',
	'BE',
	'BG',
	'HR',
	'CY',
	'CZ',
	'DK',
	'EE',
	'FI',
	'FR',
	'DE',
	'GR',
	'HU',
	'IE',
	'IT',
	'LV',
	'LT',
	'LU',
	'MT',
	'NL',
	'PL',
	'PT',
	'RO',
	'SK',
	'SI',
	'ES',
	'SE',
	'GB',
	'CH',
	'BH',
	'IL',
	'QA',
	'TR',
	'KE',
	'MU',
	'NG',
	'ZA',
	'UG',
	'JP',
	'KR',
	'NZ',
	'AR',
	'UY',
];

export const PLACES_TO_VISIT_SLUGS: Record<string, string> = {
	EN: 'places-to-visit',
	ES: 'lugares-para-visitar',
	FR: 'lieux-a-visiter',
	DE: 'sehenswuerdigkeiten',
	NL: 'bezienswaardigheden',
	PT: 'lugares-para-visitar',
	IT: 'luoghi-da-visitare',
};

export const HEADOUT_PRO_DARK_LOGO =
	'https://cdn-imgix.headout.com/assets/images/memberships/headout-pro-logo-black.png';

export const ERROR_MESSAGES = Object.freeze({
	UNKNOWN_IMAGE_SRC_RENDERED: 'Unknown Image Source Rendered',
	LIVE_PRICE_CHECK_FAILED: 'Live Price Check Failed',
	USER_ABORTED: 'The user aborted a request.',
});

export const RIV_CHAIR_ANIMATION =
	'https://cdn-imgix.headout.com/assets/rive/seatLoader.riv';

export const HARDCODED_BILINKS = {
	'14128':
		'JTdCJTIydCUyMiUzQSUyMkV4dHJhJTIwMTUlMjUlMjBPZmYlMjBvbiUyMFlvdXIlMjBGaXJzdCUyMFB1cmNoYXNlJTIwb2YlMjBWaWV3JTIwQXQlMjBUaGUlMjBQYWxtJTIwVGlja2V0cyUyMiUyQyUyMmRlc2MlMjIlM0ElMjJVc2UlMjBjb2RlJTIwRlJFU0gxNSUyMHVwb24lMjBjaGVja291dC4lMjBIdXJyeSUyQyUyMGxpbWl0ZWQlMjB0aW1lJTIwb2ZmZXIlMjBvbmx5LiUyMiUyQyUyMmMlMjIlM0ElMjJGUkVTSDE1JTIyJTJDJTIydHlwZSUyMiUzQSUyMlBST01PX01BTlVBTCUyMiUyQyUyMndpbGRDYXJkJTIyJTNBJTIyREFJTFklMjIlN0Q%3D',
};

export const BROADWAY_SEAT_SELECTION_VISUALIZATION = [
	10017, 11340, 1293, 19633, 23939, 24671, 24920, 25023, 519, 5838, 10069,
	11845, 17676, 19636, 24533, 24863, 24929, 512, 5806, 730, 508, 507, 24867,
	25023, 25380, 25633, 25673, 25746, 25747, 25949, 26404, 26501,
];

export const BRAND_EXPERIMENT_EXEMPTED_CITIES = ['ROME', 'PARIS', 'DUBAI'];

export const MICROSOFT_LCID_MAP: { [key: string]: number } = {
	en: 1033,
	es: 1034,
	fr: 1036,
	it: 1040,
	de: 1031,
	nl: 1043,
};

export const BI_LINK_URL =
	'https://cdn-s3.headout.com/bilink-tgid-config/bilink-tgid-map.json';

export const CTA_TYPE = {
	CANCEL: 'Cancel',
	BACK: 'Back',
	CONFIRM: 'Confirm',
	SELECT: 'Select',
};

export const SHORTER_CACHE_AGE = 10;

export const LOCALHOST = 'http://localhost:4000';

export const COOKIE_DELIMITER = '-';

export enum broadwaySectionNames {
	ORCH = 'orchestra',
	FMEZ = 'frontmezzanine',
	RMEZ = 'rearmezzanine',
	MMEZ = 'midmezzanine',
	MEZ = 'mezzanine',
	BALC = 'balcony',
	DRESS = 'dresscircle',
}

export const PAYMENT_METHODS_CONTAINER_ID = 'payment-methods-wrapper';

export const APP_VENDOR_IMAGE_HEIGHT = 45;
export const APP_VENDOR_IMAGE_WIDTH = 140;
export const FOOTER_CONTACT_ICON_DIM = 12;
export const DOWNLOAD_APP_QR_DIM = 84;

export const DEFAULT_IMAGE_DENSITY = 1.5;

export const MULTI_SELECTION_FILTER_ANIMATION_TIMEOUT = 315;

export const TICKET_CATEGORY_PAGE_SUBCATEGORIES_SECTION_INITIAL_FETCH_COUNT = 3;

export const TICKET_CATEGORY_ID = 1;

export const TOUR_PROPERTIES = {
	LANG: 'LANGUAGE_CODE',
};

export const GIF_LOADER_URL = `https://${CDN_IMGIX_BASE_SERVER}/assets/gif/headoutGifLoader.gif`;

export const GIF_LOADER_WHITE_BG_URL = `https://${CDN_IMGIX_BASE_SERVER}/assets/gif/headoutGifLoaderWhiteBg.gif`;

export const COLLECTION_DESC_TYPE = {
	COLLECTION_LANDING_PAGE: 'COLLECTION_LANDING_PAGE',
	GENERAL: 'GENERAL',
};

export const SIGNED_OUT_USER_IMG_SRC = `https://${CDN_IMGIX_BASE_SERVER}/assets/images/credits/SignedOutUser.png`;

export const MANAGE_BOOKING_VIEW_CREDITS = 'manage_booking_view_credits';

export const BOOKING_STAGES: {
	PAX_SELECTION: string;
	TIME_SELECTION: string;
} = {
	PAX_SELECTION: 'PAX_SELECTION',
	TIME_SELECTION: 'TIME_SELECTION',
};

export const CHECKOUT_DATE_TIME_DELIMITER = '•';

export const COUPON_ERROR = {
	TITLE: 'Coupon Error',
	LOGIN_ERROR_CODE: 'CAL-0408',
};

export const DISABLE_SELECT_PAGE_COLLECTIONS = [1011, 1019];

export const PAYPAL_SDK = 'PAYPAL_SDK';

export const FEE_LABELS = {
	CANCELLATION_INSURANCE_FEE: 'cancellation insurance fee',
	CONVENIENCE_FEE: 'convenience fee',
	HELIPORT_FEE: 'heliport fee',
	PRIORITY_BOARDING: 'priority boarding',
	SERVICE_CHARGE: 'service charge',
	SERVICE_TAX: 'service tax',
	TAX: 'tax',
	VAT: 'value added tax (vat)',
	VENDOR_SERVICE_FEE: 'vendor service fee',
	INTREPID_MEUSEUM: 'intrepid museum',
	SEAT_RESERVATION_FEE: 'seat reservation fee',
	FEE: 'fee',
};

// this is also getting used to control the checkout screen experiment
export const EXCLUDED_SUBCAT_IDS_DWEB_SELECT_PAX_EXP = [1019, 1011];

//#TODO: can be moved to the backend if we get more such requests
export const SKIP_FLEXI_DESCRIPTOR_LIST = [
	2895, 4851, 7312, 25078, 26765, 26612, 28318,
];

export const CONFETTI_LOTTIE = `https://tourlandish.s3.amazonaws.com/assets/lottie/confetti.json?fslkafjd`;

export const LANDMARK_MAP_MARKER_URL =
	'https://cdn-imgix-open.headout.com/itinerary/map/pass-by-landmark.svg';

export const POI_ICON_MAP: { [key: string]: string } = {
	24: 'https://cdn-imgix.headout.com/assets/svg/poi/toilets.svg',
	25: 'https://cdn-imgix.headout.com/assets/svg/wifi.svg',
	90: 'https://cdn-imgix.headout.com/assets/svg/info.svg',
	21: 'https://cdn-imgix.headout.com/assets/svg/shopping.svg',
	33: 'https://cdn-imgix.headout.com/assets/svg/accessibility.svg',
	32: 'https://cdn-imgix.headout.com/assets/svg/accessibility.svg',
	34: 'https://cdn-imgix.headout.com/assets/svg/stroller.svg',
	1: 'https://cdn-imgix.headout.com/assets/svg/poi/cloak-room.svg',
	4: 'https://cdn-imgix.headout.com/assets/svg/poi/nursing-room.svg',
	5: 'https://cdn-imgix.headout.com/assets/svg/pets.svg',
	6: 'https://cdn-imgix.headout.com/assets/svg/poi/restraurant.svg',
	7: 'https://cdn-imgix.headout.com/assets/svg/poi/first-aid.svg',

	info: 'https://cdn-imgix.headout.com/assets/svg/info.svg',
};

export const MARKER_PIN = {
	BY_BUS: 'https://cdn-imgix.headout.com/assets/svg/bus-pin.svg',
	BY_METRO: 'https://cdn-imgix.headout.com/assets/svg/train-pin.svg',
	BY_TRAM: 'https://cdn-imgix.headout.com/assets/svg/train-pin.svg',
	BY_TRAIN: 'https://cdn-imgix.headout.com/assets/svg/train-pin.svg',
	BY_CAR: 'https://cdn-imgix.headout.com/assets/svg/parking-pin.svg',
};

export const LANG_ICON_ID_MAP: {
	[k: string]: { [key: string]: number };
} = {
	enLabelMap: {
		'Souvenir shop': 21,
		Toilets: 24,
		Wifi: 25,
		ATMs: 29,
		'Wheelchair available': 32,
		'Wheelchair accessible': 33,
		'Strollers allowed': 34,
		'Transportation services': 89,
		Videoguides: 90,
		'Shower rooms': 98,
		'Cloak room': 1,
		'Baby nursing rooms': 4,
		'Service animals allowed': 5,
		'Dining outlets': 6,
		'First aid': 7,
	},
	deLabelMap: {
		Souvenirladen: 21,
		Toiletten: 24,
		WLAN: 25,
		Geldautomaten: 29,
		'Rollstuhl verfügbar': 32,
		Rollstuhlgerecht: 33,
		'Kinderwagen erlaubt': 34,
		Transferdienste: 89,
		Videoguides: 90,
	},
	esLabelMap: {
		'Tienda de recuerdos': 21,
		Aseos: 24,
		Wifi: 25,
		'Cajeros automáticos': 29,
		'Silla de ruedas disponible': 32,
		'- El palacio es accesible para sillas de ruedas.': 33,
		'Se admiten cochecitos': 34,
		'Servicios de transporte': 89,
		Videoguías: 90,
	},
	frLabelMap: {
		'Boutique de souvenirs': 21,
		Toilettes: 24,
		WiFi: 25,
		DAB: 29,
		'Fauteuil roulant disponible': 32,
		'Accessible en fauteuil roulant': 33,
		'Poussettes autorisées': 34,
		'Services de transport': 89,
		Vidéoguides: 90,
	},
	itLabelMap: {
		'Negozio di souvenir': 21,
		'Servizi igienici': 24,
		'Wi-Fi': 25,
		Bancomat: 29,
		'Sedia a rotelle disponibile': 32,
		'Accessibile alle sedie a rotelle': 33,
		'Passeggini ammessi': 34,
		'Servizi di trasporto': 89,
		Videoguide: 90,
	},
	nlLabelMap: {
		Souvenirwinkel: 21,
		Toiletten: 24,
		Wifi: 25,
		Geldautomaten: 29,
		'Rolstoel beschikbaar': 32,
		'Toegankelijk voor rolstoelen': 33,
		'Kinderwagens toegestaan': 34,
		Transportdiensten: 89,
		Videogidsen: 90,
	},
	ptLabelMap: {
		'Loja de souvenir': 21,
		Banheiros: 24,
		'Wi-Fi': 25,
		'Caixa eletrônico': 29,
		'Cadeira de rodas disponível': 32,
		'Acessível para cadeirantes': 33,
		'Carrinhos de bebê permitidos': 34,
		'Serviços de transporte': 89,
		Videoguias: 90,
	},
};

export const HOHO_ALLOWED_CITIES = ['BARCELONA', 'PARIS'];

// base root font at 100%
export const BASE_ROOT_FONT_SIZE = 16;

export const DEFAULT_REVIEWER_NAME = 'headout guest';

export const DEFAULT_TOP_REVIEWS_COUNT = 5;
