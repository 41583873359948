const EmbedScript = ({
	id,
	scriptAsString,
	scriptSrc,
	scriptAsync,
	type,
	isServer,
	callback,
	onError,
}: any) => {
	if (!isServer) {
		const script = document.createElement('script');
		script.innerHTML = scriptAsString || '';
		// Not working with ternary, may be I can't assign ''
		if (id) {
			script.id = id;
		}
		if (scriptSrc) {
			script.src = scriptSrc;
		}
		if (type) {
			script.type = type;
		}
		script.async = !(scriptAsync === false);
		document.body.appendChild(script);
		script.onload = () => {
			if (typeof callback !== 'undefined') callback();
		};
		script.onerror = error => {
			if (typeof onError !== 'undefined') onError(error);
		};
		return script;
	}
	const typeText = type ? `type="${type}"` : '';
	const idText = id ? `id="${id}"` : '';
	const scriptSrcText = scriptSrc ? `src="${scriptSrc}"` : '';
	const scriptAsStringText = scriptAsString || '';
	const scriptAsyncText = scriptAsync ? 'async' : '';

	const script = `<script ${idText} ${scriptAsyncText} ${scriptSrcText} ${typeText}>${scriptAsStringText}</script>`;
	// const parser = new DOMParser();
	// return parser.parseFromString(script, text/html);
	return script;
};

export default EmbedScript;
