import React from 'react';

export const FPXSvg = (props: any) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		x='0'
		y='0'
		version='1.1'
		viewBox='33 0 434 338.7'
		xmlSpace='preserve'
		{...props}
	>
		<g>
			<path
				fill='#242B57'
				d='M192.366 162.279L262.242 162.279 262.242 183.458 215.312 183.458 215.312 204.638 258.709 204.638 258.709 225.826 215.312 225.826 215.312 266.425 192.366 266.425z'
			></path>
			<path
				fill='#242B57'
				d='M278.714 162.279h38.684c5.394 0 10.496.489 15.298 1.465 4.807.986 9 2.677 12.581 5.079 3.582 2.403 6.418 5.639 8.528 9.708 2.109 4.068 3.161 9.194 3.161 15.375 0 6.08-.975 11.18-2.938 15.297-1.962 4.117-4.663 7.405-8.092 9.859-3.429 2.447-7.507 4.188-12.206 5.221-4.709 1.026-9.808 1.543-15.301 1.543H301.66v40.599h-22.945V162.279zm22.946 44.129h15.305a29.38 29.38 0 005.952-.588c1.915-.393 3.628-1.055 5.154-1.985 1.514-.929 2.737-2.209 3.674-3.824.93-1.622 1.399-3.652 1.399-6.106 0-2.652-.618-4.783-1.845-6.396-1.224-1.624-2.791-2.873-4.704-3.758-1.912-.879-4.042-1.442-6.4-1.691a66.231 66.231 0 00-6.763-.366H301.66v24.714z'
			></path>
			<path
				fill='#242B57'
				d='M397.275 211.995L362.852 162.279 391.682 162.279 413.893 198.754 435.225 162.279 463.029 162.279 429.342 211.557 467 266.428 438.165 266.428 412.724 224.499 388.008 266.428 360.794 266.428z'
			></path>
			<path
				fill='#0286C8'
				d='M91.866 246.884l-14.525-19.663c-2.156-2.923-5.88-6.777-5.88-10.638 0-3.851 3.704-7.705 5.88-10.639l14.525-19.681a11.643 11.643 0 012.003-2.147v-22.648c0-18.193-7.613-22.783-16.912-10.19l-31.321 42.401C40.981 199.983 33 208.277 33 216.583c0 8.294 8.002 16.593 12.635 22.937l31.321 42.35c9.299 12.604 16.912 8.056 16.912-10.175v-22.654c-.647-.525-1.308-1.194-2.002-2.157'
			></path>
			<path
				fill='#242B57'
				d='M153.399 193.675l-31.332-42.397c-9.296-12.6-16.905-8.009-16.905 10.19v22.648a11.51 11.51 0 011.985 2.147l14.548 19.681c2.166 2.935 5.875 6.788 5.875 10.639 0 3.854-3.709 7.714-5.875 10.641l-14.548 19.66a11.365 11.365 0 01-1.985 2.168v22.643c0 18.238 7.61 22.768 16.905 10.175l31.332-42.355c4.652-6.344 12.632-14.653 12.632-22.943 0-8.295-7.981-16.589-12.632-22.897'
			></path>
		</g>
	</svg>
);
