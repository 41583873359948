import {
	TBatchPOIResponse,
	TPOIActionCollectionParam,
	TPOIActionParam,
	TPOIActionTGIDParam,
	TPOIBatchActionParam,
	TPOIResponseByCollectionId,
	TPOIResponseById,
	TPOIResponseByTourGroupId,
} from 'ReduxTypes/poi';

export enum POIActions {
	REQUEST_POI_INFO_BY_ID = 'REQUEST_POI_INFO_BY_ID',
	REQUEST_BATCH_POI_INFO_BY_ID = 'REQUEST_BATCH_POI_INFO_BY_ID',
	RECEIVE_POI_INFO_BY_ID = 'RECEIVE_POI_INFO_BY_ID',
	RECEIVE_BATCH_POI_INFO_BY_ID = 'RECEIVE_BATCH_POI_INFO_BY_ID',
	REQUEST_POI_INFO_BY_TGID = 'REQUEST_POI_INFO_BY_TGID',
	RECEIVE_POI_INFO_BY_TGID = 'RECEIVE_POI_INFO_BY_TGID',
	REQUEST_POI_INFO_BY_COLLECTION_ID = 'REQUEST_POI_INFO_BY_COLLECTION_ID',
	RECEIVE_POI_INFO_BY_COLLECTION_ID = 'RECEIVE_POI_INFO_BY_COLLECTION_ID',
}

export const requestPOIInfoById = (
	info: TPOIActionParam,
): {
	payload: TPOIActionParam;
	type: POIActions.REQUEST_POI_INFO_BY_ID;
} => ({
	payload: info,
	type: POIActions.REQUEST_POI_INFO_BY_ID,
});

export const requestBatchPOIInfoById = (
	info: TPOIBatchActionParam,
): {
	payload: TPOIBatchActionParam;
	type: POIActions.REQUEST_BATCH_POI_INFO_BY_ID;
} => ({
	payload: info,
	type: POIActions.REQUEST_BATCH_POI_INFO_BY_ID,
});

export const requestPOIInfoByTgId = (
	info: TPOIActionTGIDParam,
): {
	payload: TPOIActionTGIDParam;
	type: POIActions.REQUEST_POI_INFO_BY_TGID;
} => ({
	payload: info,
	type: POIActions.REQUEST_POI_INFO_BY_TGID,
});

export const requestPOIInfoByCollectionId = (
	info: TPOIActionCollectionParam,
): {
	payload: TPOIActionCollectionParam;
	type: POIActions.REQUEST_POI_INFO_BY_COLLECTION_ID;
} => ({
	payload: info,
	type: POIActions.REQUEST_POI_INFO_BY_COLLECTION_ID,
});

export const receivePOIInfoById = (
	info: TPOIActionParam & { response: TPOIResponseById },
): {
	payload: TPOIActionParam & { response: TPOIResponseById };
	type: POIActions.RECEIVE_POI_INFO_BY_ID;
} => ({
	payload: info,
	type: POIActions.RECEIVE_POI_INFO_BY_ID,
});

export const receivePOIInfoByTgId = (
	info: TPOIActionTGIDParam & { response: TPOIResponseByTourGroupId },
): {
	payload: TPOIActionTGIDParam & { response: TPOIResponseByTourGroupId };
	type: POIActions.RECEIVE_POI_INFO_BY_TGID;
} => ({
	payload: info,
	type: POIActions.RECEIVE_POI_INFO_BY_TGID,
});

export const receivePOIInfoByCollectionId = (
	info: TPOIActionCollectionParam & { response: TPOIResponseByCollectionId },
): {
	payload: TPOIActionCollectionParam & {
		response: TPOIResponseByCollectionId;
	};
	type: POIActions.RECEIVE_POI_INFO_BY_COLLECTION_ID;
} => ({
	payload: info,
	type: POIActions.RECEIVE_POI_INFO_BY_COLLECTION_ID,
});

export const receiveBatchPOIInfoById = (
	info: TPOIBatchActionParam & {
		response: TBatchPOIResponse;
	},
): {
	payload: TPOIBatchActionParam & {
		response: TBatchPOIResponse;
	};
	type: POIActions.RECEIVE_BATCH_POI_INFO_BY_ID;
} => ({
	payload: info,
	type: POIActions.RECEIVE_BATCH_POI_INFO_BY_ID,
});
