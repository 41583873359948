import { ActionTypes } from 'Actions/actions';

import { PAGE_TYPE } from 'Constants/constants';

export const page = (state = { currentPage: PAGE_TYPE.HOME }, action: any) => {
	switch (action.type) {
		case ActionTypes.CHANGE_PAGE: {
			const currentPage = state?.currentPage;
			if (currentPage === action.newPage) {
				return state;
			}
			return {
				...state,
				currentPage: action.newPage,
				prevPage: currentPage,
			};
		}
		default:
			return state;
	}
};
