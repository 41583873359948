import styled, { css } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

export const StyledLink = styled.a`
	&#cookie-privacy-policy-link {
		text-decoration: underline;
	}
`;

export const TextContainer = styled.div`
	${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_REGULAR)}
	color: ${colors.GREY_DS.G2};
	width: max-content;

	margin: 1rem 0;
	padding-right: 1rem;
	border-right: 1px solid ${colors.GREY_DS.G6};

	@media (max-width: 768px) {
		${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_XS)}

		margin: 0.5rem 0;
		padding-right: 0.75rem;
	}
`;

export const CloseButton = styled.button`
	padding: 1.125rem 1rem;
	background-color: ${colors.WHITE};
	border: none;

	svg {
		fill: ${colors.GREY_DS.G2};
		width: 0.875rem;
		height: 0.875rem;
		cursor: pointer;
	}

	@media (max-width: 768px) {
		padding: 0.5rem 0.625rem 0.5rem 0.75rem;
	}
`;

const hidden = css`
	touch-action: none;
	pointer-events: none;
	visibility: hidden;
	opacity: 0;
`;

export const CookieContainer = styled.div<{ $isHidden?: boolean }>`
	position: fixed;
	background: ${colors.WHITE};
	left: 1.875rem;
	bottom: 1rem;
	border-radius: 0.5rem;
	border: 1px solid ${colors.GREY_DS.G6};
	z-index: 998;
	box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.12),
		0 -0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.08);

	display: flex;
	flex-direction: row;
	align-items: center;

	transition: visibility 0.3s, opacity 0.3s;

	visibility: visible;
	opacity: 1;
	${({ $isHidden }) => $isHidden && hidden}

	.cookie-icon {
		margin: 1rem 0.5rem 1rem 1rem;
	}

	@media (max-width: 768px) {
		bottom: 4.6875rem;
		left: 50%;
		transform: translateX(-50%);
		box-shadow: 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.1),
			0 0 0.0625rem 0 rgba(0, 0, 0, 0.1);

		.cookie-icon {
			margin: 0.5rem;
		}
	}
`;
