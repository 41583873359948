import {
	ItineraryActions,
	receiveItineraryData,
	requestItinerary,
} from 'Actions/itinerary';
import type { TReduxState } from 'ReduxTypes/persona';

const initialState: TReduxState = {};

export const itineraryStore = (
	state = initialState,
	{
		payload,
		type,
	}:
		| ReturnType<typeof requestItinerary>
		| ReturnType<typeof receiveItineraryData>,
): TReduxState => {
	switch (type) {
		case ItineraryActions.REQUEST_ITINERARY_DATA: {
			const { id } = payload;

			return {
				...state,
				[id]: { isFetching: true },
			};
		}

		case ItineraryActions.RECEIVE_ITINERARY_DATA: {
			const {
				id,
				response: { itineraries },
			} = payload;

			return {
				...state,
				[id]: {
					itineraries,
					isFetching: false,
				},
			} as TReduxState;
		}

		default:
			return state;
	}
};
