import React, { MouseEventHandler } from 'react';

import Modal from '../common/modal';

type Props = {
	onClose?: (...args: any[]) => any;
	onBodyClick?: (...args: any[]) => any;
	isOpen?: boolean;
	imageUrl?: string;
};

class ImageModal extends React.Component<Props> {
	close: MouseEventHandler = e => {
		e.preventDefault();
		this.props.onClose && this.props.onClose();
	};

	bodyClick: MouseEventHandler<HTMLDivElement> = e => {
		// @ts-ignore
		if (e.target.className.trim() === 'image-modal-image') {
			this.props.onBodyClick && this.props.onBodyClick();
		}
	};

	outsideClick: MouseEventHandler = e => {
		// @ts-ignore
		if (e.target.className.trim() === 'core-modal-container') {
			this.close(e);
		}
	};

	render() {
		if (!this.props.isOpen) {
			return null;
		}
		return (
			<div className='image-modal-container' onClick={this.outsideClick}>
				<Modal
					open={this.props.isOpen}
					contentClassName='image-modal-content'
					// @ts-expect-error TS(2769): No overload matches this call.
					onClick={this.outsideClick}
				>
					<div
						className='image-modal-wrapper'
						onClick={this.bodyClick}
					>
						<img
							alt='Offer'
							src={this.props.imageUrl}
							className='image-modal-image'
						/>
						<div className='image-modal-exit' onClick={this.close}>
							×
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

export default ImageModal;
