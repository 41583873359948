import cookies from 'nookies';

import { COOKIE, TIME } from 'Constants/constants';

import { setCurrencyCookie } from './cookieUtils';
import { log } from './logUtils';
import { getQueryObjectFromUrl } from './urlUtils';

/**
 * initiates the cookies in response from request
 * @returns function returning Promise that resolves to same object as the input
 * @param req
 * @param res
 * @param context
 */
// @ts-expect-error TS(6133): 'res' is declared but its value is never read.
const initCookies = async (req: any, res: any, context: any) => {
	try {
		// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		const allCookies = cookies?.[context];
		// set display traffic cookie if it came from display traffic
		if (
			!allCookies[COOKIE.IS_DISPLAY_TRAFFIC] &&
			req.url.indexOf('channel=display') !== -1
		) {
			// @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
			cookies.set(context, COOKIE.IS_DISPLAY_TRAFFIC, true, {
				httpOnly: false,
				path: '/',
				maxAge: TIME.IN_MINUTES * 30,
			});
		}

		// set User-Agent Cookie (Hacky way to check IE from Server Side)
		if (req.headers['user-agent']) {
			cookies.set(context, COOKIE.USER_AGENT, req.headers['user-agent'], {
				httpOnly: false,
				path: '/',
				maxAge: TIME.SECONDS_IN_YEARS,
			});
		}

		const query = getQueryObjectFromUrl(req.url);
		if (query?.currency) {
			setCurrencyCookie(query.currency as string, context);
		}

		// sanitize cookies
		allCookies.forEach((value: any, key: any) => {
			if ([...value].some(char => char.charCodeAt(0) > 127)) {
				cookies.destroy(context, key);
			}
		});
	} catch (err) {
		log(err);
	}
};

export default initCookies;
