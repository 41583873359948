import { ActionTypes } from 'Actions/actions';
import { cityActions } from 'Actions/city';

import { TOP_PERFORMING_CITIES } from 'Constants/constants';

type InitialState = {
	currentCityCode: string | null;
	citiesMap: { [key: string]: any };
	cityCodes: string[];
	closeByCities: string[];
	nearByCityCodes: string[];
	isFetching: boolean;
	isFetchingNearBy: boolean;
	discoverableCityCodes: string[];
	totalCitiesCount: number;
	discoverableCitiesCount: number;
};

const initialState: InitialState = {
	currentCityCode: null,
	citiesMap: {},
	cityCodes: [],
	closeByCities: [],
	nearByCityCodes: [],
	isFetching: false,
	isFetchingNearBy: false,
	discoverableCityCodes: [],
	totalCitiesCount: 0,
	discoverableCitiesCount: 0,
};

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
export const city = (state = initialState, action) => {
	switch (action.type) {
		case cityActions.CHANGE_CITY: {
			return {
				...state,
				currentCityCode: action.cityCode,
			};
		}
		case cityActions.REQUEST_CITIES: {
			return {
				...state,
				isFetching: true,
			};
		}
		case ActionTypes.REQUEST_NEARBY_CITIES_FROM_LAT_LONG: {
			return {
				...state,
				isFetchingNearBy: true,
			};
		}
		case cityActions.RECEIVE_CITIES: {
			const { json, receivedAt } = action;
			const cityCodes = json.map((c: any) => c.cityCode);
			const discoverableCityCodes = json
				.filter((c: any) => c.discoverable)
				.map((c: any) => c.cityCode);
			const citiesMap = json.reduce(
				(acc: any, c: any) => ({ ...acc, [c.cityCode]: c }),
				{},
			);
			return {
				...state,
				isFetching: false,
				cityCodes: cityCodes,
				discoverableCityCodes: discoverableCityCodes,
				citiesMap: citiesMap,
				lastUpdated: receivedAt,
				totalCitiesCount: json.length,
				discoverableCitiesCount: discoverableCityCodes.length,
			};
		}
		case ActionTypes.RECEIVE_CLOSEBY_CITY_CODES: {
			const { cities } = action;
			return {
				...state,
				closeByCities: cities,
			};
		}
		case ActionTypes.RECEIVE_NEARBY_CITIES_FROM_LAT_LONG: {
			const { codes } = action;
			return {
				...state,
				nearByCityCodes: codes,
				isFetchingNearBy: false,
			};
		}

		case cityActions.TRIM_CITIES: {
			const {
				currentCityCode,
				citiesMap,
				cityCodes,
				nearByCityCodes,
				discoverableCityCodes,
			} = state;
			const { retainCityCount } = action;
			let trimmedCitiesMap: (typeof initialState)['citiesMap'] =
				new Set();

			if (retainCityCount) {
				Object.keys(citiesMap)
					.slice(0, retainCityCount)
					.forEach(
						cityCode =>
							(trimmedCitiesMap[cityCode] = citiesMap[cityCode]),
					);
			} else {
				Object.keys(citiesMap).forEach(cityCode => {
					if (
						cityCode === currentCityCode ||
						nearByCityCodes.includes(cityCode)
					)
						trimmedCitiesMap[cityCode] = citiesMap[cityCode];
				});
			}

			TOP_PERFORMING_CITIES.forEach(cityCode => {
				if (cityCode in citiesMap) {
					trimmedCitiesMap[cityCode] = citiesMap[cityCode];
				}
			});

			return {
				...state,
				citiesMap: trimmedCitiesMap,
				cityCodes: cityCodes.filter(c =>
					Object.keys(trimmedCitiesMap).includes(c),
				),
				discoverableCityCodes: discoverableCityCodes.filter(c =>
					Object.keys(trimmedCitiesMap).includes(c),
				),
			};
		}

		default:
			return state;
	}
};
