import React from 'react';

import { read, write } from 'Utils/localStorageUtils';
import { error } from 'Utils/logUtils';

import ImageModal from './imageModal';

type Props = {
	location?: any;
};

type State = any;

class PromotionalPopup extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			validBannerAvailable: false,
			isPopupVisible: false,
		};
	}

	componentDidMount() {
		(this as any).popupInfo = this.getPopupInfoFromParam(
			this.props.location,
		);
		if ((this as any).popupInfo) {
			this.setState({ validBannerAvailable: true });
			if (!read(this.getPopupCampaignKey())) {
				this.setState({ isPopupVisible: true });
				write(this.getPopupCampaignKey(), true);
			}
		}
	}

	componentDidCatch(err: any, errInfo: any) {
		error(err, errInfo);
	}

	getPopupCampaignKey = () => `${(this as any).popupInfo.id}-popup-triggered`;

	getPopupInfoFromParam = (location: any) => {
		const infoRaw = location.query.hp;
		if (!infoRaw) {
			return null;
		}
		const info = JSON.parse(decodeURIComponent(atob(infoRaw)));
		const { id, imageUrl, redirectUrl } = info;
		if (!imageUrl || !redirectUrl || !id) {
			return null;
		}
		return info;
	};

	render() {
		if (!this.state.validBannerAvailable || !this.state.isPopupVisible) {
			return null;
		}
		return (
			<div className='promotional-popup'>
				<ImageModal
					onClose={() => this.setState({ isPopupVisible: false })}
					onBodyClick={() => {
						window.location.href = (
							this as any
						).popupInfo.redirectUrl;
					}}
					isOpen={this.state.isPopupVisible}
					imageUrl={(this as any).popupInfo.imageUrl}
				/>
			</div>
		);
	}
}

export default PromotionalPopup;
