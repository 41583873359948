import Error from 'next/error';

const redirect = (redirectUrl: any, permanently = false) => {
	const error = new Error(
		// @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
		`moved ${permanently ? 'permanently ' : ''}to ${redirectUrl}`,
	);
	(error as any).statusCode = permanently ? 301 : 303;
	(error as any).redirectUrl = redirectUrl;
	return error;
};

// Redundant method, can we merged with renderError
const notFound = (msg: any) => {
	const error = new Error(msg);
	(error as any).statusCode = 404;
	return error;
};

const renderError = (res: any, statusCode: any) => {
	if (res) {
		res.statusCode = 404;
		// @ts-expect-error TS(2345): Argument of type '"NOT FOUND"' is not assignable t... Remove this comment to see the full error message
		const error = new Error('NOT FOUND');
		(error as any).statusCode = 404;
		return error;
	}

	return {
		statusCode,
		error: true,
	};
};

const ErrorCode = (pageStatusCode: any, APIServerStatusCode: any) => {
	const sanitizedPageStatusCode =
		pageStatusCode && pageStatusCode < 400 ? null : pageStatusCode;
	const sanitizedAPIStatusCode =
		APIServerStatusCode && APIServerStatusCode < 400
			? null
			: APIServerStatusCode;
	return sanitizedPageStatusCode || sanitizedAPIStatusCode;
};

export { ErrorCode, notFound, redirect, renderError };
