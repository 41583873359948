import React from 'react';

export const PayPalSvg = (props: any) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='20'
		height='21'
		viewBox='0 0 20 21'
		fill='none'
		{...props}
	>
		<path
			d='M6.43431 20.3074L6.78222 18.0977L6.00724 18.0797H2.30664L4.87837 1.77373C4.88635 1.72451 4.9123 1.67861 4.95021 1.64601C4.98813 1.61342 5.03669 1.59546 5.08725 1.59546H11.327C13.3985 1.59546 14.828 2.02651 15.5744 2.87729C15.9243 3.27641 16.1471 3.69349 16.2549 4.15247C16.368 4.63408 16.37 5.20947 16.2596 5.91125L16.2516 5.96247V6.41215L16.6015 6.61037C16.8962 6.7667 17.1303 6.94563 17.3099 7.15051C17.6093 7.49176 17.8029 7.92547 17.8847 8.43966C17.9692 8.9685 17.9412 9.59777 17.8029 10.3102C17.6432 11.1297 17.3851 11.8435 17.0365 12.4275C16.7159 12.9657 16.3075 13.412 15.8225 13.7579C15.3595 14.0865 14.8094 14.336 14.1874 14.4956C13.5847 14.6526 12.8976 14.7318 12.1439 14.7318H11.6583C11.311 14.7318 10.9738 14.8568 10.709 15.081C10.4436 15.3098 10.268 15.6225 10.2141 15.9644L10.1775 16.1633L9.56283 20.058L9.53489 20.201C9.52757 20.2462 9.51493 20.2689 9.49631 20.2842C9.47968 20.2981 9.45573 20.3074 9.43245 20.3074H6.43431Z'
			fill='#253B80'
		/>
		<path
			d='M16.9321 6.01416C16.9135 6.13323 16.8922 6.25496 16.8683 6.38002C16.0454 10.6047 13.2302 12.0641 9.6347 12.0641H7.80403C7.36432 12.0641 6.99379 12.3834 6.92527 12.8171L5.98798 18.7613L5.72256 20.4463C5.67799 20.731 5.89751 20.9877 6.18489 20.9877H9.43181C9.81631 20.9877 10.1429 20.7083 10.2035 20.3292L10.2354 20.1642L10.8467 16.2848L10.886 16.0719C10.9458 15.6914 11.2731 15.4121 11.6576 15.4121H12.1432C15.2891 15.4121 17.7517 14.1349 18.4714 10.439C18.7721 8.89513 18.6165 7.60597 17.8209 6.69931C17.5801 6.42592 17.2814 6.19908 16.9321 6.01416Z'
			fill='#179BD7'
		/>
		<path
			d='M16.0709 5.67108C15.9451 5.63449 15.8154 5.60123 15.6824 5.5713C15.5487 5.54203 15.4116 5.51609 15.2706 5.49347C14.777 5.41365 14.2362 5.37573 13.6568 5.37573H8.76613C8.64572 5.37573 8.5313 5.40301 8.42886 5.45223C8.20335 5.56066 8.03572 5.77419 7.99514 6.03561L6.95474 12.625L6.9248 12.8173C6.99332 12.3836 7.36385 12.0643 7.80356 12.0643H9.63423C13.2297 12.0643 16.0449 10.6042 16.8678 6.38018C16.8924 6.25512 16.913 6.13339 16.9317 6.01432C16.7235 5.9039 16.4979 5.80944 16.2551 5.72895C16.1953 5.709 16.1334 5.68971 16.0709 5.67108Z'
			fill='#222D65'
		/>
		<path
			d='M7.99598 6.03541C8.03655 5.77399 8.20419 5.56046 8.4297 5.4527C8.5328 5.40347 8.64656 5.3762 8.76696 5.3762H13.6576C14.237 5.3762 14.7779 5.41412 15.2715 5.49394C15.4125 5.51656 15.5495 5.5425 15.6832 5.57177C15.8163 5.6017 15.946 5.63496 16.0717 5.67155C16.1342 5.69017 16.1961 5.70946 16.2566 5.72875C16.4994 5.80924 16.725 5.90437 16.9332 6.01412C17.178 4.45291 16.9312 3.38992 16.087 2.42738C15.1564 1.36773 13.4767 0.914062 11.3274 0.914062H5.08765C4.6486 0.914062 4.27409 1.23336 4.20623 1.66773L1.60723 18.1413C1.55601 18.4673 1.80746 18.7613 2.13608 18.7613H5.98835L6.95558 12.6248L7.99598 6.03541Z'
			fill='#253B80'
		/>
	</svg>
);
