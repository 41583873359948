import { ActionTypes } from 'Actions/actions';
import type { BannersData } from 'ReduxTypes/banner';

type State = Record<
	string,
	Record<
		string,
		{
			bannersList: BannersData;
			isFetching: boolean;
		}
	>
>;

type Action = {
	type: string;
	cityCode: string;
	params: string;
	bannersList?: BannersData;
};

export const banners = (state: State = {}, action: Action) => {
	switch (action.type) {
		case ActionTypes.REQUEST_BANNERS: {
			const { cityCode, params } = action;
			return {
				...state,
				[cityCode]: {
					...state[cityCode],
					[params]: {
						...state?.[cityCode]?.[params],
						isFetching: true,
					},
				},
			};
		}
		case ActionTypes.RECEIVE_BANNERS: {
			const { cityCode, params, bannersList } = action;
			return {
				...state,
				[cityCode]: {
					...state?.[cityCode],
					[params]: { bannersList, isFetching: false },
				},
			};
		}
		default:
			return state;
	}
};
