import {
	addQueryParamToString,
	getApiString,
	getBaseUrl,
	getSEOLanguageLabel,
} from 'Utils/urlUtils';

import type { TCategory } from 'ReduxTypes/categories';

import { TICKET_CATEGORY_ID } from 'Constants/constants';

import { getWhitelistedCategoryIds } from './hostUtils';

export const getParams = (category: any) => {
	if (!category) return ``;
	const { tags = [], id } = category;
	return `tags=${tags.join(',')}&categoryId=${id}`;
};

export const getCategoryId = ({ location, params }: any) => {
	return (params && params.id) || location.query.id;
};

export const getCityFromLocationObject = (location: any) => {
	return getApiString(location.query.city);
};

export const getCategoryUrl = ({
	category,
	paramLang = 'en',
	withBase = false,
}: {
	category: TCategory;
	paramLang?: string;
	withBase?: boolean;
}) => {
	const { id: categoryId } = category; // ideally this should be there in every category model. but this is to support older models. like in search
	if (!category || !categoryId) return null;
	const currentLang = paramLang ? getApiString(paramLang) : 'EN';
	const entitySlug = category?.urlSlugs?.[currentLang];
	return `${withBase ? getBaseUrl() : ''}${entitySlug}`;
};

export const isNoIndex = ({ category, paramLang }: any) => {
	if (!category) return true; // tour-list pages
	const { supportedLanguages, noIndex } = category;
	if (noIndex === true) return true;
	if (supportedLanguages.contains(getApiString(paramLang))) {
		return false;
	}
	return true;
};

export const getCategoryMetaTags = ({ category }: any) => {
	if (!category) return null;
	const { supportedLanguages } = category;
	const tags = supportedLanguages.map((lang: any) => {
		return {
			rel: 'alternate',
			hreflang: getSEOLanguageLabel(lang),
			href: `${getCategoryUrl({
				category,
				paramLang: lang,
				withBase: true,
			})}`,
		};
	});
	tags.push({
		rel: 'alternate',
		hreflang: 'x-default',
		href: getCategoryUrl({
			category,
			withBase: true,
		}),
	});
	return tags;
};

export const getWhitelistedCategoryUrl = ({ host }: any) => {
	const whitelistedCategoryIds = getWhitelistedCategoryIds({ host });
	if (whitelistedCategoryIds) {
		return `/category/${whitelistedCategoryIds[0]}`;
	}
	return null;
};

export const showCategories = ({ host }: any) =>
	!getWhitelistedCategoryIds({ host });

export const getDetailedCollectionData = (
	collectionIds: any,
	collectionsMap: any,
) => {
	if (!collectionsMap || !collectionIds) return;
	return collectionIds
		.slice(0, 5)
		.map((id: any) => collectionsMap?.[String(id)])
		.map((collection: any) => {
			collection.imageUrl =
				collection.heroImageUrl || collection.cardImageUrl;
			collection.searchType = 'collections';
			return collection;
		});
};

export const getProductListCatSubcatId = (
	productListByCityCode: any,
	pageSortingParams: string,
	subCategoryId: string,
	categoryId: string,
) => {
	if (subCategoryId) {
		if (!pageSortingParams.includes('subCategoryId')) {
			pageSortingParams = addQueryParamToString(pageSortingParams, {
				subCategoryId: subCategoryId,
			});
		}
	} else if (categoryId) {
		if (!pageSortingParams.includes('categoryId')) {
			pageSortingParams = addQueryParamToString(pageSortingParams, {
				categoryId: categoryId,
			});
		}
	}

	return getProductListDetails(productListByCityCode, pageSortingParams);
};
export const getProductListDetails = (
	productListByCityCode: any,
	pageSortingParams: any,
) => {
	const productIds =
		productListByCityCode?.[pageSortingParams]?.productIdList || [];
	let nextPageUrl;
	let prevPageUrl;
	let nextPageOffset;
	let prevPageOffset;
	let totalItems;
	const productListInfo =
		productListByCityCode?.[pageSortingParams]?.lastUpdatedPageInfo;
	const totalExperiencesWithoutFilters =
		productListByCityCode?.[pageSortingParams]?.metaData?.unFilteredMetaData
			?.totalCount;
	if (productListInfo) {
		const { nextUrl, prevUrl, nextOffset, prevOffset, total } =
			productListInfo;
		nextPageUrl = nextUrl;
		prevPageUrl = prevUrl;
		nextPageOffset = nextOffset;
		prevPageOffset = prevOffset;
		totalItems = total;
	}
	const isFetching = productListByCityCode?.[pageSortingParams]?.isFetching;
	const isLoading = isFetching === undefined ? true : isFetching;
	return {
		isLoading,
		nextPageUrl,
		prevPageUrl,
		productIds,
		totalItems,
		totalExperiencesWithoutFilters,
		nextOffset: nextPageOffset,
		prevOffset: prevPageOffset,
	};
};

export const checkIfTicketCategory = (categoryDetails: Partial<TCategory>) => {
	return Number(categoryDetails.id) === TICKET_CATEGORY_ID;
};
