import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { useRouter } from 'next/router';

import LSpan from 'Components/common/localizedTags/localizedSpan';

import Cookie from 'Assets/svg/cookie';
import { CrossIconSvg } from 'Assets/svg/mobile/bookingPage';

import { getDeviceType, getUserCountryCode } from 'Utils/stateUtils';

import { DEVICE_TYPE, GDPR_COUNTRY_CODES } from 'Constants/constants';
import { strings } from 'Constants/strings';

import {
	CloseButton,
	CookieContainer,
	StyledLink,
	TextContainer,
} from './styles';
import { TProps } from './types';

export const HIDE_COOKIE_BANNER_KEY = 'cookie-banner-closed';

const CookieBanner = ({ hideCookieBanner }: TProps) => {
	const [isHidden, setIsHidden] = useState(false);
	const router = useRouter();
	const path = useRef<any>();

	const hide = (
		e?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
	) => {
		e?.stopPropagation();
		setTimeout(hideCookieBanner, 300);
		setIsHidden(true);
	};

	const { isDesktop, isGDPRCompliant } = useSelector(state => {
		const isDesktop = getDeviceType(state) === DEVICE_TYPE.DESKTOP;
		const countryCode = getUserCountryCode(state);
		const isGDPRCompliant =
			!countryCode || GDPR_COUNTRY_CODES.includes(countryCode);

		return { isDesktop, isGDPRCompliant };
	});

	useEffect(() => {
		if (!path.current) {
			path.current = router.asPath;
			return;
		}
		hide();
	}, [router.asPath]);

	useEffect(() => {
		if (!isGDPRCompliant) hide();
	}, [isGDPRCompliant]);

	if (!isGDPRCompliant) return null;

	return (
		<CookieContainer $isHidden={isHidden} key={'cookie-banner'}>
			<Cookie className='cookie-icon' />
			<TextContainer>
				<LSpan>
					{isDesktop
						? strings.COOKIE_BANNER.DESKTOP
						: strings.COOKIE_BANNER.MOBILE}{' '}
				</LSpan>
				<Link href={'/privacy-policy/'} passHref legacyBehavior>
					<StyledLink
						onClick={hide}
						id='cookie-privacy-policy-link'
						target='_blank'
					>
						{strings.COOKIE_BANNER.LEARN_MORE}
					</StyledLink>
				</Link>
			</TextContainer>
			<CloseButton onClick={hide}>
				<CrossIconSvg />
			</CloseButton>
		</CookieContainer>
	);
};

export default CookieBanner;
