import {
	identifyUser,
	sendVariableToDataLayer,
	trackUserLogout,
} from 'Utils/analytics';
import { formatPrice } from 'Utils/gen';

import { ActionTypes } from 'Actions/actions';

import { ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { HSID_DEFAULT_VALUE, UserProfileTypes } from 'Constants/constants';

const initialStateReducer = {
	hsid: HSID_DEFAULT_VALUE,
	attributes: {},
	resetAffiliatePassword: {},
};

const onRequestUser = (state: any, action: any) => ({
	...state,
	isFetchingUser: true,
	baseCurrencyCode: action.currencyCode,
});

// @ts-expect-error TS(7006): Parameter 'state' implicitly has an 'any' type.
const setSandboxID = (state, { hsid }) => ({
	...state,
	hsid: hsid,
});

const updateUserAttributes = (state: any, action: any) => {
	const mergedAttributes = {
		...state?.attributes,
		...action.attributes,
	};
	identifyUser({ attributes: mergedAttributes });
	return {
		...state,
		attributes: mergedAttributes,
	};
};

const changeUserProfileType = (state: any, action: any) => {
	const { userProfileType } = action;
	return {
		...state,
		profileType: userProfileType,
	};
};

const onReceiveUser = (state: any, action: any) => {
	const { user, lastUpdatedAt } = action;
	const userProfile = user?.userProfileType;
	if (userProfile !== UserProfileTypes.ANONYMOUS) {
		sendVariableToDataLayer({
			name: ANALYTICS_PROPERTIES.SIGNED_IN,
			value: true,
		});
	}
	if (user) {
		const userWalletPrice = formatPrice(
			user?.walletCredits,
			user?.walletCurrency,
		);
		sendVariableToDataLayer({
			name: ANALYTICS_PROPERTIES.USER_WALLET_CREDITS,
			value: userWalletPrice,
		});
	}
	return {
		...state,
		user: user,
		profileType: user?.userProfileType,
		lastUpdatedAt,
		isFetchingUser: false,
	};
};

const onLoginFailure = (state: any) => ({
	...state,
	isFetchingUser: false,
});

const onReceiveAffiliateResetPassword = (state: any, action: any) => {
	const { resetAffiliatePassword } = action;
	return {
		...state,
		resetAffiliatePassword: resetAffiliatePassword,
	};
};

const onDeleteUser = (state: any) => {
	const { hsid } = state;
	sendVariableToDataLayer({
		name: ANALYTICS_PROPERTIES.SIGNED_IN,
		value: false,
	});
	trackUserLogout();
	return { ...initialStateReducer, hsid };
};

const openLoginModal = (state: any) => ({
	...state,
	isLoginModalOpen: true,
});

const closeLoginModal = (state: any) => ({
	...state,
	isLoginModalOpen: false,
});

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
export const userStore = (state = initialStateReducer, action) => {
	switch (action.type) {
		case ActionTypes.REQUEST_AFFILIATE_DETAILS: {
			return state;
		}
		case ActionTypes.CHANGE_PROFILE_TYPE: {
			return changeUserProfileType(state, action);
		}
		case ActionTypes.UPDATE_USER_ATTRIBUTES: {
			return updateUserAttributes(state, action);
		}
		case ActionTypes.REQUEST_USER_DETAILS: {
			return onRequestUser(state, action);
		}
		case ActionTypes.RECEIVE_USER_DETAILS: {
			return onReceiveUser(state, action);
		}
		case ActionTypes.DELETE_USER_DETAILS: {
			return onDeleteUser(state);
		}
		case ActionTypes.HANDLE_LOGIN_FALURE: {
			return onLoginFailure(state);
		}
		case ActionTypes.REQUEST_AFFILIATE_RESET_PASSWORD: {
			return initialStateReducer;
		}
		case ActionTypes.RECEIVE_AFFILIATE_RESET_PASSWORD_DETAILS: {
			return onReceiveAffiliateResetPassword(state, action);
		}
		case ActionTypes.OPEN_LOGIN_MODAL: {
			return openLoginModal(state);
		}
		case ActionTypes.CLOSE_LOGIN_MODAL: {
			return closeLoginModal(state);
		}
		case ActionTypes.SET_SANDBOX_ID: {
			return setSandboxID(state, action);
		}
		default:
			return state;
	}
};
