import { ActionTypes } from 'Actions/actions';

// @ts-expect-error TS(7006): Parameter 'pageUrl' implicitly has an 'any' type.
export const setServerPageStatus = (pageUrl, statusCode, info = '') => ({
	type: ActionTypes.SET_SERVER_PAGE_STATUS,
	pageUrl,
	statusCode,
	info,
});

/**
 * Important: Only to be used when the API is critical to the page/flow.
 * Don't use it for non-critical APIs (eg: collection top list, etc)
 */
export const setAPIServerAPIStatus = (
	APIUrl: string,
	statusCode: any,
	info = '',
) => ({
	type: ActionTypes.SET_SERVER_API_STATUS,
	APIUrl,
	statusCode,
	info,
});
