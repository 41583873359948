export enum PRODUCT_CARD_ACTION_TYPES {
	SET_ACTIVE_PRODUCT_CARD = 'SET_ACTIVE_PRODUCT_CARD',
	UPDATE_ACTIVE_PRODUCT_CARD_QUEUE = 'UPDATE_ACTIVE_PRODUCT_CARD_QUEUE',
}

export const setActiveProductCard = (
	productCardUniqueId: string | null,
): {
	payload: { productCardUniqueId: string | null };
	type: PRODUCT_CARD_ACTION_TYPES.SET_ACTIVE_PRODUCT_CARD;
} => ({
	payload: { productCardUniqueId },
	type: PRODUCT_CARD_ACTION_TYPES.SET_ACTIVE_PRODUCT_CARD,
});

export const updateActiveProductCardQueue = (
	productCardUniqueId: string | null,
): {
	payload: { productCardUniqueId: string | null };
	type: PRODUCT_CARD_ACTION_TYPES.UPDATE_ACTIVE_PRODUCT_CARD_QUEUE;
} => ({
	payload: { productCardUniqueId },
	type: PRODUCT_CARD_ACTION_TYPES.UPDATE_ACTIVE_PRODUCT_CARD_QUEUE,
});
