import { generateFilterKey } from 'Utils/gen';
import { evaluateReviewStoreKey } from 'Utils/reviewUtils';

import { ActionTypes } from 'Actions/actions';
import {
	TReviewActionCityParam,
	TReviewActionCollectionParam,
	TReviewActionPersonaParam,
	TReviewActionTGIDParam,
	TReviewState,
} from 'ReduxTypes/review';

const initialState: TReviewState = {
	byTgIdAndFilter: {},
	byCollectionIdAndSortOrder: {},
	byCityAndSortOrder: {},
	byPersonaAndCityAndSortOrder: {},
	status: {
		isFetching: {
			byTgIdAndFilter: {},
			byCollectionIdAndSortOrder: {},
			byCityAndSortOrder: {},
			byPersonaAndCityAndSortOrder: {},
		},
	},
};

export const reviewStore = (
	state = initialState,
	action: {
		payload:
			| TReviewActionTGIDParam
			| TReviewActionCollectionParam
			| TReviewActionCityParam;
		type: string;
	},
) => {
	switch (action.type) {
		case ActionTypes.REQUEST_REVIEWS_BY_TGID: {
			const { tgId, filterType } = <TReviewActionTGIDParam>action.payload;
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byTgIdAndFilter: {
							...state.status.isFetching.byTgIdAndFilter,
							[evaluateReviewStoreKey(tgId, filterType)]: true,
						},
					},
				},
			};
		}

		case ActionTypes.RECEIVE_REVIEWS_BY_TGID: {
			const { reviews, tgId, filterType } = <TReviewActionTGIDParam>(
				action.payload
			);
			if (!reviews) return;
			const { items } = reviews;
			const reviewsMap = state?.byTgIdAndFilter;
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byTgIdAndFilter: {
							...state.status.isFetching.byTgIdAndFilter,
							[evaluateReviewStoreKey(tgId, filterType)]: false,
						},
					},
				},
				byTgIdAndFilter: {
					...reviewsMap,
					[evaluateReviewStoreKey(tgId, filterType)]: items,
				},
			};
		}

		case ActionTypes.REQUEST_REVIEWS_BY_COLLECTION: {
			const { collectionId, sortOrder } = <TReviewActionCollectionParam>(
				action.payload
			);
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byCollectionIdAndSortOrder: {
							...state.status.isFetching
								.byCollectionIdAndSortOrder,
							[evaluateReviewStoreKey(collectionId, sortOrder)]:
								true,
						},
					},
				},
			};
		}

		case ActionTypes.RECEIVE_REVIEWS_BY_COLLECTION: {
			const { reviews, collectionId, sortOrder } = <
				TReviewActionCollectionParam
			>action.payload;
			if (!reviews) return;
			let { items, total, nextOffset } = reviews;
			nextOffset ??= total;
			const reviewsMap = state?.byCollectionIdAndSortOrder;
			const key = evaluateReviewStoreKey(collectionId, sortOrder);
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byCollectionIdAndSortOrder: {
							...state.status.isFetching
								.byCollectionIdAndSortOrder,
							[key]: false,
						},
					},
				},
				byCollectionIdAndSortOrder: {
					...reviewsMap,
					[key]: {
						reviews: items,
						total,
						nextOffset,
					},
				},
			};
		}

		case ActionTypes.APPEND_REVIEWS_BY_COLLECTION: {
			const { reviews, collectionId, sortOrder } = <
				TReviewActionCollectionParam
			>action.payload;
			if (!reviews) return;
			let { items, total, nextOffset } = reviews;
			/**
			 * PAGINATION LOGIC
			 * not using this right now because we
			 * are only showing 8 reviews which are
			 * fetched together. In case in future we
			 * plan to show more reviews, pagination
			 * can be used.
			 */
			nextOffset ??= total;
			const reviewsMap = state?.byCollectionIdAndSortOrder;
			const key = evaluateReviewStoreKey(collectionId, sortOrder);
			let { reviews: storedReviews = [], nextOffset: currentOffset = 0 } =
				state.byCollectionIdAndSortOrder[key];
			const currentReviews = [...storedReviews];
			currentOffset ??= 0;
			if (nextOffset > currentOffset) {
				currentReviews.push(...items);
			}
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byCollectionIdAndFilter: {
							...state.status.isFetching
								.byCollectionIdAndSortOrder,
							[key]: false,
						},
					},
				},
				byCollectionIdAndFilter: {
					...reviewsMap,
					[key]: {
						reviews: currentReviews,
						total,
						nextOffset: Math.max(nextOffset, currentOffset),
					},
				},
			};
		}

		case ActionTypes.REQUEST_REVIEWS_BY_CITY: {
			const { city, sortOrder } = <TReviewActionCityParam>action.payload;
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byCityAndSortOrder: {
							...state.status.isFetching.byCityAndSortOrder,
							[evaluateReviewStoreKey(city, sortOrder)]: true,
						},
					},
				},
			};
		}

		case ActionTypes.RECEIVE_REVIEWS_BY_CITY: {
			const { reviews, city, sortOrder } = <TReviewActionCityParam>(
				action.payload
			);
			if (!reviews) return;
			let { items, total, nextOffset } = reviews;
			nextOffset ??= total;
			const reviewsMap = state?.byCityAndSortOrder;
			const key = evaluateReviewStoreKey(city, sortOrder);
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byCityAndSortOrder: {
							...state.status.isFetching.byCityAndSortOrder,
							[key]: false,
						},
					},
				},
				byCityAndSortOrder: {
					...reviewsMap,
					[key]: {
						reviews: items,
						total,
						nextOffset,
					},
				},
			};
		}

		case ActionTypes.APPEND_REVIEWS_BY_CITY: {
			const { reviews, city, sortOrder } = <TReviewActionCityParam>(
				action.payload
			);
			if (!reviews) return;
			let { items, total, nextOffset } = reviews;
			/**
			 * PAGINATION LOGIC
			 * not using this right now because we
			 * are only showing 8 reviews which are
			 * fetched together. In case in future we
			 * plan to show more reviews, pagination
			 * can be used.
			 */
			nextOffset ??= total;
			const reviewsMap = state?.byCityAndSortOrder;
			const key = evaluateReviewStoreKey(city, sortOrder);
			let { reviews: storedReviews = [], nextOffset: currentOffset = 0 } =
				state.byCityAndSortOrder[key];
			const currentReviews = [...storedReviews];
			currentOffset ??= 0;
			if (nextOffset > currentOffset) {
				currentReviews.push(...items);
			}
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byCityAndSortOrder: {
							...state.status.isFetching.byCityAndSortOrder,
							[key]: false,
						},
					},
				},
				byCityAndSortOrder: {
					...reviewsMap,
					[key]: {
						reviews: currentReviews,
						total,
						nextOffset: Math.max(nextOffset, currentOffset),
					},
				},
			};
		}

		case ActionTypes.REQUEST_REVIEWS_BY_PERSONA: {
			const {
				personaAffinityId,
				city,
				sortOrder = '',
			} = <TReviewActionPersonaParam>action.payload;
			const key = generateFilterKey(personaAffinityId, city, sortOrder);
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byPersonaAndCityAndSortOrder: {
							...state.status.isFetching
								.byPersonaAndCityAndSortOrder,
							[key]: true,
						},
					},
				},
			};
		}

		case ActionTypes.RECEIVE_REVIEWS_BY_PERSONA: {
			const {
				reviews,
				personaAffinityId,
				city,
				sortOrder = '',
			} = <TReviewActionPersonaParam>action.payload;
			if (!reviews) return;
			let { items, total, nextOffset } = reviews;
			nextOffset ??= total;
			const reviewsMap = state?.byPersonaAndCityAndSortOrder;
			const key = generateFilterKey(personaAffinityId, city, sortOrder);
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byPersonaAndCityAndSortOrder: {
							...state.status.isFetching
								.byPersonaAndCityAndSortOrder,
							[key]: false,
						},
					},
				},
				byPersonaAndCityAndSortOrder: {
					...reviewsMap,
					[key]: {
						reviews: items,
						total,
						nextOffset,
					},
				},
			};
		}

		default:
			return state;
	}
};
