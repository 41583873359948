export const localizedMetaDataLabels = {
	en: {
		CITIES_FEED: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: (cityName, year) =>
				`Things To Do In ${cityName}: Attractions, Tours, Activities in ${year} | Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			DESCRIPTION: cityName =>
				`Here are the best things to do in ${cityName}. Book tickets to tours, attractions, events & more. Enjoy flexible cancelation, additional cashback and 24/7 support. Let\'s go.`,
		},
		PLACES_TO_VISIT: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: cityName =>
				`Best Places to Visit in ${cityName} – Book Tickets – Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			DESCRIPTION: cityName =>
				`Book tickets to the most iconic places to visit in ${cityName}. Visit historic landmarks, museums, theme parks and the newest attractions in ${cityName}.`,
		},
		HOMEPAGE: {
			TITLE: 'Headout: Things To Do, Attractions, Tours, Events & Experiences',
			DESCRIPTION:
				"Explore the world's best experiences - from expert-led tours and incredible landmarks to activities and events. Book tickets at the lowest prices and best deals.",
		},
		GLOBAL_CITIES_PAGE: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: cityName =>
				`Find Tours, Attractions & Event Tickets in ${cityName} cities at Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityNum' implicitly has an 'any' type.
			DESCRIPTION: cityNum =>
				`Book top attractions & experiences in Dubai, Singapore, Rome, NYC, Sydney, Paris, Bangkok, and ${cityNum} more cities around the world only at Headout.`,
		},
		ALL_EXPERIENCES_PAGE: {
			TITLE: (cityName: string) =>
				`The Best ${cityName} Tours | Top Experiences in ${cityName}`,
			DESCRIPTION: (cityName: string) =>
				`Discover the best of ${cityName} with museums, attractions, tours, cruises and more. Headout offers exclusive deals, flexible cancellations and 24/7 support.`,
		},
	},
	fr: {
		CITIES_FEED: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: (cityName, year) =>
				`Expériences à ${cityName} : Attractions, visites, activités en ${year} | Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			DESCRIPTION: cityName =>
				`Meilleures expériences à ${cityName}. Réservez des billets pour des visites, des attractions etc. Annulation flexible, cashbacks et assistance 24/7. C'est parti !`,
		},
		PLACES_TO_VISIT: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: cityName =>
				`Meilleures attractions à ${cityName} – Réserver des billets – Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			DESCRIPTION: cityName =>
				`Réservez des billets pour les lieux les plus emblématiques à visiter à ${cityName}. Visitez des sites historiques, des musées, des parcs à thème et les dernières attractions à ${cityName}.`,
		},
		HOMEPAGE: {
			TITLE: 'Headout : Expériences, attractions, visites, et événements',
			DESCRIPTION: `Découvrez les meilleures visites, attractions et escapades à travers monde avec Headout.  Meilleurs prix, réservation rapide et cashbacks. C\'est parti !`,
		},
		GLOBAL_CITIES_PAGE: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: cityName =>
				`Expériences, visites, attractions dans ${cityName} villes avec Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityNum' implicitly has an 'any' type.
			DESCRIPTION: cityNum =>
				`Réservez des billets pour des attractions et des expériences à Dubaï, Rome, Paris, New York, Sydney, Singapour, Bangkok et ${cityNum} autres villes du monde avec Headout.`,
		},
		ALL_EXPERIENCES_PAGE: {
			TITLE: (cityName: string) =>
				`Meilleurs tours de ${cityName} | Expériences à ${cityName}`,
			DESCRIPTION: (cityName: string) =>
				`Découvrez ${cityName} avec des musées, attractions, visites, croisières, et autres. Headout propose des exclusivités, annulations flexibles et une assistance 24/7.`,
		},
	},
	it: {
		CITIES_FEED: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: (cityName, year) =>
				`Cose da fare a ${cityName}: Attrazioni, Tour, Attività nel ${year} | Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			DESCRIPTION: cityName =>
				`Ecco le migliori cose da fare a ${cityName}. Prenota biglietti per tour, attrazioni ed eventi. Goditi la cancellazioni flessibili, Cashback e supporto online 24/7`,
		},
		PLACES_TO_VISIT: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: cityName =>
				`I migliori luoghi da visitare a ${cityName} – Prenota i biglietti – Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			DESCRIPTION: cityName =>
				`Prenota i biglietti per le attrazioni più iconiche di ${cityName}. Luoghi storici, musei, parchi tematici e tutte le nuove attrazioni di ${cityName}.`,
		},
		HOMEPAGE: {
			TITLE: 'Headout: Cose da fare, Attrazioni, Tour, Eventi ed Esperienze',
			DESCRIPTION:
				'Headout ha selezionato le migliori esperienze e cose da fare in giro per il mondo - tour, attrazioni, viaggi, eventi. Prezzi bassi, prenotazioni veloci e Cashback da riscattare.',
		},
		GLOBAL_CITIES_PAGE: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: cityName =>
				`Scegli con Headout tra tour, attrazioni e biglietti per eventi in ${cityName} città `,
			// @ts-expect-error TS(7006): Parameter 'cityNum' implicitly has an 'any' type.
			DESCRIPTION: cityNum =>
				`Prenota con Headout le migliori attrazioni ed esperienze a Dubai, Singapore, Roma, NYC, Sydney, Parigi, Bangkok e in altre ${cityNum} città del mondo.`,
		},
		ALL_EXPERIENCES_PAGE: {
			TITLE: (cityName: string) =>
				`I migliori tour di ${cityName} Esperienze top a ${cityName}`,
			DESCRIPTION: (cityName: string) =>
				`Scopri il meglio di ${cityName} con musei, attrazioni, tour, crociere e molto altro. Con Headout avrai offerte esclusive, cancellazioni flessibili e assistenza 24/7.`,
		},
	},
	es: {
		CITIES_FEED: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: (cityName, year) =>
				`Qué hacer en ${cityName}: Atracciones, tours y entradas ${year} | Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			DESCRIPTION: cityName =>
				`Descubre las mejores cosas que hacer en ${cityName}. Reserva tus entradas tours, eventos y mucho más. Disfruta de cancelación flexible, Cashbask adicional y asistencia 24/7.`,
		},
		PLACES_TO_VISIT: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: cityName =>
				`Mejores lugares para visitar en ${cityName} - Reservar entradas - Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			DESCRIPTION: cityName =>
				`Reserva entradas para los lugares más emblemáticos de ${cityName}. Visita monumentos históricos, museos, parques temáticos y las atracciones más nuevas en ${cityName}.`,
		},
		HOMEPAGE: {
			TITLE: 'Headout: atracciones, tours, eventos y experiencias',
			DESCRIPTION:
				'Hemos seleccionado las mejores experiencias para ti: excursiones, atracciones, viajes y eventos en Headout. ¡El mejor precio, reserva rápida y cashback!',
		},
		GLOBAL_CITIES_PAGE: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: cityName =>
				`Tours, atracciones y entradas en ${cityName} ciudades sólo en Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityNum' implicitly has an 'any' type.
			DESCRIPTION: cityNum =>
				`Reserva las mejores atracciones y experiencias en Dubai, Singapur, Roma, Nueva York, Sydney, París, Bangkok y ${cityNum} ciudades más en todo el mundo, sólo en Headout.`,
		},
		ALL_EXPERIENCES_PAGE: {
			TITLE: (cityName: string) =>
				`Mejores tours en ${cityName} | Experiencias en ${cityName}`,
			DESCRIPTION: (cityName: string) =>
				`Descubre lo mejor de ${cityName} con museos, atracciones, tours guiados, cruceros y más. Headout ofrece ofertas exclusivas, cancelaciones flexibles y atención 24/7.`,
		},
	},
	de: {
		CITIES_FEED: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: (cityName, year) =>
				`Erlebnisse in ${cityName}: Attraktionen, Touren, Aktivitäten ${year} | Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			DESCRIPTION: cityName =>
				`Finden Sie die besten Erlebnisse in ${cityName}. Buchen Sie Tickets für Touren und Attraktionen mit flexibler Stornierung, Cashback und 24-Stunden Support.`,
		},
		PLACES_TO_VISIT: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: cityName =>
				`${cityName} Attraktionen: Tickets bei Headout buchen`,
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			DESCRIPTION: cityName =>
				`Buchen Sie Tickets für Top-Sehenswürdigkeiten in ${cityName} – von historischen Wahrzeichen, bis hin zu Museen, Freizeitparks und den neuesten Attraktionen!`,
		},
		HOMEPAGE: {
			TITLE: 'Headout: Erlebnisse, Attraktionen, Touren und Events',
			DESCRIPTION:
				'Entdecken Sie die weltweit besten Erlebnisse, Touren, Attraktionen und Events mit Headout. Günstige Preise, schnelle Buchungen und lukratives Cashback.',
		},
		GLOBAL_CITIES_PAGE: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: cityName =>
				`Erlebnisse, Touren, Attraktionen in ${cityName} Städten bei Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityNum' implicitly has an 'any' type.
			DESCRIPTION: cityNum =>
				`Buchen Sie Tickets für Attraktionen und Erlebnisse in Dubai, Rom, Paris, New York, Sydney, Singapur, Bangkok & ${cityNum} weiteren Städten auf der ganzen Welt bei Headout.`,
		},
		ALL_EXPERIENCES_PAGE: {
			TITLE: (cityName: string) =>
				`Die besten ${cityName} Touren und Erlebnisse in ${cityName}`,
			DESCRIPTION: (cityName: string) =>
				`Entdecken Sie die Top-Attraktionen in ${cityName} mit Museen, Touren, Schifffahrten uvm. Headout bietet exklusive Deals, flexible Stornierungen und 24 Std. Support.`,
		},
	},
	pt: {
		CITIES_FEED: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: (cityName, year) =>
				`O que fazer em ${cityName}: atrações, tours e atividades em ${year} | Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			DESCRIPTION: cityName =>
				`Aqui estão as melhores coisas a fazer em ${cityName}. Reserve para tours, atrações, eventos e mais. Cancelamentos flexíveis, cashback adicional e suporte 24x7.`,
		},
		PLACES_TO_VISIT: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: (cityName, year) =>
				`Melhores lugares para visitar em ${cityName} - Reservar ingressos - Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			DESCRIPTION: cityName =>
				`Reserve ingressos para os locais mais icônicos de ${cityName}. Visite monumentos históricos, museus, parques temáticos e as atrações mais novas de ${cityName}.`,
		},
		HOMEPAGE: {
			TITLE: 'Headout: atividades, atrações, tours, eventos e experiências',
			DESCRIPTION:
				'Selecionamos as melhores experiências e coisas para fazer - tours, atrações, viagens e eventos na Headout. Preços mais baixos, reservas mais rápidas e cashback.',
		},
		GLOBAL_CITIES_PAGE: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: cityName =>
				`Encontre tours, atrações e eventos em ${cityName} cidades na Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityNum' implicitly has an 'any' type.
			DESCRIPTION: cityNum =>
				`Reserve as melhores atrações e experiências em Dubai, Singapura, Roma, NYC, Sydney, Paris, Bangkok e mais ${cityNum} cidades ao redor do mundo somente na Headout.`,
		},
		ALL_EXPERIENCES_PAGE: {
			TITLE: (cityName: string) =>
				`Melhores tours em ${cityName} | Experiências em ${cityName}`,
			DESCRIPTION: (cityName: string) =>
				`Descubra o melhor de ${cityName} com museus, atrações, tours, cruzeiros e mais. A Headout oferece ofertas exclusivas, cancelamentos flexíveis e suporte 24h.`,
		},
	},
	nl: {
		CITIES_FEED: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: (cityName, year) =>
				`De 10 beste tours, attracties & activititeit in ${cityName} in ${year} | Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			DESCRIPTION: cityName =>
				`Ontdek de leukste dingen om te doen in {0} met Headout. Boek je ${cityName} tours & tickets online met onze beste prijsgarantie voor een zorgeloze ervaring.`,
		},
		PLACES_TO_VISIT: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: cityName =>
				`Beste plaatsen om te bezoeken in ${cityName} - Boek tickets - Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			DESCRIPTION: cityName =>
				`Boek tickets voor de meest iconische plaatsen om te bezoeken in ${cityName}. Bezoek historische bezienswaardigheden, musea, themaparken en de nieuwste attracties in ${cityName}.`,
		},
		HOMEPAGE: {
			TITLE: 'Headout: Ontdek attracties, activititeiten & evenementen in jouw stad',
			DESCRIPTION:
				'ontdek en boek topattracties, sightseeing tours, evenementen ' +
				'en dingen om te doen in jouw stad. Krijg exclusieve kortingen en ' +
				'prioritaire toegang met Headout.',
		},
		GLOBAL_CITIES_PAGE: {
			// @ts-expect-error TS(7006): Parameter 'cityName' implicitly has an 'any' type.
			TITLE: cityName =>
				`Find Tours, Attractions & Event Tickets in ${cityName} cities at Headout`,
			// @ts-expect-error TS(7006): Parameter 'cityNum' implicitly has an 'any' type.
			DESCRIPTION: cityNum =>
				`Book top attractions & experiences in Dubai, Singapore, Rome, NYC, Sydney, Paris, Bangkok, and ${cityNum} more cities around the world only at Headout.`,
		},
		ALL_EXPERIENCES_PAGE: {
			TITLE: (cityName: string) =>
				`De beste ${cityName} tours | Topervaringen in ${cityName}`,
			DESCRIPTION: (cityName: string) =>
				`Ontdek het beste van ${cityName} met musea, attracties, rondleidingen, boottochten en meer. Headout biedt exclusieve deals, flexibele annuleringen en 24/7 support.`,
		},
	},
};
