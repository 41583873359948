import { faqActions } from 'Actions/faq';

const initialState = {
	general: [],
	byCategoryId: {},
	status: {},
};

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
export const faqStore = (state = initialState, action) => {
	switch (action.type) {
		case faqActions.REQUEST_FAQS: {
			return state;
		}
		case faqActions.RECEIVE_FAQS: {
			const { generalFaqs, categoryFaqs } = action;
			const byCategoryId = categoryFaqs.reduce((acc: any, w: any) => {
				const { categoryId, faqs } = w;
				return {
					...acc,
					[categoryId.toString()]: faqs.map((x: any) => x),
				};
			}, {});
			return {
				...state,
				general: generalFaqs.map((x: any) => x),
				byCategoryId: {
					...state.byCategoryId,
					byCategoryId,
				},
			};
		}
		default:
			return state;
	}
};
