import { createContext, useEffect, useState } from 'react';
import algoliasearch, { SearchClient, SearchIndex } from 'algoliasearch/lite';

import { getAlgoliaConfig } from 'Utils/keyUtils';

export const AlgoliaSearchContext = createContext<{
	[key: string]: SearchIndex;
}>({});

const AlgoliaSearchContextProvider = ({
	children,
	searchIndexes,
	lang,
	getAgClient,
}: any) => {
	const [algoliaClient, setAlgoliaClient] = useState<SearchClient | null>(
		null,
	);

	useEffect(() => {
		const { APPLICATION_ID, SEARCH_API } = getAlgoliaConfig();
		const agClient = getAgClient
			? null
			: algoliasearch(APPLICATION_ID, SEARCH_API);
		setAlgoliaClient(agClient);
	}, [getAgClient]);

	const getAllAlgoliaIndexes = ({
		searchIndexes,
		algoliaClient,
		lang,
	}: {
		searchIndexes: { [key: string]: string };
		algoliaClient: SearchClient | null;
		lang: string;
	}) => {
		const initiatedSearchIndexes: {
			[key: string]: SearchIndex;
		} = {};
		Object.keys(searchIndexes).forEach(
			searchIndex =>
				(initiatedSearchIndexes[searchIndex] = algoliaClient?.initIndex(
					`${searchIndexes[searchIndex]}${
						searchIndex !== 'citySearchIndex' ? `-${lang}` : ''
					}`,
				)!),
		);
		return initiatedSearchIndexes;
	};

	return (
		<AlgoliaSearchContext.Provider
			value={getAllAlgoliaIndexes({
				searchIndexes,
				algoliaClient,
				lang,
			})}
		>
			{children}
		</AlgoliaSearchContext.Provider>
	);
};

export default AlgoliaSearchContextProvider;
