import { ActionTypes } from 'Actions/actions';

export const citiesList = (state = {}, action: any) => {
	switch (action.type) {
		case ActionTypes.REQUEST_CITIES_LIST: {
			const { params } = action.payload;
			const map = { isFetching: true };
			return {
				...state,
				[params]: {
					// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					...state?.[params],
					...map,
				},
			};
		}
		case ActionTypes.RECEIVE_CITIES_LIST: {
			const {
				params,
				metaData,
				pageData: lastUpdatedPageInfo,
				response,
				receivedAt,
			} = action.payload;
			const {
				nextOffset,
				prevOffset,
				total,
				prevUrl,
				nextUrl,
				cities,
				discoverableCities,
				nonDiscoverableCities,
			} = response;

			const cityIdList = cities?.map(
				({ cityCode }: { cityCode: string }) => cityCode,
			);
			const discoverableCityIdList = discoverableCities?.map(
				({ cityCode }: { cityCode: string }) => cityCode,
			);
			const nonDiscoverableCityIdList = nonDiscoverableCities?.map(
				({ cityCode }: { cityCode: string }) => cityCode,
			);

			const partialMap = {
				metaData,
				lastUpdatedPageInfo,
				cityIdList,
				discoverableCityIdList,
				nonDiscoverableCityIdList,
				receivedAt,
				nextOffset,
				prevOffset,
				total,
				prevUrl,
				nextUrl,
				isFetching: false,
			};

			return {
				...state,
				[params]: partialMap,
			};
		}

		default:
			return state;
	}
};
