import { notify } from 'Components/common/notify';

import { getBaseRequestOptions, getPostRequest } from 'Utils/fetchUtils';
import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getCurrentLanguageCode } from 'Utils/stateUtils';
import { getApiBaseUrl } from 'Utils/urlUtils';

import {
	receiveCheckoutMetadata,
	requestCheckoutMetadata,
} from 'Actions/checkoutMetadata';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';

export interface TTourIdRequestTypes {
	tourId: number;
	date: string;
	time: string;
}

interface TfetchCheckoutMetadataParams {
	id: string;
	requestArray: Array<TTourIdRequestTypes>;
}

export const fetchCheckoutMetadata =
	({ id, requestArray }: TfetchCheckoutMetadataParams) =>
	(dispatch: any, getState: any) => {
		const lang: string = getCurrentLanguageCode(getState());
		const url = `${getApiBaseUrl()}/api/v1/checkout-page-info`;
		// Prevent any "Malformed request" i.e. request without any of - tourId, date or time.
		if (
			!requestArray.length ||
			requestArray.some(
				({ tourId, date, time }) => !tourId || !date || !time,
			)
		)
			return Promise.resolve();
		dispatch(requestCheckoutMetadata({ id }));
		const baseOptions = getPostRequest({
			language: lang.toUpperCase(),
			checkoutBookingComponents: requestArray,
		});
		const options = getBaseRequestOptions(getState(), baseOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				const { checkoutPageInfoItems } = json;
				dispatch(
					receiveCheckoutMetadata({
						id,
						payload: checkoutPageInfoItems,
					}),
				);
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
			});
	};
