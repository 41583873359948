import { DefaultRootState } from 'react-redux';

import { combineUnique, customMergeDeep } from 'Utils/gen';

import { ActionTypes } from 'Actions/actions';

export interface State extends DefaultRootState {
	byId: object;
	byCardId: object;
	byIdInEnglish: object;
	status: {
		isFetching: {
			byId: object;
			byIds: object;
		};
	};
	productPageExperimentVariant: string | null;
}

export const initialState: State = {
	byId: {},
	byCardId: {},
	byIdInEnglish: {},
	status: { isFetching: { byId: {}, byIds: {} } },
	productPageExperimentVariant: null,
};

const addProductCardsToState = (state: State, productCards: any) => {
	const productCardsById = productCards.reduce((acc: any, product: any) => {
		const { id } = product;
		const {
			topReviews: _topReviews,
			microBrandsHighlight: _microBrandsHighlight,
			highlights: _highlights,
			inclusions: _inclusions,
			exclusions: _exclusions,
			...productData
		} = product;
		return {
			...acc,
			[id.toString()]: productData,
		};
	}, {});
	return {
		...state,
		byCardId: {
			...state.byCardId,
			...productCardsById,
		},
		status: {
			...state['status'],
			isFetching: {
				...state['status']['isFetching'],
				byIds: false,
			},
		},
	};
};

export const productStore = (state = initialState, action: any) => {
	switch (action.type) {
		case ActionTypes.REQUEST_PRODUCTS:
		case ActionTypes.REQUEST_TRANSLATED_PRODUCT_CONTENT: {
			return {
				...state,
				status: {
					...state['status'],

					isFetching: {
						...state['status']['isFetching'],
						byIds: true,
					},
				},
			};
		}
		case ActionTypes.RECEIVE_PRODUCTS: {
			const { productCards } = action;
			return addProductCardsToState(state, productCards);
		}
		case ActionTypes.RECEIVE_SIMILAR_PRODUCTS:
		case ActionTypes.RECEIVE_PRODUCT_LIST: {
			const { productCards } = action;
			return addProductCardsToState(state, productCards);
		}
		case ActionTypes.REQUEST_PRODUCT: {
			return {
				...state,
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byId: {
							...state.status.isFetching.byId,
							[String(action.id)]: true,
						},
					},
				},
			};
		}
		case ActionTypes.RECEIVE_PRODUCT: {
			const { productJson } = action;
			const { id } = productJson;
			const productMap = state?.byId;
			return {
				...state,
				byId: {
					...productMap,
					[String(id)]: {
						...productJson,
					},
				},
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byId: {
							...state.status.isFetching.byId,
							[String(id)]: false,
						},
					},
				},
			};
		}
		case ActionTypes.RECEIVE_BULK_PRODUCTS: {
			const { productsJson } = action;
			if (!productsJson) return;
			return {
				...state,
				byId: { ...(state?.byId ?? {}), ...productsJson },
			};
		}
		case ActionTypes.RECEIVE_PRODUCT_CONTENT_TRANSLATED: {
			const { productJson } = action;
			const { id } = productJson;
			const productMap = state?.byIdInEnglish;
			return {
				...state,
				byIdInEnglish: { ...productMap, [String(id)]: productJson },
				status: {
					...state.status,
					isFetching: {
						...state.status.isFetching,
						byId: {
							...state.status.isFetching.byId,
							[String(id)]: false,
						},
					},
				},
			};
		}
		case ActionTypes.RESET_PRODUCT: {
			const { id } = action.payload;
			const product = {
				id: Number(id),
			};
			return {
				...state,

				byId: {
					...state['byId'],
					[String(id)]: product,
				},
			};
		}
		default:
			return state;
	}
};

export const similarProductStore = (state = {}, action: any) => {
	switch (action.type) {
		case ActionTypes.REQUEST_SIMILAR_PRODUCTS: {
			const { id } = action;
			return customMergeDeep(state, {
				...{},
				[id]: {
					productIdList: [],
					isFetching: true,
				},
			});
		}
		case ActionTypes.RECEIVE_SIMILAR_PRODUCTS: {
			const { id, productIdList } = action;
			const finalProductIdList = combineUnique(
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				state?.[id]?.productIdList,
				productIdList,
			);
			return {
				...state,
				[id]: {
					productIdList: finalProductIdList,
					isFetching: false,
				},
			};
		}
		default:
			return state;
	}
};
