export const INPUT_FIELD_IDS = {
	USER_FIELD_PMPCMN_FULL_NAME: 'user_field_PMPCMN_FULL_NAME',
	USER_FIELD_EMAIL: 'user_field_email',
	USER_FIELD_PHONE: 'user_field_phone',
	PAYMENT_FIELD_CARD_NUMBER: 'payment_field_card_number',
	PAYMENT_FIELD_CARD_NAME: 'payment_field_card_name',
	PAYMENT_FIELD_EXP_MONTH: 'payment_field_exp_month',
	PAYMENT_FIELD_EXP_YEAR: 'payment_field_exp_year',
	PAYMENT_FIELD_CVV: 'payment_field_cvv',
};
