import {
	ALGOLIA_API_KEYS,
	DEVICE_TYPE,
	GCLOUD_KEY,
	GOOGLE_AUTH_CLIENT_ID,
	GTM_AUTH_PROD,
	GTM_KEY_EXT,
	GTM_KEY_PROD,
	GTM_KEY_TEST,
	GTM_PREVIEW_PROD,
	LIVE_CHAT_LICENCE_KEY,
	SIFT_JS_KEY,
	SMARTLOOK_KEY,
} from 'Constants/constants';

import { isProductionEnvironment, isTestingEnvironment } from './envUtils';

export const getFBAppId = () => {
	if (isProductionEnvironment()) {
		return '514573102040959';
	} else if (isTestingEnvironment()) {
		return '570705863094349';
	}
	return '570705863094349';
};

export const getFBPageId = () => {
	if (isProductionEnvironment()) {
		return '533524753417048';
	} else if (isTestingEnvironment()) {
		return '212512279281275';
	}
	return '212512279281275';
};

export const getGoogleAppClientId = () => GOOGLE_AUTH_CLIENT_ID;

export const getSmartLookKey = () => SMARTLOOK_KEY;

export const getGTMKey = (deviceType = DEVICE_TYPE.DESKTOP) => {
	return isProductionEnvironment()
		? // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		  GTM_KEY_PROD[deviceType]
		: // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		  GTM_KEY_TEST[deviceType];
};

export const getGTMAuth = () => {
	if (isProductionEnvironment()) {
		return GTM_AUTH_PROD;
	}
	return null;
};

export const getGTMPreview = () => {
	if (isProductionEnvironment()) {
		return GTM_PREVIEW_PROD;
	}
	return null;
};

export const getExternalGTMKey = (host: any) => {
	switch (host) {
		case 'book.tickets.co.uk':
			return GTM_KEY_EXT.LTD;
		default:
			return null;
	}
};

export const getGCloudApiKey = () => {
	if (isProductionEnvironment()) {
		return GCLOUD_KEY.PROD;
	} else if (isTestingEnvironment()) {
		return GCLOUD_KEY.TEST;
	}
	return GCLOUD_KEY.DEV;
};

export const getLiveChatLicenceKey = () => LIVE_CHAT_LICENCE_KEY;

export const getAlgoliaConfig = () => ({
	SEARCH_API: ALGOLIA_API_KEYS.SEARCH_API_KEY,
	APPLICATION_ID: ALGOLIA_API_KEYS.APPLICATION_KEY,
});

export const getSiftAPIKey = () => SIFT_JS_KEY;
