import Router from 'next/router';

const redirectTo = (res: any, destination: any, code = 302) => {
	if (res) {
		res.statusCode = code;
		res.setHeader('Location', destination);
		res.end();
		return {};
	} else {
		Router.push(destination);
	}
};

export { redirectTo };
