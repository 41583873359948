import {
	PersonaActions,
	receiveCategoriesByPersona,
	receivePersonasByCity,
	requestCategoriesByPersona,
	requestPersonasByCity,
} from 'Actions/persona';
import type { TCategory, TReduxState } from 'ReduxTypes/persona';

import { PERSONA_ASSETS_FOLDER_LINK } from 'Constants/constants';

const initialState: TReduxState = {};

export const personaStore = (
	state = initialState,
	{
		payload,
		type,
	}:
		| ReturnType<typeof requestPersonasByCity>
		| ReturnType<typeof receivePersonasByCity>
		| ReturnType<typeof requestCategoriesByPersona>
		| ReturnType<typeof receiveCategoriesByPersona>,
): TReduxState => {
	switch (type) {
		case PersonaActions.REQUEST_PERSONAS_BY_CITY: {
			const { city } = payload;

			return {
				...state,
				[city]: { isFetching: true },
			};
		}

		case PersonaActions.RECEIVE_PERSONAS_BY_CITY: {
			const {
				city,
				response: { personaAffinities },
			} = payload;

			return {
				...state,
				[city]: {
					personaAffinities,
					isFetching: false,
				},
			} as TReduxState;
		}

		case PersonaActions.REQUEST_CATEGORIES_BY_PERSONA: {
			const { city, personaAffinityId } = payload;

			return {
				...state,
				[city]: {
					...state[city],
					[personaAffinityId]: {
						availableCategories: [],
					},
				},
			};
		}

		case PersonaActions.RECEIVE_CATEGORIES_BY_PERSONA: {
			const { city, personaAffinityId, response } = payload;
			const { categories, subCategories } = response;
			const availableCategories: TCategory[] = [
				...categories.map(c => ({ ...c, isCategory: true })),
				...subCategories.map(sc => ({ ...sc, isCategory: false })),
			].sort((a, b) => a.rank - b.rank);

			return {
				...state,
				[city]: {
					...state[city],
					[personaAffinityId]: {
						availableCategories,
					},
				},
			};
		}

		default:
			return state;
	}
};
