import {
	CITIES,
	COOKIE,
	NYC_CONCIERGE_SUPPORT_PHONE_NUMBER,
	UserProfileTypes,
} from 'Constants/constants';

import { getPeopleDescription, getTimeSlot } from './bookingUtils';
import { format, formatTime } from './dateUtils';
import { getTour } from './pricingUtils';

export const isAffiliate = (cookies: any) =>
	cookies &&
	cookies?.[COOKIE.USER_PROFILE_TYPE] ===
		UserProfileTypes.DISTRIBUTION_PARTNER;

/**
 * @param pricing
 * @param booking
 * @returns {boolean} returns true if concierge should be allowed to book, false o.w
 */
export const shouldAllowBooking = (pricing: any, booking: any) => {
	const {
		selectedDate: date,
		selectedTime: time,
		selectedTourId: tourId,
	} = booking;
	// @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
	const timeSlot = getTimeSlot(pricing, { date, time, tourId });

	if (!timeSlot) return true;

	const { isLimited, seatsLeft } = timeSlot?.availability || {};

	return !(isLimited && seatsLeft < 6);
};

/**
 * @param city
 * @returns {string} special phone number for concierges
 */
export const getPhoneNumber = (city: any) => {
	const { cityCode, phoneNumber } = city;
	if (cityCode === CITIES.NEW_YORK) return NYC_CONCIERGE_SUPPORT_PHONE_NUMBER;
	return phoneNumber;
};

/**
 * Opens concierge's mail client with booking request mail content pre filled
 * @param product Product which the concierge is trying to book
 * @param pricing pricing of the above product
 * @param booking Booking
 * @param city City of the product
 */
// @ts-expect-error TS(7006): Parameter 'product' implicitly has an 'any' type.
export const triggerBookingRequestMail = (product, pricing, booking, city) => {
	const { id, name } = product;
	const { displayName: cityName } = city;
	const {
		selectedDate: date,
		selectedTime: time,
		selectedTourId: tourId,
	} = booking;
	const tour = getTour(pricing, tourId);
	const { variantName } = tour;
	const email = 'concierge@headout.com';
	const subject = `Booking required for ${name}, ${cityName}`;
	const tickets = getPeopleDescription(booking, pricing);
	const body = encodeURIComponent(
		[
			`Product ID: ${id}\n`,
			`Product Name: ${name}\n`,
			`City: ${cityName}\n`,
			`Date: ${format(date, 'd mmm, yyyy')}\n`,
			`Time: ${formatTime(time)}\n`,
			`Tour: ${variantName}\n`,
			`Tickets: ${tickets}`,
		].join(''),
	);
	window.open(`mailto:${email}?subject=${subject}&body=${body}`);
};
