export const isBitSet = (n: any, index: any) => {
	return (n & (1 << index)) !== 0;
};

export const numberOfSetBits = (n: any) => {
	let count = 0;
	let copyN = n;
	while (copyN !== 0) {
		copyN &= copyN - 1;
		count++;
	}
	return count;
};

/*
Convets string into 32 bit umber
* */
export const hashCode = (str: any) => {
	let hash = 0;
	if (!str || str.length === 0) return hash;
	for (let i = 0; i < str.length; i++) {
		const char = str.charCodeAt(i);
		hash = (hash << 5) - hash + char;
		hash &= hash; // Convert to 32bit integer
	}
	return Math.abs(hash);
};

export const intInRangeFromString = (string: any, min: any, max: any) => {
	const numberFromString = hashCode(string);
	return (numberFromString % (max - min)) + min;
};
