/******************************
 ** Colors
 *******************************/

const colors = {
	PURPS: '#8000ff', // Purps
	PURPS_DARK: '#6600cc',
	PURPS_LIGHT: '#9933ff',
	HEADOUT_CANDY: {
		DARK_TONE: '#660031',
	},
	BG_PURPS: '#e6d1ff',
	PURPS_BS: 'rgba(128, 0, 255, 0.16)', // Purps variant to be used for box-shadows
	HAWKES_BLUE: '#d8d9de',
	PINK: '#ec1943', // Rhapsody
	WARNING_RED: '#ef0404',
	WARNING_RED_1: '#D60404',
	CANDY_PINK: '#FF007A',
	CANDY_PINK_1: '#D41A73',
	BG_PINK: '#FDE8EC',
	LIGHT_PINK: '#ed5b78',
	DARK_PINK: '#D91239',
	PALE_PINK: '#fde8ec',
	YELLOW: '#ffbb58',
	BACKGROUND_GREEN: '#DBFDDB',
	GREEN: '#08841f',
	GREEN_DARK: '#00793D',
	GREEN_DARK_2: '#0C7943',
	FADED_GREEN: '#F2FDEB',
	LIGHT_GREEN: '#33b459',
	CYAN: '#24a1b2',
	TEXT_BEACH: '#037B95',
	CHARCOAL: '#545454',
	PURPLE: '#7b68db',
	DARK_PURPLE: '#330066',
	WHITE: '#ffffff',
	WHITE_DOT3: 'rgba(255,255,255,0.3)', // DOT ALPHA
	BLACK: '#000',
	SLATE_BLACK: '#111',
	GREY: {
		BA: '#bababa',
		C4: '#c4c4c4',
		DA: '#dadada',
		E2: '#e2e2e2',
		E7: '#e7e7e7',
		EB: '#ebebeb',
		EC: '#ececec',
		F0: '#f0f0f0',
		F3: '#f3f3f3',
		F7: '#f7f7f7',
		F8: '#f8f8f8',
		FLOATING: '#F8F6FF',
		['6C']: '#6c6c6c',
		FA: '#fafafa',
		G3: '#828282',
		G7: '#f0f0f0',
		G8: '#F8F8F8',
		['6A']: '#6a6a6a',
		['9A']: '#9a9a9a',
		['9F']: '#9f9f9f',
		['22']: '#222222',
		['44']: '#444',
		['46']: '#464646',
		['66']: '#666666',
		['74']: '#747474',
		['75']: '#757575',
		['88']: '#888888',
		LIGHTEST: '#d8dadf',
	},
	GREY_DS: {
		G2: '#444444',
		G3: '#666666',
		G4: '#888888',
		G4a: '#9F9F9F',
		G5: '#C4C4C4',
		G7: '#F0F0F0',
		G8: '#F8F8F8',
		G6: '#e2e2e2',
	},
	LIGHT_BLUE: '#d6f8ff',
	DARK_BLUE: '#1A4D57',
	GENTLE_WARN_FG: '#922727',
	GENTLE_WARN_BG: '#FFD8D8',
	GREEN_3: '#088229',
	SOOTHING_GREEN: '#DBFDDB',
	JOY_MUSTARD: '#ffbc00',
	JOY_MUSTARD_3: '#906100',
	JOY_MUSTARD_LIGHT: '#FFF2CC',
	JOY_MUSTARD_LIGHT_2: 'eddcbf',
	OCEAN_BLUE: '#00C4EB',
	BEACH: '#03829D',
	BEACH1: '#037B95',
	WISP_PINK: '#FDE8EC',
	FADED_PALE: '#FFF8EF',
	PEACHY_ORANGE: '#A4563B',
	SOFT_WHITE: '#FFF9FA',
	DS_WARNING_RED: {
		LIGHT_TONE1: '#EF4C4C',
	},
	BRAND_COLORS: {
		WHITE: '#ffffff',
		SLATE_BLACK: '#111111',
		PURPS: '#8000ff',
		HEADOUT_CANDY: '#ff007a',
	},
	PURPS_LEVEL: {
		10: '#F3E9FF',
		15: '#E6D1FF',
		20: '#E6CCFF',
		25: '#DFBFFF',
		30: '#D9B3FF',
		35: '#D2A6FF',
		40: '#CC99FF',
		45: '#CC99FF',
		50: '#C68CFF',
	},
	BLUE_TERTIARY: '#009AB8',
	CANDY_TERTIARY: '#CC0062',
	CANDY_1: '#E5006E',
	OKAY_GREEN: '#13BD68',
	OKAY_GREEN_DARK_TONE: '#095730',
	DADDY: '#DADADA',
	PALE_YELLOW: '#FFF8E5',
	DARK_YELLOW: '#755A0F',
	TRANSPARENT: 'rgba(255, 255, 255, 0)',
	LINK_BLUE: '#0F43BD',
	CONFIRMATION_GREEN: '#34a853',
	SUCCESS_GREEN: '#55ca92',
	BANNER_ORANGE_TEXT: '#e8862b',
	SKELETON_GREY: '#e8e8e8',
	DARK_BLOB_BG: '#FF007A',
	DARK_PURPLE_AVATAR_BG: '#231429',
	DARK_PURPLE_BANNER_BG: '#140029',
	DARK_PURPLE_CARD_BG: '#2e1f3e',
	PALE_BLUE: '#EFF4FF',
	PALE_CYAN: '#EFFCFF',
};

export default colors;
